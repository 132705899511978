import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// css
import '../../../assets/sass/custom.scss';

// json 
// male below 35 
import defaultDiseaseMaleBelow35 from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import happyMaleBelow35 from '../../../assets/json/expression/male/below35/happy.json';
import veryHappyMaleBelow35 from '../../../assets/json/expression/male/below35/very-happy.json';
import sadMaleBelow35 from '../../../assets/json/expression/male/below35/sad.json';
import verySadMaleBelow35 from '../../../assets/json/expression/male/below35/very-sad.json';

// male above 35 
import defaultDiseaseMaleAbove35 from '../../../assets/json/default/male/above35/none-of-the-option-is-selected.json';
import happyMaleAbove35 from '../../../assets/json/expression/male/above35/happy.json';
import veryHappyMaleAbove35 from '../../../assets/json/expression/male/above35/very-happy.json';
import sadMaleAbove35 from '../../../assets/json/expression/male/above35/sad.json';
import verySadMaleAbove35 from '../../../assets/json/expression/male/above35/very-sad.json';

// male above 72 
import defaultDiseaseMaleAbove72 from '../../../assets/json/default/male/above72/none-of-the-option-is-selected.json';
import happyMaleAbove72 from '../../../assets/json/expression/male/above72/happy.json';
import veryHappyMaleAbove72 from '../../../assets/json/expression/male/above72/very-happy.json';
import sadMaleAbove72 from '../../../assets/json/expression/male/above72/sad.json';
import verySadMaleAbove72 from '../../../assets/json/expression/male/above72/very-sad.json';

// // female below 35 
import defaultDiseaseFemaleBelow35 from '../../../assets/json/default/female/below35/none-of-the-option-is-selected.json';
import happyFemaleBelow35 from '../../../assets/json/expression/female/below35/happy.json';
import veryHappyFemaleBelow35 from '../../../assets/json/expression/female/below35/very-happy.json';
import sadFemaleBelow35 from '../../../assets/json/expression/female/below35/sad.json';
import verySadFemaleBelow35 from '../../../assets/json/expression/female/below35/v-sad.json';

// // female above 35 
import defaultDiseaseFemaleAbove35 from '../../../assets/json/default/female/above35/none-of-the-option-is-selected.json';
import happyFemaleAbove35 from '../../../assets/json/expression/female/above35/happy.json';
import veryHappyFemaleAbove35 from '../../../assets/json/expression/female/above35/very-happy.json';
import sadFemaleAbove35 from '../../../assets/json/expression/female/above35/sad.json';
import verySadFemaleAbove35 from '../../../assets/json/expression/female/above35/very-sad.json';

// // female above 72
import defaultDiseaseFemaleAbove72 from '../../../assets/json/default/female/above72/none-of-the-option-is-selected.json';
import happyFemaleAbove72 from '../../../assets/json/expression/female/above72/happy.json';
import veryHappyFemaleAbove72 from '../../../assets/json/expression/female/above72/very-happy.json';
import sadFemaleAbove72 from '../../../assets/json/expression/female/above72/sad.json';
import verySadFemaleAbove72 from '../../../assets/json/expression/female/above72/very-sad.json';

// import RangeSlider from '../../shared/RangeSlider/RangeSlider';
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';


function Cholesterol2() {
    const { t, i18n } = useTranslation();

    let navigate = useNavigate()

    let [TotalCholestrol, setTotalCholestrol] = useState(50);
    let [ldl, setLdl] = useState(50)
    let [hdl, setHdl] = useState(50)
    let [errorMsg, setErrorMsg] = useState({ TotalCholestrol: "", ldl: "", hdl: "" })
    let [events, setEvents] = useState("")
    const [lottiePlayer, setLottiePlayer] = useState("");

    let contentData = {
        options: [
            {
                radio_button: [
                    {
                        rId: 1, radio_text: 'Happy', gender: {
                            Male: {
                                default: {
                                    below35: defaultDiseaseMaleBelow35,
                                    above35: defaultDiseaseMaleAbove35,
                                    above72: defaultDiseaseMaleAbove72,
                                },
                                below35: happyMaleBelow35,
                                above35: happyMaleAbove35,
                                above72: happyMaleAbove72
                            },
                            Female: {
                                default: {
                                    below35: defaultDiseaseFemaleBelow35,
                                    above35: defaultDiseaseFemaleAbove35,
                                    above72: defaultDiseaseFemaleAbove72,
                                },
                                below35: happyFemaleBelow35,
                                above35: happyFemaleAbove35,
                                above72: happyFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 2, radio_text: 'Very Happy', gender: {
                            Male: {
                                below35: veryHappyMaleBelow35,
                                above35: veryHappyMaleAbove35,
                                above72: veryHappyMaleAbove72
                            },
                            Female: {
                                below35: veryHappyFemaleBelow35,
                                above35: veryHappyFemaleAbove35,
                                above72: veryHappyFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 3, radio_text: 'Sad', gender: {
                            Male: {
                                below35: sadMaleBelow35,
                                above35: sadMaleAbove35,
                                above72: sadMaleAbove72
                            },
                            Female: {
                                below35: sadFemaleBelow35,
                                above35: sadFemaleAbove35,
                                above72: sadFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 4, radio_text: 'Very Sad', gender: {
                            Male: {
                                below35: verySadMaleBelow35,
                                above35: verySadMaleAbove35,
                                above72: verySadMaleAbove72
                            },
                            Female: {
                                below35: verySadFemaleBelow35,
                                above35: verySadFemaleAbove35,
                                above72: verySadFemaleAbove72
                            }
                        }
                    },
                ]
            }
        ]
    }

    const handle_var = document.getElementsByClassName('rangeslider__handle-label');

    const handleTotChol = (value) => {
        let ttlChl = value
        TotalCholestrol = ttlChl
        setTotalCholestrol(TotalCholestrol)
        setErrorMsg({ ...errorMsg, TotalCholestrol: "" })
        setEvents(!events)
        window.localStorage.setItem('total-cholestrol', JSON.stringify(TotalCholestrol))
        handle_var[0].innerHTML = TotalCholestrol
    }
    const HandleLdl = (value) => {
        let ldlVal = value
        ldl = ldlVal
        setLdl(ldl)
        setErrorMsg({ ...errorMsg, ldl: "" })
        setEvents(!events)
        window.localStorage.setItem('ldl', JSON.stringify(ldl))
        handle_var[1].innerHTML = ldl
    }
    const handleHbl = (value) => {
        let hdlVal = value
        hdl = hdlVal
        setHdl(hdl)
        setErrorMsg({ ...errorMsg, hdl: "" })
        setEvents(!events)
        window.localStorage.setItem('hdl', JSON.stringify(hdl))
        handle_var[2].innerHTML = hdl
    }

    const handleSubmit = () => {
        const TotalCholestrol = window.localStorage.getItem('total-cholestrol')
        const ldl = window.localStorage.getItem('ldl')
        const hdl = window.localStorage.getItem('hdl')
        if (!TotalCholestrol && !ldl && !hdl) {
            setErrorMsg({ ...errorMsg, TotalCholestrol: t('Messages.Cholesterol2.1'), ldl: t('Messages.Cholesterol2.2'), hdl: t('Messages.Cholesterol2.3') })
        } else if (TotalCholestrol && ldl && hdl) {
            navigate(`${homePageUrl}blood-pressure`)
        } else if (!TotalCholestrol || JSON.parse(TotalCholestrol) === null || JSON.parse(TotalCholestrol) === undefined) {
            setErrorMsg({ ...errorMsg, TotalCholestrol: t('Messages.Cholesterol2.1') })
        } else if (!ldl || JSON.parse(ldl) === null || JSON.parse(ldl) === undefined) {
            setErrorMsg({ ...errorMsg, ldl: t('Messages.Cholesterol2.2') })
        } else if (!hdl || JSON.parse(hdl) === null || JSON.parse(hdl) === undefined) {
            setErrorMsg({ ...errorMsg, hdl: t('Messages.Cholesterol2.3') })
        }
    }

    useEffect(() => {
        const TotalCholestrol = JSON.parse(window.localStorage.getItem('total-cholestrol'))
        const ldl = JSON.parse(window.localStorage.getItem('ldl'))
        const hdl = JSON.parse(window.localStorage.getItem('hdl'))
        setTotalCholestrol(TotalCholestrol)
        setLdl(ldl)
        setHdl(hdl)
        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        let sadData = [(TotalCholestrol <= 39), (TotalCholestrol >= 201 && TotalCholestrol <= 225), (ldl <= 19), (ldl >= 131 && ldl >= 160), (hdl >= 51 && hdl <= 59)]
        let vSadData = [(TotalCholestrol >= 226), (ldl >= 161), (hdl <= 30 && hdl >= 50)]

        let sadCount = sadData.filter(rep => rep == true).length
        let vSadCount = vSadData.filter(rep => rep == true).length
        console.log(sadCount)
        console.log(vSadCount)

        if (JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en") {
            let lang = "en";
            handleClick(lang);
        }
        else {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
        if (TotalCholestrol == null && ldl == null && hdl == null) {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
            console.log('default')
        }
        console.log(TotalCholestrol)
        console.log(ldl)
        console.log(hdl)
    }, [])

    useEffect(() => {
        const TotalCholestrol = JSON.parse(window.localStorage.getItem('total-cholestrol'))
        const ldl = JSON.parse(window.localStorage.getItem('ldl'))
        const hdl = JSON.parse(window.localStorage.getItem('hdl'))
        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        let sadData = [(TotalCholestrol >= 201 && TotalCholestrol <= 225), (ldl >= 131 && ldl >= 160), (hdl >= 51 && hdl <= 59)]
        let vSadData = [(TotalCholestrol >= 226), (ldl >= 161), (hdl >= 10 && hdl <= 50)]

        let sadCount = sadData.filter(rep => rep == true).length
        let vSadCount = vSadData.filter(rep => rep == true).length

        console.log(sadCount)
        console.log(vSadCount)
        if (TotalCholestrol == null && ldl == null && hdl == null) {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
            console.log('default')
        } else if (sadCount == 0 && vSadCount == 0) {
            let lottie = contentData.options[0].radio_button[0].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            console.log(lottie, 'happy')
            setLottiePlayer(lottie)
        } else if ((sadCount >= 1) && (vSadCount == 0)) {
            let lottie = contentData.options[0].radio_button[2].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            console.log(lottie, 'sad')
            setLottiePlayer(lottie)
        } else if (vSadCount >= 1) {
            let lottie = contentData.options[0].radio_button[3].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            console.log(lottie, 'v sad')
            setLottiePlayer(lottie)
        }
    }, [events])

    const handleClick = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t)
    }

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className='align-items-center'>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('Cholesterol2.heading')}
                                        </h2>
                                        <p className="subheading">
                                            {t('Cholesterol2.subheading')}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('Cholesterol2.heading')}
                                        </h2>
                                        <p className="subheading">
                                            {t('Cholesterol2.subheading')}
                                        </p>
                                    </div>
                                }
                                <div className="bs-form typ-range">
                                    <p className="cm-para typ-small typ-black mb-10">
                                        {t('Cholesterol2.SelectTotal')}
                                    </p>
                                    <Slider
                                        value={JSON.parse(window.localStorage.getItem('total-cholestrol')) == null ? 150 : TotalCholestrol}
                                        orientation="horizontal"
                                        onChange={handleTotChol}
                                        min={40}
                                        max={300}
                                        tooltip={false}
                                        labels={{ 0: '40 mg/dl', 100: '300+ mg/dl' }}
                                        handleLabel={JSON.parse(window.localStorage.getItem('total-cholestrol')) == null ? 150 : TotalCholestrol}
                                    />
                                </div>
                                {errorMsg.TotalCholestrol && <p className='error-message'>{errorMsg.TotalCholestrol}</p>}
                                <div className="bs-form typ-range">
                                    <p className="cm-para typ-small typ-black mb-10">
                                        {t('Cholesterol2.SelectLDL')}
                                    </p>
                                    <Slider
                                        value={JSON.parse(window.localStorage.getItem('ldl')) == null ? 80 : ldl}
                                        orientation="horizontal"
                                        onChange={HandleLdl}
                                        min={20}
                                        max={300}
                                        tooltip={false}
                                        labels={{ 0: '20 mg/dl', 100: '300+ mg/dl' }}
                                        handleLabel={JSON.parse(window.localStorage.getItem('ldl')) == null ? 80 : ldl}
                                    />
                                </div>
                                {errorMsg.ldl && <p className='error-message'>{errorMsg.ldl}</p>}
                                <div className="bs-form typ-range">
                                    <p className="cm-para typ-small typ-black mb-10">
                                        {t('Cholesterol2.SelectHDL')}
                                    </p>
                                    <Slider
                                        value={JSON.parse(window.localStorage.getItem('hdl')) == null ? 60 : hdl}
                                        orientation="horizontal"
                                        onChange={handleHbl}
                                        min={10}
                                        max={200}
                                        tooltip={false}
                                        labels={{ 0: '10 mg/dl', 100: '200+ mg/dl' }}
                                        handleLabel={JSON.parse(window.localStorage.getItem('hdl')) == null ? 60 : hdl}
                                    />
                                </div>
                                {errorMsg.hdl && <p className='error-message'>{errorMsg.hdl}</p>}
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prev={`${homePageUrl}cholesterol`} submit next={handleSubmit} />
            <Footer />
        </div>
    );
}

export default Cholesterol2;