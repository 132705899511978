import React, { useEffect, useState } from 'react';
import { Container, Progress } from 'reactstrap';
import { Link } from "react-router-dom";

import Logo from '../../../assets/images/logo.svg';
import { homePageUrl } from '../../../helper/ContentConstants';

function Header(props) {
	return (
		<Container>
			<div className='bs-header'>
				<div className='logo-box'>
					<img src={Logo} alt="Logo" />
				</div>

				{props.whatsappScreen ? null :
					<div className='bs-progressbar'>
						<Progress value={props.barValue} />
					</div>
				}

				{props.dashboardScreen &&
					<div>
						<Link className='bs-btn typ-transparent' to={`${homePageUrl}subscription-home`}>
							<span class="icon icon-clock"></span> Dashboard
						</Link>
					</div>
				}
			</div>
		</Container>
	)
}

export default Header;