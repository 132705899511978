import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Row, Col, FormGroup, Label, Input, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';


// css
import '../../../assets/sass/custom.scss';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';


function TermsCondition() {

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on typ-subscription-main'>
                        <Row>
                            <Col xxl={12} xl={12} md={12}>
                                <Link className='back-btn' to={`${homePageUrl}`}><span class="icon icon-prev-arrow"></span>Back</Link>
                            </Col>
                        </Row>
                        <Breadcrumb className='bs-breadcrumb'>
                            <BreadcrumbItem><Link to={`${homePageUrl}`}>Home</Link></BreadcrumbItem>
                            <BreadcrumbItem active>Terms & Condition</BreadcrumbItem>
                        </Breadcrumb>
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12}>
                                <div className="bs-heading typ-center">
                                    <h2 className="heading">
                                        Terms & Condition
                                    </h2>
                                </div>
                                <div className='bs-content'>
                                    <p ><strong><span >Terms of Usage</span></strong></p>
                                    <p ><span >The following is the Privacy Policy &amp; Terms of Usage, read together with the Privacy Policy, that govern your purchase and use of the products and services (the &ldquo;Products&rdquo;) from Heart Health Meter, and constitute a legally binding agreement, between you (&ldquo;the Customer&rdquo;, the &ldquo;User&rdquo; or &ldquo;the Caregiver&rdquo;) and Heart Health Meter app.</span></p>
                                    <p ><span >In terms of Information Technology Act, 2000, this document is an electronic record. Being generated by a computer system it does not require any physical or digital signatures.</span></p>
                                    <p ><span >&nbsp;</span></p>
                                    <p ><span >This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of &lsquo;The App&rsquo;.</span></p>
                                    <p ><span >&nbsp;</span></p>
                                    <p ><span >The Mobile Application Heart Health Meter (hereinafter referred to as &lsquo;The App&rsquo;) belongs to Vaidya Sane Ayurved Laboratories Pvt Ltd having registered office at S.no. 81/1, Plot No.7, Rutuja Park, Orchid School lane, Balewadi Phata, Baner, Pune-411037 Maharashtra, India and its Corporate Office at 701, Ishan Arcade Tower No. 2, Gokhale Road, Naupada, Thane (W) 400602, Maharashtra, India (hereinafter referred to as &lsquo;Company&rsquo;)and it&rsquo;s Brand-Madhavbaug</span><sup><span >TM</span></sup><span >.</span></p>
                                    <p ><span >&nbsp;</span></p>
                                    <p ><span >For the purpose of these Terms of Service, wherever the context so requires the term &lsquo;You&rsquo;/ &lsquo;User&rsquo; shall mean any natural or legal person who has downloaded and installed &lsquo;The App&rsquo; and provided Registration Data while registering on &lsquo;The App&rsquo;. Any services provided by &lsquo;The App&rsquo; which do not require registration do not absolve you of the contractual relationship established by this Agreement.</span></p>
                                    <p ><span >&nbsp;</span></p>
                                    <p ><span >Wherever the context requires, the term &lsquo;Doctor/registered medical practitioners&rsquo; shall mean any person permitted to practice medicine by the MCI (Medical Council of India) or any regional council for registration of Doctor/registered medical practitioners duly authorized by the government who will be verified and authenticated by us.</span></p>
                                    <p ><span >&nbsp;</span></p>
                                    <p ><span >By downloading and installing &lsquo;The App&rsquo;, you are bound by these Terms of Service and any other binding document as decided by the Company. You are requested to read them carefully before accepting the same and moving forward to using &lsquo;the App&rsquo;.</span></p>
                                    <p ><span >&nbsp;</span></p>
                                    <p ><span >By visiting, viewing, accessing or otherwise using any of the services or information created, collected, compiled or submitted to &lsquo;The App&rsquo;, you are deemed to have agreed to all the following Terms of Usage of Sale/Service. So, before you use &lsquo;The App&rsquo;, please read these Terms carefully as you agree to be bound by them. If you do not accept these Terms and Conditions, please do not use &lsquo;The App&rsquo;.</span></p>
                                    <p ><span >&nbsp;</span></p>
                                    <ol>
                                        <li><span ><span > </span></span><strong><span >CREATING AN ACCOUNT</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >To use certain features of &lsquo;The App&rsquo; (e.g., ordering Products, posting rating/reviews, receiving Madhavbaug</span><sup><span >TM</span></sup><span >&rsquo;s e-newsletters), you must set up an account with &lsquo;The App&rsquo; and provide certain information about yourself as prompted by the Customer Information form, including, your name, gender, email address, account password, mobile phone number and billing/shipping address. All of your registration information is protected by our Privacy Policy.</span></li>
                                        <li><span ><span > </span></span><span >You represent and warrant that the information you submit during registration is truthful and accurate and that you will maintain the accuracy of such information.</span></li>
                                        <li><span ><span > </span></span><span >Your Heart Health Meter username and password are personal to you. You may not transfer your account and you will be responsible for the activities associated with your Heart Health Meter Account.</span></li>
                                        <li><span ><span > </span></span><span >Heart Health Meter will not be liable for any loss or damages as a result of your failure to maintain the confidentiality of your account credentials. If you suspect any unauthorised use of your Heart Health Meter account, you shall immediately notify the same to the email ID&nbsp;</span><a href="mailto:info@Madhavbaug.org"><span >info@Madhavbaug.org</span></a><strong><span >.</span></strong></li>
                                        <li><span ><span > </span></span><span >It is your responsibility to keep your email address up-to-date on your account setup at &lsquo;The App&rsquo; so that we can communicate with you electronically.</span></li>
                                        <li><span ><span > </span></span><span >By creating this account you agree to receive communications from us via email, telephone, SMS and chat. If you, at any time, wish to discontinue receiving communications from us, you agree to notify us by email.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="2">
                                        <li><span ><span > </span></span><strong><span >COMMUNICATIONS</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >By using &lsquo;The App&rsquo;, it is deemed that you have consented to receiving calls, auto-dialled and/or pre-recorded messages or calls, from us at any time with the use of the telephone number that has been provided by you for the use of this App, subject to the Privacy Policy. This includes contacting you through information received through other parties as well as contacting other parties through the information you provide to us. The use of this App you also consent to receive SMS from us at any time we deem fit. This consent to be contacted is for purposes that include and are not limited to clarification calls and marketing and promotional calls. In case you wish to stop the contact with us for the same, you may send us a mail to the effect at&nbsp;</span><a href="mailto:info@madhavbaug.org"><span >info@madhavbaug.org</span></a><span >.</span></li>
                                        <li><span ><span > </span></span><span >You may also be contacted by Service Providers with whom we have entered into a contract in furtherance of our rights, duties and obligations under other agreements, contracts and policies followed by us. Such contact will be made only in pursuance of the respective contracts, agreements and/or policies.</span></li>
                                        <li><span ><span > </span></span><span >The sharing of the information provided by you will be governed by the Privacy Policy. We will not give out your contact information to third parties not connected with &lsquo;The App&rsquo; and the services offered by it, and/or for any purpose not in furtherance of the activities and aims of the Company. Please refer to the Privacy Policy for more information.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="3">
                                        <li><span ><span > </span></span><strong><span >SECURITY POLICY</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >All your transactions are secure in &lsquo;The App&rsquo;. Your personal information is encrypted by using 256-bit Secure Sockets Layer (SSL) encryption technology before being sent over the Internet, which ensures the privacy and high level of security of all your information.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="4">
                                        <li><span ><span > </span></span><strong><span >CUSTOMER MEMBERSHIP / USAGE / ELIGIBILITY&nbsp;</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >The membership / usage of &lsquo;The App&rsquo; is restricted to those above 18 years of age, or to such group that can be termed to have attained &lsquo;majority&rsquo; under the laws of the respective jurisdictions where &lsquo;The App&rsquo; can be seen and is accessible or to emancipated minors, who possess legal parental or guardian consent, or are in a position so as to be fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms of Service, and to abide by and comply with these Terms of Service.</span></li>
                                        <li><span ><span > </span></span><span >Membership cannot be availed of by those who are deemed Incompetent to Contract under the Indian Contract Act, 1872 subject to the aforementioned conditions.</span></li>
                                        <li><span ><span > </span></span><span >Further, you are solely responsible for protecting the confidentiality of your username and password and any activity under the account will be deemed to have been done by you. In the case that you provide us with false and inaccurate details or the Company has reasonable reasons to believe you have done so, we hold the rights to suspend your account and/or terminate this Agreement without any notice and without being held liable for the same.</span></li>
                                        <li><span ><span > </span></span><span >You understand &lsquo;The App&rsquo; downloaded to your phone or tablet is owned by us and we may automatically upgrade &lsquo;The App&rsquo; and these Terms shall apply to the upgrades as well. We reserve the right to terminate this agreement without any notice and suspend the account without assigning any reason.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="5">
                                        <li><span ><span > </span></span><strong><span >PRICE AND PAYMENT</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >The installation and use of &lsquo;The App&rsquo; is free of cost and this includes only the usage of certain services of &lsquo;The App&rsquo;. You hereby understand that this no charge policy maybe amended at any time at the discretion of the Company. However, in the event, you use &lsquo;The App&rsquo; to choose a Doctor / registered medical practitioners and book an appointment, you shall be required to pay for the consultation. In case of video or phone consult, you shall be required to pay for the consultation prior and in the case of clinic consult, the patient shall pay the Doctor/registered medical practitioners after the consult.</span></li>
                                        <li><span ><span > </span></span><span >For the purpose of this section, Buyer would include any user choosing to use the services offered on &lsquo;The App&rsquo;. Seller means Us/App/Company.</span></li>
                                        <li><span ><span > </span></span><span >While availing any of the payment methods available on &lsquo;The App&rsquo;, We will not be responsible or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to you due to &ndash;</span></li>
                                        <li><span ><span > </span></span><span >Lack of authorization for any transaction(s), or</span></li>
                                        <li><span ><span > </span></span><span >Exceeding the preset limit mutually agreed by you and between &lsquo;Bank(s)&rsquo;, or</span></li>
                                        <li><span ><span > </span></span><span >Any payment issues arising out of the transaction, or</span></li>
                                        <li><span ><span > </span></span><span >Decline of transaction for any other reason(s).</span></li>
                                        <li><span ><span > </span></span><span >All payments made against the services on &lsquo;The App&rsquo; by you shall be compulsorily in Indian Rupees. &lsquo;The App&rsquo; will not facilitate transaction with respect to any other form of currency with respect to the purchases made on &lsquo;The App&rsquo;.</span></li>
                                        <li><span ><span > </span></span><span >All our product prices include all applicable statutory taxes.</span></li>
                                        <li><span ><span > </span></span><span >We make every effort to make sure that the pricing and availability of Products on &lsquo;The App&rsquo; is accurate and up to date. However, rarely, there may be an error on the pricing of a product or an error related to product availability. In such cases, we are not responsible for any typographical errors and we reserve the right to cancel the sale.</span></li>
                                        <li><span ><span > </span></span><span >We reserve the right to correct any inaccuracies or omissions related to pricing and product availability / descriptions, even after you have submitted your order, and to change or update any other information at any time without prior notice.</span></li>
                                        <li><span ><span > </span></span><span >Following are &lsquo;The App&rsquo; delivery charges, which are based on the order value &ndash;</span>
                                            <ul>
                                                <li><span ><span > </span></span><span >Minimum order value is Rs 1,000/-</span></li>
                                                <li><span ><span > </span></span><span >Orders equal and or above Rs 1,000/- will have standard Delivery charges of Rs 80/- per order.</span></li>
                                                <li><span ><span > </span></span><span >Delivery charges quoted above are applicable on ALL Orders in &lsquo;The App&rsquo;.</span></li>
                                            </ul>
                                        </li>
                                        <li><span ><span > </span></span><span >Delivery times may vary depending on the delivery location as well as the type of product you order.</span></li>
                                        <li><span ><span > </span></span><span >You can make the payment via any one of the methods as &ndash; Credit / Debit Card, Net Banking.</span></li>
                                        <li><span ><span > </span></span><span >Credit / Debit Card and Net Banking Payments are processed via our online payment service partners.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="6">
                                        <li><span ><span > </span></span><strong><span >USE OF INFORMATION ON &lsquo;THE APP&rsquo;</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >The information on &lsquo;The App&rsquo; is not a substitute for professional medical advice or treatment for specific medical conditions. It is important that you do not make medical decisions, and you should always seek the advice from qualified healthcare professional with any questions you may have about your medical condition.</span></li>
                                        <li><span ><span > </span></span><span >&lsquo;The App&rsquo; does not endorse any views or opinions that may be included in &lsquo;The App&rsquo; Content, or other information provided through our Services. Your submission of questions or reviews on our Products does not constitute a professional relationship between you and &lsquo;The App&rsquo;.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="7">
                                        <li><span ><span > </span></span><strong><span >THIRD PARTY INFORMATION</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >The content on &lsquo;The App&rsquo; which includes all text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, design documents, and artwork is our content (hereinafter referred to as &lsquo;Content&rsquo;). &lsquo;The App&rsquo; may contain links to / content of third party Websites that are not associated with us. We have no control over any third party user generated content as we are merely an intermediary for the purposes of the content. In the event, if any of the Third Party Content infringes any Intellectual Property of any person, the Third Party shall be solely responsible for any loss caused and we shall not be liable. You may send as an email at&nbsp;</span><a href="mailto:info@madhavbaug.org"><span >info@madhavbaug.org</span></a><span >to report any such content.</span></li>
                                        <li><span ><span > </span></span><span >Other than that when expressly allowed, any use of our Content and it being reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including &ldquo;mirroring&rdquo;) to any other computer, server or other medium for publication or distribution or for any commercial enterprise, without our express prior written consent is not allowed.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="8">
                                        <li><span ><span > </span></span><strong><span >USER CONTENT</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >The content that you upload or post will become our property and you grant us the worldwide, perpetual and transferable rights in such Content. We shall be entitled to, consistent with our Privacy Policy as adopted in accordance with applicable law, use the Content or any of its elements for any type of use forever, including but not limited to promotional and advertising purposes and in any media whether now known or hereafter devised, including the creation of derivative works that may include any content you provide and are not entitled to any payment or other compensation for such use. You also grant us the right to sub-license these rights, and the right to bring an action for infringement of these rights. We reserve the right to edit or remove any material submitted to this App, or stored on our servers, or hosted or published upon &lsquo;The App&rsquo;.</span></li>
                                        <li><span ><span > </span></span><span >Those posting any form of User content on &lsquo;The App&rsquo; must verify the truthfulness and authenticity of the particulars of the content, including the time, place and nature. Before allowing such content to be posted on &lsquo;The App&rsquo;, we have the right and authority to verify the accuracy of such particulars related to the content with the respective sources provided by the user posting such content, if we feel that there is a need to check such accuracy. We have the right to upload content on behalf of third parties, subsequent to collecting such information and verifying it if we deem it necessary. Notwithstanding this, we can in no way be held liable for any false or misleading information.</span></li>
                                        <li><span ><span > </span></span><span >In case any content is considered to be unlawful or against the law within any jurisdiction in which &lsquo;The App&rsquo; can be seen and accessed, it shall be removed forthwith by us on being intimated by the authorities of the respective jurisdiction that such content is deemed unlawful. We cannot be held liable or questioned for the same.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol >
                                        <li><strong><span >USER OBLIGATIONS</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >You are bound not to cut, copy, distribute, modify, recreate, reverse engineer, distribute, disseminate post, publish or create derivative works from, transfer, or sell any information or software obtained from &lsquo;The App&rsquo;. With our prior permission limited use may be allowed. For the removal of doubt, it is clarified that unlimited or wholesale reproduction, copying of the content for commercial or non-commercial purposes and unwarranted modification of data and information within the content of &lsquo;The App&rsquo; is not permitted. You shall not, nor allow third parties on your behalf to (i) make and distribute copies of &lsquo;The App&rsquo; (ii) attempt to copy, reproduce, alter, modify, reverse engineer, disassemble, decompile, transfer, exchange or translate &lsquo;The App&rsquo; or (iii) create derivative works of &lsquo;The App&rsquo; of any kind whatsoever.</span></li>
                                        <li><span ><span > </span></span><span >You agree not to access (or attempt to access) &lsquo;The App&rsquo; and/or the materials or Services by any means other than through the interface that is provided by &lsquo;The App&rsquo;. The use of deep-link, robot, spider or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of &lsquo;The App&rsquo; or Content, or in any way reproduce or circumvent the navigational structure or presentation of &lsquo;The App&rsquo;, materials or any Content, to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through &lsquo;The App&rsquo;. You acknowledge and agree that by accessing or using &lsquo;The App&rsquo; or its services; you may be exposed to content from other users that you may consider offensive, indecent or otherwise objectionable. We disclaim all liabilities arising in relation to such offensive content on &lsquo;The App&rsquo;. Further, you may report such offensive content by sending us an e-mail to our official e-mail address.</span></li>
                                        <li><span ><span > </span></span><span >&lsquo;The App&rsquo; by nature allows you to upload information; you undertake to ensure that such material is in accordance with applicable laws. Further, you undertake not to &ndash;</span></li>
                                        <li><span ><span > </span></span><span >Abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise violate the legal rights of others;</span></li>
                                        <li><span ><span > </span></span><span >Engage in any activity that interferes with or disrupts access to &lsquo;The App&rsquo; or the Services (or the servers and networks which are connected to &lsquo;The App&rsquo;);</span></li>
                                        <li><span ><span > </span></span><span >Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;</span></li>
                                        <li><span ><span > </span></span><span >Publish, post, disseminate, any information which is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another&rsquo;s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever; or unlawfully threatening or unlawfully harassing including but not limited to &ldquo;indecent representation of women&rdquo; within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</span></li>
                                        <li><span ><span > </span></span><span >Post any file that infringes the copyright, patent or trademark of other legal entities.</span></li>
                                        <li><span ><span > </span></span><span >Upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of &lsquo;The App&rsquo; or another&rsquo;s computer;</span></li>
                                        <li><span ><span > </span></span><span >Download any file posted by another user of a Service that you know, or reasonably should know, cannot be legally distributed in such manner;</span></li>
                                        <li><span ><span > </span></span><span >Probe, scan or test the vulnerability of &lsquo;The App&rsquo; or any network connected to &lsquo;The App&rsquo;, breach the security or authentication measures on &lsquo;The App&rsquo; or any network connected to &lsquo;The App&rsquo;. You may not reverse look-up, trace or seek to trace any information on any other user, of or visitor to &lsquo;The App&rsquo;, or any other customer of &lsquo;The App&rsquo;, including any account not owned by you, to its source, or exploit &lsquo;The App&rsquo; or Service or information made available or offered by or through &lsquo;The App&rsquo;, in any way whether or not the purpose is to reveal any information, including but not limited to personal identification information, other than your own information, as provided for by &lsquo;The App&rsquo;;</span></li>
                                        <li><span ><span > </span></span><span >Disrupt or interfere with the security of, or otherwise cause harm to &lsquo;The App&rsquo;, systems resources, accounts, passwords, servers or networks connected to or accessible through &lsquo;The App&rsquo; or any affiliated or linked sites;</span></li>
                                        <li><span ><span > </span></span><span >Collect or store data about other users in connection with the prohibited conduct and activities set forth in this Section.</span></li>
                                        <li><span ><span > </span></span><span >Use &lsquo;The App&rsquo; or any material or Content for any purpose that is unlawful or prohibited by these Terms of Service, or to solicit the performance of any illegal activity or other activity which infringes the rights of this App or other third parties;</span></li>
                                        <li><span ><span > </span></span><span >Violate any code of conduct or other guidelines, which may be applicable for or to any particular Service;</span></li>
                                        <li><span ><span > </span></span><span >Violate any applicable laws or regulations for the time being in force within India;</span></li>
                                        <li><span ><span > </span></span><span >Violate the Terms of Service including but not limited to any applicable Additional Terms of &lsquo;The App&rsquo; contained herein or elsewhere;</span></li>
                                        <li><span ><span > </span></span><span >Violate any code of conduct or other guidelines, which may be applicable for or to any particular Service;</span></li>
                                        <li><span ><span > </span></span><span >Threaten the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</span></li>
                                        <li><span ><span > </span></span><span >Publish, post, disseminate information that is false, inaccurate or misleading; violate any applicable laws or regulations for the time being in force in or outside India;</span></li>
                                        <li><span ><span > </span></span><span >Directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force.</span></li>
                                        <li><span ><span > </span></span><span >Create liability for us or cause us to lose (in whole or in part) the services of our internet service provider (&ldquo;ISPs&rdquo;) or other suppliers;</span></li>
                                        <li><span ><span > </span></span><span >While we shall not be held liable or answerable to Users for any such acts on your part, violation of this clause would lead to potential criminal legal action against you, either by other Users of &lsquo;The App&rsquo; or by us.</span></li>
                                        <li><span ><span > </span></span><span >We can (and You hereby expressly authorize Us to) disclose any information about you to law enforcement or other government officials, as we, in our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury. You understand that we have the right at all times to disclose any information (including the identity of the persons providing information or materials on &lsquo;The App&rsquo;) as necessary to satisfy any law, regulation or valid governmental request. This may include, without limitation, disclosure of the information in connection with investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court order or subpoena.</span></li>
                                        <li><span ><span > </span></span><span >We have no obligation, to monitor the material posted on &lsquo;The App&rsquo;. We shall have the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms of Service. Notwithstanding this right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON &lsquo;THE APP&rsquo;. In no event shall we assume or have any responsibility or liability for any Content posted or for any claims, damages or losses resulting from use of Content and/or appearance of Content on &lsquo;The App&rsquo;. You hereby represent and warrant that you have all necessary rights in and to all Content which you provide and all information it contains and that such Content shall not infringe any proprietary or other rights of third parties or contain any libellous, tortuous, or otherwise unlawful information.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="10">
                                        <li><span ><span > </span></span><strong><span >DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >All information, Products and services included on or otherwise made available to you through this App are provided by &lsquo;The APP&rsquo; with Madhavbaug</span><sup><span >TM</span></sup><span >Clinics &amp; Hospitals &ndash; initiative of Vaidya Sane Ayurved Lab Pvt Ltd &amp; it&rsquo;s franchisees on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis, either expressed or implied, we specifically disclaim warranties of any kind to the extent allowed by the applicable law. You expressly agree that your use of &lsquo;The App&rsquo; is at your sole risk.</span></li>
                                        <li><span ><span > </span></span><span >&lsquo;The App&rsquo; assumes no responsibility for any damages or viruses that may infect your computer equipment or other property on account of your access to, use of, or browsing in this site.</span></li>
                                        <li><span ><span > </span></span><span >&lsquo;The App&rsquo; has exerted reasonable efforts to ensure that all information published on &lsquo;The App&rsquo; is accurate at the time of posting; however, there may be errors in such information for which we shall have no liability. We reserve the right to remove or alter any of the information contained on &lsquo;The App&rsquo; at our sole discretion.</span></li>
                                        <li><span ><span > </span></span><span >&lsquo;The App&rsquo; cannot guarantee the adequacy, currency or completeness of &lsquo;The App&rsquo; content. &lsquo;The App&rsquo; does not warrant or endorse the effectiveness, quality or safety of the Products available on it.</span></li>
                                        <li><span ><span > </span></span><span >We disclaim responsibility for any harm to persons resulting from any instructions or Products referred to in &lsquo;The App&rsquo;.</span></li>
                                        <li><span ><span > </span></span><span >Please note that while &lsquo;The App&rsquo; has made every effort to accurately display the colours of products on its App, the actual colour you see will depend on your monitor. We cannot guarantee that your monitor&rsquo;s display of any colour will be the same as the colour of the Products delivered to you.</span></li>
                                        <li><span ><span > </span></span><span >We may let you view our information and communicate with us through the Social Media services such as Face book and Twitter. &lsquo;The App&rsquo; explicitly disclaims any responsibility for the terms of use and privacy policies that govern these third-party Websites, which are in no way associated with us.</span></li>
                                        <li><span ><span > </span></span><span >&lsquo;The App&rsquo; accepts no responsibility for any loss or damage suffered due to your reliance on the product reviews posted by &lsquo;The App&rsquo; Users.</span></li>
                                        <li><span ><span > </span></span><span >&lsquo;The App&rsquo; reserves the right to modify or withdraw any part of &lsquo;The App&rsquo; or any of its content at any time without notice.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="11">
                                        <li><span ><span > </span></span><strong><span >APP AVAILABILITY</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >We take all reasonable care to ensure the availability of &lsquo;The App&rsquo; 24 hours every day, 365 days per year. However, &lsquo;The App&rsquo; may become temporarily unavailable due to maintenance, server or other technical issues, or for reasons beyond our control. &lsquo;The App&rsquo; does not warrant uninterrupted access to this App or any linked App. However, we may, but shall not be obliged to, issue a notice when we know of scheduled maintenance of &lsquo;The App&rsquo;.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="12">
                                        <li><span ><span > </span></span><strong><span >LINKS TO THIRD-PARTY WEBSITES</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >We provide link to third-party Websites, our online payment service partners, which process your Net Banking and Credit / Debit Card payments, respectively. We are not responsible for the availability or proper functioning of such linked pages or sites, and we will not be held responsible for any loss or inconvenience encountered as a result of using such Website link.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="13">
                                        <li><span ><span > </span></span><strong><span >TERMS OF SALE</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >&lsquo;The App&rsquo; may accept or decline any order placed by a Customer in its absolute discretion without liability to you.</span></li>
                                        <li><span ><span > </span></span><span >&lsquo;The App&rsquo; reserves the right to discontinue any program or offer on &lsquo;The App&rsquo;.</span></li>
                                        <li><span ><span > </span></span><span >We reserve the right, without prior notice, to limit the order quantity of any Products available on &lsquo;The App&rsquo;</span></li>
                                        <li><span ><span > </span></span><span >We acknowledge and you agree that you have fully and accurately disclosed your personal information and personal health information and consent to its use by &lsquo;The App&rsquo; and/or its affiliates. You confirm that you have had a physical examination by a physician and do not require a physical examination.</span></li>
                                        <li><span ><span > </span></span><span >You understand that all Products shall be sold &amp; dispensed by the authorised Madhavbaug</span><sup><span >TM</span></sup><span >Clinics at &lsquo;The App&rsquo; and/or its affiliates.</span></li>
                                        <li><span ><span > </span></span><span >If you are a patient / parent / authorised person on behalf of a patient, you authorise and appoint &lsquo;The App&rsquo; and/or its affiliates, as your attorney and agent, to take all steps, sign all documents and to act on your behalf as if you were personally present and acting for yourself for the limited purposes of (a) obtaining a valid prescription for any prescription which you have sent the; and (b) packaging your prescriptions and delivering them to you. By making this purchase you hereby authorise Heart Health Meter and/or its affiliates to ship the order by Post/courier to your designated location. This authorisation shall include, but not be limited to: collecting and using your personal and personal health information as reasonably necessary for the fulfilment of your order. &ldquo;YOU HAVE READ AND UNDERSTAND THESE TERMS AND AGREE THAT THEY SHALL BE BINDING UPON YOU AND YOUR ASSIGNS, HEIRS AND PERSONAL REPRESENTATIVES.&rdquo;</span></li>
                                        <li><span ><span > </span></span><span >If you are the parent / legal guardian / authorised person for the patient disclosed herein, you affirm that you are over the age of majority, and have full authority to sign for and provide the above representations to &lsquo;The App&rsquo; and/or its affiliates on the Patient&rsquo;s behalf.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="14">
                                        <li><span ><span > </span></span><strong><span >PRESCRIPTION MEDICATION POLICY</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >&lsquo;The App&rsquo; takes all precautions to strictly abide by the laws and regulations set forth in the dispensing of prescription medications. By placing the order for your prescription medicines, you acknowledge and accept the following terms regarding the purchase of any prescription medicines.</span></li>
                                        <li><span ><span > </span></span><span >&lsquo;The App&rsquo; will not dispense any prescription medication without a valid prescription from an authorised Medical officer of Madhavbaug</span><sup><span >TM</span></sup><span >clinic whenever required.</span></li>
                                        <li><span ><span > </span></span><span >If our Madhavbaug</span><sup><span >TM</span></sup><span >clinic has any questions or concerns about the prescription(s), you hereby authorize them to consult with an authorised Medical officer of Madhavbaug</span><sup><span >TM</span></sup><span >&nbsp;clinic about the query related with said the prescription.</span></li>
                                        <li><span ><span > </span></span><span >The drug information provided in &lsquo;The App&rsquo; is for informative purposes only and this App is not intended to provide final diagnosis, treatment or medical advice. We are not liable for any adverse effects or harm to you as a result of your reliance on the information in &lsquo;The App&rsquo;.</span></li>
                                        <li><span ><span > </span></span><span >&lsquo;The App&rsquo; requires either the User or Customer or the Caregiver to confirm he/she is completely aware of the indications, side effects, drug interactions, effects of missed dose or overdose of the medicines he/she orders from us. It is imperative to seek professional advice from authorised Medical officer of Madhavbaug</span><sup><span >TM</span></sup><span >clinic before purchasing or consuming any medicine from &lsquo;The App&rsquo;.</span></li>
                                        <li><span ><span > </span></span><span >At &lsquo;The App&rsquo;, a Caregiver can order prescription medicines on your behalf.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="15">
                                        <li><span ><span > </span></span><strong><span >CANCELLATION, RETURN AND REFUND POLICY</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><strong><span >Refund Policy</span></strong><span >: At &lsquo;The App&rsquo;, we do our best to ensure that you are completely satisfied with our products and services. And we are happy to issue a full refund based on the conditions listed below &ndash;</span></li>
                                        <li><span ><span > </span></span><span >You received a defective item;</span></li>
                                        <li><span ><span > </span></span><span >the ordered item(s) is lost or damaged during transit;</span></li>
                                        <li><span ><span > </span></span><span >the ordered item(s) is past its expiry date;</span></li>
                                        <li><span ><span > </span></span><span >Please note: Mode of refund may vary depending on circumstances. If the mode of refund is by Credit/Debit Card or Net Banking, please allow 7 to 10 working days for the credit to appear in your account. While we regret any inconvenience caused by this time frame, it is the bank&rsquo;s policy that delays the refund timing and we have no control over that.</span></li>
                                        <li><span ><span > </span></span><span >We won&rsquo;t do any Cash Refund in any Circumstances.</span></li>
                                        <li><span ><span > </span></span><strong><span >How to Request a Refund</span></strong><span >: To request a refund, simply email us your order details, including the reason why you&rsquo;re requesting a refund. We take customer feedback very seriously and use it to constantly improve our quality of service.</span></li>
                                        <li><span ><span > </span></span><span >If you have any queries, give missed call at customer service miss call number 022 &ndash; 48933666, email us at info@madhavbaug.org, or contact our customer support executives through online chat. We&rsquo;re here for you! (online Chat Support is available between 10AM to 6PM on weekdays only)</span></li>
                                        <li><span ><span > </span></span><strong><span >Return Policy</span></strong><span >: We do our best to ensure that the products you order are delivered according to your specifications. However, should you receive an incomplete order, damaged or incorrect product(s), please notify Madhavbaug</span><sup><span >TM</span></sup><span >Customer Support immediately or within 3 working days of receiving the products, to ensure prompt resolution. Please note that &lsquo;The App&rsquo; will not accept liability for such delivery issues if you fail to notify us within 3 working days of receipt of Product or service.</span></li>
                                        <li><span ><span > </span></span><span >We also understand that various circumstances may arise leading you to want to return a product or products that are not defective. In these cases, we may allow the return of unopened, unused products after deducting a 25% restocking charge, ONLY if you notify us within 15 working days of receipt.</span></li>
                                        <li><span ><span > </span></span><strong><span >Return Policy Exceptions</span></strong><span >: Please note that we are unable to offer replacements or exchanges for the following product categories: Diet, Health Monitor &amp; Equipments. Also, &lsquo;The App&rsquo; reserves the right to refuse returns (or refunds) for certain products, as marked in the respective product pages as &ldquo;Note: This item cannot be returned for a refund or exchange.&rdquo;</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="16">
                                        <li><span ><span > </span></span><strong><span >CANCELLATION:</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >&lsquo;The App&rsquo; shall be only an intermediary connecting the User with the Doctor/registered medical practitioners of the service booked. Once the appointment has been made and a confirmation has been given to the User, the Company shall inform the Doctor/registered medical practitioners who shall render the consult at the agreed time, location and mode provided at the time of booking the service.</span></li>
                                        <li><span ><span > </span></span><span >Once an appointment has made and it is confirmed at our end, no amount shall be refunded, in the event of a cancellation. However, in certain circumstances, the Company at its discretion shall permit a refund of the full or partial amount if the Doctor/registered medical practitioners becomes unavailable or if an alternative Doctor/registered medical practitioners is arranged to consult. The decision of the Company shall be final in such cases.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="17">
                                        <li><span ><span > </span></span><strong><span >LOCATION:</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >Currently we provide the phone call and face to face video call services in India. We also book appointments for direct consultation. For the same please contact us at&nbsp;</span><a href="mailto:info@madhavbaug.org"><span >info@madhavbaug.org</span></a></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="18">
                                        <li><span ><span > </span></span><strong><span >COPYRIGHTS, TRADEMARKS AND OTHER INTELLECTUAL PROPERTY RIGHTS:</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >As per the Terms and Conditions of &lsquo;The App&rsquo;, it grants access to Users / Customers to view the content solely for visiting, ordering, and communicating only. All materials in this App, including, but not limited to, images, illustrations, text, logos and page headers, that are part of this App are copyrights and/or other intellectual properties owned by Company. All other trademarks not owned by Company that appear on this App are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by &lsquo;The App&rsquo;.</span></li>
                                        <li><span ><span > </span></span><span >You hereby agree that you will not reproduce, duplicate or copy the content of &lsquo;The App&rsquo; for any purpose, unless you have been specifically permitted to do so in a separate agreement with this App.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="19">
                                        <li><span ><span > </span></span><strong><span >USER INDEMNIFICATION:</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >You hereby agree to indemnify, defend, hold harmless &lsquo;The App&rsquo; and its officers, directors, employees, agents, information providers, partners, licensors, advertisers and suppliers from and against all claims, losses, expenses, damages and costs, including reasonable legal fees and costs, resulting from any violation of this agreement, or any activity related to your account, or any information or material you have submitted or will submit to us in violation of any law or in breach of any third party rights (including, but not limited to, claims in respect of defamation, invasion of privacy, or infringement of any other intellectual property right). We reserve the right to exclusively defend and control any claims arising from the above and any such indemnification matters, and you hereby agree to cooperate fully with us in asserting any available defences.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="20">
                                        <li><span ><span > </span></span><strong><span >COMPLIANCE WITH LAWS:&nbsp;</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >The User of &lsquo;The App&rsquo; shall comply with all the applicable laws including without limitation to Foreign Exchange Management Act, 1999 and the rules made and notifications issued there under and the Exchange Control Manual as may be issued by Reserve Bank of India from time to time, Customs Act, Information and Technology Act, 2000 as amended by the Information Technology (Amendment) Act 2008, Prevention of Money Laundering Act, 2002 and the rules made there under, Foreign Contribution Regulation Act, 1976 and the rules made there under, Income Tax Act, 1961 and the rules made there under, Export Import Policy of government of India) applicable to them respectively for using Payment Facility and &lsquo;The App&rsquo;.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="21">
                                        <li><span ><span > </span></span><strong><span >APPLICABLE LAW AND JURISDICTION:</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >These Terms and Conditions and your use of this App and its content will be governed by and construed in all respects in accordance with the laws of India subject to the jurisdictional courts of Thane, Maharashtra.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="22">
                                        <li><span ><span > </span></span><strong><span >DISPUTE RESOLUTION:</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >These Terms of Service will be governed by and construed in accordance with the laws of India without regard to its conflicts of law provisions. Any failure of &lsquo;The App&rsquo; / Company to enforce or exercise any provision of this Agreement or related right shall not constitute a waiver of that right or provision. The section titles used in this Agreement are purely for convenience and carry with them no legal or contractual effect. In the event of termination of this Agreement for any reason, you agree the following provisions will survive: the provisions regarding limitations on your use of Content, the license(s) you have granted to &lsquo;The App&rsquo; / Company, and all other provisions for which survival is equitable or appropriate.</span></li>
                                        <li><span ><span > </span></span><span >In the case of a conflict between these terms and the terms of any electronic or machine readable statement or policy, these Terms of Service shall prevail. Similarly, in case of a conflict between these terms and our Privacy Policy, these Terms of Service shall prevail.</span></li>
                                        <li><span ><span > </span></span><span >All disputes involving but not limited to rights conferred, compensation, refunds, and other claims will be resolved through a two-step Alternate Dispute Resolution (&ldquo;ADR&rdquo;) mechanism. It is further agreed to by the Parties that the contents of this Section shall survive even after the termination or expiry of the Terms and/or Policy.</span></li>
                                        <li><span ><span > </span></span><strong><span >Mediation</span></strong><span >: In case of any dispute between the parties, the Parties will attempt to resolve the same amicably amongst themselves, to the mutual satisfaction of both Parties. In the event that the Parties are unable to reach such an amicable solution within thirty (30) days of one Party communicating the existence of a dispute to the other Party, the dispute will be resolved by arbitration, as detailed here in below;</span></li>
                                        <li><span ><span > </span></span><strong><span >Arbitration</span></strong><span >: In the event that the Parties are unable to amicably resolve a dispute by mediation, said dispute will be referred to arbitration by a sole arbitrator to be appointed by the Company, and the award passed by such sole arbitrator will be valid and binding on both Parties. The Parties shall bear their own costs for the proceedings, although the sole arbitrator may, in his/her sole discretion, direct either Party to bear the entire cost of the proceedings. The arbitration shall be conducted in English, and the seat of Arbitration shall be the city of Thane in the state of Maharashtra, India. The Parties expressly agree that the Terms, Policy and any other agreements entered into between the Parties are governed by the laws, rules and regulations of India, and that the Courts at Thane shall have exclusive jurisdiction over any disputes arising between the Parties.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="23">
                                        <li><span ><span > </span></span><strong><span >SEVERABILITY:</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >If any provision of these Terms and Conditions shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Terms and Conditions and shall not affect the validity and enforceability of any remaining provisions.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="24">
                                        <li><span ><span > </span></span><strong><span >DISCLAIMER OF WARRANTIES AND LIABILITIES:</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >YOU EXPRESSLY UNDERSTAND AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.&nbsp;&lsquo;THE APP&rsquo;, SERVICES AND OTHER MATERIALS ARE PROVIDED BY THIS APP IS ON AN &ldquo;AS IS&rdquo; BASIS WITHOUT WARRANTY OF ANY KIND, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT &ndash;</span></li>
                                        <li><span ><span > </span></span><span >YOUR REQUIREMENTS WILL BE MET OR THAT SERVICES PROVIDED WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE;</span></li>
                                        <li><span ><span > </span></span><span >MATERIALS, INFORMATION OBTAINED AND RESULTS WILL BE EFFECTIVE, ACCURATE OR RELIABLE;</span></li>
                                        <li><span ><span > </span></span><span >ANY ERRORS OR DEFECTS IN THE W/APP, SERVICES OR OTHER MATERIALS WILL BE CORRECTED.</span></li>
                                        <li><span ><span > </span></span><span >TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE WILL HAVE NO LIABILITY RELATED TO USER CONTENT ARISING UNDER INTELLECTUAL PROPERTY RIGHTS, LIBEL, PRIVACY, PUBLICITY, OBSCENITY OR OTHER LAWS. WE ALSO DISCLAIM ALL LIABILITY WITH RESPECT TO THE MISUSE, LOSS, MODIFICATION OR UNAVAILABILITY OF ANY USER CONTENT.</span></li>
                                        <li><span ><span > </span></span><span >THE USER UNDERSTANDS AND AGREES THAT ANY MATERIAL OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH &lsquo;THE APP&rsquo; IS DONE ENTIRELY AT THEIR OWN DISCRETION AND RISK AND THEY WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO THEIR COMPUTER SYSTEMS OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL OR DATA. WE ARE NOT RESPONSIBLE FOR ANY TYPOGRAPHICAL ERROR LEADING TO AN INVALID COUPON. WE ACCEPT NO LIABILITY FOR ANY ERRORS OR OMISSIONS, WITH RESPECT TO ANY INFORMATION PROVIDED TO YOU WHETHER ON BEHALF OF ITSELF OR THIRD PARTIES.</span></li>
                                        <li><span ><span > </span></span><span >WE SHALL NOT BE LIABLE FOR ANY THIRD PARTY PRODUCT OR SERVICES.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol >
                                        <li><strong><span >SUBMISSIONS:</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >&lsquo;The App&rsquo; reserves sole discretion to accept, edit or reject any and all materials you may send to us (collectively, &ldquo;Submissions&rdquo;) as part of the features in &lsquo;The App&rsquo;, including but not limited to, Ratings &amp; Reviews, Ask Our Executives or Doctors Your Questions and Testimonials. Such Submissions should not be offensive on moral, religious, racial or political grounds or of an abusive, indecent, threatening, unlawful, obscene, defamatory, menacing or otherwise objectionable nature.</span></li>
                                        <li><span ><span > </span></span><span >&lsquo;The App&rsquo; shall be deemed to own all known and hereafter existing rights of every kind and nature regarding the Submissions. By posting, uploading, inputting or providing your Submissions, you hereby grant unrestricted use of the Submissions for any purpose, without compensation to you, including a non-terminable, royalty-free and non-exclusive license to use, copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat your Submission; and to publish your name in connection with your Submission.</span></li>
                                        <li><span ><span > </span></span><span >You also represent and warrant that your Submissions will not infringe, misappropriate or violate a third party&rsquo;s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy.</span></li>
                                        <li><span ><span > </span></span><span >The User/Customer shall be prohibited to introduce, post, or transmit any information or software, which contains a virus, worm or other harmful components into &lsquo;The App&rsquo;.</span></li>
                                        <li><span ><span > </span></span><span >&lsquo;The App&rsquo; does not endorse and is not responsible for any Submissions on its App. We will not be liable for any loss or harm caused by the Submissions or your reliance on the information, including but not limited to, statements, opinions and reviews posted by the Users/Customers, which may be inaccurate, offensive, obscene, threatening or harassing.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="26">
                                        <li><span ><span > </span></span><strong><span >DISCLAIMERS:</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >You understand that &lsquo;The App&rsquo; is a platform that Users utilize to locate Doctor/registered medical practitioners and make appointments for consult at Madhavbaug</span><sup><span >TM</span></sup><span >Even though we take all the necessary steps to verify the authenticity of the Doctor/registered medical practitioners, You understand and agree that we shall not be liable for any deficiencies including but not limited to misconduct, injury caused or lack of knowledge from the end of the Doctor/registered medical practitioners. We are not a party to such interaction and take no liability that arises from any such acts of the Doctor/registered medical practitioners.</span></li>
                                        <li><span ><span > </span></span><strong><span >Healthcare Services</span></strong><span >: All of the Treating Providers who deliver Services through &lsquo;The App&rsquo; are independent professionals solely responsible for the services each provides to you.</span></li>
                                        <li><span ><span > </span></span><strong><span >Informed Consent</span></strong><span >: The Tele Health Services you receive from Treating Providers are not intended to replace a primary care physician relationship or be your permanent medical home. You should seek emergency help or follow-up care when recommended by a Treating Provider or when otherwise needed and continue to consult with your primary care physician and other healthcare professionals as recommended. You will have direct access to customer support services to follow up on medication reactions, side effects or other adverse events. Among the benefits of our Services are improved access to healthcare professionals and convenience. However, as with any health service, there are potential risks associated with the use of Tele Health. These risks include, but may not be limited to:</span></li>
                                        <li><span ><span > </span></span><span >In rare cases, information transmitted may not be sufficient (e.g. poor resolution of images) to allow for appropriate health care decision making by the Treating Provider;</span></li>
                                        <li><span ><span > </span></span><span >Delays in evaluation or treatment could occur due to failures of the electronic equipment. If this happens, you may be contacted by phone or other means of communication.</span></li>
                                        <li><span ><span > </span></span><span >In rare cases, a lack of access to all of your health records may result in adverse drug interactions or allergic reactions or other judgment errors;</span></li>
                                        <li><span ><span > </span></span><span >Although the electronic systems we use will incorporate network and software security protocols to protect the privacy and security of health information, in rare instances, security protocols could fail, causing a breach of privacy of personal health information</span></li>
                                        <li><span ><span > </span></span><span >By accepting these Terms of Use, you acknowledge that you understand and agree with the following:</span></li>
                                        <li><span ><span > </span></span><span >You understand that you may expect the anticipated benefits from the use of Tele Health in your care, but that no results can be guaranteed or assured. You understand that the laws that protect the privacy and security of health information apply to Tele Health, and have received Madhavbaug</span><sup><span >TM</span></sup><span >&rsquo;s Notice of Privacy Practices, which describes these protections in more detail. Electronic communications are directed to your Treating Provider(s) and their supervisees through a secure, encrypted video interface and electronic health record.</span></li>
                                        <li><span ><span > </span></span><span >Your Treating Provider may determine that the Services are not appropriate for some or all of your treatment needs, and accordingly may elect not to provide Tele Health services to you through the Site.</span></li>
                                        <li><span ><span > </span></span><span >You can send messages to your Treating Provider by contacting our Customer Support. If you are experiencing a medical emergency, you should call the emergency ambulance number or go to the nearest emergency room.</span></li>
                                        <li><span ><span > </span></span><span >All communication between the Doctor/registered medical practitioners and you, is a separate transaction which includes without any limitation all warranties related to consult and after consult services related to consult. We do not have any control over such information and play no determinative role in the finalization of the same and hence do not stand liable for the outcomes of such communication. We do not endorse any specific Doctor/registered medical practitioners on &lsquo;The App&rsquo; nor place any guarantee as to its quality and value. Any such recommendations that are done are done by other users and the Company does not verify or acknowledge the same.</span></li>
                                        <li><span ><span > </span></span><span >Further we may suggest apt treatments or services in accordance with your information within our knowledge but we do not guarantee any effectiveness or success upon using these services. Subject to the above sub-clauses, a contract exists between the Doctor/registered medical practitioners and You and as such any breach of contract and thus, any claim arising from such breach is the subject matter of the Doctor/registered medical practitioners and you alone and we are in no way a party to such breach or involved in any suit claim/negligence/damages etc arising from the same breach.</span></li>
                                        <li><span ><span > </span></span><span >We are in no way liable for any deficiency of services if any arises including but not limited to cancellation of order due to any unavailability of the Doctor/registered medical practitioners. We are not liable for the general conduct of the Doctor/registered medical practitioners and we shall not be liable for any unwarranted act performed by the Doctor/registered medical practitioners. You are advised to ensure your safety and the safety of your belongings.</span></li>
                                        <li><span ><span > </span></span><span >The content on &lsquo;The App&rsquo; is general in nature and summarized, and is provided for informational purposes only. The content of &lsquo;The App&rsquo;, including without limitation, text, copy, audio, video, photographs, illustrations, graphics and other visuals, is for informational purposes only and does not constitute professional advice, or recommendations of any kind.</span></li>
                                        <li><span ><span > </span></span><span >We do not assure any liability for the contents of any material provided on &lsquo;The App&rsquo;. Reliance on any information provided by us, other visitors to &lsquo;The App&rsquo; is solely at your own risk. We assume no liability or responsibility for damage or injury to persons or property arising from any use of any product, information, idea, or instruction contained in the materials provided to you. We reserve the right to change or discontinue, at any time, any aspect or feature of this Site without notice.</span></li>
                                        <li><span ><span > </span></span><span >You do hereby grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty- free, sub-licensable right to exercise the copyright, publicity, and database rights you have in your information, in any media now known or not currently known, with respect to your information to enable us to use the information for the purpose of providing the Services.</span></li>
                                        <li><span ><span > </span></span><span >You release and indemnify us and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions of the Users of &lsquo;The App&rsquo; and specifically waive any claims that You may have in this behalf under any applicable law. Please note that there could be risks in dealing with underage persons or people acting under false pretence.</span></li>
                                        <li><span ><span > </span></span><span >The parties hereby agree that the Platform shall not be held liable neither to the Doctors / registered medical practitioners or the Patients / users for any offer of service/consultation/ communication made between them for whatsoever reason it may be. Further the platform/company shall not be held liable neither by the Doctor/registered medical practitioners nor the patients/users for any technical mishap of whatever kind.</span></li>
                                        <li><span ><span > </span></span><span >The Platform is only an intermediary and cannot be held liable for any dispute/claim/damages etc that arise between the Doctors/registered medical practitioners and the Patients/users for whatsoever reason it may be. Both of them hereby indemnify the company for any such claims.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="27">
                                        <li><span ><span > </span></span><strong><span >INDEMNIFICATION AND LIMITATION OF LIABILITY:</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS THIS W/APP INCLUDING BUT NOT LIMITED TO ITS AFFILIATE VENDORS, AGENTS AND EMPLOYEES FROM AND AGAINST ANY AND ALL LOSSES, LIABILITIES, CLAIMS, DAMAGES, DEMANDS, COSTS AND EXPENSES (INCLUDING LEGAL FEES AND DISBURSEMENTS IN CONNECTION THEREWITH AND INTEREST CHARGEABLE THEREON) ASSERTED AGAINST OR INCURRED BY US THAT ARISE OUT OF, RESULT FROM, OR MAY BE PAYABLE BY VIRTUE OF, ANY BREACH OR NON-PERFORMANCE OF ANY REPRESENTATION, WARRANTY, COVENANT OR AGREEMENT MADE OR OBLIGATION TO BE PERFORMED BY YOU PURSUANT TO THESE TERMS OF USE. FURTHER, YOU AGREE TO HOLD US HARMLESS AGAINST ANY CLAIMS MADE BY ANY THIRD PARTY DUE TO, OR ARISING OUT OF, OR IN CONNECTION WITH, YOUR USE OF &lsquo;THE APP&rsquo;, ANY CLAIM THAT YOUR MATERIAL CAUSED DAMAGE TO A THIRD PARTY, YOUR VIOLATION OF THE TERMS OF SERVICE, OR YOUR VIOLATION OF ANY RIGHTS OF ANOTHER, INCLUDING ANY INTELLECTUAL PROPERTY RIGHTS.</span></li>
                                        <li><span ><span > </span></span><span >IN NO EVENT SHALL WE, OR OUR OFFICERS, DIRECTORS, EMPLOYEES, PARTNERS OR SUPPLIERS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY SPECIAL, INCIDENTAL, INDIRECT, CONSEQUENTIAL OR PUNITIVE DAMAGES WHATSOEVER, INCLUDING THOSE RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER OR NOT FORESEEABLE OR WHETHER OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR BASED ON ANY THEORY OF LIABILITY, INCLUDING BREACH OF CONTRACT OR WARRANTY, NEGLIGENCE OR OTHER TORTIOUS ACTION, OR ANY OTHER CLAIM ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF OR ACCESS TO THE W/APP, SERVICES OR MATERIALS. THE LIMITATIONS AND EXCLUSIONS IN THIS SECTION APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="28">
                                        <li><span ><span > </span></span><strong><span >TERMINATION:</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >You agree that &lsquo;The App&rsquo;, in its sole discretion and for any or no reason, including without limitation if you breach these Terms and Conditions, may terminate your access to and use of &lsquo;The App&rsquo;, at any time. You agree that any termination of your access to &lsquo;The App&rsquo; or suspension of your account may be effected without prior notice, and you agree that &lsquo;The App&rsquo; shall not be liable to you for any such termination. Your right to use &lsquo;The App&rsquo; / Service immediately ceases upon termination of your access / use of &lsquo;The App&rsquo;.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="29">
                                        <li><span ><span > </span></span><strong><span >CHANGES TO THESE TERMS AND CONDITIONS:</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >We reserve the right to amend these terms and conditions from time to time without further notice to you. Any such amendments we make shall be effective once we post a revised version of these Terms and Conditions on &lsquo;The App&rsquo;. It is your responsibility to review the &lsquo;The App&rsquo; Terms and Conditions regularly. Your continued use of &lsquo;The App&rsquo; following the publication of any such changes will constitute your agreement to follow and be automatically bound by the amended terms and conditions.</span></li>
                                    </ul>
                                    <p ><span >&nbsp;</span></p>
                                    <ol start="30">
                                        <li><span ><span > </span></span><strong><span >MISCELLANEOUS:</span></strong></li>
                                    </ol>
                                    <ul>
                                        <li><span ><span > </span></span><span >We reserve the right to change these Terms of Service at any time without notice in its discretion and to notify users of any such changes solely by changing this Terms of Service. Your continued use of &lsquo;The App&rsquo; after the posting of any amended Terms of Service shall constitute your agreement to be bound by any such changes. Your use of this App prior to the time this Terms of Service was posted will be governed according to the Terms of Service that applied at the time of your use.</span></li>
                                        <li><span ><span > </span></span><span >We may modify, suspend, discontinue or restrict the use of any portion, including the availability of any portion of the Content at any time, without notice or liability. We may deny access to any person or user at any time for any reason. In addition, we may at any time transfer rights and obligations under this Agreement to any affiliate, subsidiary or business unit, or any of their affiliated companies or divisions, or any entity that acquires us or any of their assets.</span></li>
                                        <li><span ><span > </span></span><span >We hereby reserve the right to block usage of &lsquo;The App&rsquo; if any breach of the Terms of Service is caused by a User. This can in no way be construed as a waiver of any legal right under Indian law to claims any damages or initiate any legal proceedings against the User</span></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on typ-height-auto mt-50'>
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12}>
                                <div className="bs-heading typ-full-width">
                                    <h2 className="heading mb-40">
                                        Contact Information
                                    </h2>
                                </div>
                                <div className='bs-content'>
                                    <p>
                                        If you have questions about this Privacy Policy, please contact 02-248933666 or our customer service at <a href="mailto:info@madhavbaug.org">info@madhavbaug.org</a>
                                    </p>
                                    <p>
                                        <strong>Grievance Officer</strong> <br />
                                        In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:
                                    </p>
                                    <p>
                                        <strong>Mrs. Charu Shilotri</strong> <br />
                                        Vaidya Sane Ayurved Lab Prv Ltd. <br />
                                        Phone: <a href="tel:02248933666">022-48933666</a> <br />
                                        Email: <a href="mailto:info@madhavbaug.org">info@madhavbaug.org</a> <br />
                                        Office Time: Mon – Sat (10:00 – 18:00)
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            {/* <RoutingArrow submit prevBtn={handlePrev} next={handleSubmit} /> */}
            <Footer />
        </div>
    )
}

export default TermsCondition;