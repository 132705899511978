import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Row, Col, FormGroup, Label, Input, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';


// css
import '../../../assets/sass/custom.scss';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';


function PrivacyPolicy() {

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on typ-mb-on typ-subscription-main'>
                        <Row>
                            <Col xxl={12} xl={12} md={12}>
                                <Link className='back-btn' to={`${homePageUrl}`}><span class="icon icon-prev-arrow"></span>Back</Link>
                            </Col>
                        </Row>
                        <Breadcrumb className='bs-breadcrumb'>
                            <BreadcrumbItem><Link to={`${homePageUrl}`}>Home</Link></BreadcrumbItem>
                            <BreadcrumbItem active>Privacy & Policy</BreadcrumbItem>
                        </Breadcrumb>
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12}>
                                <div className="bs-heading typ-center">
                                    <h2 className="heading">
                                        Privacy & Policy
                                    </h2>
                                </div>
                                <div className='bs-content'>
                                    <ol>
                                        <li><strong>Absolute Confidentiality</strong></li>
                                    </ol>
                                    <ul>
                                        <li>The Term “<strong>Information</strong>” used in this document includes all your information which is –</li>
                                    </ul>
                                    <ul>
                                        <li>Entered by you in <strong>Heart Health Meter ‘The App’</strong></li>
                                        <li>Collected from your Google User Account, within the scope and method as defined in this document</li>
                                        <li>Generated for you by ‘The App’ through its own algorithms</li>
                                        <li>Generated for you by the Authorized Madhavbaug Personnel</li>
                                    </ul>
                                    <ul>
                                        <li>mibPULSE (also referred to as &#8220;The App&#8221; or &#8220;App&#8221; or “Madhavbaug” or &#8220;We&#8221; or “Us”) is totally committed to protecting your privacy and ensuring the security of any personal information received from you (&#8220;Customer&#8221;). At App, the confidentiality of our Customers is a top priority. We strictly adhere to the requirements of the data protection law established in India and this Privacy Policy is published in accordance with –</li>
                                    </ul>
                                    <ul>
                                        <li>Section 43 A of the Information Technology Act, 2000;</li>
                                        <li>Regulation 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (the &#8220;SPI Rules&#8221;); and</li>
                                        <li>Regulation 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011.</li>
                                    </ul>
                                    <ul>
                                        <li>The purpose of this privacy policy statement is to explain to you what personal information we collect from your visit(s) to our App, and how we protect the information that you provide to us when you use ‘The App’. You can be assured that we will use such information sensibly and carefully, and only in accordance with this privacy statement. We maintain compliance with the highest standards of application privacy, so our visitors/Customers can securely place orders on our App. This Privacy Policy is effective from 01/05/2020. Any information that you provide to us is subject to our Privacy Policy.</li>
                                    </ul>
                                    <ol start="2">
                                        <li><strong>Your Consent</strong></li>
                                    </ol>
                                    <ul>
                                        <li>By accessing ‘The App’ and whenever you submit any information to us, or use products and services offered by our App, you agree to be bound by the terms of this Privacy Policy. Please read below carefully the entire Privacy Policy before using ‘The App’.</li>
                                    </ul>
                                    <ul>
                                        <li>As part of providing our Services to you, we may need to send routine communications to you, such as order transaction emails and other administrative messages through email and notifications posted on the Website or through other means available, including text and other forms of messaging. Though we prefer to communicate with our Customers via email, as may be deemed necessary, you agree to receive our phone calls, related to your orders/request on App.</li>
                                    </ul>
                                    <ul>
                                        <li>When you sign up to receive our newsletters, we may periodically send emails about new products, special offers or other information, which you may find it interesting and beneficial as well. If you do not wish to receive promotional information from us, you can, at any time, choose to opt out of receiving our promotional newsletters. To unsubscribe from our newsletters, you can follow the directions included at the newsletter.</li>
                                    </ul>
                                    <ul>
                                        <li>You must ensure to provide us with the correct or up-to-date personal Information. This will allow us to contact you for further correspondences with respect to your order at App. We are not responsible for any inaccurate information you provide to us, which may affect your shopping experience on our App.</li>
                                    </ul>
                                    <ul>
                                        <li>If, at any time, you want to access, update, modify or eliminate any of your account information in ‘The App’, you may do so by clicking &#8220;Your Account” and then review / change your sensitive personal information as you want it to be.</li>
                                    </ul>
                                    <ul>
                                        <li>‘The App’ may, in future, request other optional information from you in order to customise ‘The App’ to deliver personalised service to you.</li>
                                    </ul>
                                    <ol start="3">
                                        <li><strong>What Information we collect about you</strong></li>
                                    </ol>
                                    <ul>
                                        <li>We do not automatically collect your personally identifiable information. However, you might provide us with your personal and demographic information – including your Name, Gender, Age, Address, Phone number, Email address and your medical prescription(s), in the following ways –</li>
                                    </ul>
                                    <ul>
                                        <li>As a New Customer, when you register/create an account, place your order at ‘The App’;</li>
                                        <li>As an Existing Customer, when you update your existing profile on ‘The App’;</li>
                                        <li>When you sign up for newsletters at ‘The App’;</li>
                                    </ul>
                                    <ul>
                                        <li>During your visit to ‘The App’, we may automatically receive technical information about your computer / device, including your IP address, your computer operating system, time-zone, browser type and browser plug-in details, due to the communication protocol settings on the Internet.</li>
                                    </ul>
                                    <ul>
                                        <li>When you browse ‘The App’, we may also collect information regarding the pages you viewed, the web addresses from which you arrive or click through to, time spent on certain pages, download errors and page response times. All these information help us analyse Customers&#8217; trends and preferences, and thus assist us in improving our service.</li>
                                    </ul>
                                    <ul>
                                        <li>For giving an enhancement to your health Information, ‘The App’ also collects your <strong>Google User Data</strong> in the Method and Scope described below –</li>
                                    </ul>
                                    <ul>
                                        <li><strong>Google User Authentication</strong> – ‘The App’ asks you to authenticate your Google User Account using your Google User credentials, through Google’s Authentication API which ensures complete security and confidentiality. To authenticate your Google User Account in ‘The App’ is optional and at your discretion. Note that, in the absence of this Authentication, ‘The App’ will not have the respective user data.</li>
                                    </ul>
                                    <ul>
                                        <li><strong>Google Fit Authentication &#8211; mibPULSE</strong> Link: <a target="_blank" href="https://youtu.be/_HwzqS4D68Y">https://youtu.be/_HwzqS4D68Y</a></li>
                                    </ul>
                                    <ul>
                                        <li><strong>Google User Data</strong> – ‘The App’ collects your following data from your Google Account &#8211;</li>
                                    </ul>
                                    <ul>
                                        <li><strong>Steps Data</strong> as available in your Google Account</li>
                                        <li><strong>Sleep Data</strong> as available in your Google Account</li>
                                    </ul>
                                    <ol start="4">
                                        <li><strong>How we Use the Information collected from you</strong></li>
                                    </ol>
                                    <ul>
                                        <li>We will collect and retain your personally identifiable information only to the extent that it is necessary to fulfil our services to you. Any Personal information submitted by you to ‘The App’ or collected by us will be used ONLY for the purposes mentioned below –</li>
                                    </ul>
                                    <ul>
                                        <li>To carry out our obligations arising from your requests for our products and services;</li>
                                        <li>To improve ‘The App’ functionality, and to customize your future experience with us;</li>
                                        <li>To make sure that ‘The App’ content is presented in the manner that is most effective for you;</li>
                                        <li>To communicate with you about any changes to our products and services;</li>
                                        <li>To verify your identity and perform checks to prevent fraud;</li>
                                    </ul>
                                    <ul>
                                        <li>The Information which is Collected and Generated is stored in encrypted format on our Secured Backup Systems because of its size, complexity and subsequent usage of it by you and the Authorized Madhavbaug Personnel. Note that, we do not store your Credit Card details, Debit Card details, Google Account User details</li>
                                    </ul>
                                    <ol start="5">
                                        <li><strong>With whom we share your Information</strong></li>
                                    </ol>
                                    <ul>
                                        <li>All your Information in ‘The App’ is accessible only to YOU and the Authorized Madhavbaug Personnel, no other entity has access to this Information</li>
                                    </ul>
                                    <ul>
                                        <li>We do not sell, rent or exchange your any personally identifiable information that you provide to us or is collected through ‘The App’, with any third party for commercial reasons or otherwise. However, we engage trusted online payment service partners to perform payment processing and authorisation services (for net banking or credit / debit card validation). Through your use of ‘The App’, you consent to our collection and transfer of your information to our payment service partner websites.</li>
                                    </ul>
                                    <ul>
                                        <li>As with any business, it is possible that as our business develops, we might sell or buy other businesses or assets. In such transactions, User / Customer information is typically one of the transferred business assets. Accordingly, in the event that ‘The App’, or substantially all of our assets, is acquired by a third party, such information may be one of the assets that is transferred or acquired by a third party. Under such circumstances, ‘The App’ would, to the extent possible, require the acquiring party to follow the practices described in this Privacy Policy. You acknowledge that such transfers may occur, and that any acquirer of ‘The App’ may continue to process your personal information as set forth in this Privacy Policy.</li>
                                    </ul>
                                    <ol start="6">
                                        <li><strong>Who has access to your Information</strong></li>
                                    </ol>
                                    <ul>
                                        <li>Your personally identifiable information can be accessed only on a need-to-know basis by certain employees of our company who are designated to carry out your requested activity, and all such employees are bound to strict confidentiality obligations.</li>
                                    </ul>
                                    <ol start="7">
                                        <li><strong>How we use cookies</strong></li>
                                    </ol>
                                    <ul>
                                        <li>&#8220;Cookies&#8221; are small pieces of information placed by a website onto your computer&#8217;s hard drive. Cookies do not collect any sensitive personal information from you. We use cookies to analyse data about our web page traffic, which helps us save your preferences for your future visits. This allows us to customise our website according to your interests, which will enable us to deliver a more personalised service to our customers. You may choose to accept or decline cookies. Please be aware that by declining cookies you may be unable to use our website to its fullest capability.</li>
                                    </ul>
                                    <ol start="8">
                                        <li><strong>Security Policy</strong></li>
                                    </ol>
                                    <ul>
                                        <li>We guarantee that all your personal information with us is secure. ‘The App’ takes careful precaution to protect our Customers personal information from unauthorised access, improper use or disclosure or unauthorised modification. To prevent unauthorised access, we have put in place the latest industry-standard security technology and procedures to safeguard the information we collect online. Your personal information is encrypted and is protected with 256-bit Secure Sockets Layer (SSL) software, which encrypts all information you input. We store your personally identifiable information on the computer servers placed in a secure environment. Even though we have taken significant steps to protect your personally identifiable information, no company, including our Website, can fully eliminate security risks associated with Personal Information.</li>
                                    </ul>
                                    <ol start="9">
                                        <li><strong>Maintain confidentiality of your Personal Information</strong></li>
                                    </ol>
                                    <ul>
                                        <li>We understand the confidential nature of your Personal Information including but not limited to Demographics, Health Parameters, Health Reports, Doctor Prescriptions etc. you provide to us to process your medication order and we treat your information as protected information.</li>
                                    </ul>
                                    <ol start="10">
                                        <li><strong>Changes to our Privacy Policy</strong></li>
                                    </ol>
                                    <ul>
                                        <li>We may revise this Privacy Policy from time to time by updating this page, and so we urge you to review it periodically to ensure that you agree with any such changes that we make. We will make best efforts to inform you of any important amendments by e-mail, message on the Website or notification in Your Account. However, it is your responsibility to check the Policy regularly to ensure that you agree with it and your continued use of the Website will be deemed to be your acceptance of any changes that we make.</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on typ-height-auto mt-50'>
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12}>
                                <div className="bs-heading typ-full-width">
                                    <h2 className="heading mb-40">
                                        Contact Information
                                    </h2>
                                </div>
                                <div className='bs-content'>
                                    <p>
                                        If you have questions about this Privacy Policy, please contact 02-248933666 or our customer service at <a href="mailto:info@madhavbaug.org">info@madhavbaug.org</a>
                                    </p>
                                    <p>
                                        <strong>Grievance Officer</strong> <br />
                                        In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:
                                    </p>
                                    <p>
                                        <strong>Mrs. Charu Shilotri</strong> <br/>
                                        Vaidya Sane Ayurved Lab Prv Ltd. <br/>
                                        Phone: <a href="tel:02248933666">022-48933666</a> <br/>
                                        Email: <a href="mailto:info@madhavbaug.org">info@madhavbaug.org</a> <br/>
                                        Office Time: Mon – Sat (10:00 – 18:00)
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            {/* <RoutingArrow submit prevBtn={handlePrev} next={handleSubmit} /> */}
            <Footer />
        </div>
    )
}

export default PrivacyPolicy;