import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images

// css
import '../../../assets/sass/custom.scss';

// json 
import defaultHeightMaleBelow35 from '../../../assets/json/expression/male/below35/happy.json';
import defaultHeightMaleAbove35 from '../../../assets/json/expression/male/above35/happy.json';
import defaultHeightMaleAbove72 from '../../../assets/json/expression/male/above72/happy.json';
import defaultHeightFemaleBelow35 from '../../../assets/json/expression/female/below35/happy.json';
import defaultHeightFemaleAbove35 from '../../../assets/json/expression/female/above35/happy.json';
import defaultHeightFemaleAbove72 from '../../../assets/json/expression/female/above72/happy.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function HalfWayDone() {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        setTimeout(() => {
            if (JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en") {
                let lang = "en";
                handleClick(lang);
            }
            else {
                handleClick(JSON.parse(window.localStorage.getItem('lang')));
            }
            handleNavigationLottie()
        }, 1000);
        // Aos.init()
    }, [])

    const handleClick = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t)
    }
    const [lottiePlayer, setLottiePlayer] = useState("");
    let contentData = {
        options: [
            {
                radio_button: [
                    {
                        rId: 1, radio_text: 'text', gender: {
                            Male: {
                                default: {
                                    below35: defaultHeightMaleBelow35,
                                    above35: defaultHeightMaleAbove35,
                                    above72: defaultHeightMaleAbove72,
                                },
                            },
                            Female: {
                                default: {
                                    below35: defaultHeightFemaleBelow35,
                                    above35: defaultHeightFemaleAbove35,
                                    above72: defaultHeightFemaleAbove72,
                                },
                            }
                        }
                    }
                ]
            }
        ]
    }

    const handleNavigationLottie = () => {
        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
        console.log(lottie)
        setLottiePlayer(lottie);
    }

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className="align-items-center">
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('HalfwayDone.heading')}
                                        </h2>
                                        <p className="subheading">
                                            {t('HalfwayDone.subheading')}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('HalfwayDone.heading')}
                                        </h2>
                                        <p className="subheading">
                                            {t('HalfwayDone.subheading')}
                                        </p>
                                    </div>
                                }
                                <p className="cm-para typ-center typ-max-wid">
                                    {t('HalfwayDone.ContinueFilling')}
                                </p>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prev={`${homePageUrl}height`} next={`${homePageUrl}disease-details`} />
            <Footer />
        </div>
    );
}

export default HalfWayDone;