import React, { useEffect, useState } from 'react';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images
import Right from '../../../assets/images/select-language/right.png';

// css
import '../../../assets/sass/custom.scss';

// json 
import defaultHeightMaleBelow35 from '../../../assets/json/expression/male/below35/happy.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';



function SelectLanguage() {
    console.log('sselect language page is here..!')

    //States
    let [language, setLanguage] = useState('English')
    const [lottiePlayer, setLottiePlayer] = useState(defaultHeightMaleBelow35);

    //Content
    let contentData = {
        question_text: 'Select Language',
        descriptions: ['Please Select Your Language'],
        imgUrl: "../../../assets/images/select-language/avatar1.png",
        options: [
            {
                qId: 1,
                option_id: 1,
                option_text_prefix: "Aa",
                option_text: 'English',
            }, {
                qId: 1,
                option_id: 2,
                option_text_prefix: "आ",
                option_text: 'Hindi',
            }, {
                qId: 1,
                option_id: 3,
                option_text_prefix: "आ",
                option_text: 'Marathi',
            }, {
                qId: 1,
                option_id: 4,
                option_text_prefix: "અ",
                option_text: 'Gujrathi',
            },
            // {
            //     qId: 1,
            //     option_id: 5,
            //     option_text_prefix: "ಆ",
            //     option_text: 'Kannad',
            // }
        ]
    }

    const handleRadioBtn = (key) => {
        let val;
        if (key == 1) {
            val = "en";
            window.localStorage.setItem('lang', JSON.stringify(val))
        }
        else if (key == 2) {
            val = "hi";
            window.localStorage.setItem('lang', JSON.stringify(val))
        }
        else if (key == 3) {
            val = "mr";
            window.localStorage.setItem('lang', JSON.stringify(val))
        }
        else if (key == 4) {
            val = "gj";
            window.localStorage.setItem('lang', JSON.stringify(val))
        }
        else {
            val = "kn";
            window.localStorage.setItem('lang', JSON.stringify(val))
        }
        console.log("key value is >>>", key)
        language = contentData.options[key - 1].option_text
        console.log("language is >>", language)
        setLanguage(language)
    }

    // useEffect(() => {
    //     window.localStorage.setItem('language', JSON.stringify(language))
    // });

    useEffect(() => {
        if (window.localStorage.length > 0) {
            window.localStorage.clear();
        }
        const currentUrl = window.location.href;
            // Log current URL to the console
            console.log("currentUrl",currentUrl);
            if (currentUrl.includes('sid=')) {               
                const selectedValue = currentUrl.match(/=(.*)/)[1];
                localStorage.setItem("sid",selectedValue);
              } else {
                localStorage.setItem("sid",'');
              }
        const data = window.localStorage.getItem('lang')
        setLanguage(JSON.parse(data))
    }, [])

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className='align-items-center'>
                            <Col xl={{ offset: 6, size: 6 }} lg={{ offset: 6, size: 6 }} md="12">
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                                <div className="bs-form">
                                    <FormGroup className='radio-box' tag="fieldset">
                                        {contentData.options.map((item) => {
                                            return (
                                                <React.Fragment key={item.option_id}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="radio" name="radio1" value={item.option_text} onChange={() => handleRadioBtn(item.option_id)}
                                                                defaultChecked={item.option_id === 1 ? true : false} />
                                                            <div className='radio-label'><i className="language">{item.option_text_prefix}</i>{item.option_text}
                                                                <img src={Right} className='right-icon' alt="Next-arrow" />
                                                            </div>
                                                        </Label>
                                                    </FormGroup>
                                                </React.Fragment>
                                            )
                                        })}
                                    </FormGroup>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <Footer />
            <RoutingArrow next={`${homePageUrl}take-the-test`} />
        </div>
    )
}

export default SelectLanguage;