import React, { useState, useEffect } from "react";
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images
import Right from '../../../assets/images/select-language/right.svg';

// css
import '../../../assets/sass/custom.scss';

// helper 
import handleLottie from '../../../helper/lottieFunction';

// json 
// male below 35 
import defaultLottieMaleBelow35 from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import veryHappyMaleBelow35 from '../../../assets/json/expression/male/below35/very-happy.json';
import verySadMaleBelow35 from '../../../assets/json/expression/male/below35/very-sad.json';

// male above 35 
import defaultLottieMaleAbove35 from '../../../assets/json/default/male/above35/none-of-the-option-is-selected.json';
import veryHappyMaleAbove35 from '../../../assets/json/expression/male/above35/very-happy.json';
import verySadMaleAbove35 from '../../../assets/json/expression/male/above35/very-sad.json';

// male above 72 
import defaultLottieMaleAbove72 from '../../../assets/json/default/male/above72/none-of-the-option-is-selected.json';
import veryHappyMaleAbove72 from '../../../assets/json/expression/male/above72/very-happy.json';
import verySadMaleAbove72 from '../../../assets/json/expression/male/above72/very-sad.json';

// // female below 35 
import defaultLottieFemaleBelow35 from '../../../assets/json/default/female/below35/none-of-the-option-is-selected.json';
import veryHappyFemaleBelow35 from '../../../assets/json/expression/female/below35/very-happy.json';
import verySadFemaleBelow35 from '../../../assets/json/expression/female/below35/v-sad.json';

// // female above 35 
import defaultLottieFemaleAbove35 from '../../../assets/json/default/female/above35/none-of-the-option-is-selected.json';
import veryHappyFemaleAbove35 from '../../../assets/json/expression/female/above35/very-happy.json';
import verySadFemaleAbove35 from '../../../assets/json/expression/female/above35/very-sad.json';

// // female above 72
import defaultLottieFemaleAbove72 from '../../../assets/json/default/female/above72/none-of-the-option-is-selected.json';
import veryHappyFemaleAbove72 from '../../../assets/json/expression/female/above72/very-happy.json';
import verySadFemaleAbove72 from '../../../assets/json/expression/female/above72/very-sad.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function SymtomSituations() {
    const { t, i18n } = useTranslation();
    let [symtomSituations, setSymtomSituations] = useState("")
    let [errorMsg, setErrorMsg] = useState('')
    const [lottiePlayer, setLottiePlayer] = useState("");

    let navigate = useNavigate()


    const initialValues = { spriteImg: "bg-1", radio_text: "" }
    let [contentValues, setContentValues] = useState(initialValues)
    let [language, setLanguage] = useState("");


    let contentData = {
        qId: 5,
        question_text: t('SymtomSituations.questions.question_text'),
        descriptions: [t('SymtomSituations.questions.descriptions')],
        options: [
            {
                qId: 5,
                option_id: 1,
                option_text: t('SymtomSituations.questions.option_text'),
                imgClass: 'bg-1',
                radio_button: [
                    {
                        rId: 1, radio_text: t('SymtomSituations.questions.rId1'), gender: {
                            Male: {
                                default: {
                                    below35: defaultLottieMaleBelow35,
                                    above35: defaultLottieMaleAbove35,
                                    above72: defaultLottieMaleAbove72,
                                },
                                below35: verySadMaleBelow35,
                                above35: verySadMaleAbove35,
                                above72: verySadMaleAbove72
                            },
                            Female: {
                                default: {
                                    below35: defaultLottieFemaleBelow35,
                                    above35: defaultLottieFemaleAbove35,
                                    above72: defaultLottieFemaleAbove72,
                                },
                                below35: verySadFemaleBelow35,
                                above35: verySadFemaleAbove35,
                                above72: verySadFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 2, radio_text: t('SymtomSituations.questions.rId2'), gender: {
                            Male: {
                                below35: verySadMaleBelow35,
                                above35: verySadMaleAbove35,
                                above72: verySadMaleAbove72
                            },
                            Female: {
                                below35: verySadFemaleBelow35,
                                above35: verySadFemaleAbove35,
                                above72: verySadFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 3, radio_text: t('SymtomSituations.questions.rId3'), gender: {
                            Male: {
                                below35: verySadMaleBelow35,
                                above35: verySadMaleAbove35,
                                above72: verySadMaleAbove72
                            },
                            Female: {
                                below35: verySadFemaleBelow35,
                                above35: verySadFemaleAbove35,
                                above72: verySadFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 4, radio_text: t('SymtomSituations.questions.rId4'), gender: {
                            Male: {
                                below35: veryHappyMaleBelow35,
                                above35: veryHappyMaleAbove35,
                                above72: veryHappyMaleAbove72
                            },
                            Female: {
                                below35: veryHappyFemaleBelow35,
                                above35: veryHappyFemaleAbove35,
                                above72: veryHappyFemaleAbove72
                            }
                        }
                    },
                ]
            }
        ]
    }


    const SymtomSituations = (e, key) => {
        symtomSituations = contentData.options[0].radio_button[key - 1].radio_text;
        console.log("radio text value is >> >>", contentData.options[0].radio_button[key - 1].radio_text);
        contentValues.spriteImg = contentData.options[0].imgClass
        contentValues.radio_text = contentData.options[0].radio_button[key - 1].radio_text
        let name;
        if (key == 1) {
            name = "While at Rest";
            // setPatient("While at Rest")
        }
        else if (key == 2) {
            name = "During Routine Workout";
            // setPatient("During Routine Workout")
        }
        else if (key == 3) {
            name = "After Heavy Exercise";
            // setPatient("After Heavy Exercise")
        }
        else {
            name = "No Such Symptoms";
            // setPatient("No Such Symptoms")
        }
        setContentValues({
            spriteImg: contentValues.spriteImg,
            radio_text: contentValues.radio_text,
        })
        setSymtomSituations(symtomSituations)
        setErrorMsg("")
        window.localStorage.setItem('symptom-situation', JSON.stringify(name))
        window.localStorage.setItem('symptom-situation1', JSON.stringify(symtomSituations))
        let lottie = handleLottie(key, contentData)
        setLottiePlayer(lottie);
    }

    const handleSubmit = () => {
        const noSuchSymptoms = JSON.parse(window.localStorage.getItem('symptom-situation'));
        if (noSuchSymptoms) {
            navigate(`${homePageUrl}sugar-values`)
        } else setErrorMsg(t('Messages.SymtomSituations.1'))

    }

    const handleNavigationLottie = () => {
        let symtomSituation = JSON.parse(window.localStorage.getItem('symptom-situation'))
        let symtomSituation1 = JSON.parse(window.localStorage.getItem('symptom-situation1'))
        console.log("symptopm situation value is >>", symtomSituation);
        console.log("symptoms situation value is >1", symtomSituation1);
        if (JSON.parse(window.localStorage.getItem('lang')) == "en") {
            console.log("Here");
            setSymtomSituations(symtomSituation)
            let gender = localStorage.getItem('gender').replaceAll('"', '');
            let age = localStorage.getItem('age')
            if (symtomSituation == null) {
                let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log(lottie, 'null?')
                setLottiePlayer(lottie);
            } else {
                // symtomSituations = symtomSituations.replaceAll('"', '')
                console.log("sympops situation >>12", symtomSituations);
                let id = contentData.options[0].radio_button.find((rep) => rep.radio_text == symtomSituation).rId
                console.log(id, 'not null?')
                SymtomSituations(1, id)
            }
        }
        else {
            console.log("symptoms situation value is >11122", symtomSituation1);
            setSymtomSituations(symtomSituation1)
            let gender = localStorage.getItem('gender').replaceAll('"', '');
            let age = localStorage.getItem('age')
            if (symtomSituation1 == null) {
                let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log(lottie, 'null?')
                setLottiePlayer(lottie);
            } else {
                symtomSituation1 = symtomSituation1.replaceAll('"', '')
                console.log("sympops situation >>12", symtomSituation1);
                let id = contentData.options[0].radio_button.find((rep) => rep.radio_text == symtomSituation1).rId
                console.log(id, 'not null?')
                SymtomSituations(1, id)
            }
        }

    }

    useEffect(() => {
        if (JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en") {
            let lang = "en";
            handleClick(lang);
        }
        else {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
        handleNavigationLottie()
    }, []);

    const handleClick = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t)
    }

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className='align-items-center'>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('SymtomSituations.SymtomSituations')}
                                        </h2>
                                        <p className="subheading">
                                            {t('SymtomSituations.OccurenceOfTheseSymptoms')}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('SymtomSituations.SymtomSituations')}
                                        </h2>
                                        <p className="subheading">
                                            {t('SymtomSituations.TheseSymptomsArise')}.
                                        </p>
                                    </div>
                                }
                                <p className="cm-para typ-black typ-center typ-mob-left typ-medium typ-max-wid typ-form-title">
                                    {t('SymtomSituations.WhenDoYourExperience')}?
                                </p>
                                <div className="bs-form mt-0">
                                    <FormGroup className="radio-box typ-disease">
                                        {
                                            console.log("Symtomps situation is >>", symtomSituations)
                                        }
                                        {contentData.options[0].radio_button.map((item) => {
                                            return (
                                                <React.Fragment key={item.rId}>
                                                    <FormGroup className="check-box typ-btn" check>
                                                        <Label check>
                                                            <input type="radio" name="radio"
                                                                value={item.radio_text}
                                                                checked={item.radio_text === symtomSituations} onChange={(e) => SymtomSituations(e, item.rId)} />{' '}
                                                            <div className='checkbox-label'>
                                                                <img src={Right} className='right-icon' alt="Next-arrow" />
                                                                {item.radio_text}
                                                            </div>
                                                        </Label>
                                                    </FormGroup>
                                                </React.Fragment>
                                            )
                                        })}
                                    </FormGroup>
                                    {errorMsg && <p className='error-message'>{errorMsg}</p>}
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prev={`${homePageUrl}symptoms`} submit next={handleSubmit} />
            <Footer />
        </div>
    );
}

export default SymtomSituations;