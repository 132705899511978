import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, FormGroup, Input, Label, Button, Modal, ModalHeader, ModalFooter, ModalBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { useLocation, useNavigate, Link, NavLink,useSearchParams} from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from "axios";
// animation 
// import Aos from "aos";
// import "aos/dist/aos.css";

//constant
import { homePageUrl,getExerciseDataFromApi, getEducationDataFromApi,getSingleUserDataFromApi } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images 
import ytThumbnail from "../../../assets/images/subscription/yt1.jpg";
import ytThumb from "../../../assets/images/subscription/ytThumb.jpg";

// css
import '../../../assets/sass/custom.scss';

// json 
import LottiFiles from '../../../assets/json/start-meter.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { animate } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
var ytBgImg = {
    backgroundImage: 'url(' + ytThumbnail + ')',
    backgroundSize: "Cover",
    backgroundPosition: "center center",
};


function SubscriptionEducationVideosList() {
    const { t, i18n } = useTranslation();

    const location = useLocation();
    let navigate = useNavigate();
    const inputRef = useRef(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const toggle = () => setModalIsOpen(!modalIsOpen);
    const handleOpen = () => inputRef.current.focus();

    const [myclass, changeclass] = useState("");

    const addclass = () => {
        // console.log(12);
        changeclass(`active`)
    }
    const removeClass = () => {
        // console.log(12);
        changeclass(`inactive`)
    }

    let [educationData, setEducationData] = useState("");
    let [userId,setUserId] = useState("");
    let [planName,setPlanName] = useState("")
    let [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        if(location.state != null)
        {
            console.log("Here",location)
            setUserId(location.state.userId);
            window.localStorage.setItem('userId', JSON.stringify(location.state.userId))
            console.log("location.state.userId >>",location.state.userId)
            getUserData(location.state.userId);
        }
        else
        {
            if(searchParams.get('userId')!= null || searchParams.get('userId') != undefined)
            {
                console.log("2");
                console.log("Here")
                const id =searchParams.get('userId') 
                setUserId(id);
                setTimeout(()=>{
                    window.localStorage.setItem('userId', JSON.stringify(id))
                    console.log("user id is here >>",id);
                    getUserData(id);
                },500);
                
            }
            else
            {
                console.log("3");
                let uid = JSON.parse(window.localStorage.getItem('userId'));
                setUserId(uid);
                getUserData(uid);
            }
            
        }

        if(JSON.parse(window.localStorage.getItem('lang')) != "")
        {
            handleClick("en");
        }
        else if(JSON.parse(window.localStorage.getItem('lang')) == "mr")
        {
            handleClick("mr");
        }
        else
        {
            handleClick("hi");
        }

        // if(searchParams.get('userId'))
        // {
        //     console.log("There")
        //     setUserId(location.state.userId);
        // }
        getEducationData();
        // setVideoLinks(location.state.video_links);
      }, []);

      const handleClick = (lang) => 
    {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>",lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>",t)
    }

      const getUserData = async (id) => {
        if(userId != null || userId != undefined)
        {
            const formData = new FormData()
            formData.append('userId', id)
            // formData.append('uid', "12456")
            const response = await axios({
                method: "post",
                url: getSingleUserDataFromApi,
                data: formData,
            });
            console.log("response is >>",response.data.users.plan_name)
            if(response.status == 200)
            {
                setPlanName(response.data.users.plan_name);
            }
        }
    }
    // useEffect(() => {
    //     if(location.state == null)
    //     {
    //         setUserId(JSON.parse(window.localStorage.getItem('userId')))
    //     }
    //     else
    //     {
    //         setUserId(location.state.userId);
    //     }
    //     //set-up
    //     // if (process.env.NODE_ENV === "production") {
    //     //   window.localStorage.clear();
    //     // }
    //     // return () => {
    //     getEducationData();
    //     // };
    // }, []);

    const getEducationData = async () => {
        try {
        let uid;
        if(location.state == null)
        {
            uid = JSON.parse(window.localStorage.getItem('userId'))
        }
        else
        {
            uid = location.state.userId;
        }
        let data =uid;
        const formData = new FormData()
        formData.append('userId', data)
        const response = await axios({
            method: "post",
            url: getEducationDataFromApi,
            data: formData,
        });
        if (response.data.statusCode == 200) {
            console.log("Response is >>",response)
            setEducationData(response.data.data);
        }
        } catch (error) {
        // console.log("error is ", error);
        }
    };

    const goToVideoPage= (data)=>{
        if(location.state == null)
        {
            setUserId(JSON.parse(window.localStorage.getItem('userId')))
        }
        else
        {
            setUserId(location.state.userId);
        }
        console.log("data from method is >>",data)
        let video_links = data.video_links;
        let video_topics = data.video_topics;
        let day_number = data.day_number;
        let ad_banner = data.ad_banner;
        let ad_link = data.add_link;
        setTimeout(()=>{
            navigate(`${homePageUrl}subscription-education-videos-player`, {
                state: { video_links,userId,video_topics,day_number,ad_banner,ad_link}
            });
        },200);
        
        // to={`${homePageUrl}subscription-videos-player`}
    }
    // {
    //     console.log("exercise value is >>", educationData);
    //   }
    return (


        <div className='main' >
            <AnimationWrapper>
                <motion.div exit={{ x: "-100%", opacity: 0 }}>
                    <Container>
                        <Row className='text-center'>
                            <Col xl="12" lg="12" md="12">
                                <div className='bs-card typ-pl-on typ-subscription-main'>
                                    <Breadcrumb className='bs-breadcrumb'>
                                        <BreadcrumbItem><NavLink to={`${homePageUrl}subscription-home`} >Home</NavLink></BreadcrumbItem>
                                        {/* <BreadcrumbItem><NavLink to={`${homePageUrl}subscription-video`} state= {{ userId: userId}}>Videos</NavLink></BreadcrumbItem> */}
                                        <BreadcrumbItem active>Disease Education</BreadcrumbItem>
                                    </Breadcrumb>
                                    <Row className="align-items-center justify-content-center sec-save-my-heart-plan">
                                        <Col xl="12" lg="12" md="12">
                                            <div className="bs-heading typ-center">
                                                <h2 className="heading">
                                                {t('SubscriptionEducationVideosList.2')}
                                                </h2>
                                                <p className="subheading">
                                                {t('SubscriptionEducationVideosList.3')} 
                                                {
                                                    planName == "SMH Basic" ? " "+30+" "  : " "+90+" "
                                                } {t('SubscriptionEducationVideosList.4')}.


                                                </p>
                                                <p className="subheading">
                                                {t('SubscriptionEducationVideosList.5')}.
                                                </p>
                                            </div>
                                        </Col>
                                        {
                                        educationData != "" &&
                                        educationData.map((data) => ( 
                                            <Col xl="12" lg="12" md="12">
                                                    <Link to="" onClick={() => { goToVideoPage(data) }}>
                                                <div className="bs-player typ-list">
                                                    <div className='left-col'>
                                                        <p className='title'>Day {data.day_number}</p>
                                                        <p className='desc'>{data.video_topics}</p>
                                                    </div>
                                                    <div className='right-col'>
                                                        <picture className="w-100">
                                                            <source media="(max-width:992px)" srcSet={data.video_thumbnail} />
                                                            <img src={data.video_thumbnail} alt="band" />
                                                            <span className="icon icon-yt-play"></span>
                                                        </picture>
                                                    </div>
                                                </div>
                                                </Link>
                                            </Col> 
                                        ))}
                                        
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </motion.div>
            </AnimationWrapper>
            <Footer />
        </div>
    )
}

export default SubscriptionEducationVideosList;