import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate,useSearchParams } from 'react-router-dom';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';

//constant
import { homePageUrl,getUserDetailById } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

// images
import Right from '../../../assets/images/select-language/right.svg';

// css
import '../../../assets/sass/custom.scss';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import axios from 'axios';

import { saveGeneralDetails } from '../../../helper/ContentConstants';


function AddYourVitals() {

    const navigate = useNavigate()

    const contentData = {
        qId: 4,
        question_text: 'General Details',
        descriptions: ['Enter Correct information for us to fill into our recrod books'],
        options: [
            {
                qId: 4,
                option_id: 1,
                option_text: 'Male',
                icon_name: 'icon-male',
                imgClass: 'male'
            }, {
                qId: 4,
                option_id: 2,
                option_text: 'Female',
                icon_name: 'icon-female',
                imgClass: 'female'
            }
        ]
    }

    const initialValues = { spriteImg: "bg-1", userAge: 25, gender: "" }
    let [contentValues, setContentValues] = useState(initialValues)
    let [errorMsg, setErrorMsg] = useState('')
    let [searchParams, setSearchParams] = useSearchParams();
    // Slider age loop
    const createElements = (number) => {
        var elements = [];
        let i;
        for (i = 18; i < number; i++) {
            elements.push(<SwiperSlide key={i} index={i}>{i}</SwiperSlide>)
        }
        return elements;
    }

    //Swiper slide change for age 
    const handleChange = (event) => {
        console.log("Value is >>",event)
        let activeIndex = event.realIndex
        contentValues.userAge = activeIndex + 1
        setContentValues({ userAge: contentValues.userAge, gender: contentValues.gender })
        window.localStorage.setItem('weight', JSON.stringify(contentValues.userAge + 17))
        const weight = window.localStorage.getItem('weight')
        // console.log("age is >>",age)
    }

    const handleSubmit = () => {
        navigate(`${homePageUrl}sugar-values3`);
    }

    let [bgClass, setBgClass] = useState("bg-1")

    useEffect(() => {
        if(searchParams.get('userId') != null)
        {
            const id =searchParams.get('userId');
            setTimeout(() => {
                const id =searchParams.get('userId') 
                getUserData(id);
            },500);
        }
        else
        {
            console.log("null")
        }
    })


    const getUserData = async (id) => {
            const formData = new FormData()
            formData.append('userId', id)
            // formData.append('uid', "12456")
            const response = await axios({
                method: "post",
                url: getUserDetailById,
                data: formData,
            });
            console.log("response is >>",response.data.users.id)
            if(response.status == 200)
            {
                window.localStorage.setItem('userId', JSON.stringify(response.data.users.id))
            }
    }



    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card'>
                        <Row>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                <div className="bs-heading typ-center">
                                    <h2 className="heading">
                                        Add your Vitals
                                    </h2>
                                </div>
                            </Col>
                            <Col className='cm-img-col' xl="6" lg="6" md="12">
                                <div className={`bs-img-sprite typ-bmi bg-1`}>
                                </div>
                            </Col>
                            <Col xl="6" lg="6" md="12" className=''>
                                <div className="bs-heading typ-center">
                                    <h2 className="heading">
                                        Weight
                                    </h2>
                                    <p className="subheading">
                                        Enter your Correct information to Calculate your BMI (Body Mass index)
                                    </p>
                                </div>
                                <div className="bs-form">
                                    <div className='bs-swiper'>
                                        <p className='slider-title'>
                                            What is your weight?
                                        </p>
                                        <div className='swiper-parent'>
                                        <Swiper className='age-swiper'
                                                initialSlide={JSON.parse(window.localStorage.getItem('weight')) === null ? 60 - 25 : JSON.parse(window.localStorage.getItem('weight')) - 25}
                                                spaceBetween={0} grabCursor={false} slidesPerView={7} slideToClickedSlide={true} centeredSlides='true'
                                                breakpoints={{
                                                    767: {
                                                        slidesPerView: 5,
                                                    },
                                                    992: {
                                                        slidesPerView: 5,
                                                    },
                                                    1024: {
                                                        slidesPerView: 5,
                                                    },
                                                    1366: {
                                                        slidesPerView: 9,
                                                    },
                                                }} onSlideChange={e => handleChange(e)}
                                            // onSwiper={getAge}
                                            >
                                                {createElements(251)}
                                            </Swiper>
                                            {/* <Swiper initialSlide={JSON.parse(window.localStorage.getItem('age')) === null ? 40 - 18 : JSON.parse(window.localStorage.getItem('age')) - 18}
                                                className='age-swiper' spaceBetween={0} grabCursor={false} slidesPerView={7} slideToClickedSlide={true} centeredSlides='true'
                                                breakpoints={{
                                                    767: {
                                                        slidesPerView: 5,
                                                    },
                                                    992: {
                                                        slidesPerView: 5,
                                                    },
                                                    1024: {
                                                        slidesPerView: 5,
                                                    },
                                                    1366: {
                                                        slidesPerView: 9,
                                                    },
                                                }}
                                                mousewheel={true}
                                                onSlideChange={e => handleChange(e)}
                                            // onSwiper={getAge}
                                            >
                                                {createElements(101)}
                                            </Swiper> */}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow submit next={handleSubmit} />
            <Footer />
        </div>
    )
}


export default AddYourVitals;