import React, { useState, useEffect } from 'react';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { json, useNavigate } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// Import Swiper styles
import 'swiper/css';

// helper 
import handleLottie from '../../../helper/lottieFunction';

// css
import '../../../assets/sass/custom.scss';

// json 
// male below 35 
import defaultDrinkMaleBelow35 from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import neverDrinkMaleBelow35 from '../../../assets/json/drink/male/below35/never.json';
import onceMonthDrinkMaleBelow35 from '../../../assets/json/drink/male/below35/once-a month-or-occasionally.json';
import twoToTenDrinkMaleBelow35 from '../../../assets/json/drink/male/below35/2-to-10 times-a-month.json';
import elevenToFifteenDrinkMaleBelow35 from '../../../assets/json/drink/male/below35/11-to-15-times-a-month.json';
import moreThanFifteenDrinkMaleBelow35 from '../../../assets/json/drink/male/below35/more-than-15-times-a-month.json';
import dailyDrinkMaleBelow35 from '../../../assets/json/drink/male/below35/daily.json';

// male above 35 
import defaultDrinkMaleAbove35 from '../../../assets/json/default/male/above35/none-of-the-option-is-selected.json';
import neverDrinkMaleAbove35 from '../../../assets/json/drink/male/above35/never.json';
import onceMonthDrinkMaleAbove35 from '../../../assets/json/drink/male/above35/once-a-month-or-occasionally.json';
import twoToTenDrinkMaleAbove35 from '../../../assets/json/drink/male/above35/2-to-10-times-a-month.json';
import elevenToFifteenDrinkMaleAbove35 from '../../../assets/json/drink/male/above35/11-to-15-times-a-month.json';
import moreThanFifteenDrinkMaleAbove35 from '../../../assets/json/drink/male/above35/more-than-15-times-a-month.json';
import dailyDrinkMaleAbove35 from '../../../assets/json/drink/male/above35/daily.json';

// male above 72 
import defaultDrinkMaleAbove72 from '../../../assets/json/default/male/above72/none-of-the-option-is-selected.json';
import neverDrinkMaleAbove72 from '../../../assets/json/drink/male/above72/never.json';
import onceMonthDrinkMaleAbove72 from '../../../assets/json/drink/male/above72/once-a-month-or-occasionally.json';
import twoToTenDrinkMaleAbove72 from '../../../assets/json/drink/male/above72/2-to-10-times-a-month.json';
import elevenToFifteenDrinkMaleAbove72 from '../../../assets/json/drink/male/above72/11-to-15-times-a-month.json';
import moreThanFifteenDrinkMaleAbove72 from '../../../assets/json/drink/male/above72/more-than-15-times-a-month.json';
import dailyDrinkMaleAbove72 from '../../../assets/json/drink/male/above72/daily.json';

// // female below 35 
import defaultDrinkFemaleBelow35 from '../../../assets/json/default/female/below35/none-of-the-option-is-selected.json';
import neverDrinkFemaleBelow35 from '../../../assets/json/drink/female/below35/no-alcohol.json';
import onceMonthDrinkFemaleBelow35 from '../../../assets/json/drink/female/below35/once-a-month-or-occasionally.json';
import twoToTenDrinkFemaleBelow35 from '../../../assets/json/drink/female/below35/2-10-times-a-month.json';
import elevenToFifteenDrinkFemaleBelow35 from '../../../assets/json/drink/female/below35/11-to-15-times-a-month.json';
import moreThanFifteenDrinkFemaleBelow35 from '../../../assets/json/drink/female/below35/more-than-15-times-a-month.json';
import dailyDrinkFemaleBelow35 from '../../../assets/json/drink/female/below35/daily.json';

// // female above 35 
import defaultDrinkFemaleAbove35 from '../../../assets/json/default/female/above35/none-of-the-option-is-selected.json';
import neverDrinkFemaleAbove35 from '../../../assets/json/drink/female/above35/never.json';
import onceMonthDrinkFemaleAbove35 from '../../../assets/json/drink/female/above35/once-a-month-or-occasionally.json';
import twoToTenDrinkFemaleAbove35 from '../../../assets/json/drink/female/above35/2-to-10-times-a-month.json';
import elevenToFifteenDrinkFemaleAbove35 from '../../../assets/json/drink/female/above35/11-to-15-times-a-month.json';
import moreThanFifteenDrinkFemaleAbove35 from '../../../assets/json/drink/female/above35/more-than-15-times-a-month.json';
import dailyDrinkFemaleAbove35 from '../../../assets/json/drink/female/above35/daily.json';

// // female above 72
import defaultDrinkFemaleAbove72 from '../../../assets/json/default/female/above72/none-of-the-option-is-selected.json';
import neverDrinkFemaleAbove72 from '../../../assets/json/drink/female/above72/never.json';
import onceMonthDrinkFemaleAbove72 from '../../../assets/json/drink/female/above72/once-a-month-or-occasionally.json';
import twoToTenDrinkFemaleAbove72 from '../../../assets/json/drink/female/above72/2-10-times-a-month.json';
import elevenToFifteenDrinkFemaleAbove72 from '../../../assets/json/drink/female/above72/11-15-times-a-month.json';
import moreThanFifteenDrinkFemaleAbove72 from '../../../assets/json/drink/female/above72/more-than-15-times-a-month.json';
import dailyDrinkFemaleAbove72 from '../../../assets/json/drink/female/above72/daily.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function Alcohol() {
    const { t, i18n } = useTranslation();

    let navigate = useNavigate()
    let [alcoholConsumption, setAlcoholConsumption] = useState('')
    let [language, setLanguage] = useState("")
    const [lottiePlayer, setLottiePlayer] = useState("");

    let [errorMsg, setErrorMsg] = useState({ tobacco: "", alcohol: "" })

    let contentData = {
        qId: 6,
        question_text: t('LifeStyleAssessment.Alcohol.question_text'),
        descriptions: [t('LifeStyleAssessment.Alcohol.descriptions')],
        options: [
            {
                qId: 6,
                option_id: 1,
                option_text: t('LifeStyleAssessment.Alcohol.option_text'),
                imgClass: 'bg-2',
                radio_button: [
                    {
                        rId: 1, radio_text: t('LifeStyleAssessment.Alcohol.rId1'), gender: {
                            Male: {
                                default: {
                                    below35: defaultDrinkMaleBelow35,
                                    above35: defaultDrinkMaleAbove35,
                                    above72: defaultDrinkMaleAbove72,
                                },
                                below35: neverDrinkMaleBelow35,
                                above35: neverDrinkMaleAbove35,
                                above72: neverDrinkMaleAbove72
                            },
                            Female: {
                                default: {
                                    below35: defaultDrinkFemaleBelow35,
                                    above35: defaultDrinkFemaleAbove35,
                                    above72: defaultDrinkFemaleAbove72,
                                },
                                below35: neverDrinkFemaleBelow35,
                                above35: neverDrinkFemaleAbove35,
                                above72: neverDrinkFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 2, radio_text: t('LifeStyleAssessment.Alcohol.rId2'), gender: {
                            Male: {
                                below35: onceMonthDrinkMaleBelow35,
                                above35: onceMonthDrinkMaleAbove35,
                                above72: onceMonthDrinkMaleAbove72
                            },
                            Female: {
                                below35: onceMonthDrinkFemaleBelow35,
                                above35: onceMonthDrinkFemaleAbove35,
                                above72: onceMonthDrinkFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 3, radio_text: t('LifeStyleAssessment.Alcohol.rId3'), gender: {
                            Male: {
                                below35: twoToTenDrinkMaleBelow35,
                                above35: twoToTenDrinkMaleAbove35,
                                above72: twoToTenDrinkMaleAbove72
                            },
                            Female: {
                                below35: twoToTenDrinkFemaleBelow35,
                                above35: twoToTenDrinkFemaleAbove35,
                                above72: twoToTenDrinkFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 4, radio_text: t('LifeStyleAssessment.Alcohol.rId4'), gender: {
                            Male: {
                                below35: elevenToFifteenDrinkMaleBelow35,
                                above35: elevenToFifteenDrinkMaleAbove35,
                                above72: elevenToFifteenDrinkMaleAbove72
                            },
                            Female: {
                                below35: elevenToFifteenDrinkFemaleBelow35,
                                above35: elevenToFifteenDrinkFemaleAbove35,
                                above72: elevenToFifteenDrinkFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 5, radio_text: t('LifeStyleAssessment.Alcohol.rId5'), gender: {
                            Male: {
                                below35: moreThanFifteenDrinkMaleBelow35,
                                above35: moreThanFifteenDrinkMaleAbove35,
                                above72: moreThanFifteenDrinkMaleAbove72
                            },
                            Female: {
                                below35: moreThanFifteenDrinkFemaleBelow35,
                                above35: moreThanFifteenDrinkFemaleAbove35,
                                above72: moreThanFifteenDrinkFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 6, radio_text: t('LifeStyleAssessment.Alcohol.rId6'), gender: {
                            Male: {
                                below35: dailyDrinkMaleBelow35,
                                above35: dailyDrinkMaleAbove35,
                                above72: dailyDrinkMaleAbove72
                            },
                            Female: {
                                below35: dailyDrinkFemaleBelow35,
                                above35: dailyDrinkFemaleAbove35,
                                above72: dailyDrinkFemaleAbove72
                            }
                        }
                    },
                ]
            }
        ]
    }

    const handleAlcohol = (e, key) => {
        console.log("key value is here >>111",key )
        let ha;
        if(key == 1)
        {
            ha = "Never";
        }
        else if (key == 2)
        {
            ha = "Once a month or Occasionally";
        }
        else if ( key == 3)
        {
            ha = "2 to 10 times a month"
        }
        else if ( key == 4)
        {
            ha  = "11 to 15 times a month"
        }
        else if ( key == 5)
        {
            ha = "More than 15 times a month";
        }
        else if (key == 6)
        {
            ha = "Daily";
        }
        console.log("value is >>",ha)
        alcoholConsumption = contentData.options[0].radio_button[key - 1].radio_text;
        console.log("alcoholConsumption is >>",alcoholConsumption)
        setAlcoholConsumption(alcoholConsumption)
        setErrorMsg({ alcohol: "" })
        window.localStorage.setItem('alcohol', JSON.stringify(ha))
        window.localStorage.setItem('alcohol1', JSON.stringify(alcoholConsumption))
        console.log(ha)
        let lottie = handleLottie(key, contentData)
        setLottiePlayer(lottie);
    }

    const handleSubmit = () => {
        const alcohol = JSON.parse(window.localStorage.getItem('alcohol'))
        if (alcohol) {
            setErrorMsg({ alcohol: "" })
            navigate(`${homePageUrl}stress`)
        }
        else {
            setErrorMsg({ alcohol: t('Messages.LifestyleAssessment.1') })
        }

    }

    const handleNavigationLottie = () => {
        let alcohol = window.localStorage.getItem('alcohol')
        let alcohol1 = window.localStorage.getItem('alcohol1')
        console.log("alcohol value is >>", alcohol)
        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        setAlcoholConsumption(JSON.parse(alcohol))
        if (alcohol == null) {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
        } else {
            alcohol = alcohol.replaceAll('"', '')
            alcohol1 = alcohol1.replaceAll('"', '')
            console.log();
            if(JSON.parse(window.localStorage.getItem('lang')) == "en")
            {
                let id = contentData.options[0].radio_button.find((rep) => rep.radio_text == alcohol).rId
                handleAlcohol(1, id)
            }
            else
            {
                let id = contentData.options[0].radio_button.find((rep) => rep.radio_text == alcohol1).rId
                handleAlcohol(1, id)
            }
            
        }
    }

    //get local storage
    useEffect(() => {
        const alcohol = window.localStorage.getItem('alcohol')
        setAlcoholConsumption(JSON.parse(alcohol))

        if(JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en")
        {
            let lang = "en";
            handleClick(lang);
        }
        else
        {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
        handleNavigationLottie()
    },[])

    const handleClick = (lang) => 
    {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>",lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>",t)
        setLanguage(lang);
    }

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className='align-items-center'>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading typ-lottie">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                            </Col>
                            {
                                console.log("alcoholConsumption value is >>",alcoholConsumption)
                            }
                            {
                                console.log("language value is >>",language)
                            }
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                            <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                                <p className="cm-para typ-medium typ-center typ-form-title typ-mob-left typ-max-wid">
                                    {contentData.options[0].option_text}
                                </p>
                                <div>
                                    <div className='bs-form mt-0'>
                                        <FormGroup className='radio-box typ-timeline' tag="fieldset">
                                            {contentData.options[0].radio_button.map((item) => {
                                                { 
                                                    console.log("item.radio_text value is >>1",alcoholConsumption,item.radio_text === alcoholConsumption)
                                                }
                                                return (
                                                    <React.Fragment key={item.rId}>
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input type="radio"
                                                                    name="exercise"
                                                                    checked={item.radio_text === alcoholConsumption}
                                                                    value={item.radio_text}
                                                                    onChange={(e) => handleAlcohol(e, item.rId)} />{' '}
                                                                <div className='check-box-label'>
                                                                    <div className='circle-box'>
                                                                        <div className='circle'></div>
                                                                    </div>
                                                                    <p className='radio-txt'>
                                                                        {item.radio_text}
                                                                    </p>
                                                                </div>
                                                            </Label>
                                                        </FormGroup>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </FormGroup>
                                        {errorMsg.alcohol && <p className='error-message'>{errorMsg.alcohol}</p>}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prev={`${homePageUrl}tobacco`} submit next={handleSubmit} />
            <Footer />
        </div>
    )
}


export default Alcohol;