import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

//Constant
import { homePageUrl } from "./helper/ContentConstants";

//Pages
import Test from "./compnents/pages/test/Test";
import SelectLanguage from "./compnents/pages/selectLanguage/SelectLanguage";
import TakeThetest from "./compnents/pages/takeTheTest/TakeTheTest";
import GeneralDetails from "./compnents/pages/generalDetails/generalDetails";
import GeneralDetails2 from "./compnents/pages/generalDetails/generalDetails2";

import SleepHours from "./compnents/pages/lifestyleAssessment/SleepHours";
import Exercise from "./compnents/pages/lifestyleAssessment/Exercise";
import VegetableInTake from "./compnents/pages/lifestyleAssessment/VegetableInTake";
import Tobacco from "./compnents/pages/lifestyleAssessment/Tobacco";
import Alcohol from "./compnents/pages/lifestyleAssessment/Alcohol";

import Weight from "./compnents/pages/bmi/Weight";
import Height from "./compnents/pages/bmi/Height";

import HalfWayDone from "./compnents/pages/halfwayDone/halfwayDone";
import DiseaseDetails from "./compnents/pages/diseaseDetails/diseaseDetails";
import FamilyHistory from "./compnents/pages/familyHistory/FamilyHistory";
import MedicationDetails from "./compnents/pages/medicationDetails/MedicationDetails";
import MedicationDetails2 from "./compnents/pages/medicationDetails/MedicationDetails2";
import SugarValues from "./compnents/pages/sugarValues/SugarValues";
import SugarValues2 from "./compnents/pages/sugarValues/SugarValues2";
import SugarValues3 from "./compnents/pages/sugarValues/SugarValues3";
import HeartDisease from "./compnents/pages/heartDisease/HeartDisease";
import OtpVerification from "./compnents/pages/otpVerification/OtpVerification";
import OtpVerification2 from "./compnents/pages/otpVerification/OtpVerification2";
import OtpVerification3 from "./compnents/pages/otpVerification/OtpVerification3";
import Symptoms from "./compnents/pages/symptoms/Symptoms";
import SymtomSituations from "./compnents/pages/symtomSituations/SymtomSituations";
import AlmostDone from "./compnents/pages/almostDone/AlmostDone";
import Cholesterol from "./compnents/pages/cholesterol/Cholesterol";
import Cholesterol2 from "./compnents/pages/cholesterol/Cholesterol2";
import BloodPressure from "./compnents/pages/bloodPressure/BloodPressure";
import BloodPressure2 from "./compnents/pages/bloodPressure/BloodPressure2";
import BloodPressure3 from "./compnents/pages/bloodPressure/BloodPressure3";
import HealthScore from "./compnents/pages/healthScore/HealthScore";
// import HealthScore2 from "./compnents/pages/healthScore/HealthScore2";
import SelectPatient from "./compnents/pages/selectPatient/SelectPatient";
import WaistSize from "./compnents/pages/waistSize/WaistSize";
import Stress from "./compnents/pages/stress/Stress";
import Header from "./compnents/layouts/header/Header";
import Reports from "./compnents/pages/reports/Reports"
import SubscriptionHome from "./compnents/pages/subscription/SubscriptionHome";
import ThankYou from "./compnents/pages/thankYou/ThankYou";
import SubscriptionVideo from "./compnents/pages/subscription/SubscriptionVideos";
import SubscriptionVideosList from "./compnents/pages/subscription/SubscriptionVideosList";
import SubscriptionMed from "./compnents/pages/subscription/SubscriptionMed";
import SubscriptionDiscountCoupons from "./compnents/pages/subscription/SubscriptionDiscountCoupons";
import SubscriptionVideosPlayer from "./compnents/pages/subscription/SubscriptionVideosPlayer";
import SubscriptionMeditationVideosList from "./compnents/pages/subscription/SubscriptionMeditationVideosList";
import SubscriptionEducationVideosList from "./compnents/pages/subscription/SubscriptionEducationVideosList";
import SubscriptionEducationVideosPlayer from "./compnents/pages/subscription/SubscriptionEducationVideosPlayer";
import SubscriptionMeditationVideosPlayer from "./compnents/pages/subscription/SubscriptionMeditationVideosPlayer";
import PrivacyPolicy from "./compnents/pages/privacyPolicy/PrivacyPolicy";
import TermsCondition from "./compnents/pages/termsCondition/TermsCondition";
import AddYourVitals from "./compnents/pages/addYourVitals/AddYourVitals";
import RecordSubmitted from "./compnents/pages/recordSubmitted/RecordSubmitted";
import Exersice from "./compnents/pages/exersice/Exersice";
import DiseaseEducation from "./compnents/pages/diseaseEducation/DiseaseEducation";
import Meditation from "./compnents/pages/meditation/Meditation";
import Vitals from "./compnents/pages/vitals/Vitals";
import PauseDailyVideo from "./compnents/pages/pauseDailyVideo/PauseDailyVideo";
import PaymentSuccessful from "./compnents/pages/paymentSuccessful/PaymentSuccessful";
import PaymentFailure from "./compnents/pages/paymentFailure/PaymentFailure";
import WhatsAppLinkFailure from "./compnents/pages/whatsAppLinkFailure/WhatsAppLinkFailure";
import VerifyUser from "./compnents/pages/verifyUser/VerifyUser";
import Assessment from "./compnents/pages/weeklyAssessment/Assessment";
import ThankYouMessage from "./compnents/pages/thankYouMessage/ThankYouMessage";
import ExerciseVerify from "./compnents/pages/exerciseVerify/ExerciseVerify";
import DiseaseEducationVerify from "./compnents/pages/diseaseEducationVerify/DiseaseEducationVerify";
import MeditationVerify from "./compnents/pages/meditationVerify/MeditationVerify";
import DiseaseEducationVideo from "./compnents/pages/diseaseEducationVideo/DiseaseEducationVideo";
import MeditationVideo from "./compnents/pages/meditationVideo/MeditationVideo";
import FeedbackComplaints from "./compnents/forms/FeedbackComplaints";

import { AnimatePresence } from "framer-motion";


function App() {

  const location = useLocation();
  let [progressbarVal, setProgressbarVal] = useState(0)
  let [whatsapp, setWhatsapp] = useState(false)
  let [dashboard, setDashboard] = useState(false)

  useEffect(() => {
    let myLocation = location.pathname
    let pageCount = 29;
    let perPageVal = 100 / pageCount
    if (myLocation == `${homePageUrl}`) { setProgressbarVal(0) }
    else if (myLocation == `${homePageUrl}select-patient`) { setProgressbarVal(perPageVal * 1) }
    else if (myLocation == `${homePageUrl}general-details`) { setProgressbarVal(perPageVal * 2) }
    else if (myLocation == `${homePageUrl}general-details2`) { setProgressbarVal(perPageVal * 3) }

    else if (myLocation == `${homePageUrl}sleep-hours`) { setProgressbarVal(perPageVal * 4) }
    else if (myLocation == `${homePageUrl}exercise`) { setProgressbarVal(perPageVal * 5) }
    else if (myLocation == `${homePageUrl}vegetable-in-take`) { setProgressbarVal(perPageVal * 5) }
    else if (myLocation == `${homePageUrl}tobacco`) { setProgressbarVal(perPageVal * 7) }
    else if (myLocation == `${homePageUrl}alcohol`) { setProgressbarVal(perPageVal * 8) }

    else if (myLocation == `${homePageUrl}stress`) { setProgressbarVal(perPageVal * 9) }
    else if (myLocation == `${homePageUrl}waist-size`) { setProgressbarVal(perPageVal * 10) }

    else if (myLocation == `${homePageUrl}weight`) { setProgressbarVal(perPageVal * 11) }
    else if (myLocation == `${homePageUrl}height`) { setProgressbarVal(perPageVal * 11) }


    else if (myLocation == `${homePageUrl}halfway-done`) { setProgressbarVal(perPageVal * 12) }
    else if (myLocation == `${homePageUrl}disease-details`) { setProgressbarVal(perPageVal * 13) }
    else if (myLocation == `${homePageUrl}family-history`) { setProgressbarVal(perPageVal * 14) }
    else if (myLocation == `${homePageUrl}medication-details`) { setProgressbarVal(perPageVal * 15) }
    else if (myLocation == `${homePageUrl}medication-details2`) { setProgressbarVal(perPageVal * 16) }
    else if (myLocation == `${homePageUrl}heart-disease`) { setProgressbarVal(perPageVal * 17) }
    else if (myLocation == `${homePageUrl}heart-disease`) { setProgressbarVal(perPageVal * 18) }
    else if (myLocation == `${homePageUrl}symptoms`) { setProgressbarVal(perPageVal * 19) }
    else if (myLocation == `${homePageUrl}symtom-situations`) { setProgressbarVal(perPageVal * 20) }
    else if (myLocation == `${homePageUrl}sugar-values`) { setProgressbarVal(perPageVal * 21) }
    else if (myLocation == `${homePageUrl}sugar-values2`) { setProgressbarVal(perPageVal * 22) }
    else if (myLocation == `${homePageUrl}cholesterol`) { setProgressbarVal(perPageVal * 23) }
    else if (myLocation == `${homePageUrl}cholesterol2`) { setProgressbarVal(perPageVal * 24) }
    else if (myLocation == `${homePageUrl}blood-pressure`) { setProgressbarVal(perPageVal * 25) }
    else if (myLocation == `${homePageUrl}blood-pressure2`) { setProgressbarVal(perPageVal * 26) }
    else if (myLocation == `${homePageUrl}almost-done`) { setProgressbarVal(perPageVal * 27) }
    else if (myLocation == `${homePageUrl}otp-verification`) { setProgressbarVal(perPageVal * 28) }
    else if (myLocation == `${homePageUrl}health-score`) { setProgressbarVal(100) }
  }, [location]);

  useEffect(() => {
    let myLocation = location.pathname;
    if (myLocation.includes("reports")) {
      // console.log("whatsapp screen")
      setWhatsapp(true)
    }
    if (myLocation.includes("subscription-home") || myLocation.includes("subscription-video-list") || myLocation.includes("subscription-med")
      || myLocation.includes("subscription-discount-coupons") || myLocation.includes("subscription-videos-player") || myLocation.includes("subscription-education-video-list") ||
      myLocation.includes("subscription-meditation-video-list") || myLocation.includes("subscription-education-videos-player") || myLocation.includes("subscription-meditation-videos-player") ||
      myLocation.includes("terms-condition") || myLocation.includes("disease-education") || myLocation.includes("exercise-video") || myLocation.includes("payment-successful") ||
      myLocation.includes("payment-failure") || myLocation.includes("whatsapp-link-failure")  || myLocation.includes("assessment") ||
      myLocation.includes("thank-you-message")  || myLocation.includes("user-verification") || myLocation.includes("disease-education-video") 
    ) {
      setWhatsapp(true)
      setDashboard(true)
    }

    if(myLocation.includes("exersice") || myLocation.includes("meditation") || myLocation.includes("user-verify") || myLocation.includes("assessment")
    || myLocation.includes("verify-user") || myLocation.includes("user-verification") )
    {
      setWhatsapp(true)
      setDashboard(false)
    }

    if(myLocation.includes("meditation-video"))
    {
      setWhatsapp(true)
      setDashboard(true)
    }

    // if()
    // {
    //   setWhatsapp(true)
    //   setDashboard(false)
    // }

    // if()
    // {
    //   setWhatsapp(true)
    //   setDashboard(false)
    // }



    if (myLocation.includes("vitals")) {
      setWhatsapp(true)
      setDashboard(false)
    }

    if (myLocation.includes("privacy-policy") || myLocation.includes("terms-condition") || myLocation.includes("user-verification")) {
      setWhatsapp(true)
      setDashboard(false)
    }

    if ((myLocation.includes("pause-daily-video")) || (myLocation.includes("payment-successful"))) {
      setDashboard(true)
    }
  })


  return (
    <>
      <Header barValue={progressbarVal} whatsappScreen={whatsapp} dashboardScreen={dashboard} />
      <AnimatePresence >
        <Routes location={location} key={location.pathname}>
          <Route path={`${homePageUrl}test`} element={<Test />} />
          <Route path={`${homePageUrl}`} element={<SelectLanguage />} />
          <Route path={`${homePageUrl}take-the-test`} element={<TakeThetest />} />
          <Route path={`${homePageUrl}select-patient`} element={<SelectPatient />} />
          <Route path={`${homePageUrl}general-details`} element={<GeneralDetails />} />
          <Route path={`${homePageUrl}general-details2`} element={<GeneralDetails2 />} />

          <Route path={`${homePageUrl}sleep-hours`} element={<SleepHours />} />
          <Route path={`${homePageUrl}exercise`} element={<Exercise />} />
          <Route path={`${homePageUrl}vegetable-in-take`} element={<VegetableInTake />} />
          <Route path={`${homePageUrl}tobacco`} element={<Tobacco />} />
          <Route path={`${homePageUrl}alcohol`} element={<Alcohol />} />

          <Route path={`${homePageUrl}stress`} element={<Stress />} />

          <Route path={`${homePageUrl}weight`} element={<Weight />} />
          <Route path={`${homePageUrl}height`} element={<Height />} />

          <Route path={`${homePageUrl}waist-size`} element={<WaistSize />} />
          <Route path={`${homePageUrl}halfway-done`} element={<HalfWayDone />} />
          <Route path={`${homePageUrl}disease-details`} element={<DiseaseDetails />} />
          <Route path={`${homePageUrl}family-history`} element={<FamilyHistory />} />
          <Route path={`${homePageUrl}medication-details`} element={<MedicationDetails />} />
          <Route path={`${homePageUrl}medication-details2`} element={<MedicationDetails2 />} />
          <Route path={`${homePageUrl}sugar-values`} element={<SugarValues />} />
          <Route path={`${homePageUrl}sugar-values2`} element={<SugarValues2 />} />
          <Route path={`${homePageUrl}sugar-values3`} element={<SugarValues3 />} />
          <Route path={`${homePageUrl}heart-disease`} element={<HeartDisease />} />
          <Route path={`${homePageUrl}otp-verification`} element={<OtpVerification />} />
          <Route path={`${homePageUrl}otp-verification2`} element={<OtpVerification2 />} />
          <Route path={`${homePageUrl}otp-verification3`} element={<OtpVerification3 />} />
          <Route path={`${homePageUrl}symptoms`} element={<Symptoms />} />
          <Route path={`${homePageUrl}symtom-situations`} element={<SymtomSituations />} />
          <Route path={`${homePageUrl}almost-done`} element={<AlmostDone />} />
          <Route path={`${homePageUrl}cholesterol`} element={<Cholesterol />} />
          <Route path={`${homePageUrl}cholesterol2`} element={<Cholesterol2 />} />
          <Route path={`${homePageUrl}blood-pressure`} element={<BloodPressure />} />
          <Route path={`${homePageUrl}blood-pressure2`} element={<BloodPressure2 />} />
          <Route path={`${homePageUrl}blood-pressure3`} element={<BloodPressure3 />} />
          <Route path={`${homePageUrl}health-score`} element={<HealthScore />} />
          {/* <Route path={`${homePageUrl}health-score2`} element={<HealthScore2 />} /> */}
          <Route path={`${homePageUrl}reports`} element={<Reports />} />
          <Route path={`${homePageUrl}thank-you`} element={<ThankYou />} />
          <Route path={`${homePageUrl}subscription-home`} element={<SubscriptionHome />} />

          <Route path={`${homePageUrl}subscription-video`} element={<SubscriptionVideo />} />

          <Route path={`${homePageUrl}subscription-video-list`} element={<SubscriptionVideosList />} />
          <Route path={`${homePageUrl}subscription-med`} element={<SubscriptionMed />} />
          <Route path={`${homePageUrl}subscription-discount-coupons`} element={<SubscriptionDiscountCoupons />} />
          <Route path={`${homePageUrl}subscription-videos-player`} element={<SubscriptionVideosPlayer />} />
          <Route path={`${homePageUrl}subscription-education-video-list`} element={<SubscriptionEducationVideosList />} />
          <Route path={`${homePageUrl}subscription-meditation-video-list`} element={<SubscriptionMeditationVideosList />} />
          <Route path={`${homePageUrl}subscription-education-videos-player`} element={<SubscriptionEducationVideosPlayer />} />
          <Route path={`${homePageUrl}subscription-meditation-videos-player`} element={<SubscriptionMeditationVideosPlayer />} />
          <Route path={`${homePageUrl}privacy-policy`} element={<PrivacyPolicy />} />
          <Route path={`${homePageUrl}terms-condition`} element={<TermsCondition />} />
          <Route path={`${homePageUrl}add-your-vitals`} element={<AddYourVitals />} />
          <Route path={`${homePageUrl}record-submitted`} element={<RecordSubmitted />} />
          <Route path={`${homePageUrl}exersice`} element={<Exersice />} />
          <Route path={`${homePageUrl}disease-education`} element={<DiseaseEducation />} />
          <Route path={`${homePageUrl}meditation`} element={<Meditation />} />
          <Route path={`${homePageUrl}vitals`} element={<Vitals />} />
          <Route path={`${homePageUrl}exercise-video`} element={<PauseDailyVideo />} />
          <Route path={`${homePageUrl}payment-successful`} element={<PaymentSuccessful />} />
          <Route path={`${homePageUrl}payment-failure`} element={<PaymentFailure />} />
          <Route path={`${homePageUrl}whatsapp-link-failure`} element={<WhatsAppLinkFailure />} />
          <Route path={`${homePageUrl}verify-user`} element={<ExerciseVerify />} />
          <Route path={`${homePageUrl}assessment`} element={<Assessment />} />
          <Route path={`${homePageUrl}thank-you-message`} element={<ThankYouMessage />} />
          <Route path={`${homePageUrl}user-verify`} element={<DiseaseEducationVerify />} />
          <Route path={`${homePageUrl}user-verification`} element={<MeditationVerify />} />
          <Route path={`${homePageUrl}disease-education-video`} element={<DiseaseEducationVideo />} />
          <Route path={`${homePageUrl}meditation-video`} element={<MeditationVideo />} />
          <Route path={`${homePageUrl}feedback-complaint`} element={<FeedbackComplaints />} />
          <Route path={`${homePageUrl}`} element={<SubscriptionHome />} />
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;