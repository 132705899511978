import React, { useState, useEffect } from "react";
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images
import Right from '../../../assets/images/select-language/right.svg';

// css
import '../../../assets/sass/custom.scss';

// json 

// default 
import defaultLottie from '../../../assets/json/cholestrol/cholesterol.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
function Cholesterol() {
    const { t, i18n } = useTranslation();

    let navigate = useNavigate()

    let [bloodCholesterol, setBloodCholesterol] = useState('')
    let [errorMsg, setErrorMsg] = useState('')
    let [language, setLanguage] = useState("")

    const [lottiePlayer, setLottiePlayer] = useState(defaultLottie);

    let radioValues = [{
        rId: 1, radio_text: t('Cholesterol.questions.rId1')
    },
    {
        rId: 2, radio_text: t('Cholesterol.questions.rId2')
    }]

    // let radioValues = [{ rId: 1, radio_text: "Yes" }, { rId: 2, radio_text: "No" }]
    // let radioValues = [{ rId: 1, radio_text: t('Cholesterol.questions.rId1') },
    // { rId: 2, radio_text:  t('Cholesterol.questions.rId2')  }]
    const handleBloodCholesterol = (key) => {
        bloodCholesterol = radioValues[key - 1].radio_text;
        let bp;
        if (key == 1) {
            bp = "Yes"
        }
        else {
            bp = "No"
        }
        setBloodCholesterol(bloodCholesterol)
        setErrorMsg("")
        window.localStorage.setItem('blood-cholesterol', JSON.stringify(bp))
        window.localStorage.setItem('blood-cholesterol1', JSON.stringify(bloodCholesterol))
    }

    const handlePrevious = () => {
        const bloodSugar = JSON.parse(window.localStorage.getItem('blood-sugar'))
        if (bloodSugar == "No") {
            navigate(`${homePageUrl}sugar-values`)
        }
        else navigate(`${homePageUrl}sugar-values2`)
    }

    const handleSubmit = () => {
        const bloodCholesterol = window.localStorage.getItem('blood-cholesterol')
        if (!bloodCholesterol) {
            setErrorMsg(t('Messages.Cholesterol.1'))
        }
        else if (JSON.parse(bloodCholesterol) == "Yes") {
            navigate(`${homePageUrl}cholesterol2`)
        }
        else navigate(`${homePageUrl}blood-pressure`)
    }

    const handleNavigationLottie = () => {
        let bloodCholesterol = JSON.parse(window.localStorage.getItem('blood-cholesterol'))
        setBloodCholesterol(bloodCholesterol)
    }

    //get local storage
    useEffect(() => {
        const bloodCholesterol = JSON.parse(window.localStorage.getItem('blood-cholesterol'))
        const bloodCholesterol1 = JSON.parse(window.localStorage.getItem('blood-cholesterol1'))
        console.log("Nllod cholesterol value is >>",bloodCholesterol);
        console.log("Nllod cholesterol value is >>123",bloodCholesterol1);
        
        if (JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en") {
            let lang = "en";
            handleClick(lang);
            setBloodCholesterol(bloodCholesterol)
        }
        else {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
            setBloodCholesterol(bloodCholesterol1)
        }

        // handleNavigationLottie()
    }, [])
    const handleClick = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t)
        setLanguage(lang);

    }

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className='align-items-center'>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('Cholesterol.heading')}
                                        </h2>
                                        <p className="subheading">
                                            {t('Cholesterol.subheading')}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('Cholesterol.heading')}
                                        </h2>
                                        <p className="subheading">
                                            {t('Cholesterol.subheading')}
                                        </p>
                                    </div>
                                }
                                <p className="cm-para typ-black typ-center typ-mob-left typ-medium typ-max-wid typ-form-title">
                                    {t('Cholesterol.DoYouKnowYour')}?
                                </p>
                                <p className="cm-para typ-black typ-center typ-mob-left typ-medium typ-max-wid typ-form-title typ-grey">
                                    ({t('Cholesterol.PreferablyTested')})
                                </p>
                                {
                                    console.log("Blood cholesterol value is >>",bloodCholesterol)
                                }
                                <div className="bs-form">
                                    <FormGroup className='radio-box typ-gender' tag="fieldset">
                                        {radioValues.map((item) => {
                                            return (
                                                <React.Fragment key={item.rId}>
                                                    <FormGroup className="check-box typ-btn form-check" check>
                                                        <Label check>
                                                            <Input
                                                                value={item.radio_text}
                                                                checked={item.radio_text === bloodCholesterol}
                                                                type="radio" name="radio1" onChange={() => handleBloodCholesterol(item.rId)} />{''}
                                                            <div className='radio-label'>{item.radio_text}
                                                                <img src={Right} className='right-icon' alt="Next-arrow" />
                                                            </div>
                                                        </Label>
                                                    </FormGroup>
                                                </React.Fragment>
                                            )
                                        })}
                                    </FormGroup>
                                    {errorMsg && <p className="error-message">{errorMsg}</p>}
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prevBtn={handlePrevious} submit next={handleSubmit} />
            <Footer />
        </div>
    );
}

export default Cholesterol;