import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from 'reactstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";

//constant
import { homePageUrl } from "../../../helper/ContentConstants";
// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images

// css
import "../../../assets/sass/custom.scss";

// components
import Footer from "../../layouts/footer/Footer";
import RoutingArrow from "../../shared/RoutingArrow/RoutingArrow";
import { addUserApi } from '../../../helper/ContentConstants';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function PaymentFailure() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
      setTimeout(()=>{
          if(JSON.parse(window.localStorage.getItem('lang')) != "")
          {
              handleClick("en");
          }
          else if(JSON.parse(window.localStorage.getItem('lang')) == "mr")
          {
              handleClick("mr");
          }
          else
          {
              handleClick("hi");
          }
      },1000);
},[])

const handleClick = (lang) => 
    {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>",lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>",t)
    }
  return (
    <div className="main">
      <Container>
        <AnimationWrapper>
          <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card'>
            <Row className="align-items-center">
              <Col
                xl={{ size: 6, offset: 6 }}
                lg={{ size: 6, offset: 6 }}
                md={12}
                sm={6}
              >
                {isMobile && (
                  <div className="bs-heading typ-center">
                    <h2 className="heading">{t('PaymentFailure.1')}</h2>
                    <p className="subheading">{t('PaymentFailure.2')}.</p>
                  </div>
                )}
              </Col>
              <Col xl="6" lg="6" md="12">
                <div className="bs-img-sprite typ-hafway-done bg-1"></div>
              </Col>
              <Col xl="6" lg="6" md="12">
                {isBrowser && (
                  <div className="bs-heading typ-center">
                    <h2 className="heading">{t('PaymentFailure.1')}</h2>
                    <p className="subheading">{t('PaymentFailure.2')}.</p>
                  </div>
                )}
                <Link to={`${homePageUrl}health-score`} className='bs-btn typ-check-score btn btn-secondary'>
                {t('PaymentFailure.3')}
                </Link>
              </Col>
            </Row>
          </motion.div>
        </AnimationWrapper>
      </Container>
      <Footer />
    </div>
  );
}

export default PaymentFailure;
