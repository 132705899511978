import React, { useEffect, useState } from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  FormGroup,
  Input,
  Label,
  Table,
  Modal,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

//constant
import {
  homePageUrl,
  userDetailApi,
  createOrder,
  payment,
  subscriptionHome,
  PlanDetails,
  getUserLatestPlanDetails,
  getAssessmentLogs,
  getAssessmentDetailsById,
} from "../../../helper/ContentConstants";
import hhmImage from "../../../helper/ImageConstants";

// images
import yt_thumbnail from "../../../assets/images/health-score/your-customized-plan.jpg";
import exersice_img from "../../../assets/images/health-score/exersice.png";
import calender_img from "../../../assets/images/health-score/calender.png";
import monitoring_img from "../../../assets/images/health-score/monitoring.png";
import madhav_baug from "../../../assets/images/health-score/madhav-baug.jpg";
import popular from "../../../assets/images/svg/popular.svg";

// css
import "../../../assets/sass/custom.scss";

// json
import LottiFiles from "../../../assets/json/health-score.json";
import excellent from "../../../assets/json/excellent.json";
import veryGood from "../../../assets/json/very-good.json";
import fair from "../../../assets/json/fair.json";
import good from "../../../assets/json/good.json";
import poor from "../../../assets/json/poor.json";

// components
import Footer from "../../layouts/footer/Footer";
import axios from "axios";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useRazorpay from "react-razorpay";

function Reports() {
  const Razorpay = useRazorpay();
  let navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  // accordion
  const [open, setOpen] = useState("1");
  const accordion = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  //Final Result parameters
  let [finalPoint, setFinalPoint] = useState("");
  let [showScore, setShowScore] = useState("");
  let [healthStatus, setHealthStatus] = useState("");
  let [messageDesc, setMessageDesc] = useState("");
  let [isReadMore, setIsReadMore] = useState(true);
  let [finalMsg, setFinalMsg] = useState("");
  let [scoreMeter, setScoreMeter] = useState("");
  let [imgClass, setImgClass] = useState("bg-1");

  //Score analysis (Zones)
  let [severe, setSevere] = useState("");
  let [moderate, setModerate] = useState("");
  let [mild, setMild] = useState("");

  // console.log("severe value", severe)

  //All logic values
  let [agepointsVal, setAgepointsVal] = useState("");
  let [alcoholpointsVal, setAlcoholpointsVal] = useState("");
  let [bmipointsVal, setBmipointsVal] = useState("");
  let [bplowerpointsVal, setBplowerpointsVal] = useState("");
  let [bppointsVal, setBppointsVal] = useState("");
  let [diseasecalpointsVal, setDiseasecalpointsVal] = useState("");
  let [exercisepointsVal, setExercisepointsVal] = useState("");
  let [familyhistorypointsVal, setFamilyhistorypointsVal] = useState("");
  let [hdlpointVal, setHdlpointVal] = useState("");
  let [heartSurgerypointsVal, setHeartSurgerypointsVal] = useState("");
  let [ldlpointVal, setLdlpointVal] = useState("");
  let [mealpointsVal, setMealpointsVal] = useState("");
  let [noofMedicinepointsVal, setNoofMedicinepointsVal] = useState("");
  let [sleepingpointsVal, setSleepingpointsVal] = useState("");
  let [tobaccopointsVal, setTobaccopointsVal] = useState("");
  let [stresspointsVal, setStresspointsVal] = useState("");
  let [sugarfpointVal, setSugarfpointVal] = useState("");
  let [sugarhbpointVal, setSugarhbpointVal] = useState("");
  let [sugarpppointVal, setSugarpppointVal] = useState("");
  let [symptomspointVal, setSymptomspointVal] = useState("");
  let [totcholpointVal, setTotcholpointVal] = useState("");
  let [waistSizePointsVal, setWaistSizePointsVal] = useState("");
  let [anginapointVal, setAnginapointVal] = useState("");
  let [lifestylepointVal, setLifestylepointVal] = useState("");
  let [heartDiseasepointVal, setHeartDiseasepointVal] = useState("");

  //Score Analysis values and messages
  let [bmiVal, setBmiVal] = useState("");

  let [bmiMessage, setBmiMessage] = useState("");
  let [ageMessage, setAgeMessage] = useState("");
  let [sleepMsg, setSleepMsg] = useState("");
  let [mealMsg, setMealMsg] = useState("");
  let [excerciseMsg, setExcerciseMsg] = useState("");
  let [alcoholMsg, setAlcoholMsg] = useState("");
  let [tobaccoMsg, setTobaccoMsg] = useState("");
  let [stressMsg, setStressMsg] = useState("");
  let [diastolicLowerBpMsg, setDiastolicLowerBp] = useState("");
  let [systolicUpperBpMsg, setSystolicUpperBpMsg] = useState("");
  let [fastingSugarMsg, setFastingSugarMsg] = useState("");
  let [postFastingSugarMsg, setPostFastingSugarMsg] = useState("");
  let [hba1cMsg, setHba1cMsg] = useState("");
  let [heartBlockageMsg, setHeartBlockageMsg] = useState("");
  let [symptomsMsg, setSymptomsMsg] = useState("");
  let [totalCholestrolMsg, setTotalCholestrolMsg] = useState("");
  let [ldlMessage, setLdlMsg] = useState("");
  let [hdlMessage, setHdlMsg] = useState("");
  let [aginaMsg, setAginaMsg] = useState("");
  let [familyhistoryMsg, setFamilyhistoryMsg] = useState("");
  let [lifestyleMsg, setLifestyleMsg] = useState("");

  //Actual values from each page from Database
  let [sleephour, setSleephour] = useState("");
  let [excercise, setExcercise] = useState("");
  let [vegetableInTake, setVegetableInTake] = useState("");
  let [tobacco, setTobacco] = useState("");
  let [alcohol, setAlcohol] = useState("");
  let [stress, setStress] = useState("");
  let [weight, setWeight] = useState("");
  let [heightFt, setHeightFt] = useState("");
  let [heightIn, setHeightIn] = useState("");
  let [hCholesterol, sethCholesterol] = useState("");
  let [hBbpHypertension, sethBbpHypertension] = useState("");
  let [diabete, setdiabete] = useState("");
  let [hDisease, sethDisease] = useState("");
  let [noDisease, setnoDisease] = useState("");
  let [famHCholesterol, setfamHCholesterol] = useState("");
  let [famHBbpHypertension, setfamHBbpHypertension] = useState("");
  let [famDiabete, setfamDiabete] = useState("");
  let [famHDisease, setfamHDisease] = useState("");
  let [famNoDisease, setfamNoDisease] = useState("");
  let [medicine, setmedicine] = useState("");
  let [medicineCount, setmedicineCount] = useState("");
  let [irregualrMedicine, setirregualrMedicine] = useState("");
  let [heartBlockage, setheartBlockage] = useState("");
  let [heartSurgery, setheartSurgery] = useState("");
  let [frequentUrination, setfrequentUrination] = useState("");
  let [excessiveHunger, setexcessiveHunger] = useState("");
  let [woundNotHealing, setwoundNotHealing] = useState("");
  let [weightLoss, setweightLoss] = useState("");
  let [weightGain, setweightGain] = useState("");
  let [jointPains, setjointPains] = useState("");
  let [headache, setheadache] = useState("");
  let [weaknessOfFatigue, setweaknessOfFatigue] = useState("");
  let [constipation, setconstipation] = useState("");
  let [sweating, setsweating] = useState("");
  let [noSymptoms, setnoSymptoms] = useState("");
  let [whileAtRest, setwhileAtRest] = useState("");
  let [duringRoutineWorkout, setduringRoutineWorkout] = useState("");
  let [afterHeavyExercise, setafterHeavyExercise] = useState("");
  let [noSuchSymptoms, setnoSuchSymptoms] = useState("");
  let [bloodSugar, setbloodSugar] = useState("");
  let [fastingSugar, setfastingSugar] = useState("");
  let [postFastingSugar, setpostFastingSugar] = useState("");
  let [hbac1, sethbac1] = useState("");
  let [bloodCholesterol, setbloodCholesterol] = useState("");
  let [totalCholestrol, settotalCholestrol] = useState("");
  let [ldl, setldl] = useState("");
  let [hdl, sethdl] = useState("");
  let [bloodPressure, setbloodPressure] = useState("");
  let [systolicUpperBp, setsystolicUpperBp] = useState("");
  let [diastolicLowerBp, setdiastolicLowerBp] = useState("");
  let [waistSize, setwaistSize] = useState("");
  let [tingling, setTingling] = useState("");
  let [age, setAge] = useState("");
  let [gender, setGender] = useState("Male");
  let [diseasePriority, setDiseasePriority] = useState("");
  let [userValue, setUserValue] = useState("");
  let [orderId, setOrderId] = useState("");
  let [diseaseArray, setDiseaseArray] = useState([]);
  let [planName, setPlanName] = useState("");
  let [planAmount, setPlanAmount] = useState("");
  let [success, setSuccess] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  let [planDetail, setPlanDetail] = useState("");
  let [paymentStatus, setPaymentStatus] = useState("");
  let [userLatestPlan, setUserLatestPlan] = useState("");
  let [userAssessmentLog, setUserAssessmentLog] = useState("");
  let [symptomsSituation, setSymptomsSituation] = useState("");
  useEffect(() => {
    let redZone = document.querySelectorAll(
      "#severeZone .bs-accordion .accordion .accordion-item"
    );
    let yellowZone = document.querySelectorAll(
      "#moderateZone .bs-accordion .accordion .accordion-item"
    );
    let greenZone = document.querySelectorAll(
      "#mildZone .bs-accordion .accordion .accordion-item"
    );
    // console.log("my red zone", redZone.length, "yellowZone", yellowZone.length, "greenZone", greenZone.length)
    setSevere((redZone.length / 16) * 100);
    setModerate((yellowZone.length / 16) * 100);
    setMild((greenZone.length / 16) * 100);
    // console.log(severe, moderate, mild)
  });

  useEffect(() => {
    //set-up
    if (process.env.NODE_ENV === "production") {
      window.localStorage.clear();
    }
    getUserData();
    DataCalculculation();
    // clean -up
    return () => {
      getUserData();
      DataCalculculation();
    };
  }, [success]);

  const getUserData = async () => {
    try {
      let id = location.state.id;
      console.log("Id is >>",id);
      // console.log("params value is >>",location.state);
      // console.log("params value is >>",location.state);
      // setUserValue(2751000);
      console.log("value is >>", 10139);
      let formData = new FormData();
      formData.append('id', location.state.id)
      const response = await axios({
        method: "post",
        url: getAssessmentDetailsById,
        data:formData
        // url: userDetailApi + "10139",
      });
      console.log("Response i1s >>", response.data.data[0]);

      if (response.data.statusCode == 200) {
        setTimeout(() => {
          setPaymentStatus(response.data.payment);
          console.log("Payment status is >>", paymentStatus);

          setSleephour(response.data.data[0].sleep);
          setExcercise(response.data.data[0].exercise);
          setVegetableInTake(response.data.data[0].vegetables);
          setTobacco(response.data.data[0].tabacco);
          setAlcohol(response.data.data[0].alcohol);
          setStress(response.data.data[0].stress);
          setWeight(response.data.data[0].weight);
          setHeightFt(response.data.data[0].heightFeet);
          setHeightIn(response.data.data[0].heightInches);
          sethCholesterol(
            response.data.data[0].cholesterolDiagnosed);
          sethBbpHypertension(
            response.data.data[0].highBpDiagnosed);
          setdiabete(response.data.data[0].diabetesDiagnosed);
          sethDisease(response.data.data[0].heartDiseaseDiagnosed);
          setnoDisease(response.data.data[0].noneOfTheAbove);
          setfamHCholesterol(
            response.data.data[0].familyCholesterol
          );
          setfamHBbpHypertension(
            response.data.data[0].familyHistoryHighBp
          );
          setfamDiabete(
            response.data.data[0].familyHistoryDiabetes
          );
          setfamHDisease(
            response.data.data[0].familyHistoryHeartDisease
          );
          setfamNoDisease(
            response.data.data[0].familyNoneOfTheAbove
          );
          setmedicine(response.data.data[0].medicines);
          setmedicineCount(response.data.data[0].noOfMedicine);
          setirregualrMedicine(
            response.data.data[0].missMedicines
          );
          setheartBlockage(response.data.data[0].heartBlockages);
          setheartSurgery(response.data.data[0].heartSurgery);
          setfrequentUrination(
            response.data.data[0].frequentUrination
          );
          setexcessiveHunger(
            response.data.data[0].excessiveHungerThirst
          );
          setwoundNotHealing(
            response.data.data[0].woundNotHealing
          );
          setweightLoss(response.data.data[0].weightLoss);
          setweightGain(response.data.data[0].weightGain);
          setjointPains(response.data.data[0].jointPains);
          setheadache(response.data.data[0].headache);
          setweaknessOfFatigue(
            response.data.data[0].weaknessFatigue
          );
          setconstipation(response.data.data[0].constipation);
          setsweating(response.data.data[0].sweating);
          setwhileAtRest(response.data.data[0].whileAtRest);
          setduringRoutineWorkout(
            response.data.data[0].duringRoutineWorkout
          );
          setafterHeavyExercise(
            response.data.data[0].afterHeavyExercise
          );
          setnoSuchSymptoms(response.data.data[0].noSuchSymtoms);
          setbloodSugar(response.data.data[0].sugarValue);
          setfastingSugar(response.data.data[0].sugarFS);
          setpostFastingSugar(response.data.data[0].sugarPP);
          sethbac1(response.data.data[0].hbA1c);
          setbloodCholesterol(
            response.data.data[0].cholesterolValue
          );
          settotalCholestrol(
            Number(response.data.data[0].totalCholesterol)
          );
          setldl(response.data.data[0].LDL);
          sethdl(response.data.data[0].HDL);
          setbloodPressure(
            response.data.data[0].BloodPressureValue
          ); 
          setsystolicUpperBp(response.data.data[0].systolic);
          setdiastolicLowerBp(response.data.data[0].diastolic);
          setnoSymptoms(response.data.data[0].noAboveSymptoms);
          setwaistSize(response.data.data[0].waistSize);
          setTingling(response.data.data[0].tinglingNumbness);
          setAge(response.data.data[0].age);
          setGender(response.data.data[0].gender);
          // setSymtomSituations(response.data.data[0].symptomSituation))
          setAgepointsVal(Number(response.data.data[0].agepoints));
          setAlcoholpointsVal(Number(response.data.data[0].alcoholpoints));
          setExcercise(response.data.data[0].exercise);
          setBmipointsVal(Number(response.data.data[0].bmipoints));
          setBplowerpointsVal(Number(response.data.data[0].bplowerpoints));
          setBppointsVal(Number(response.data.data[0].bppoints));
          setDiseasecalpointsVal(
            Number(response.data.data[0].diseasecalpoints)
          );
          setExercisepointsVal(
           Number(response.data.data[0].exercisepoints) 
          );
          setFamilyhistorypointsVal(
           Number(response.data.data[0].familyhistorypoints)
          );
          setHdlpointVal(Number(response.data.data[0].hdlpoint));
          setHeartSurgerypointsVal(
            Number(response.data.data[0].heartSurgerypoints)
          );
          setLdlpointVal(Number(response.data.data[0].ldlpoint));
          setMealpointsVal(Number(response.data.data[0].mealpoints));
          setNoofMedicinepointsVal(
            Number(response.data.data[0].noofMedicinepoints)
          );
          setSleepingpointsVal(
            response.data.data[0].sleepingpoints
          );
          setTobaccopointsVal(Number(response.data.data[0].tobaccopoints));
          setStresspointsVal(Number(response.data.data[0].stresspoints));
          setSugarfpointVal(Number(response.data.data[0].sugarfpoint));
          setSugarhbpointVal(Number(response.data.data[0].sugarhbpoint));
          setSugarpppointVal(Number(response.data.data[0].sugarpppoint));
          setSymptomspointVal(Number(response.data.data[0].symptomspoint));
          setTotcholpointVal(Number(response.data.data[0].totcholpoint));
          setWaistSizePointsVal(
            response.data.data[0].waistSizePoints
          );
          setAnginapointVal(Number(response.data.data[0].anginapoint));
          setLifestylepointVal(
           Number(response.data.data[0].lifestylepoint)
          );
          setHeartDiseasepointVal(
            response.data.data[0].heartDiseasepoint
          );
          setFinalPoint(response.data.data[0].score);
          setSymptomsSituation(response.data.data[0].symptomSituation);

          setSuccess(true);
        }, 3000);
        // console.log(
        //   "Data is fetched",
        //   JSON.parse(response.data.data[0].sleep)
        // );
      }
    } catch (error) {
      // console.log("error is ", error);
    }
  };

  // score message & calculation
  const DataCalculculation = () => {
    //calculate BMI
    let hmt = Number(heightFt) * 0.3048 + Number(heightIn) * 0.0254;
    let bmi = Number(weight) / (hmt * hmt);
    bmi = Math.round(bmi * 10) / 10;
    setBmiVal(bmi);

    // bmi message
    if (bmi < 18.5) {
      setBmiMessage("Your BMI says that you are Underweight");
    } else if (bmi >= 18.5 && bmi <= 24.9) {
      setBmiMessage("Your BMI says that you are Normal.");
    } else if (bmi >= 25.0 && bmi <= 29.9) {
      setBmiMessage("Your BMI says that you are Overweight.");
    } else if (bmi >= 30.0 && bmi <= 34.9) {
      setBmiMessage("Your BMI says that you are Obese.");
    } else if ((bmi >= 35.0 && bmi <= 39.9) || bmi > 40.0) {
      setBmiMessage("Your BMI says that you are Extremely Obese.");
    }

    //age message
    if (agepointsVal >= 2.6 && agepointsVal <= 7.5) {
      setAgeMessage(
        `Research says that age ${age} may bring some risk factors for heart diseases and associated risk factors.`
      );
    } else if (agepointsVal >= 7.6 && agepointsVal <= 12.5) {
      setAgeMessage(
        `Research says that age ${age} may bring mild risk factors for heart diseases and associated risk factors.`
      );
    } else if (agepointsVal >= 12.6 && agepointsVal <= 15) {
      setAgeMessage(`Research says that age ${age} may bring moderate risk factors for heart diseases and associated risk factors.
      `);
    } else if (age > 61 && agepointsVal === 15) {
      setAgeMessage(
        "Research says being at an age greater than 60 is in itself a risk factor for heart diseases and associated risk factors."
      );
    } else if (agepointsVal >= 2.5) {
      setAgeMessage(
        "Your age works as a shield against heart diseases and risk factors."
      );
    }

    // sleeping message
    if (sleepingpointsVal === 2 || sleepingpointsVal === 1.75) {
      setSleepMsg(
        "Allow yourself time to Rest & Sleep, as your current sleep cycle is not enough to maintain good health. You need more than 8 hours of sleep in 24 hours."
      );
    } else if (sleepingpointsVal === 1 || sleepingpointsVal === 0.5) {
      setSleepMsg(
        "Allow yourself time to Rest & Sleep to maintain good health. You need more than 8 hours of sleep in 24 hours."
      );
    } else if (sleepingpointsVal === 0) {
      setSleepMsg(
        "You are doing Good! Sleeping well is the best medicine to maintain good health."
      );
    }

    // mealpoints
    if (mealpointsVal === 2 && vegetableInTake === "Never") {
      setMealpointsVal(2);
      setMealMsg("Improve your diet with nutritional food intake.");
    } else if (mealpointsVal === 2) {
      setMealMsg("Your Diet is running low on Nutrients.");
    } else if (mealpointsVal === 1.5) {
      setMealMsg("Your Diet has only a Fair Amount of Nutrients.");
    } else if (mealpointsVal === 1.25) {
      setMealMsg("Your Diet has only an Average Amount of Nutrients.");
    } else if (
      mealpointsVal === 0 &&
      vegetableInTake === "5 to 6 times a week"
    ) {
      setMealMsg("Your Diet has a Good Balance of Nutrients.");
    } else if (mealpointsVal === 0) {
      setMealMsg(
        "Keep it up! Your Diet has an Excellent Balance of Nutrients."
      );
    }

    // exercise message
    if (exercisepointsVal === 0) {
      setExcerciseMsg("Awesome! Keep Exercising.");
    } else if (exercisepointsVal == 0.5) {
      setExcerciseMsg("Good! Try to Exercise at least five times a week.");
    } else if (exercisepointsVal == 2) {
      setExcerciseMsg(
        "We recommend you add exercises to your regime immediately."
      );
    } else if (exercisepointsVal >= 1 && exercisepointsVal <= 1.5) {
      setExcerciseMsg(
        "You need to exercise regularly. Try exercising at least five days a week."
      );
    }

    //smoke message
    if (tobaccopointsVal === 0) {
      setTobaccoMsg("Awesome! Keep it Up");
    } else if (tobaccopointsVal == 1) {
      setTobaccoMsg("Smoking is injurious to health! Quit smoking!");
    } else if (tobaccopointsVal == 1.5) {
      setTobaccoMsg(
        "Smoking Occasionally is also detrimental to health! Quit Smoking & Tobacco Products!"
      );
    } else if (tobaccopointsVal >= 2) {
      setTobaccoMsg(
        "Smoking / Tobacco is injurious to health! Quit Smoking & stop consuming tobacco products!"
      );
    }

    //alcohol message
    if (alcoholpointsVal === 0) {
      setAlcoholMsg("Awesome! Keep it Up");
    } else if (alcoholpointsVal == 1.5) {
      setAlcoholMsg(
        "Is your alcohol intake Very Often? Drink Responsibly! Don't exceed the consumption of Alcohol above 30ml."
      );
    } else if (alcoholpointsVal == 2) {
      setAlcoholMsg(
        "Alcohol Addiction! Is your alcohol intake Very High? Drink Responsibly! Don't exceed your Alcohol intake above 30ml."
      );
    } else if (alcoholpointsVal <= 0.5 >= 1) {
      setAlcoholMsg(
        "Drink Alcohol mindfully. Keep your intake of Alcohol limit to 30ml."
      );
    }

    //Stress point
    console.log("stresspointsVal is >>12",typeof stresspointsVal)
    if (stresspointsVal === 3) {
      setStressMsg(
        "Stress Detected! Try going for a walk in nature to keep mental stress out of your Life."
      );
    } else if (stresspointsVal === 2) {
      setStressMsg(
        "You are Stressed Out! Take Steps to keep stress out of your Life."
      );
    } else if (stresspointsVal === 0) {
      setStressMsg("Good, No Stress!");
    }

    //heartSurgerypoints
    if (heartSurgerypointsVal === 0) {
      setHeartBlockageMsg("");
    } else if (heartSurgerypointsVal === 5) {
      setHeartBlockageMsg(
        "Lifestyle modification and cardiac rehab can help prevent bypass blockages or post-pasty."
      );
    } else if (heartSurgerypointsVal === 1) {
      setHeartBlockageMsg(
        "Post-pasty or bypass blockages in the coronary artery? It would be best if you had proper lifestyle modification with cardiac rehab."
      );
    }

    //symptomspoint
    let fUrination,
      eHunger,
      woundNotHeal,
      wLoss,
      wGain,
      jPain,
      hache,
      wFatigue,
      contis,
      sweat,
      tinglingNumbness;

    if (frequentUrination) {
      fUrination = 2;
    } else {
      fUrination = 0;
    }
    if (excessiveHunger) {
      eHunger = 2;
    } else {
      eHunger = 0;
    }
    if (woundNotHealing) {
      woundNotHeal = 2;
    } else {
      woundNotHeal = 0;
    }
    if (weightLoss) {
      wLoss = 2;
    } else {
      wLoss = 0;
    }
    if (weightGain) {
      wGain = 2;
    } else {
      wGain = 0;
    }
    if (jointPains) {
      jPain = 2;
    } else {
      jPain = 0;
    }
    if (headache) {
      hache = 2;
    } else {
      hache = 0;
    }
    if (weaknessOfFatigue) {
      wFatigue = 2;
    } else {
      wFatigue = 0;
    }
    if (constipation) {
      contis = 2;
    } else {
      contis = 0;
    }
    if (sweating) {
      sweat = 2;
    } else {
      sweat = 0;
    }
    if (tingling) {
      tinglingNumbness = 2;
    } else {
      tinglingNumbness = 0;
    }

    if (symptomspointVal === 0) {
      setSymptomsMsg("Good, No Symptoms!");
    } else {
      setSymptomsMsg(
        "Symptoms detected. It would help if you took actions to improve your health."
      );
    }

    //anginapoint(symptom situation)
    // if(symtomSituations === 5) {
    //   setAginaMsg("Alarming! You are at a very High Heart Health Risk. Get in touch with the doctor immediately.")
    // } else if(symtomSituations === 3) {
    //   setAginaMsg("Do not Neglect! You are at significant Heart Health Risk. Get in touch with the doctor.")
    // } else if(symtomSituations === 2) {
    //   setAginaMsg("Need to evaluate heart disease? Get in touch with the doctor and perform tests.")
    // } else if(symtomSituations === 0) {
    //   setAginaMsg("Good, No Cardiac Symptoms!")
    // }

    //familyhistory message
    if (familyhistorypointsVal === 0) {
      setFamilyhistoryMsg("Good. No family History!");
    } else {
      setFamilyhistoryMsg(
        "Family history increases chances of developing a heart condition"
      );
    }

    //sugarf message
    if (sugarfpointVal === 0) {
      setFastingSugarMsg(
        "Your Fasting Sugar Reading is in Normal Range! No Action Needed, Normal Fasting Sugar Range is 70mg/dL to 100mg/dL"
      );
    } else if (sugarfpointVal == 5) {
      setFastingSugarMsg(
        "Your Fasting Sugar Reading is in the Hypoglycemia Range - Be Cautious! We recommend Doctor's Check-up, Normal Fasting Sugar Range is 70mg/dL to 100mg/dL"
      );
    } else if (sugarfpointVal >= 3 && sugarfpointVal <= 5) {
      setFastingSugarMsg(
        "Your Fasting Sugar Reading is in the Prediabetic Range - Be Cautious! We recommend Doctor's Check-up, Normal Fasting Sugar Range is 70mg/dL to 100mg/dL"
      );
    } else if (sugarfpointVal >= 5.5 && sugarfpointVal <= 10) {
      setFastingSugarMsg(
        "Diabetic Range: Your Fasting Sugar Reading is Very High - Be Cautious! We recommend an immediate Doctor Check-up to Keep Your Score in Check!, Normal Fasting Sugar Range is 70mg/dL to 100mg/dL"
      );
    }

    //sugarpp message
    if (sugarpppointVal === 0) {
      setPostFastingSugarMsg(
        "Your Post Meal Sugar Reading is in Normal Range! No Action is Needed, Normal Post Meal Sugar Range is 70mg/dL to 140mg/dL"
      );
    } else if (sugarpppointVal == 5) {
      setPostFastingSugarMsg(
        "Your Post Meal Sugar Reading is in the Hypoglycemia Range - Be Cautious! We recommend Doctor's Check-up, Normal Post Meal Sugar Range is 70mg/dL to 140mg/dL"
      );
    } else if (sugarpppointVal >= 3 && sugarpppointVal <= 8.5) {
      setPostFastingSugarMsg(
        "Your Post Meal Sugar Reading is in the Prediabetic Range - Be Cautious! We recommend Doctor's Check-up, Normal Post Meal Sugar Range is 70mg/dL to 140mg/dL"
      );
    } else if (sugarpppointVal >= 9 && sugarpppointVal <= 10) {
      setPostFastingSugarMsg(
        "Diabetic Range: Your Post Meal Sugar Reading is Very High - Be Cautious! Doctor Check-up Highly Recommended to Keep Your Score in Check!, Normal Post Meal Sugar Range is 70mg/dL to 140mg/dL"
      );
    }

    //sugarhbpoint
    if (hbac1 > 15.1 && sugarhbpointVal === 10) {
      setHba1cMsg(
        "Diabetic Range: Your Hba1c Reading is at a Dangerous Range - Be Cautious! Doctor Check-up Highly Recommended to Keep Your Score in Check!, Normal Hba1c Range is 3.0mg/dL to 5.7mg/dL"
      );
    } else if (sugarhbpointVal == 0) {
      setHba1cMsg(
        "Your Hba1c Reading is in the Normal Range! No Action is Needed, Normal Hba1c Range is 3.0mg/dL to 5.7mg/dL"
      );
    } else if (sugarhbpointVal >= 3 && sugarhbpointVal <= 4.5) {
      setHba1cMsg(
        "Your Hba1c Reading is in the Prediabetic Range - Be Cautious! We recommend Doctor's Check-up, Normal Hba1c Range is 3.0mg/dL to 5.7mg/dL"
      );
    } else if (sugarhbpointVal >= 5 && sugarhbpointVal <= 8) {
      setHba1cMsg(
        "Your Hba1c Reading is in Diabetic Range - Be Cautious! Doctor Check-up Highly Recommended to Keep your Score in Check!, Normal Hba1c Range is 3.0mg/dL to 5.7mg/dL"
      );
    } else if (sugarhbpointVal >= 8.5 && sugarhbpointVal <= 10) {
      setHba1cMsg(
        "Diabetic Range: Your Hba1c Reading is at Very High - Be Cautious! Doctor Check-up Highly Recommended to Keep Your Score in Check!, Normal Hba1c Range is 3.0mg/dL to 5.7mg/dL"
      );
    }

    //totcholpoint
    if (totcholpointVal >= 3 && totcholpointVal <= 5) {
      setTotalCholestrolMsg(
        "Your Total Cholesterol Values are in Borderline. We recommend Doctor's Check-up, Normal Total cholesterol Range is 40mg/dL to 200mg/dL"
      );
    } else if (totcholpointVal >= 6 && totcholpointVal <= 7.5) {
      setTotalCholestrolMsg(
        "Your Total Cholesterol Values are in Very High Range, and Your Heart is at Risk. We recommend Doctor's Check-up, Normal Total cholesterol Range is 40mg/dL to 200mg/dL"
      );
    } else if (totcholpointVal > 7.5 && totcholpointVal <= 10) {
      setTotalCholestrolMsg(
        "Your Total Cholesterol value is in the Dangerous Range, Major Risk for your Heart. We recommend Doctor's Check-up, Normal Total cholesterol Range is 40mg/dL to 200mg/dL"
      );
    } else if (totcholpointVal == 0) {
      setTotalCholestrolMsg(
        "Your Total Cholesterol Values are in good Heart Healthy Range, Normal Total cholesterol is 40mg/dL to 200mg/dL"
      );
    }

    //ldlpoint
    if (ldlpointVal >= 0.5 && ldlpointVal <= 3) {
      setLdlMsg(
        "Your LDL Values are in Borderline. We recommend Doctor's Check-up!, Normal LDL Range is 20mg/dL to 130mg/dL"
      );
    } else if (ldlpointVal >= 3.5 && ldlpointVal <= 6) {
      setLdlMsg(
        "Your LDL Values are in a Very High Range, and Your Heart is at Risk. We recommend Doctor's Check-up!, Normal LDL Range is 20mg/dL to 130mg/dL"
      );
    } else if (ldlpointVal >= 6.5 && ldlpointVal <= 10) {
      setLdlMsg(
        "Your LDL Values are in the Dangerous Range, Major Risk for your Heart. We recommend Doctor's Check-up!, Normal LDL Range is 20mg/dL to 130mg/dL"
      );
    } else if (ldlpointVal == 0) {
      setLdlMsg(
        "Your LDL Values are in Good Heart Healthy Range, Normal LDL Range is 20mg/dL to 130mg/dL"
      );
    }

    // hdlpoint
    if (hdl >= 151 && hdlpointVal === 0) {
      setHdlMsg("Your HDL Values are in Very Good Heart Healthy Range");
    } else if (hdlpointVal >= 5 && hdlpointVal <= 7) {
      setHdlMsg(
        "Your HDL Values are in a Very Low Range, and Your Heart is at Risk. We recommend Doctor's Check-up!, Normal HDL Range should be Above 60"
      );
    } else if (hdlpointVal >= 2 && hdlpointVal <= 4.5) {
      setHdlMsg(
        "Your HDL Values are in Borderline. We recommend Doctor's Check-up!, Normal HDL Range should be Above 60"
      );
    } else if (hdlpointVal >= 7.5 && hdlpointVal <= 10) {
      setHdlMsg(
        "Your HDL Values are in the Dangerous Range, Major Risk for your Heart. We recommend Doctor's Check-up!, Normal HDL Range should be Above 60"
      );
    } else if (hdlpointVal == 0) {
      setHdlMsg(
        "Your HDL Values are in Good Heart Healthy Range, Normal HDL Range should be Above 60"
      );
    }

    //bppoints(Systolic (Upper BP))
    if (
      systolicUpperBp >= 176 &&
      systolicUpperBp <= 220 &&
      bppointsVal === 10
    ) {
      setSystolicUpperBpMsg(
        "Alarming Systolic Reading! We recommend an immediate Doctor Check-up!, Normal BP Systolic Range is between 90mmHg to 120mmHg"
      );
    } else if (
      systolicUpperBp >= 70 &&
      systolicUpperBp <= 79 &&
      bppointsVal === 6
    ) {
      setSystolicUpperBpMsg(
        "Very Low Systolic Reading! Doctor Check-up Recommended!, Normal BP Systolic Range is between 90mmHg to 120mmHg"
      );
    } else if (
      systolicUpperBp >= 80 &&
      systolicUpperBp <= 89 &&
      bppointsVal == 4
    ) {
      setSystolicUpperBpMsg(
        "Very Low Systolic Reading! Doctor Check-up Recommended!, Normal BP Systolic Range is between 90mmHg to 120mmHg"
      );
    } else if (systolicUpperBp >= 221 && bppointsVal === 10) {
      setSystolicUpperBpMsg(
        "Dangerous High Blood Pressure! We recommend an immediate Doctor Check-up!, Normal BP Systolic Range is between 90mmHg to 120mmHg"
      );
    } else if (systolicUpperBp <= 69 && bppointsVal === 10) {
      setSystolicUpperBpMsg(
        "Dangerous Low Blood Pressure! We recommend an immediate Doctor check-up!, Normal BP Systolic Range is between 90mmHg to 120mmHg"
      );
    } else if (bppointsVal >= 3 && bppointsVal <= 5) {
      setSystolicUpperBpMsg(
        "Elevated Systolic Reading! Doctor Check-up Recommended!, Normal BP Systolic Range is between 90mmHg to 120mmHg"
      );
    } else if (bppointsVal >= 5.5 && bppointsVal <= 10) {
      setSystolicUpperBpMsg(
        "Very High Systolic Reading! Doctor Check-up Recommended!, Normal BP Systolic Range is between 90mmHg to 120mmHg"
      );
    } else if (bppointsVal == 0) {
      setSystolicUpperBpMsg(
        "Normal Range, Normal BP Systolic Range is between 90mmHg to 120mmHg"
      );
    }

    //bplowerpoints
    if (
      (diastolicLowerBp >= 40 &&
        diastolicLowerBp <= 49 &&
        bplowerpointsVal === 6) ||
      (diastolicLowerBp >= 50 &&
        diastolicLowerBp <= 59 &&
        bplowerpointsVal === 4)
    ) {
      setDiastolicLowerBp(
        "Very Low Diastolic Reading! Doctor Check-up Recommended!, Normal BP Diastolic Range is between 60mmHg to 80mmHg"
      );
    } else if (diastolicLowerBp <= 39 && bplowerpointsVal == 10) {
      setDiastolicLowerBp(
        "Dangerous Low Blood Pressure! We recommend an immediate Doctor Check-up!, Normal BP Diastolic Range is between 60mmHg to 80mmHg"
      );
    } else if (diastolicLowerBp >= 141 && (bplowerpointsVal = 10)) {
      setDiastolicLowerBp(
        "Dangerous High Blood Pressure! We recommend an immediate Doctor Check-up!, Normal BP Diastolic Range is between 60mmHg to 80mmHg"
      );
    } else if (bplowerpointsVal == 0) {
      setDiastolicLowerBp(
        "Normal Range., Normal BP Diastolic Range is between 60mmHg to 80mmHg"
      );
    } else if (bplowerpointsVal >= 3 && bplowerpointsVal <= 5) {
      setDiastolicLowerBp(
        "Elevated Diastolic Reading! Doctor Check-up Recommended!, Normal BP Diastolic Range is between 60mmHg to 80mmHg"
      );
    } else if (bplowerpointsVal >= 5.5 && bplowerpointsVal <= 9) {
      setDiastolicLowerBp(
        "High Diastolic Reading! Doctor Check-up Recommended!, Normal BP Diastolic Range is between 60mmHg to 80mmHg"
      );
    } else if (bplowerpointsVal >= 9.5 && bplowerpointsVal <= 10) {
      setDiastolicLowerBp(
        "Very High Diastolic Reading! Doctor Check-up Recommended!, Normal BP Diastolic Range is between 60mmHg to 80mmHg"
      );
    }

    if (lifestylepointVal <= 3.9) {
      setLifestyleMsg("Good! Keep it Up");
    } else if (lifestylepointVal >= 4 && lifestylepointVal <= 8.9) {
      setLifestyleMsg("Fair! Lifestyle Improvement Needed");
    } else if (lifestylepointVal >= 9) {
      setLifestyleMsg("Poor Lifestyle! Major Lifestyle Improvement Needed");
    }

    // console.log("lifestylepointVal", lifestylepointVal);

    if (finalPoint <= 39 && finalPoint <= 49) {
      setHealthStatus("Critical");
      setScoreMeter(poor);
      setFinalMsg("Watch Out! Your Heart is at very High Risk");
      setMessageDesc(
        `As per your current Heart Health Assessment your Heart is not strong enough to fight Heart Attack! Strongly consider taking important steps to immediately work on severe and moderate Risk Factors affecting your Heart Health

        Improvements in your Lifestyle, Diet, Exercise, Disease Reversal Therapies with Continuous Monitoring can go a long way in improving your Heart Health, follow below guided steps to improve your score and act for your Healthier Future.
        .`
      );
      setImgClass("bg-1");
    } else if (finalPoint >= 50 && finalPoint <= 74) {
      setHealthStatus("FAIR");
      setScoreMeter(fair);
      setFinalMsg("Watch Out! Your Heart is at Risk");
      setMessageDesc(
        `As per your current Heart Health Assessment your Heart is not strong enough to fight Heart Attack! Strongly consider taking important steps to immediately work on severe and moderate Risk Factors affecting your Heart Health

        Improvements in your Lifestyle, Diet, Exercise, Disease Reversal Therapies with Continuous Monitoring can go a long way in improving your Heart Health, follow below guided steps to improve your score and act for your Healthier Future.
        .`
      );
      setImgClass("bg-2");
    } else if (finalPoint >= 75 && finalPoint <= 84) {
      setHealthStatus("GOOD");
      setScoreMeter(good);
      setFinalMsg("HEALTHY! with Risks");
      setMessageDesc(
        `Your Heart Health score is in the healthy spectrum now, but you have risk factors which may put you at a Higher Risk in the future.

        Improvements in your Lifestyle such as Stress, Sleep, Good Diet, Exercise can go a long way in improving your Heart Health, follow below guided steps to manage your risk factors to act for your Healthier Future.`
      );
      setImgClass("bg-3");
    } else if (finalPoint >= 85 && finalPoint <= 94) {
      setHealthStatus("VERY GOOD");
      setScoreMeter(veryGood);
      setFinalMsg("HEALTHY! with Minor Risks");
      setMessageDesc(
        `Your Heart Health score is in the healthy spectrum now, but you have few risk factors which may put you at a Higher Risk in the future.

        Improvements in your Lifestyle such as Stress, Sleep, Good Diet, Exercise can go a long way in improving your Heart Health, follow below guided steps to manage your risk factors to act for your Healthier Future.`
      );
      setImgClass("bg-4");
    } else if (finalPoint >= 95) {
      setHealthStatus("EXCELLENT");
      setScoreMeter(excellent);
      setFinalMsg("Your Heart is HEALTHY! Keep it up");
      setMessageDesc(
        `Congratulations, your Heart is in Excellent condition, with no future risks.
         
        Your heart is beating full of life. Whatever you are doing, it can't be more right, just don’t fall off the track. You are an admiration for all of us. Keep it up! `
      );
      setImgClass("bg-5");
    }

    if (
      isNaN(finalPoint) ||
      finalPoint == null ||
      finalPoint == undefined ||
      finalPoint == ""
    ) {
      setShowScore("Loading");
    } else if (finalPoint <= 39) {
      setShowScore(39);
    } else if (finalPoint >= 95) {
      setShowScore(95);
    } else {
      setShowScore(finalPoint);
    }
  };

  //   diabete == null || diabete == false
  // hDisease == null || hDisease == false
  // hCholesterol == null || hCholesterol == false
  // hBbpHypertension == null || hBbpHypertension == false
  // bmiVal > 26


  //Readmore function
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };


  return (
    <div className="main no-mb">
      {/* <Header2 /> */}
      <Container>
        <div className="bs-card typ-mob-height-auto typ-pl-on typ-mb-on">
          <Row className="align-items-center">
            <Col
              xl={{ offset: 6, size: 6 }}
              lg={{ offset: 6, size: 6 }}
              md="12"
            >
              <div className="bs-heading typ-center">
                <h2 className="heading">Your Heart Health Score</h2>
                <p className="subheading">Detailed analysis of your report</p>
              </div>
            </Col>

            <Col xl="6" lg="6" md="12">
              <div className="bs-lottifiles">
                {/* <img className='multi-color-bg' src={MultiColorBand} alt="multi-color-band" /> */}
                <Player
                  className="multi-color-bg"
                  autoplay
                  speed={1.5}
                  loop
                  src={scoreMeter}
                  // style={{ height: "300px", width: "300px" }}
                ></Player>
                {/* <img
                  className="band-girl"
                  src={BandGirl}
                  alt="multi-color-band"
                /> */}
                <div
                  className={`bs-img-sprite band-girl typ-band-girl ${gender} ${imgClass}`}
                ></div>
                <div className="lotti-content-box">
                  <p className="result-txt">
                    {showScore === "Loading" ? "Loading" : healthStatus}
                  </p>
                  {showScore && <p className="result-no">{showScore}</p>}
                  {/* <img className='indicator' src={Indicator} alt="multi-color-band" /> */}
                </div>
              </div>
            </Col>

            <Col xl="6" lg="6" md="12">
              <div className="bs-heading typ-center">
                <h2 className="heading">
                  {showScore === "Loading" ? "Loading" : healthStatus}
                </h2>
                <p className="subheading">
                  {showScore === "Loading" ? "Loading" : finalMsg}
                </p>
              </div>
              {/* <p style={{ whiteSpace: "pre-line" }}>{Parser(defaultMsg)}</p> */}
              <p
                className="cm-para typ-grey6 typ-center typ-max-wid"
                style={{ whiteSpace: "pre-line" }}
              >
                {showScore === "Loading" ? (
                  "Loading"
                ) : isReadMore ? (
                  <React.Fragment>
                    {messageDesc.slice(0, 5000)}
                    {/* <button onClick={toggleReadMore} className="red">
                      ...Read more
                    </button> */}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {messageDesc}
                    <button onClick={toggleReadMore} className="red">
                      {/* Show less */}
                    </button>
                  </React.Fragment>
                )}
              </p>
            </Col>
          </Row>
        </div>
      </Container>

      <Container>
        <div className="bs-card typ-height-auto typ-pl-on typ-mb-on typ-score-analysis">
          <Row>
            <Col xl="12" lg="12" md="12">
              <div className="bs-heading typ-full-width typ-mob-center">
                <h2 className="heading">Your Score Analysis</h2>
                <p className="subheading typ-20">
                  Strongly consider taking important steps toward better
                  lifestyle choices and immediately work on below mentioned
                  severe and moderate Risk Factors to improve your Heart Health,
                  remember even small steps can help.
                </p>
                <div className="bs-tabs">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames([
                          { active: activeTab === "1" },
                          "typ-red",
                        ])}
                        onClick={() => toggle("1")}
                      >
                        <div>({Math.round(severe)}%)</div>
                        Severe Risk Factors
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames([
                          { active: activeTab === "2" },
                          "typ-yellow",
                        ])}
                        onClick={() => toggle("2")}
                      >
                        <div>({Math.round(moderate)}%)</div>
                        Moderate Risk Factors
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames([
                          { active: activeTab === "3" },
                          "typ-green",
                        ])}
                        onClick={() => toggle("3")}
                      >
                        {
                          console.log("mild value is >>",mild)
                        }
                        <div>({Math.round(mild)}%)</div>
                        Mild Risk Factors
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab}>
                    <TabPane className="typ-red" tabId="1" id="severeZone">
                      <div className="bs-accordion">
                        <Accordion open={open} toggle={accordion}>
                          {/* Disease History severe start */}
                          {diseasecalpointsVal >= 27 && (
                            <AccordionItem>
                              <AccordionHeader targetId="1">
                                <span className="icon icon-history"></span>
                                <span className="acc-title">
                                  Disease History
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="1">
                                <p className="acc-body-title">
                                  You have been detected with following history
                                  of disease:{" "}
                                </p>
                                <ul>
                                  {hCholesterol == "true" && <li>High Cholestrol</li>}
                                  {hBbpHypertension == "true" && (
                                    <li>High BP / Hypertension</li>
                                  )}
                                  {diabete == "true" && <li>Diabetes</li>}
                                  {hDisease == "true" && <li>Heart Disease</li>}
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Disease History severe end */}
                          {
                            console.log("symptomspointVal is >>12",symptomspointVal)
                          }
                          {/* Diagnosis of Symptoms and Health Issues severe start */}
                          {symptomspointVal >= 2 && (
                            <AccordionItem>
                              <AccordionHeader targetId="2">
                                <span className="icon icon-diagonisis"></span>
                                <span className="acc-title">
                                  Diagnosis of Symptoms and Health Issues
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="2">
                                <p className="acc-body-title">
                                  You have been detected with following Symptoms
                                  and Health Issues:
                                </p>
                                {
                                  console.log("frequentUrination value is >>>1",typeof frequentUrination)
                                }
                                <ul>
                                  {frequentUrination != "null" && frequentUrination && (
                                    <li>Frequent Urination</li>
                                  )}
                                  {excessiveHunger != "null" && excessiveHunger && (
                                    <li>Excessive Hunger / Thirst</li>
                                  )}
                                  {woundNotHealing !="null" && woundNotHealing && (
                                    <li>Wound Not Healing</li>
                                  )}
                                  {weightLoss !="null" && weightLoss && <li>Weight Loss</li>}
                                  {weightGain !="null" && weightGain && <li>Weight Gain</li>}
                                  {jointPains !="null" && jointPains  && <li>Joint Pains</li>}
                                  {headache !="null" && headache && <li>Headache</li>}
                                  {weaknessOfFatigue != "null" && weaknessOfFatigue && (
                                    <li>Weakness or Fatigue</li>
                                  )}
                                  {constipation != "null" && constipation && <li>Constipation</li>}
                                  {sweating != "null" && sweating && <li>Sweating</li>}
                                  {tingling != "null" && tingling && <li>Tingling or Numbness</li>}
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Diagnosis of Symptoms and Health Issues severe end */}
                          {/* Heart Disease Symptoms severe start */}
                          {
                            console.log("heartDiseasepointVal is >111>",heartDiseasepointVal)
                          }
                          {heartDiseasepointVal != "null" &&  heartDiseasepointVal && (
                            <AccordionItem>
                              <AccordionHeader targetId="3">
                                <span className="icon icon-heart-disease"></span>
                                <span className="acc-title">
                                  Heart Disease Symptoms
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="3">
                                <ul>
                                  <li>
                                    Symptoms of Heart Disease have been
                                    Detected, Immediate Doctor Check-up
                                    recommended.
                                  </li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Heart Disease Symptoms severe end */}
                            {
                              console.log("diseasecalpointsVal is >>here",diseasecalpointsVal)
                            }
                          {/* Medicine History severe start */}
                          {((diseasecalpointsVal >= 27 &&
                            medicine == "Yes" &&
                            irregualrMedicine === "Yes") ||
                            (diseasecalpointsVal >= 27 &&
                              medicine == "No")) && (
                              <AccordionItem>
                                <AccordionHeader targetId="4">
                                  <span className="icon icon-medicine-history"></span>
                                  <span className="acc-title">
                                    Medicine History
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="4">
                                  <ul>
                                    {medicine === "Yes" ? (
                                      <li>
                                        Having {medicineCount} & taking Medicines{" "}
                                        {irregualrMedicine === "Yes"
                                          ? "Irregularly"
                                          : "Regularly"}
                                      </li>
                                    ) : (
                                      <li>
                                        You have been Detected with{" "}
                                        {hCholesterol == "true" && "High Cholestrol, "}{" "}
                                        {hBbpHypertension == "true" &&
                                          "High BP / Hypertension, "}{" "}
                                        {diabete == "true" && "Diabetes, "}{" "}
                                        {hDisease == "true" && "Heart Disease, "}But have
                                        not been prescribed any Medication. We
                                        recommend an immediate Doctor Check-up to
                                        Keep your Score in Check!
                                      </li>
                                    )}
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/* Medicine History severe end */}

                          {/* Cholesterol Vitals severe start */}
                          
                          {((totcholpointVal >= 6.00 && totcholpointVal <= 10) ||
                            (ldlpointVal >= 3.5 && ldlpointVal <= 10) ||
                            (hdlpointVal >= 5 && hdlpointVal <= 10)) && (
                              <AccordionItem>
                                <AccordionHeader targetId="5">
                                  <span className="icon icon-cholesterol-vitals"></span>
                                  <span className="acc-title">
                                    Cholesterol Vitals
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="5">
                                  <ul>
                                    {bloodCholesterol === "Yes" ? (
                                      <>
                                        {totalCholestrol && (
                                          <li>
                                            Total Cholesterol - {totalCholestrol}{" "}
                                            mg/dL: {totalCholestrolMsg}
                                          </li>
                                        )}
                                        {ldl && (
                                          <li>
                                            LDL - {ldl} mg/dL: {ldlMessage}
                                          </li>
                                        )}
                                        {hdl && (
                                          <li>
                                            HDL - {hdl} mmol/L: {hdlMessage}
                                          </li>
                                        )}
                                      </>
                                    ) : (
                                      <li>
                                        Your Score maybe affected due to missing
                                        Vitals, Doctor Check-up is Recommended!
                                      </li>
                                    )}
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/* Cholesterol Vitals severe end */}
                          
                          {/* Blood Sugar Vitals severe start */}
                          {((sugarfpointVal >= 5.5 && sugarfpointVal <= 10) ||
                            (sugarpppointVal >= 5.5 && sugarpppointVal <= 10) ||
                            (sugarhbpointVal >= 4.5 &&
                              sugarhbpointVal <= 10)) && (
                              <AccordionItem>
                                <AccordionHeader targetId="6">
                                  <span className="icon icon-blood-sugar-vitals"></span>
                                  <span className="acc-title">
                                    Blood Sugar Vitals
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="6">
                                  <ul>
                                    {bloodSugar === "Yes" ? (
                                      <>
                                        {fastingSugar && (
                                          <li>
                                            Fasting Sugar - {fastingSugar} mg/dL:{" "}
                                            {fastingSugarMsg}
                                          </li>
                                        )}
                                        {postFastingSugar && (
                                          <li>
                                            Post Fasting Sugar -{" "}
                                            {postFastingSugar} mg/dL:{" "}
                                            {postFastingSugarMsg}
                                          </li>
                                        )}
                                        {hbac1 && (
                                          <li>
                                            HBA1C - {hbac1} mmol/L: {hba1cMsg}
                                          </li>
                                        )}
                                      </>
                                    ) : (
                                      <li>
                                        Your Score maybe affected due to missing
                                        Vitals, Doctor Check-up is Recommended!
                                      </li>
                                    )}
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/* Blood Sugar Vitals severe end */}
                          {
                            console.log("bplowerpointsVal is >>",typeof bplowerpointsVal)
                          }
                          {/* BP Vitals severe start */}
                          {((bppointsVal >= 6 && bppointsVal <= 10) ||
                            (bplowerpointsVal >= 5.5 && bppointsVal <= 10)) && (
                              <AccordionItem>
                                <AccordionHeader targetId="7">
                                  <span className="icon icon-bp-vitals"></span>
                                  <span className="acc-title">
                                    BP Vitals
                                    {bloodPressure === "Yes" && (
                                      <>
                                        : {systolicUpperBp} / {diastolicLowerBp}
                                      </>
                                    )}
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="7">
                                  <ul>
                                    {bloodPressure === "Yes" ? (
                                      <>
                                        {systolicUpperBp && (
                                          <li>
                                            Systolic (Upper BP) -{" "}
                                            {systolicUpperBp} mg/dL:{" "}
                                            {systolicUpperBpMsg}
                                          </li>
                                        )}
                                        {diastolicLowerBp && (
                                          <li>
                                            Diastolic (Lower BP) -{" "}
                                            {diastolicLowerBp} mg/dL:{" "}
                                            {diastolicLowerBpMsg}
                                          </li>
                                        )}
                                      </>
                                    ) : (
                                      <li>
                                        Your Score maybe affected due to missing
                                        Vitals, Doctor Check-up is Recommended!
                                      </li>
                                    )}
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/* BP Vitals severe end */}
                          {
                            console.log("agepointsVal is >>",agepointsVal) 
                          }
                          {/* Age severe start */}
                          {agepointsVal >= 9 && (
                            <AccordionItem>
                              <AccordionHeader targetId="8">
                                <span className="icon icon-age"></span>
                                <span className="acc-title">
                                  Age: {age} yrs
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="8">
                                <ul>
                                  {age > 61 && agepointsVal === 15 ? (
                                    <li>
                                      Research says being at an age greater than
                                      60 is in itself a risk factor for heart
                                      diseases and associated risk factors.
                                    </li>
                                  ) : agepointsVal >= 12.6 <= 15 ? (
                                    <li>
                                      Research says that age {age} may bring
                                      moderate risk factors for heart diseases
                                      and associated risk factors.
                                    </li>
                                  ) : (
                                    <li>
                                      Research says that age {age} may bring
                                      mild risk factors for heart diseases and
                                      associated risk factors.
                                    </li>
                                  )}
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Age severe end */}
                          {
                            console.log("bmipointsVal is >>here",bmipointsVal)
                          }
                          {/* Weight & BMI severe start */}
                          {bmipointsVal >= 12.00 && bmipointsVal <= 20 && (
                            <AccordionItem>
                              <AccordionHeader targetId="9">
                                <span className="icon icon-weight"></span>
                                <span className="acc-title">
                                  Weight: {weight}, BMI: {bmiVal}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="9">
                                <ul>
                                  <li>{bmiMessage}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Weight & BMI severe end */}
                            {
                              console.log("mealpointsVal is >>here,",mealpointsVal)
                            }
                          {/* Diet severe start */}
                          {mealpointsVal === 2.00 && (
                            <AccordionItem>
                              <AccordionHeader targetId="10">
                                <span className="icon icon-diet"></span>
                                <span className="acc-title">Diet</span>
                              </AccordionHeader>
                              <AccordionBody accordionId="10">
                                <ul>
                                  <li>{mealMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Diet severe end */}
                            
                          {/*  Rest & Sleep severe start */}
                          {sleepingpointsVal >= 1.75 &&
                            sleepingpointsVal <= 2 && (
                              <AccordionItem>
                                <AccordionHeader targetId="11">
                                  <span className="icon icon-rest-sleep"></span>
                                  <span className="acc-title">
                                    Rest & Sleep: {sleephour}
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="11">
                                  <ul>
                                    <li>{sleepMsg}</li>
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/*  Rest & Sleep severe end */}
                          {
                              console.log("exercisepointsVal is >>12",exercisepointsVal)
                            }
                          {/* Exercise severe start */}
                          {exercisepointsVal === 2 && (
                            <AccordionItem>
                              <AccordionHeader targetId="12">
                                <span className="icon icon-excercise"></span>
                                <span className="acc-title">
                                  Exercise: {excercise}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="12">
                                <ul>
                                  <li>{excerciseMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Exercise severe end */}
                          {
                              console.log("stresspointsVal is >>12",stresspointsVal)
                            }
                          {/* Stress severe start */}
                          {stresspointsVal === 3 && (
                            <AccordionItem>
                              <AccordionHeader targetId="13">
                                <span className="icon icon-stress"></span>
                                <span className="acc-title">
                                  Stress: {stress}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="13">
                                <ul>
                                  <li>{stressMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Stress severe end */}
                          {
                              console.log("tobaccopointsVal is >>21",tobaccopointsVal)
                          }
                          {/* Smoking & Tobacco severe start */}
                          {tobaccopointsVal >= 1.5 && tobaccopointsVal <= 3 && (
                            <AccordionItem>
                              <AccordionHeader targetId="14">
                                <span className="icon icon-smoking"></span>
                                <span className="acc-title">
                                  Smoking & Tobacco: {tobacco}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="14">
                                <ul>
                                  <li>{tobaccoMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Smoking & Tobacco severe end */}
                          {
                            console.log("alcoholpointsVal is >>12",alcoholpointsVal)
                          }
                          {/* Alcohol severe start */}
                          {alcoholpointsVal >= 1 && alcoholpointsVal <= 2 && (
                            <AccordionItem>
                              <AccordionHeader targetId="15">
                                <span className="icon icon-alcohol"></span>
                                <span className="acc-title">
                                  Alcohol: {alcohol}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="15">
                                <ul>
                                  <li>{alcoholMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Alcohol severe end */}
                          {
                              console.log("lifestylepointVal is >>",lifestylepointVal)
                          }
                          {/* Lifestyle severe start */}
                          {lifestylepointVal >= 9 && (
                            <AccordionItem>
                              <AccordionHeader targetId="16">
                                <span className="icon icon-lifestyle"></span>
                                <span className="acc-title">
                                  Lifestyle: {lifestyleMsg}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="16">
                                <ul>
                                  <li>{lifestyleMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Lifestyle severe end */}
                        </Accordion>
                      </div>
                    </TabPane>
                    {
                      console.log("diseasecalpointsVal is >>11",diseasecalpointsVal)
                    }
                    <TabPane className="typ-yellow" tabId="2" id="moderateZone">
                      <div className="bs-accordion">
                        <Accordion open={open} toggle={accordion}>
                          {/* Medicine History moderate start*/}
                          {diseasecalpointsVal >= 27 &&
                            medicine == "Yes" &&
                            irregualrMedicine === "No" && (
                              <AccordionItem>
                                <AccordionHeader targetId="1">
                                  <span className="icon icon-medicine-history"></span>
                                  <span className="acc-title">
                                    Medicine History
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="1">
                                  <ul>
                                    <li>
                                      Having {medicineCount} & taking Medicines{" "}
                                      {irregualrMedicine === "Yes"
                                        ? "Irregularly"
                                        : "Regularly"}
                                    </li>
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/* Medicine History moderate end*/}

                          {/* Cholesterol Vitals moderate start*/}
                          {
                            //moderate
                            (totcholpointVal >= 0.1 && totcholpointVal <= 5) ||
                              (ldlpointVal >= 0.1 && ldlpointVal <= 4) ||
                              (hdlpointVal >= 0.1 && hdlpointVal <= 4.5) ? (
                              (totcholpointVal >= 6 && totcholpointVal <= 10) ||
                                (ldlpointVal >= 3.5 && ldlpointVal <= 10) ||
                                (hdlpointVal >= 5 && hdlpointVal <= 10) ? null : (
                                <AccordionItem>
                                  <AccordionHeader targetId="2">
                                    <span className="icon icon-cholesterol-vitals"></span>
                                    <span className="acc-title">
                                      Cholesterol Vitals
                                    </span>
                                  </AccordionHeader>
                                  <AccordionBody accordionId="2">
                                    <ul>
                                      {bloodCholesterol === "Yes" ? (
                                        <>
                                          {totalCholestrol && (
                                            <li>
                                              Total Cholesterol -{" "}
                                              {totalCholestrol} mg/dL:{" "}
                                              {totalCholestrolMsg}
                                            </li>
                                          )}
                                          {ldl && (
                                            <li>
                                              LDL - {ldl} mg/dL: {ldlMessage}
                                            </li>
                                          )}
                                          {hdl && (
                                            <li>
                                              HDL - {hdl} mmol/L: {hdlMessage}
                                            </li>
                                          )}
                                        </>
                                      ) : (
                                        <li>
                                          Your Score maybe affected due to
                                          missing Vitals, Doctor Check-up is
                                          Recommended!
                                        </li>
                                      )}
                                    </ul>
                                  </AccordionBody>
                                </AccordionItem>
                              )
                            ) : null
                          }
                          {/* Cholesterol Vitals moderate end*/}

                          {/* Blood Sugar Vitals moderate start*/}
                          {
                            //moderate
                            (sugarfpointVal >= 0.1 && sugarfpointVal <= 5) ||
                              (sugarpppointVal >= 0.1 && sugarpppointVal <= 5) ||
                              (sugarhbpointVal >= 0.1 && sugarhbpointVal <= 5) ? (
                              (sugarfpointVal >= 5.5 && sugarfpointVal <= 10) ||
                                (sugarpppointVal >= 5.5 &&
                                  sugarpppointVal <= 10) ||
                                (sugarhbpointVal >= 4.5 &&
                                  sugarhbpointVal <= 10) ? null : (
                                <AccordionItem>
                                  <AccordionHeader targetId="3">
                                    <span className="icon icon-blood-sugar-vitals"></span>
                                    <span className="acc-title">
                                      Blood Sugar Vitals
                                    </span>
                                  </AccordionHeader>
                                  <AccordionBody accordionId="3">
                                    <ul>
                                      {bloodSugar === "Yes" ? (
                                        <>
                                          {fastingSugar && (
                                            <li>
                                              Fasting Sugar - {fastingSugar}{" "}
                                              mg/dL: {fastingSugarMsg}
                                            </li>
                                          )}
                                          {postFastingSugar && (
                                            <li>
                                              Post Fasting Sugar -{" "}
                                              {postFastingSugar} mg/dL:{" "}
                                              {postFastingSugarMsg}
                                            </li>
                                          )}
                                          {hbac1 && (
                                            <li>
                                              HBA1C - {hbac1} mmol/L: {hba1cMsg}
                                            </li>
                                          )}
                                        </>
                                      ) : (
                                        <li>
                                          Your Score maybe affected due to
                                          missing Vitals, Doctor Check-up is
                                          Recommended!
                                        </li>
                                      )}
                                    </ul>
                                  </AccordionBody>
                                </AccordionItem>
                              )
                            ) : null
                          }
                          {/* Blood Sugar Vitals moderate end*/}

                          {/* BP Vitals moderate start */}
                          {
                            //moderate
                            (bppointsVal >= "0.1" && bppointsVal <= "5.5") ||
                              (bplowerpointsVal >= "0.1" && bppointsVal <= "5") ? (
                              (bppointsVal >= "5.6" && bppointsVal <= "10") ||
                                (bplowerpointsVal >= "5.1" &&
                                  bppointsVal <= "10") ? null : (
                                <AccordionItem>
                                  <AccordionHeader targetId="4">
                                    <span className="icon icon-bp-vitals"></span>
                                    <span className="acc-title">
                                      BP Vitals
                                      {bloodPressure === "Yes" && (
                                        <>
                                          : {systolicUpperBp} /{" "}
                                          {diastolicLowerBp}
                                        </>
                                      )}
                                    </span>
                                  </AccordionHeader>
                                  <AccordionBody accordionId="4">
                                    <ul>
                                      {bloodPressure === "Yes" ? (
                                        <>
                                          {systolicUpperBp && (
                                            <li>
                                              Systolic (Upper BP) -{" "}
                                              {systolicUpperBp} mg/dL:{" "}
                                              {systolicUpperBpMsg}
                                            </li>
                                          )}
                                          {diastolicLowerBp && (
                                            <li>
                                              Diastolic (Lower BP) -{" "}
                                              {diastolicLowerBp} mg/dL:{" "}
                                              {diastolicLowerBpMsg}
                                            </li>
                                          )}
                                        </>
                                      ) : (
                                        <li>
                                          Your Score maybe affected due to
                                          missing Vitals, Doctor Check-up is
                                          Recommended!
                                        </li>
                                      )}
                                    </ul>
                                  </AccordionBody>
                                </AccordionItem>
                              )
                            ) : null
                          }
                          {/* BP Vitals moderate end */}
                          {
                            console.log("agepointsVal is >>1212",agepointsVal)
                          }
                          {/* Age moderate start */}
                          {agepointsVal >= 2.5 && agepointsVal <= 8.5 && (
                            <AccordionItem>
                              <AccordionHeader targetId="5">
                                <span className="icon icon-age"></span>
                                <span className="acc-title">
                                  Age: {age} yrs
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="5">
                                <ul>
                                  <ul>
                                    {agepointsVal >= "2.6" &&
                                      agepointsVal <= "7.5" ? (
                                      <li>
                                        Research says that age {age} may bring
                                        some risk factors for heart diseases and
                                        associated risk factors.
                                      </li>
                                    ) : (
                                      <li>
                                        Research says that age {age} may bring
                                        mild risk factors for heart diseases and
                                        associated risk factors.
                                      </li>
                                    )}
                                  </ul>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Age moderate end */}
                          {
                            console.log("bmipointsVal is >>1",bmipointsVal)
                          }
                          {/*  Weight & Bmi moderate start */}
                          {bmipointsVal >= 5 && bmipointsVal <= 11 && (
                            <AccordionItem>
                              <AccordionHeader targetId="6">
                                <span className="icon icon-weight"></span>
                                <span className="acc-title">
                                  Weight: {weight}, BMI: {bmiVal}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="6">
                                <ul>
                                  <li>{bmiMessage}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/*  Weight & Bmi moderate end */}
                            {
                              console.log("mealpointsVal msg is >>",mealpointsVal)
                            }
                          {/*  Diet moderate start */}
                          {mealpointsVal >= 1.25 && mealpointsVal <= 1.5 && (
                            <AccordionItem>
                              <AccordionHeader targetId="7">
                                <span className="icon icon-diet"></span>
                                <span className="acc-title">Diet</span>
                              </AccordionHeader>
                              <AccordionBody accordionId="7">
                                <ul>
                                  <li>{mealMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/*  Diet moderate end */}

                          {/*  Rest & Sleep moderate start */}
                          {sleepingpointsVal >= 0.5 &&
                            sleepingpointsVal <= 1 && (
                              <AccordionItem>
                                <AccordionHeader targetId="8">
                                  <span className="icon icon-rest-sleep"></span>
                                  <span className="acc-title">
                                    Rest & Sleep: {sleephour}
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="8">
                                  <ul>
                                    <li>{sleepMsg}</li>
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/*  Rest & Sleep moderate end */}
                              {
                                console.log("exercisepointsVal is >1>",exercisepointsVal)
                              }
                          {/*  Exercise moderate start */}
                          {exercisepointsVal >= 1 &&
                            exercisepointsVal <= 1.5 && (
                              <AccordionItem>
                                <AccordionHeader targetId="9">
                                  <span className="icon icon-excercise"></span>
                                  <span className="acc-title">
                                    Exercise: {excercise}
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="9">
                                  <ul>
                                    <li>{excerciseMsg}</li>
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/*  Exercise moderate end */}
                          {
                            console.log("stress value is >>",stresspointsVal) 
                          }
                          {/*  Stress moderate start */}
                          {stresspointsVal === 2 && (
                            <AccordionItem>
                              <AccordionHeader targetId="10">
                                <span className="icon icon-stress"></span>
                                <span className="acc-title">
                                  Stress: {stress}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="10">
                                <ul>
                                  <li>{stressMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/*  Stress moderate end */}

                          {/*  Smoking & Tobacco moderate start */}
                          {tobaccopointsVal === "1" && (
                            <AccordionItem>
                              <AccordionHeader targetId="11">
                                <span className="icon icon-smoking"></span>
                                <span className="acc-title">
                                  Smoking & Tobacco: {tobacco}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="11">
                                <ul>
                                  <li>{tobaccoMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/*  Smoking & Tobacco moderate end */}
                            {
                              console.log("alcoholpointsVal is >>111",alcoholpointsVal)
                            }
                          {/*  Alcohol moderate start */}
                          {alcoholpointsVal === 0.5 && (
                            <AccordionItem>
                              <AccordionHeader targetId="12">
                                <span className="icon icon-alcohol"></span>
                                <span className="acc-title">
                                  Alcohol: {alcohol}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="12">
                                <ul>
                                  <li>{alcoholMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/*  Alcohol moderate end */}
                            {
                              console.log("lifestylepointVal is >>111",lifestylepointVal)
                            }
                          {/*  Lifestyle moderate start */}
                          {lifestylepointVal >= 4 &&
                            lifestylepointVal <= 8.9 && (
                              <AccordionItem>
                                <AccordionHeader targetId="13">
                                  <span className="icon icon-lifestyle"></span>
                                  <span className="acc-title">
                                    Lifestyle: {lifestyleMsg}
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="13">
                                  <ul>
                                    <li>{lifestyleMsg}</li>
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/*  Lifestyle moderate end */}
                        </Accordion>
                      </div>
                    </TabPane>
                    {
                      console.log("diseasecalpointsVal is >>>",diseasecalpointsVal) 
                    }
                    <TabPane className="typ-green" tabId="3" id="mildZone">
                      <div className="bs-accordion">
                        <Accordion open={open} toggle={accordion}>
                          {/* Disease History mild start */}
                          {diseasecalpointsVal === 0 && (
                            <AccordionItem>
                              <AccordionHeader targetId="1">
                                <span className="icon icon-history"></span>
                                <span className="acc-title">
                                  Disease History
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="1">
                                <ul>
                                  <li>
                                    You have not been diagnosed with any Chronic
                                    Disease such as Heart Disease, Diabetes,
                                    Hypertension or Cholesterol.
                                  </li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Disease History mild end */}
                            {
                              console.log("symptomspointVal is >>>",symptomspointVal)
                            }
                          {/* Diagnosis of Symptoms and Health Issues mild start */}
                          {symptomspointVal === 0 && (
                            <AccordionItem>
                              <AccordionHeader targetId="2">
                                <span className="icon icon-diagonisis"></span>
                                <span className="acc-title">
                                  Diagnosis of Symptoms and Health Issues
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="2">
                                <ul>
                                  <li>
                                    You have not been diagnosed with any
                                    symptoms related to Chronic Disease such as
                                    Heart Disease, Diabetes, Hypertension or
                                    Cholesterol.
                                  </li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Diagnosis of Symptoms and Health Issues mild end */}
                            {
                              console.log("heartDiseasepointVal is >>1211",heartDiseasepointVal)
                            }
                          {/* Heart Disease Symptoms mild start */}
                          {!heartDiseasepointVal == "true" && (
                            <AccordionItem>
                              <AccordionHeader targetId="3">
                                <span className="icon icon-heart-disease"></span>
                                <span className="acc-title">
                                  Heart Disease Symptoms
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="3">
                                <ul>
                                  <li>
                                    No Symptoms of Heart Disease have been
                                    Detected
                                  </li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Heart Disease Symptoms mild end */}
                            {
                              console.log("diseasecalpointsVal is here >>",diseasecalpointsVal)
                            }
                          {/* Medicine History mild start */}
                          {diseasecalpointsVal < 27 && (
                            <AccordionItem>
                              <AccordionHeader targetId="4">
                                <span className="icon icon-medicine-history"></span>
                                <span className="acc-title">
                                  Medicine History
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="4">
                                <ul>
                                  <li>No Medicine History</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Medicine History mild end */}
                            {
                              console.log("totcholpointVal is here >>",typeof totcholpointVal)
                            }
                          {/* Cholesterol Vitals mild start */}
                          {totcholpointVal === 0 &&
                            ldlpointVal === 0 &&
                            hdlpointVal === 0 && (
                              <AccordionItem>
                                <AccordionHeader targetId="5">
                                  <span className="icon icon-cholesterol-vitals"></span>
                                  <span className="acc-title">
                                    Cholesterol Vitals
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="5">
                                  <ul>
                                    {bloodCholesterol === "Yes" ? (
                                      <>
                                        {totalCholestrol && (
                                          <li>
                                            Total Cholesterol -{" "}
                                            {totalCholestrol} mg/dL:{" "}
                                            {totalCholestrolMsg}
                                          </li>
                                        )}
                                        {ldl && (
                                          <li>
                                            LDL - {ldl} mg/dL: {ldlMessage}
                                          </li>
                                        )}
                                        {hdl && (
                                          <li>
                                            HDL - {hdl} mmol/L: {hdlMessage}
                                          </li>
                                        )}
                                      </>
                                    ) : (
                                      <li>
                                        Your Score maybe affected due to missing
                                        Vitals, Doctor Check-up is Recommended!
                                      </li>
                                    )}
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/* Cholesterol Vitals mild end */}

                          {/* Blood Sugar Vitals start */}
                          {sugarfpointVal === 0 &&
                            sugarpppointVal === 0 &&
                            sugarhbpointVal === 0 && (
                              <AccordionItem>
                                <AccordionHeader targetId="6">
                                  <span className="icon icon-blood-sugar-vitals"></span>
                                  <span className="acc-title">
                                    Blood Sugar Vitals
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="6">
                                  <ul>
                                    {bloodSugar === "Yes" ? (
                                      <>
                                        {fastingSugar && (
                                          <li>
                                            Fasting Sugar - {fastingSugar}{" "}
                                            mg/dL: {fastingSugarMsg}
                                          </li>
                                        )}
                                        {postFastingSugar && (
                                          <li>
                                            Post Fasting Sugar -{" "}
                                            {postFastingSugar} mg/dL:{" "}
                                            {postFastingSugarMsg}
                                          </li>
                                        )}
                                        {hbac1 && (
                                          <li>
                                            HBA1C - {hbac1} mmol/L: {hba1cMsg}
                                          </li>
                                        )}
                                      </>
                                    ) : (
                                      <li>
                                        Your Score maybe affected due to missing
                                        Vitals, Doctor Check-up is Recommended!
                                      </li>
                                    )}
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/* Blood Sugar Vitals mild end */}
                          {
                            console.log("bppointsVal is >>111",bppointsVal)
                          }
                          {/* BP Vitals mild start */}
                          {bppointsVal === 0 && bplowerpointsVal === 0 && (
                            <AccordionItem>
                              <AccordionHeader targetId="7">
                                <span className="icon icon-bp-vitals"></span>
                                <span className="acc-title">
                                  BP Vitals
                                  {bloodPressure === "Yes" && (
                                    <>
                                      : {systolicUpperBp} / {diastolicLowerBp}
                                    </>
                                  )}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="7">
                                <ul>
                                  {bloodPressure === "Yes" ? (
                                    <>
                                      {systolicUpperBp && (
                                        <li>
                                          Systolic (Upper BP) -{" "}
                                          {systolicUpperBp} mg/dL:{" "}
                                          {systolicUpperBpMsg}
                                        </li>
                                      )}
                                      {diastolicLowerBp && (
                                        <li>
                                          Diastolic (Lower BP) -{" "}
                                          {diastolicLowerBp} mg/dL:{" "}
                                          {diastolicLowerBpMsg}
                                        </li>
                                      )}
                                    </>
                                  ) : (
                                    <li>
                                      Your Score maybe affected due to missing
                                      Vitals, Doctor Check-up is Recommended!
                                    </li>
                                  )}
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* BP Vitals mild end */}
                          {
                            console.log("agepoints value>11",agepointsVal)
                          }
                          {/* Age mild start */}
                          {agepointsVal === 2 && (
                            <AccordionItem>
                              <AccordionHeader targetId="8">
                                <span className="icon icon-age"></span>
                                <span className="acc-title">
                                  Age: {age} yrs
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="8">
                                <ul>
                                  <li>
                                    Your age works as a shield against heart
                                    diseases and risk factors.
                                  </li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Age mild end */}

                          {/* Weight & Bmi mild start */}
                          {bmipointsVal >= 0 && bmipointsVal <= 2 && (
                            <AccordionItem>
                              <AccordionHeader targetId="9">
                                <span className="icon icon-weight"></span>
                                <span className="acc-title">
                                  Weight: {weight}, BMI: {bmiVal}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="9">
                                <ul>
                                  <li>{bmiMessage}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Weight & Bmi mild end */}

                          {/* Diet mild start */}
                          {mealpointsVal === 0 && (
                            <AccordionItem>
                              <AccordionHeader targetId="10">
                                <span className="icon icon-diet"></span>
                                <span className="acc-title">Diet</span>
                              </AccordionHeader>
                              <AccordionBody accordionId="10">
                                <ul>
                                  <li>{mealMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Diet mild end */}

                          {/* Rest & Sleep mild start */}
                          {sleepingpointsVal === 0 && (
                            <AccordionItem>
                              <AccordionHeader targetId="11">
                                <span className="icon icon-rest-sleep"></span>
                                <span className="acc-title">
                                  Rest & Sleep: {sleephour}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="11">
                                <ul>
                                  <li>{sleepMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Rest & Sleep mild end */}

                          {/* Exercise mild start */}
                          {exercisepointsVal >= 0 &&
                            exercisepointsVal <= 0.5 && (
                              <AccordionItem>
                                <AccordionHeader targetId="12">
                                  <span className="icon icon-excercise"></span>
                                  <span className="acc-title">
                                    Exercise: {excercise}
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="12">
                                  <ul>
                                    <li>{excerciseMsg}</li>
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/* Exercise mild end */}

                          {/* Stress mild start */}
                          {stresspointsVal === 0 && (
                            <AccordionItem>
                              <AccordionHeader targetId="13">
                                <span className="icon icon-stress"></span>
                                <span className="acc-title">
                                  Stress: {stress}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="13">
                                <ul>
                                  <li>{stressMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Stress mild end */}

                          {/* Smoking & Tobacco mild start */}
                          {tobaccopointsVal === 0 && (
                            <AccordionItem>
                              <AccordionHeader targetId="14">
                                <span className="icon icon-smoking"></span>
                                <span className="acc-title">
                                  Smoking & Tobacco: {tobacco}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="14">
                                <ul>
                                  <li>{tobaccoMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Smoking & Tobacco mild end */}

                          {/* Alcohol mild start */}
                          {alcoholpointsVal === 0 && (
                            <AccordionItem>
                              <AccordionHeader targetId="15">
                                <span className="icon icon-alcohol"></span>
                                <span className="acc-title">
                                  Alcohol: {alcohol}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="15">
                                <ul>
                                  <li>{alcoholMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Alcohol mild end */}

                          {/* Lifestyle mild start */}
                          {lifestylepointVal <= 3.9 && (
                            <AccordionItem>
                              <AccordionHeader targetId="16">
                                <span className="icon icon-lifestyle"></span>
                                <span className="acc-title">
                                  Lifestyle: {lifestyleMsg}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="16">
                                <ul>
                                  <li>{lifestyleMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Lifestyle mild end */}
                        </Accordion>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      
      {(diseasecalpointsVal >= 27 ||
        bmiVal >= 30 ||
        heartDiseasepointVal ||
        bppointsVal >= 5 ||
        bplowerpointsVal >= 5 ||
        totcholpointVal >= 5 ||
        ldlpointVal >= 5 ||
        hdlpointVal >= 5 ||
        bppointsVal >= 5 ||
        bplowerpointsVal >= 5) && (
        <>
          {userLatestPlan == null ? (
            <Container>
              <div className="bs-card typ-height-auto typ-price-table typ-pl-on typ-mb-on typ-score-analysis">
                <Row className="align-items-center justify-content-between">
                  <Col xl="12" lg="12" md="12">
                    <div class="bs-heading typ-center">
                      <h2 class="heading mb-40">
                        Learn how to Improve your score in just 90 days through
                        Ayurveda
                      </h2>
                    </div>
                  </Col>
                  <Col xl="12" lg="12" md="12">
                    <Row className="align-items-center justify-content-between sec-yt">
                      <Col xl="5" lg="5" md="12">
                        {/* <a href="" target="_blank">
                    <img
                      src={yt_thumbnail}
                      alt="BigCo Inc. logo"
                      style={{ width: "100%" }}
                    />
                  </a> */}
                        <iframe
                          title="vimeo-player"
                          src="https://player.vimeo.com/video/768170272?h=9ecfae64f4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                          width="100%"
                          height="360"
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                      </Col>
                      <Col xl="6" lg="6" md="12">
                        <ul className="counter-increment">
                          <li>
                            <p>
                              As per your Assessment your score is {showScore}{" "}
                              and you have been diagnosed and detected with
                            </p>
                            {hCholesterol && <p>• High Cholestrol</p>}
                            {hBbpHypertension && (
                              <p>• High BP / Hypertension</p>
                            )}
                            {diabete && <p>• Diabetes</p>}
                            {hDisease && <p>• Heart Disease</p>}
                            {bmiVal >= 30 && <p>• Obesity</p>}
                            {heartDiseasepointVal &&
                              (hDisease == null || hDisease == false) && (
                                <p>• Symptoms of Heart Disease</p>
                              )}
                            {(bppointsVal >= 5 || bplowerpointsVal >= 5) &&
                              (diabete == null || diabete == false) && (
                                <p>• Symptoms of Diabetes</p>
                              )}
                            {(totcholpointVal >= 5 ||
                              ldlpointVal >= 5 ||
                              hdlpointVal >= 5) &&
                              (hCholesterol == null ||
                                hCholesterol == false) && (
                                <p>• Symptoms of Cholesterol </p>
                              )}
                            {(bppointsVal >= 5 || bplowerpointsVal >= 5) &&
                              (hBbpHypertension == null ||
                                hBbpHypertension == false) && (
                                <p>• Symptoms of High BP / Hypertension</p>
                              )}
                          </li>
                          <li>
                            To Improve your Heart Health Score, We have created
                            a customized Save My Heart Homebased Program, that
                            will teach you techniques to manage and even reverse
                            your diagnosed disease in 90 Days, simply by
                            following age old Ayurveda Knowledge.
                          </li>
                          <li>
                            Save My Heart Program is customized for every
                            individual, as no two people are the same, Watch the
                            Video to understand how the Program Works to Improve
                            your Heart Health score by following simple steps to
                            live a disease-free life.
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Container>
          ) : (
            ""
          )}
          {userLatestPlan == null ? (
            <Container>
              <div className="bs-card typ-height-auto typ-price-table typ-pl-on typ-mb-on typ-score-analysis">
                <Row className="align-items-center justify-content-between">
                  <Col xl="12" lg="12" md="12">
                    <div class="bs-heading typ-center">
                      <h2 class="heading mb-40">
                        How does Save My Heart Plan work?
                      </h2>
                    </div>
                  </Col>
                  <Col xl="12" lg="12" md="12">
                    <Row className="align-items-center justify-content-between sec-save-my-heart-plan">
                      <Col xl="4" lg="4" md="12">
                        <div className="bs-card typ-icn-txt typ-height-auto">
                          <img src={exersice_img} alt="exersice" />
                          <p className="desc">
                            Watch and follow the videos on Exercise, Disease
                            Education, and Lifestyle Modification.
                          </p>
                        </div>
                      </Col>
                      <Col xl="4" lg="4" md="12">
                        <div className="bs-card typ-icn-txt typ-height-auto">
                          <img src={calender_img} alt="calender" />
                          <p className="desc">
                            Schedule Disease Reversal Consultation Appointment
                            with our Network of 450 Doctors.
                          </p>
                        </div>
                      </Col>
                      <Col xl="4" lg="4" md="12">
                        <div className="bs-card typ-icn-txt typ-height-auto">
                          <img src={monitoring_img} alt="monitoring" />
                          <p className="desc">
                            Continuous Monitoring and Tracking of your Vitals
                            and Heart Health.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Container>
          ) : (
            ""
          )}
          {userLatestPlan == null ? (
            <Container>
              <div className="bs-card typ-height-auto typ-price-table typ-pl-on typ-mb-on typ-score-analysis">
                <Row className="align-items-center justify-content-between">
                  <Col xl="12" lg="12" md="12">
                    <div class="bs-heading typ-center typ-full-width">
                      <h2 class="heading">
                        Select disease you want to manage first
                      </h2>
                      <p className="subheading">
                        Your Customised Save My Heart Program will resolve your
                        health concerns as per below order, you are also allowed
                        to alter your program order as per your requirement
                      </p>
                    </div>
                  </Col>
                  <Col xl="12" lg="12" md="12">
                    <div className="bs-form typ-list mt-0">
                      <FormGroup
                        className="radio-box typ-timeline"
                        tag="fieldset"
                      >
                        {diabete == null || diabete == false ? null : (
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="sleep"
                                // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                onChange={() => setDiseasePriority("Diabetes")}
                                // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                value={"Diabetes"}
                              />{" "}
                              <div className="check-box-label">
                                <div className="circle-box">
                                  <div className="circle"></div>
                                </div>
                                <p className="radio-txt">Diabetes</p>
                              </div>
                            </Label>
                          </FormGroup>
                        )}
                        {bmiVal >= 30 && (
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="sleep"
                                // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                onChange={() => setDiseasePriority("Obesity")}
                                // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                value={"obesity"}
                              />{" "}
                              <div className="check-box-label">
                                <div className="circle-box">
                                  <div className="circle"></div>
                                </div>
                                <p className="radio-txt">Obesity</p>
                              </div>
                            </Label>
                          </FormGroup>
                        )}
                        {hCholesterol == null ||
                        hCholesterol == false ? null : (
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="sleep"
                                // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                onChange={() =>
                                  setDiseasePriority("Cholestrol")
                                }
                                // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                value={"Cholestrol"}
                              />{" "}
                              <div className="check-box-label">
                                <div className="circle-box">
                                  <div className="circle"></div>
                                </div>
                                <p className="radio-txt">High Cholestrol</p>
                              </div>
                            </Label>
                          </FormGroup>
                        )}
                        {hBbpHypertension == null ||
                        hBbpHypertension == false ? null : (
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="sleep"
                                // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                onChange={() =>
                                  setDiseasePriority("Hypertension")
                                }
                                // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                value={"Hypertension"}
                              />{" "}
                              <div className="check-box-label">
                                <div className="circle-box">
                                  <div className="circle"></div>
                                </div>
                                <p className="radio-txt">
                                  High BP / Hypertension
                                </p>
                              </div>
                            </Label>
                          </FormGroup>
                        )}
                        {hDisease == null || hDisease == false ? null : (
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="sleep"
                                // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                onChange={() =>
                                  setDiseasePriority("Heart-Disease")
                                }
                                // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                value={"HeartDisease"}
                              />{" "}
                              <div className="check-box-label">
                                <div className="circle-box">
                                  <div className="circle"></div>
                                </div>
                                <p className="radio-txt">Heart Disease</p>
                              </div>
                            </Label>
                          </FormGroup>
                        )}
                        {heartDiseasepointVal &&
                          (hDisease == null || hDisease == false) && (
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="sleep"
                                  // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                  onChange={() =>
                                    setDiseasePriority("Heart-Disease")
                                  }
                                  // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                  value={"HeartDisease"}
                                />{" "}
                                <div className="check-box-label">
                                  <div className="circle-box">
                                    <div className="circle"></div>
                                  </div>
                                  <p className="radio-txt">
                                    Symptoms of Heart disease
                                  </p>
                                </div>
                              </Label>
                            </FormGroup>
                          )}
                        {(bppointsVal >= 5 || bplowerpointsVal >= 5) &&
                          (diabete == null || diabete == false) && (
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="sleep"
                                  // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                  onChange={() =>
                                    setDiseasePriority("Diabetes")
                                  }
                                  // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                  value={"Diabetes"}
                                />{" "}
                                <div className="check-box-label">
                                  <div className="circle-box">
                                    <div className="circle"></div>
                                  </div>
                                  <p className="radio-txt">
                                    Symptoms of Diabetes
                                  </p>
                                </div>
                              </Label>
                            </FormGroup>
                          )}
                        {(totcholpointVal >= 5 ||
                          ldlpointVal >= 5 ||
                          hdlpointVal >= 5) &&
                          (hCholesterol == null || hCholesterol == false) && (
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="sleep"
                                  // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                  onChange={() =>
                                    setDiseasePriority("Cholestrol")
                                  }
                                  // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                  value={"Cholestrol"}
                                />{" "}
                                <div className="check-box-label">
                                  <div className="circle-box">
                                    <div className="circle"></div>
                                  </div>
                                  <p className="radio-txt">
                                    Symptoms of Cholesterol
                                  </p>
                                </div>
                              </Label>
                            </FormGroup>
                          )}

                        {(bppointsVal >= 5 || bplowerpointsVal >= 5) &&
                          (hBbpHypertension == null ||
                            hBbpHypertension == false) && (
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="sleep"
                                  // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                  onChange={() =>
                                    setDiseasePriority("Hypertension")
                                  }
                                  // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                  value={"Hypertension"}
                                />{" "}
                                <div className="check-box-label">
                                  <div className="circle-box">
                                    <div className="circle"></div>
                                  </div>
                                  <p className="radio-txt">
                                    Symptoms of High BP / Hypertension
                                  </p>
                                </div>
                              </Label>
                            </FormGroup>
                          )}
                      </FormGroup>
                      {/* {errorMsg.sleephour && <p className='error-message'>{errorMsg.sleephour}</p>} */}
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          ) : (
            ""
          )}
        </>
      )}

      {/* <Container>
        <div className="bs-card typ-mob-height-auto typ-pl-on">
          <Row className="align-items-center">
            <Col
              xl={{ offset: 6, size: 6 }}
              lg={{ offset: 6, size: 6 }}
              md="12"
            >
              <div className="bs-heading typ-center">
                <h2 className="heading typ-full-width">
                  How can I improve my score?
                </h2>
              </div>
            </Col>
            <Col xl="6" lg="6" md="12">
              <div className="bs-popup typ-max-wid">
                <img
                  className="popup-img"
                  src={MadhavBaug}
                  alt="multi-color-band"
                />
                <span className="icon icon-yt-play"></span>
              </div>
            </Col>
            <Col xl="6" lg="6" md="12">
              <p className="cm-para typ-bold typ-center">
                We will create a customised plan to help you improve your score
              </p>
              <div className="bs-heading typ-center">
                <p className="subheading">
                  We will be focusing on Heart disease at the start of your
                  plan, you can rearrange it if you wish to
                </p>
              </div>
              <div className="bs-drag">
                <ul>
                  <li>
                    <span className="icon icon-heart-disease"></span>
                    Heart disease
                  </li>
                  <li>
                    <span className="icon icon-iron-man"></span>
                    Breathlessness and Heavy chest pain
                  </li>
                  <li>
                    <span className="icon icon-band-aid"></span>
                    Wound not healing
                  </li>
                  <li>
                    <span className="icon icon-diabetes"></span>
                    Diabetes
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container className="d-none">
        <Row>
          <Col xl="12" lg="12" md="12">
            <div className="text-center">
              <Button className="bs-btn typ-fix-width mt-25 mob-mb-25">
                Start my Free Plan now
              </Button>
            </div>
          </Col>
        </Row>
      </Container> */}
      {/* <div className="only-desk"> */}
      <Footer />
      {/* </div> */}
      {/* <RoutingArrow prev={`${homePageUrl}`} next={`${homePageUrl}select-patient`} /> */}
    </div>
  );
}

export default Reports;
