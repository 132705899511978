import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import OtpInput from "react-otp-input";
import axios from 'axios';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// Import Swiper styles
import 'swiper/css';

// css
import '../../../assets/sass/custom.scss';

// components
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useNavigate } from "react-router-dom";

function OtpVerification2() {
    let navigate = useNavigate();
    let [otp, setOtp] = useState('');
    let [ui, setUi] = useState('');
    let [data, setData] = useState(0)

    const handleChange = (otp1) => {
        console.log("ui value is >>", otp1);
        setUi(otp1);
        let otp_value = otp1;
        console.log("state value is >>", typeof otp);
        if (otp_value == otp) {
            // console.log("match");
            SaveDetail()
            // sendWhatsapp()
        }
        else {
            console.log("No match");
        }
    };
    useEffect(() => {
        return () => {
            console.log("Health status is >>", JSON.parse(window.localStorage.getItem('healthStatus')));
            sendOtp();
        }
    }, []);

    // const getUserIp = async() =>{
    //     axios('https://api.ipify.org?format=json').then(response => {
    //         console.log("api resposne is >> ",response);
    //       }).then((res) => {
    //         // console.log("Api response is >>",res);
    //       }).catch((err) => console.error('Problem fetching my IP', err))
    // }

    const sendWhatsapp = async (id) => {
        // console.log("id is >>",id);
        const status = JSON.parse(window.localStorage.getItem('healthStatus'))
        const score = JSON.parse(window.localStorage.getItem('score'))
        const name = JSON.parse(window.localStorage.getItem('name'))
        const mobile = JSON.parse(window.localStorage.getItem('mobile'))
        const formatted_mobile_number = `91${mobile}`
        console.log("formatted mobile is >>", formatted_mobile_number);
        try {
            const formData = new FormData()
            formData.append('mobile', formatted_mobile_number)
            formData.append('score', score)
            formData.append('name', name)
            formData.append('status', status)
            formData.append('uid', id)
            const response = await axios({
                method: "post",
                url: "http://localhost:8000/api/whatapplink",
                data: formData,
            });
            console.log("Response is >>", response);
            if (response.status == 200) {
                // navigate(`${homePageUrl}health-score`,{uniqueId});
            }
        } catch (error) {
            console.log("error is ", error)
        }

    }
    const sendOtp = async () => {
        const mobile = JSON.parse(window.localStorage.getItem('mobile'))
        console.log("Mobile numberr is >>", mobile);
        const formatted_mobile_number = `91${mobile}`
        try {
            const formData = new FormData()
            formData.append('mobile', formatted_mobile_number)
            const response = await axios({
                method: "post",
                url: "http://localhost:8000/api/sendSMS",
                data: formData,
            });
            console.log("Response is >>", response);
            if (response.data.statusCode == 200) {
                setOtp(response.data.otp);
                //   sendWhatsapp();
                //   navigate(`${homePageUrl}health-score`);
            }
        } catch (error) {
            console.log("error is ", error)
        }
    }

    const SaveDetail = async () => {
        const allKeys = JSON.parse(JSON.stringify(window.localStorage));
        console.log("All keys >>", allKeys);
        try {
            const response = await axios({
                method: "post",
                url: "http://localhost:8000/api/addUserData",
                data: allKeys,
            });
            console.log("Response is >>", response);
            if (response.data.statusCode == 200) {
                let uid = response.data.uniqueId;
                sendWhatsapp(uid);
                navigate(`${homePageUrl}health-score`, {
                    state: { uid }
                });
            }
        } catch (error) {
            console.log("error is ", error)
        }
    }

    return (
        <>
   


        <div className='main'>
            {/* <Header barValue={62} /> */}
           
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{x: "-100%", opacity: 0 }} className='bs-card'>
                    
                    <Row className='align-items-center'>
                        <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                            {
                                isMobile &&
                                <div className="bs-heading typ-center">
                                    <h2 className="heading">
                                        OTP Verification
                                    </h2>
                                    <p className="subheading">
                                        Your Assesment is Complete! <br />
                                        Enter the OTP to get your report
                                    </p>
                                </div>
                            }
                        </Col>
                        <Col xl="6" lg="6" md="12">
                            <div className="bs-img-sprite typ-otp-verification bg-1">
                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="12">
                            {
                                isBrowser &&
                                <div className="bs-heading typ-center">
                                    <h2 className="heading">
                                        OTP Verification
                                    </h2>
                                    <p className="subheading">
                                        Your Assesment is Complete! <br />
                                        Enter the OTP to get your report
                                    </p>
                                </div>
                            }
                            <form className="bs-form">
                                <FormGroup className='text-box'>
                                    <Label htmlFor="relative">Enter Your Whatsapp No.</Label>
                                    <Input type="text" name='relative' value="" />
                                    {/* <p className='error-message'>{formErrors.relative}</p> */}
                                </FormGroup>
                                <Button className='bs-btn'>
                                    Change Number
                                </Button>
                            </form>
                        </Col>
                    </Row>
                </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prev={`${homePageUrl}heart-disease`} next={`${homePageUrl}health-score`} />
            <Footer />
        </div>
        </>
    )
}


export default OtpVerification2;