import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { calcLength, motion } from 'framer-motion';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images

// css
import '../../../assets/sass/custom.scss';

// import RangeSlider from '../../shared/RangeSlider/RangeSlider';
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';

function SugarValues() {

    let navigate = useNavigate()

    let [fastingSugar, setFastingSugar] = useState(90);
    let [postFastingSugar, setPostFastingSugar] = useState(130)
    let [hbac1, setHbac1] = useState(4.5)
    let [errorMsg, setErrorMsg] = useState({ fastingSugar: "", postFastingSugar: "", hbac1: "" })

    const handle_var = document.getElementsByClassName('rangeslider__handle-label');

    const handleFastingSugar = (value) => {
        let fs = value
        fastingSugar = fs
        setFastingSugar(fastingSugar)
        setErrorMsg({ ...errorMsg, fastingSugar: "" })
        window.localStorage.setItem('fasting-sugar', JSON.stringify(fastingSugar))
        handle_var[0].innerHTML =  fastingSugar
    }
    const handlePostFastingSugar = (value) => {
        let pfs = value
        postFastingSugar = pfs
        setPostFastingSugar(postFastingSugar)
        setErrorMsg({ ...errorMsg, postFastingSugar: "" })
        window.localStorage.setItem('post-fasting-sugar', JSON.stringify(postFastingSugar))
        handle_var[1].innerHTML =  postFastingSugar
    }
    const handleHbac1 = (value) => {
        let hc1 = value
        hbac1 = Math.round(hc1 * 10) / 10
        setHbac1(hbac1)
        setErrorMsg({ ...errorMsg, hbac1: "" })
        window.localStorage.setItem('hbac1', JSON.stringify(hbac1))
        handle_var[2].innerHTML =  hbac1
    }

    const handleSubmit = () => {
        const fastingSugar = window.localStorage.getItem('fasting-sugar')
        const postFastingSugar = window.localStorage.getItem('post-fasting-sugar')
        const hbac1 = window.localStorage.getItem('hbac1')
        if(!fastingSugar && !postFastingSugar && !hbac1) {
            setErrorMsg({...errorMsg, fastingSugar: "Fill the Fasting Sugar value", postFastingSugar: "Fill the Post Fasting Sugar value", hbac1: "Fill the hbac1 value" })
        } else if(fastingSugar && postFastingSugar && hbac1) {
            navigate(`${homePageUrl}blood-pressure3`)
        } else if (!fastingSugar || JSON.parse(fastingSugar) === null || JSON.parse(fastingSugar) === undefined) {
            setErrorMsg({ ...errorMsg, fastingSugar: "Fill the Fasting Sugar value"})
        } else if (!postFastingSugar || JSON.parse(postFastingSugar) === null || JSON.parse(postFastingSugar) === undefined ) {
            setErrorMsg({...errorMsg, postFastingSugar: "Fill the Post Fasting Sugar value"})
        } else if (!hbac1 || JSON.parse(hbac1) === null || JSON.parse(hbac1) === undefined ) {
            setErrorMsg({...errorMsg, hbac1: "Fill the hbac1 value"})
        }
    }

    useEffect(() => {
        const fastingSugar = JSON.parse(window.localStorage.getItem('fasting-sugar'))
        const postFastingSugar = JSON.parse(window.localStorage.getItem('post-fasting-sugar'))
        const hbac1 = JSON.parse(window.localStorage.getItem('hbac1'))
        setFastingSugar(fastingSugar)
        setPostFastingSugar(postFastingSugar)
        setHbac1(hbac1)
    }, [])

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{x: "-100%", opacity: 0 }} className='bs-card'>
                    <Row className="align-items-center">
                        <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                            <div className="bs-heading typ-center">
                                <h2 className="heading">
                                    Sugar Values
                                </h2>
                                <p className="subheading">
                                    Enter correct information for us to fill into our record books
                                </p>
                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="12">
                            <div className="bs-img-sprite typ-sugar-values bg-1">
                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="12">
                            <div className="bs-form typ-range">
                                <p className="cm-para typ-small typ-black mb-10">
                                    Select Fasting Sugar Reading
                                </p>
                                <Slider
                                    value={JSON.parse(window.localStorage.getItem('fasting-sugar')) == null ? 90 : fastingSugar}
                                    orientation="horizontal"
                                    onChange={handleFastingSugar}
                                    min={40}
                                    max={300}
                                    tooltip={false}
                                    labels={{ 0: '40 mg/dl', 100: '300+ mg/dl' }}
                                    handleLabel={JSON.parse(window.localStorage.getItem('fasting-sugar')) == null ? 90 : fastingSugar}
                                />
                            </div>
                            {errorMsg.fastingSugar && <p className='error-message'>{errorMsg.fastingSugar}</p>}
                            <div className="bs-form typ-range">
                                <p className="cm-para typ-small typ-black mb-10">
                                    Select Post Meal Sugar Reading
                                </p>
                                <Slider
                                    value={JSON.parse(window.localStorage.getItem('post-fasting-sugar')) == null ? 130 : postFastingSugar}
                                    orientation="horizontal"
                                    onChange={handlePostFastingSugar}
                                    min={40}
                                    max={300}
                                    tooltip={false}
                                    labels={{ 0: '40 mg/dl', 100: '300+ mg/dl' }}
                                    handleLabel={JSON.parse(window.localStorage.getItem('post-fasting-sugar')) == null ? 130 : postFastingSugar}
                                />
                            </div>
                            {errorMsg.postFastingSugar && <p className='error-message'>{errorMsg.postFastingSugar}</p>}
                            <div className="bs-form typ-range">
                                <p className="cm-para typ-small typ-black mb-10">
                                    Select HBA1C Reading
                                </p>
                                <Slider
                                    value={JSON.parse(window.localStorage.getItem('hbac1')) == null ? 4.5 : hbac1}
                                    step={0.1}
                                    orientation="horizontal"
                                    onChange={handleHbac1}
                                    min={3.0}
                                    max={20.0}
                                    tooltip={false}
                                    labels={{ 0: '3 mmol/L', 100: '20 mmol/L' }}
                                    handleLabel={JSON.parse(window.localStorage.getItem('hbac1')) == null ? 4.5 : Math.round(hbac1 * 10) / 10}
                                />
                            </div>
                            {errorMsg.hbac1 && <p className='error-message'>{errorMsg.hbac1}</p>}
                        </Col>
                    </Row>
                </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prev={`${homePageUrl}add-your-vitals`} submit next={handleSubmit} />
            <Footer />
        </div>
    );
}

export default SugarValues;