import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, FormGroup, Input, Label, Button, Modal, ModalBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { useLocation, useNavigate, Link, useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';

// animation 
// import Aos from "aos";
// import "aos/dist/aos.css";

//constant
import { homePageUrl, getSingleUserDataFromApi } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images 
// import coupon1 from "../../../assets/images/discount-coupon/1.jpg";
// import coupon2 from "../../../assets/images/discount-coupon/2.jpg";
import coupon1 from "../../../assets/images/discount-coupon/bp.png";
import coupon2 from "../../../assets/images/discount-coupon/pp.png";

// css
import '../../../assets/sass/custom.scss';

// json 
import LottiFiles from '../../../assets/json/start-meter.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { animate } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import axios from "axios";

function SubscriptionDiscountCoupons() {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    let [userId, setUserId] = useState("");
    let [planName, setPlanName] = useState("")
    let [uniqueId, setUniqueId] = useState("")
    let [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        if (location.state != null) {
            console.log("Here", location)
            setUserId(location.state.userId);
            window.localStorage.setItem('userId', JSON.stringify(location.state.userId))
            console.log("user id >>", userId)
            getUserData(location.state.userId);
        }
        else {
            if (searchParams.get('userId') != null || searchParams.get('userId') != undefined) {
                const id = searchParams.get('userId')
                setUserId(id);
                setTimeout(() => {
                    window.localStorage.setItem('userId', JSON.stringify(id))
                    console.log("user id is here >>", id);
                }, 500);
                getUserData(id);
            }
            else {
                let uid = JSON.parse(window.localStorage.getItem('userId'));
                setUserId(uid);
                getUserData(uid);
            }
        }
        if(JSON.parse(window.localStorage.getItem('lang')) != "")
        {
            handleClick("en");
        }
        else if(JSON.parse(window.localStorage.getItem('lang')) == "mr")
        {
            handleClick("mr");
        }
        else
        {
            handleClick("hi");
        }
        // if(searchParams.get('userId'))
        // {
        //     console.log("There")
        //     setUserId(location.state.userId);
        // }

        // setVideoLinks(location.state.video_links);
    }, []);

    const handleClick = (lang) => 
    {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>",lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>",t)
    }

    const getUserData = async (id) => {
        if (userId != null || userId != undefined) {
            const formData = new FormData()
            formData.append('userId', id)
            // formData.append('uid', "12456")
            const response = await axios({
                method: "post",
                url: getSingleUserDataFromApi,
                data: formData,
            });
            console.log("response is >>", response.data.users.plan_name)
            if (response.status == 200) {
                setPlanName(response.data.users.plan_name);
                setUniqueId(response.data.users.uniqueId);
            }
        }
    }

    return (
        <div className='main'>
            <AnimationWrapper>
                <motion.div exit={{ x: "-100%", opacity: 0 }}>
                    <Container>
                        <Row className='text-center'>
                            <Col xl="12" lg="12" md="12">
                                <div className='bs-card typ-pl-on typ-subscription-main'>
                                    <Breadcrumb className='bs-breadcrumb'>
                                        <BreadcrumbItem><Link to={`${homePageUrl}subscription-home`}>Home</Link></BreadcrumbItem>
                                        <BreadcrumbItem active>{t('SubscriptionDiscountCoupons.1')}</BreadcrumbItem>
                                    </Breadcrumb>
                                    <Row className="align-items-center justify-content-center sec-save-my-heart-plan">
                                        <Col xl="12" lg="12" md="12">
                                            <div className="bs-heading typ-center">
                                                <h2 className="heading">
                                                    {t('SubscriptionDiscountCoupons.1')}
                                                </h2>
                                                <p className="subheading">
                                                {t('SubscriptionDiscountCoupons.2')} {planName != undefined && planName} {t('SubscriptionDiscountCoupons.3')}


                                                </p>
                                                <p className="subheading">
                                                {t('SubscriptionDiscountCoupons.4')}
                                                </p>
                                            </div>
                                        </Col>
                                        {/* {
                                            planName == "SMH Basic" ? 
                                            <Col xl="4" lg="4" md="12">
                                                <div className="bs-card typ-icn-txt typ-height-auto typ-subscription typ-discount-coupon">
                                                    <img src={coupon1} alt="" />
                                                </div>
                                            </Col>
                                            
                                            : 

                                            <Col xl="4" lg="4" md="12">
                                                <div className="bs-card typ-icn-txt typ-height-auto typ-subscription typ-discount-coupon">
                                                    <img src={coupon2} alt="" />
                                                </div>
                                            </Col>
                                        } */}

                                        <Col xl={12} lg={12} md={12}>
                                            <div className='dicount-box'>
                                                <div className='bs-card typ-discount-new typ-height-auto'>
                                                    <p className="discount">100% OFF</p>
                                                    <p className='main-title'>
                                                        Madhavbaug
                                                    </p>
                                                    <div className='inner-row'>
                                                        <div className="left-col">
                                                            <span class="icon icon-heart2"></span>
                                                            <div className='icn-txt'>
                                                                <p className='title'>
                                                                {t('SubscriptionDiscountCoupons.5')}
                                                                </p>
                                                                <p className='desc'>{t('SubscriptionDiscountCoupons.6')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="right-col">
                                                            <p>{t('SubscriptionDiscountCoupons.7')}</p>
                                                            <p className='mrp'>350</p>
                                                        </div>
                                                    </div>
                                                    <div className='coupon-row'>
                                                        <p className='therapy'>{t('SubscriptionDiscountCoupons.8')}</p>
                                                        <p className='coupon-code'>HHMDR{uniqueId}</p>
                                                    </div>
                                                </div>
                                                <div className='bs-card typ-discount-new typ-height-auto'>
                                                    <p className="discount">50% OFF</p>
                                                    <p className='main-title'>
                                                        Madhavbaug
                                                    </p>
                                                    <div className='inner-row'>
                                                        <div className="left-col">
                                                            <span class="icon icon-heart2"></span>
                                                            <div className='icn-txt'>
                                                                <p className='title'>
                                                                {t('SubscriptionDiscountCoupons.9')}
                                                                </p>
                                                                <p className='desc'>{t('SubscriptionDiscountCoupons.6')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="right-col">
                                                            <p className='price'>500</p>
                                                            <p className='mrp'>1000</p>
                                                        </div>
                                                    </div>
                                                    <div className='coupon-row'>
                                                        <p className='therapy'>{t('SubscriptionDiscountCoupons.10')}</p>
                                                        <p className='coupon-code'>HHMPK{uniqueId}</p>
                                                    </div>
                                                </div>
                                                <div className='bs-card typ-discount-new typ-height-auto'>
                                                    <p className="discount">50% OFF</p>
                                                    <p className='main-title'>
                                                        Madhavbaug
                                                    </p>
                                                    <div className='inner-row'>
                                                        <div className="left-col">
                                                            <span class="icon icon-heart2"></span>
                                                            <div className='icn-txt'>
                                                                <p className='title'>
                                                                {t('SubscriptionDiscountCoupons.11')}
                                                                </p>
                                                                <p className='desc'>{t('SubscriptionDiscountCoupons.6')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="right-col">
                                                            <p className='price'>975</p>
                                                            <p className='mrp'>1950</p>
                                                        </div>
                                                    </div>
                                                    <div className='coupon-row'>
                                                        <p className='therapy'>{t('SubscriptionDiscountCoupons.12')}</p>
                                                        <p className='coupon-code'>HHMST{uniqueId}</p>
                                                    </div>
                                                </div>
                                                <div className='bs-card typ-discount-new typ-height-auto'>
                                                    <p className="discount">30% OFF</p>
                                                    <p className='main-title'>
                                                        Madhavbaug
                                                    </p>
                                                    <div className='inner-row'>
                                                        <div className="left-col">
                                                            <span class="icon icon-heart2"></span>
                                                            <div className='icn-txt'>
                                                                <p className='title'>
                                                                {t('SubscriptionDiscountCoupons.13')}
                                                                </p>
                                                                <p className='desc'>{t('SubscriptionDiscountCoupons.6')}</p>
                                                            </div>
                                                        </div>
                                                        {/* <div className="right-col">
                                                            <p className='price'>2000</p>
                                                            <p className='mrp'>4000</p>
                                                        </div> */}
                                                    </div>
                                                    <div className='coupon-row'>
                                                        <p className='therapy'>{t('SubscriptionDiscountCoupons.14')}</p>
                                                        <p className='coupon-code'>HHMPT{uniqueId}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>


                                        <Col xl="12" lg="12" md="12">
                                            <div className="bs-heading typ-center">
                                                <p className="subheading">
                                                {t('SubscriptionDiscountCoupons.15')} : <span className='coupon-code'>HHMPK{uniqueId}</span>
                                                </p>
                                            </div>
                                        </Col>
                                        <Col xl="8" lg="8" md="8">
                                            <Link className='bs-btn' to={`${homePageUrl}subscription-med`}>
                                            {t('SubscriptionDiscountCoupons.16')}
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </motion.div>
            </AnimationWrapper>
            <Footer />
        </div>
    )
}

export default SubscriptionDiscountCoupons;