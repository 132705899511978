import React, { useState, useEffect } from 'react';

// Import Swiper styles
import 'swiper/css';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// css
import '../../../assets/sass/custom.scss';

// components
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
// import RangeSlider from '../../shared/RangeSlider/RangeSlider';
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'


function Test() {
    const [value, setValue] = useState(50);
    const [volume, setVolume] = useState(0)

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleOnChange = (value) => {
        setVolume(value)
    }
    return (
        <div className='main'>
            <Header />
            <p>{value}</p>
            <Slider
                value={volume}
                orientation="horizontal"
                onChange={handleOnChange}
                tooltip={false}
                labels={{ 0: 'Low', 50: 'Medium', 100: 'High'}}
                handleLabel={volume}
            />
            {/* <RangeSlider value={value} handleChange={handleChange} /> */}
            <RoutingArrow prev={`${homePageUrl}lifestyle-assessment`} next={`${homePageUrl}bmi`} />
            <Footer />
        </div>
    )
}


export default Test;