import React, { useEffect, useState, useRef } from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  FormGroup,
  Input,
  Label,
  Table,
  Modal,
  Spinner
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Vimeo from '@u-wave/react-vimeo';

//constant
import {
  homePageUrl,
  userDetailApi,
  createOrder,
  payment,
  subscriptionHome,
  PlanDetails,
  getUserLatestPlanDetails,
  getAssessmentLogs,
  getCoupons,
} from "../../../helper/ContentConstants";
import hhmImage from "../../../helper/ImageConstants";

// images
import yt_thumbnail from "../../../assets/images/health-score/your-customized-plan.jpg";
import exersice_img from "../../../assets/images/health-score/exersice.png";
import calender_img from "../../../assets/images/health-score/calender.png";
import monitoring_img from "../../../assets/images/health-score/monitoring.png";
import madhav_baug from "../../../assets/images/health-score/madhav-baug.jpg";
import popular from "../../../assets/images/svg/popular.svg";

// css
import "../../../assets/sass/custom.scss";

// json
import LottiFiles from "../../../assets/json/health-score.json";
import excellent from "../../../assets/json/excellent.json";
import veryGood from "../../../assets/json/very-good.json";
import fair from "../../../assets/json/fair.json";
import good from "../../../assets/json/good.json";
import poor from "../../../assets/json/poor.json";

// components
import Footer from "../../layouts/footer/Footer";
import axios from "axios";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useRazorpay from "react-razorpay";
// Toaster
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function HealthScore() {
  const Razorpay = useRazorpay();
  let navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("1");

  const [modal, setModal] = useState(false);
  const formPopup = () => {
    setModal(!modal);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  // accordion
  const [open, setOpen] = useState("1");
  const accordion = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  //Final Result parameters
  let [finalPoint, setFinalPoint] = useState("");
  let [showScore, setShowScore] = useState("");
  let [healthStatus, setHealthStatus] = useState("");
  let [messageDesc, setMessageDesc] = useState("");
  let [isReadMore, setIsReadMore] = useState(true);
  let [finalMsg, setFinalMsg] = useState("");
  let [scoreMeter, setScoreMeter] = useState("");
  let [imgClass, setImgClass] = useState("bg-1");

  //Score analysis (Zones)
  let [severe, setSevere] = useState("");
  let [moderate, setModerate] = useState("");
  let [mild, setMild] = useState("");

  // console.log("severe value", severe)

  //All logic values
  let [agepointsVal, setAgepointsVal] = useState();
  let [alcoholpointsVal, setAlcoholpointsVal] = useState("");
  let [bmipointsVal, setBmipointsVal] = useState("");
  let [bplowerpointsVal, setBplowerpointsVal] = useState("");
  let [bppointsVal, setBppointsVal] = useState("");
  let [diseasecalpointsVal, setDiseasecalpointsVal] = useState("");
  let [exercisepointsVal, setExercisepointsVal] = useState("");
  let [familyhistorypointsVal, setFamilyhistorypointsVal] = useState("");
  let [hdlpointVal, setHdlpointVal] = useState("");
  let [heartSurgerypointsVal, setHeartSurgerypointsVal] = useState("");
  let [ldlpointVal, setLdlpointVal] = useState("");
  let [mealpointsVal, setMealpointsVal] = useState("");
  let [noofMedicinepointsVal, setNoofMedicinepointsVal] = useState("");
  let [sleepingpointsVal, setSleepingpointsVal] = useState("");
  let [tobaccopointsVal, setTobaccopointsVal] = useState("");
  let [stresspointsVal, setStresspointsVal] = useState("");
  let [sugarfpointVal, setSugarfpointVal] = useState("");
  let [sugarhbpointVal, setSugarhbpointVal] = useState("");
  let [sugarpppointVal, setSugarpppointVal] = useState("");
  let [symptomspointVal, setSymptomspointVal] = useState("");
  let [totcholpointVal, setTotcholpointVal] = useState("");
  let [waistSizePointsVal, setWaistSizePointsVal] = useState("");
  let [anginapointVal, setAnginapointVal] = useState("");
  let [lifestylepointVal, setLifestylepointVal] = useState("");
  let [heartDiseasepointVal, setHeartDiseasepointVal] = useState("");

  //Score Analysis values and messages
  let [bmiVal, setBmiVal] = useState("");

  let [bmiMessage, setBmiMessage] = useState("");
  let [ageMessage, setAgeMessage] = useState("");
  let [sleepMsg, setSleepMsg] = useState("");
  let [mealMsg, setMealMsg] = useState("");
  let [excerciseMsg, setExcerciseMsg] = useState("");
  let [alcoholMsg, setAlcoholMsg] = useState("");
  let [tobaccoMsg, setTobaccoMsg] = useState("");
  let [stressMsg, setStressMsg] = useState("");
  let [diastolicLowerBpMsg, setDiastolicLowerBp] = useState("");
  let [systolicUpperBpMsg, setSystolicUpperBpMsg] = useState("");
  let [fastingSugarMsg, setFastingSugarMsg] = useState("");
  let [postFastingSugarMsg, setPostFastingSugarMsg] = useState("");
  let [hba1cMsg, setHba1cMsg] = useState("");
  let [heartBlockageMsg, setHeartBlockageMsg] = useState("");
  let [symptomsMsg, setSymptomsMsg] = useState("");
  let [totalCholestrolMsg, setTotalCholestrolMsg] = useState("");
  let [ldlMessage, setLdlMsg] = useState("");
  let [hdlMessage, setHdlMsg] = useState("");
  let [aginaMsg, setAginaMsg] = useState("");
  let [familyhistoryMsg, setFamilyhistoryMsg] = useState("");
  let [lifestyleMsg, setLifestyleMsg] = useState("");

  //Actual values from each page from Database
  let [sleephour, setSleephour] = useState("");
  let [excercise, setExcercise] = useState("");
  let [vegetableInTake, setVegetableInTake] = useState("");
  let [tobacco, setTobacco] = useState("");
  let [alcohol, setAlcohol] = useState("");
  let [stress, setStress] = useState("");
  let [weight, setWeight] = useState("");
  let [heightFt, setHeightFt] = useState("");
  let [heightIn, setHeightIn] = useState("");
  let [hCholesterol, sethCholesterol] = useState("");
  let [hBbpHypertension, sethBbpHypertension] = useState("");
  let [diabete, setdiabete] = useState("");
  let [hDisease, sethDisease] = useState("");
  let [noDisease, setnoDisease] = useState("");
  let [famHCholesterol, setfamHCholesterol] = useState("");
  let [famHBbpHypertension, setfamHBbpHypertension] = useState("");
  let [famDiabete, setfamDiabete] = useState("");
  let [famHDisease, setfamHDisease] = useState("");
  let [famNoDisease, setfamNoDisease] = useState("");
  let [medicine, setmedicine] = useState("");
  let [medicineCount, setmedicineCount] = useState("");
  let [irregualrMedicine, setirregualrMedicine] = useState("");
  let [heartBlockage, setheartBlockage] = useState("");
  let [heartSurgery, setheartSurgery] = useState("");
  let [frequentUrination, setfrequentUrination] = useState("");
  let [excessiveHunger, setexcessiveHunger] = useState("");
  let [woundNotHealing, setwoundNotHealing] = useState("");
  let [weightLoss, setweightLoss] = useState("");
  let [weightGain, setweightGain] = useState("");
  let [jointPains, setjointPains] = useState("");
  let [headache, setheadache] = useState("");
  let [weaknessOfFatigue, setweaknessOfFatigue] = useState("");
  let [constipation, setconstipation] = useState("");
  let [sweating, setsweating] = useState("");
  let [noSymptoms, setnoSymptoms] = useState("");
  let [whileAtRest, setwhileAtRest] = useState("");
  let [duringRoutineWorkout, setduringRoutineWorkout] = useState("");
  let [afterHeavyExercise, setafterHeavyExercise] = useState("");
  let [noSuchSymptoms, setnoSuchSymptoms] = useState("");
  let [bloodSugar, setbloodSugar] = useState("");
  let [fastingSugar, setfastingSugar] = useState("");
  let [postFastingSugar, setpostFastingSugar] = useState("");
  let [hbac1, sethbac1] = useState("");
  let [bloodCholesterol, setbloodCholesterol] = useState("");
  let [totalCholestrol, settotalCholestrol] = useState("");
  let [ldl, setldl] = useState("");
  let [hdl, sethdl] = useState("");
  let [bloodPressure, setbloodPressure] = useState("");
  let [systolicUpperBp, setsystolicUpperBp] = useState("");
  let [diastolicLowerBp, setdiastolicLowerBp] = useState("");
  let [waistSize, setwaistSize] = useState("");
  let [tingling, setTingling] = useState("");
  let [age, setAge] = useState("");
  let [gender, setGender] = useState("Male");
  let [diseasePriority, setDiseasePriority] = useState("");
  let [userValue, setUserValue] = useState("");
  let [orderId, setOrderId] = useState("");
  let [diseaseArray, setDiseaseArray] = useState([]);
  let [planName, setPlanName] = useState("");
  let [planAmount, setPlanAmount] = useState("");
  let [success, setSuccess] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  let [planDetail, setPlanDetail] = useState("");
  let [paymentStatus, setPaymentStatus] = useState("");
  let [userLatestPlan, setUserLatestPlan] = useState("");
  let [userAssessmentLog, setUserAssessmentLog] = useState("");
  let [couponCode, setCouponCode] = useState("");
  let [assessment, setAssessment] = useState(false);
  let [coupon, setCoupon] = useState("");
  let [discountPercent, setDiscountPercent] = useState(0);
  let [plan1Subtotal, setPlan1Subtotal] = useState("");
  let [plan2Subtotal, setPlan2Subtotal] = useState("");
  let [discountAmount, setDiscountAmount] = useState(0);
  let [userExist, setUserExist] = useState("");
  let [symptomsSituation, setSymptomsSituation] = useState("");
  let [firstName,setFirstName] = useState("");
  let [email,setEmail] = useState("");
  let [mobile,setMobile] = useState("")
  const [loading, setLoading] = useState(false);
  let stockPriceElement1;
  let stockPriceElement2;


  useEffect(() => {
    let redZone = document.querySelectorAll(
      "#severeZone .bs-accordion .accordion .accordion-item"
    );
    let yellowZone = document.querySelectorAll(
      "#moderateZone .bs-accordion .accordion .accordion-item"
    );
    let greenZone = document.querySelectorAll(
      "#mildZone .bs-accordion .accordion .accordion-item"
    );
    console.log("yellow zone value is >>", (yellowZone.length / 16) * 100)
    // console.log("my red zone", redZone.length, "yellowZone", yellowZone.length, "greenZone", greenZone.length)
    setSevere((redZone.length / 16) * 100);
    setModerate((yellowZone.length / 16) * 100);
    setMild((greenZone.length / 16) * 100);
    // console.log(severe, moderate, mild)
  });
  const ref = useRef(null);
  useEffect(() => {
    //set-up
    // if (process.env.NODE_ENV === "production") {
    //   window.localStorage.clear();
    // }
    getUserData();
    DataCalculculation();
    getPlanDetails();
    getUserPlanDetails();
    getUserAssessmentLogs();

    // clean -up
    return () => {
      getUserData();
      DataCalculculation();
      getPlanDetails();
      getUserPlanDetails();
    };
  }, [success]);

  const getUserData = async () => {
    try {
      if (JSON.parse(window.localStorage.getItem("assessment")) != null) {
        setAssessment(true);
      }
      let uid;
      if (searchParams.get("value") != null) {
        uid = searchParams.get("value");
        setUserValue(uid);
      } else {
        uid = location.state.id;
        setUserValue(location.state.id);
      }
      console.log("i am here", uid);
      const response = await axios({
        method: "get",
        url: userDetailApi + uid,
        // url: userDetailApi + "10139",
      });
      console.log("Response is >>123", response);
      if (response.data.statusCode == 200) {
        setTimeout(() => {
          setUserExist(JSON.parse(response.data.exists));
          setPaymentStatus(response.data.payment);
          console.log("Payment status is >>", paymentStatus);

          setSleephour(response.data.users[0].sleep);
          setExcercise(response.data.users[0].exercise);
          setVegetableInTake(response.data.users[0].vegetables);
          setTobacco(response.data.users[0].tabacco);
          setAlcohol(response.data.users[0].alcohol);
          setStress(response.data.users[0].stress);
          setWeight(response.data.users[0].weight);
          setHeightFt(response.data.users[0].heightFeet);
          setHeightIn(response.data.users[0].heightInches);
          sethCholesterol(
            response.data.users[0].cholesterolDiagnosed);
          sethBbpHypertension(
            response.data.users[0].highBpDiagnosed);
          setdiabete(response.data.users[0].diabetesDiagnosed);
          sethDisease(response.data.users[0].heartDiseaseDiagnosed);
          setnoDisease(response.data.users[0].noneOfTheAbove);
          setfamHCholesterol(
            response.data.users[0].familyCholesterol
          );
          setfamHBbpHypertension(
            response.data.users[0].familyHistoryHighBp
          );
          setfamDiabete(
            response.data.users[0].familyHistoryDiabetes
          );
          setfamHDisease(
            response.data.users[0].familyHistoryHeartDisease
          );
          setfamNoDisease(
            response.data.users[0].familyNoneOfTheAbove
          );
          setmedicine(response.data.users[0].medicines);
          setmedicineCount(response.data.users[0].noOfMedicine);
          setirregualrMedicine(
            response.data.users[0].missMedicines
          );
          setheartBlockage(response.data.users[0].heartBlockages);
          setheartSurgery(response.data.users[0].heartSurgery);
          setfrequentUrination(
            response.data.users[0].frequentUrination
          );
          setexcessiveHunger(
            response.data.users[0].excessiveHungerThirst
          );
          setwoundNotHealing(
            response.data.users[0].woundNotHealing
          );
          setweightLoss(response.data.users[0].weightLoss);
          setweightGain(response.data.users[0].weightGain);
          setjointPains(response.data.users[0].jointPains);
          setheadache(response.data.users[0].headache);
          setweaknessOfFatigue(
            response.data.users[0].weaknessFatigue
          );
          setconstipation(response.data.users[0].constipation);
          setsweating(response.data.users[0].sweating);
          setwhileAtRest(response.data.users[0].whileAtRest);
          setduringRoutineWorkout(
            response.data.users[0].duringRoutineWorkout
          );
          setafterHeavyExercise(
            response.data.users[0].afterHeavyExercise
          );
          setnoSuchSymptoms(response.data.users[0].noSuchSymtoms);
          setbloodSugar(response.data.users[0].sugarValue);
          setfastingSugar(response.data.users[0].sugarFS);
          setpostFastingSugar(response.data.users[0].sugarPP);
          sethbac1(response.data.users[0].hbA1c);
          setbloodCholesterol(
            response.data.users[0].cholesterolValue
          );
          settotalCholestrol(
            Number(response.data.users[0].totalCholesterol)
          );
          setldl(response.data.users[0].LDL);
          sethdl(response.data.users[0].HDL);
          setbloodPressure(
            response.data.users[0].BloodPressureValue
          );
          setsystolicUpperBp(response.data.users[0].systolic);
          setdiastolicLowerBp(response.data.users[0].diastolic);
          setnoSymptoms(response.data.users[0].noAboveSymptoms);
          setwaistSize(response.data.users[0].waistSize);
          setTingling(response.data.users[0].tinglingNumbness);
          setAge(response.data.users[0].age);
          setGender(response.data.users[0].gender);
          // setSymtomSituations(response.data.users[0].symptomSituation))
          setAgepointsVal(Number(response.data.users[0].agepoints));
          setAlcoholpointsVal(Number(response.data.users[0].alcoholpoints));
          setExcercise(response.data.users[0].exercise);
          setBmipointsVal(Number(response.data.users[0].bmipoints));
          setBplowerpointsVal(Number(response.data.users[0].bplowerpoints));
          setBppointsVal(Number(response.data.users[0].bppoints));
          setDiseasecalpointsVal(
            Number(response.data.users[0].diseasecalpoints)
          );
          setExercisepointsVal(
            Number(response.data.users[0].exercisepoints)
          );
          setFamilyhistorypointsVal(
            Number(response.data.users[0].familyhistorypoints)
          );
          setHdlpointVal(Number(response.data.users[0].hdlpoint));
          setHeartSurgerypointsVal(
            Number(response.data.users[0].heartSurgerypoints)
          );
          setLdlpointVal(Number(response.data.users[0].ldlpoint));
          setMealpointsVal(Number(response.data.users[0].mealpoints));
          setNoofMedicinepointsVal(
            Number(response.data.users[0].noofMedicinepoints)
          );
          setSleepingpointsVal(
            response.data.users[0].sleepingpoints
          );
          setTobaccopointsVal(Number(response.data.users[0].tobaccopoints));
          setStresspointsVal(Number(response.data.users[0].stresspoints));
          setSugarfpointVal(Number(response.data.users[0].sugarfpoint));
          setSugarhbpointVal(Number(response.data.users[0].sugarhbpoint));
          setSugarpppointVal(Number(response.data.users[0].sugarpppoint));
          setSymptomspointVal(Number(response.data.users[0].symptomspoint));
          setTotcholpointVal(Number(response.data.users[0].totcholpoint));
          setWaistSizePointsVal(
            response.data.users[0].waistSizePoints
          );
          setAnginapointVal(Number(response.data.users[0].anginapoint));
          setLifestylepointVal(
            Number(response.data.users[0].lifestylepoint)
          );
          setHeartDiseasepointVal(
            response.data.users[0].heartDiseasepoint
          );
          setFinalPoint(response.data.users[0].score);
          setSymptomsSituation(response.data.users[0].symptomSituation);
          setFirstName(response.data.users[0].firstName)
          setEmail(response.data.users[0].email);
          setMobile(response.data.users[0].mobile);
          setSuccess(true);
        }, 3000);

        // set disease priority
        // setTimeout(()=>{


        //   console.log("Disease priority value is >>",diseasePriority)
        // },5000);

        // console.log(
        //   "Data is fetched",
        //   JSON.parse(response.data.users[0].sleep)
        // );
      }
    } catch (error) {
      console.log("error is ", error);
    }
  };

  // score message & calculation
  const DataCalculculation = () => {
    //calculate BMI
    let hmt = Number(heightFt) * 0.3048 + Number(heightIn) * 0.0254;
    let bmi = Number(weight) / (hmt * hmt);
    bmi = Math.round(bmi * 10) / 10;
    setBmiVal(bmi);

    // setTimeout(() => {

    console.log("diabete is >1212>", diabete)
    // console.log("value is >>112121",bmi , bmi != NaN ? diseasePriority == null || diseasePriority == undefined || diseasePriority == "" && bmi > 25.1 : console.log("Here"));

    bmi != NaN ?

      hDisease != "null" && hDisease != "" && hDisease == "true" || heartBlockage != "null" && heartBlockage != "" && heartBlockage == "Yes" ? setDiseasePriority("Heart-Disease") :

        diseasePriority == null || diseasePriority == undefined || diseasePriority == "" && diabete != "null" && diabete != "" && diabete == "true" ? setDiseasePriority("Diabetes") :

          diseasePriority == null || diseasePriority == undefined || diseasePriority == "" && hBbpHypertension != "null" && hBbpHypertension != "" && hBbpHypertension == "true" ? setDiseasePriority("Hypertension") :

            diseasePriority == null || diseasePriority == undefined || diseasePriority == "" && hCholesterol != "null" && hCholesterol != "" && hCholesterol == "true" ? setDiseasePriority("Cholestrol") :

              diseasePriority == null || diseasePriority == undefined || diseasePriority == "" && bmi > 25.1 ? setDiseasePriority("Obesity") :

                diseasePriority == null || diseasePriority == undefined || diseasePriority == "" && symptomsSituation != "null" && symptomsSituation != "" ? setDiseasePriority("Heart-Disease") :

                  diseasePriority == null || diseasePriority == undefined || diseasePriority == "" && frequentUrination != "null" && frequentUrination != "" && frequentUrination == "true" ||

                    excessiveHunger != "null" && excessiveHunger != "" && excessiveHunger == "true" || woundNotHealing != "null" && woundNotHealing != "" && woundNotHealing == "true" ||

                    weightLoss != "null" && weightLoss != "" && weightLoss == "true" || weightGain != "null" && weightGain != "" && weightGain == "true" ||

                    tingling != "null" && tingling != "" && tingling == "true" ? setDiseasePriority("Diabetes") :

                    diseasePriority == null || diseasePriority == undefined || diseasePriority == "" && sweating != "null" && sweating != "" && sweating == "true" || headache != "null" && headache != "" && headache == "true" && setDiseasePriority("Hypertension")


      : console.log("frequentUrination value is >>02", diseasePriority);
    // }, 5000);

    console.log("frequentUrination value is >>03", diseasePriority);

    // bmi message
    if (bmi < 18.5) {
      setBmiMessage("Your BMI says that you are Underweight");
    } else if (bmi >= 18.5 && bmi <= 24.9) {
      setBmiMessage("Your BMI says that you are Normal.");
    } else if (bmi >= 25.0 && bmi <= 29.9) {
      setBmiMessage("Your BMI says that you are Overweight.");
    } else if (bmi >= 30.0 && bmi <= 34.9) {
      setBmiMessage("Your BMI says that you are Obese.");
    } else if ((bmi >= 35.0 && bmi <= 39.9) || bmi > 40.0) {
      setBmiMessage("Your BMI says that you are Extremely Obese.");
    }

    //age message
    if (agepointsVal >= 2.6 && agepointsVal <= 7.5) {
      setAgeMessage(
        `Research says that age ${age} may bring some risk factors for heart diseases and associated risk factors.`
      );
    } else if (agepointsVal >= 7.6 && agepointsVal <= 12.5) {
      setAgeMessage(
        `Research says that age ${age} may bring mild risk factors for heart diseases and associated risk factors.`
      );
    } else if (agepointsVal >= 12.6 && agepointsVal <= 15.00) {
      setAgeMessage(`Research says that age ${age} may bring moderate risk factors for heart diseases and associated risk factors.
      `);
    } else if (age > 61 && agepointsVal === 15.00) {
      setAgeMessage(
        "Research says being at an age greater than 60 is in itself a risk factor for heart diseases and associated risk factors."
      );
    } else if (agepointsVal >= 2.5) {
      setAgeMessage(
        "Your age works as a shield against heart diseases and risk factors."
      );
    }
    {
      console.log("sleepingpointsVal is >>1", typeof sleepingpointsVal)
    }
    // sleeping message
    if (sleepingpointsVal === 2 || sleepingpointsVal === 1.75) {
      setSleepMsg(
        "Allow yourself time to Rest & Sleep, as your current sleep cycle is not enough to maintain good health. You need more than 8 hours of sleep in 24 hours."
      );
    } else if (sleepingpointsVal === 1 || sleepingpointsVal === 0.5) {
      setSleepMsg(
        "Allow yourself time to Rest & Sleep to maintain good health. You need more than 8 hours of sleep in 24 hours."
      );
    } else if (sleepingpointsVal === 0) {
      setSleepMsg(
        "You are doing Good! Sleeping well is the best medicine to maintain good health."
      );
    }
    // mealpoints
    console.log("mealpointsVal is >>here", mealpointsVal)
    if (mealpointsVal === 2 && vegetableInTake === "Never") {
      setMealpointsVal(2);
      setMealMsg("Improve your diet with nutritional food intake.");
    } else if (mealpointsVal === 2) {
      setMealpointsVal(2);
      setMealMsg("Your Diet is running low on Nutrients.");
    } else if (mealpointsVal === 1.50) {
      setMealpointsVal(1.5);
      setMealMsg("Your Diet has only a Fair Amount of Nutrients.");
    } else if (mealpointsVal === 1.25) {
      setMealpointsVal(1.25);
      setMealMsg("Your Diet has only an Average Amount of Nutrients.");
    } else if (
      mealpointsVal === 0 &&
      vegetableInTake === "5 to 6 times a week"
    ) {
      setMealMsg("Your Diet has a Good Balance of Nutrients.");
    } else if (mealpointsVal === 0) {
      setMealMsg(
        "Keep it up! Your Diet has an Excellent Balance of Nutrients."
      );
    }

    console.log("exercisepointsVal is >here", exercisepointsVal)
    // exercise message
    if (exercisepointsVal === 0) {
      setExcerciseMsg("Awesome! Keep Exercising.");
    } else if (exercisepointsVal == 0.5) {
      setExcerciseMsg("Good! Try to Exercise at least five times a week.");
    } else if (exercisepointsVal == 2) {
      setExcerciseMsg(
        "We recommend you add exercises to your regime immediately."
      );
    } else if (exercisepointsVal >= 1 && exercisepointsVal <= 1.5) {
      setExcerciseMsg(
        "You need to exercise regularly. Try exercising at least five days a week."
      );
    }

    console.log("tobaccopointsVal is >>here,", tobaccopointsVal)
    //smoke message
    if (tobaccopointsVal === 0) {
      setTobaccoMsg("Awesome! Keep it Up");
    } else if (tobaccopointsVal == 1) {
      setTobaccoMsg("Smoking is injurious to health! Quit smoking!");
    } else if (tobaccopointsVal == 1.5) {
      setTobaccoMsg(
        "Smoking Occasionally is also detrimental to health! Quit Smoking & Tobacco Products!"
      );
    } else if (tobaccopointsVal >= 2) {
      setTobaccoMsg(
        "Smoking / Tobacco is injurious to health! Quit Smoking & stop consuming tobacco products!"
      );
    }
    {
      console.log("alcohol point is >11>", alcoholpointsVal)
    }
    //alcohol message
    if (alcoholpointsVal === 0) {
      setAlcoholMsg("Awesome! Keep it Up");
    } else if (alcoholpointsVal == 1.5) {
      setAlcoholMsg(
        "Is your alcohol intake Very Often? Drink Responsibly! Don't exceed the consumption of Alcohol above 30ml."
      );
    } else if (alcoholpointsVal == 2) {
      setAlcoholMsg(
        "Alcohol Addiction! Is your alcohol intake Very High? Drink Responsibly! Don't exceed your Alcohol intake above 30ml."
      );
    } else if (alcoholpointsVal <= 0.5 >= 1) {
      setAlcoholMsg(
        "Drink Alcohol mindfully. Keep your intake of Alcohol limit to 30ml."
      );
    }
    {
      console.log("Stress point is >1>", stresspointsVal)
    }
    //Stress point
    if (stresspointsVal === 3 || stresspointsVal === "3.00") {
      setStressMsg(
        "Stress Detected! Try going for a walk in nature to keep mental stress out of your Life."
      );
    } else if (stresspointsVal === 2 || stresspointsVal === "2.00") {
      setStressMsg(
        "You are Stressed Out! Take Steps to keep stress out of your Life."
      );
    } else if (stresspointsVal === 0) {
      setStressMsg("Good, No Stress!");
    }
    {
      console.log("Heart blockage point is >1>", heartSurgerypointsVal)
    }
    //heartSurgerypoints
    if (heartSurgerypointsVal === 0) {
      setHeartBlockageMsg("");
    } else if (heartSurgerypointsVal === 5) {
      setHeartBlockageMsg(
        "Lifestyle modification and cardiac rehab can help prevent bypass blockages or post-pasty."
      );
    } else if (heartSurgerypointsVal === 1) {
      setHeartBlockageMsg(
        "Post-pasty or bypass blockages in the coronary artery? It would be best if you had proper lifestyle modification with cardiac rehab."
      );
    }

    //symptomspoint
    let fUrination,
      eHunger,
      woundNotHeal,
      wLoss,
      wGain,
      jPain,
      hache,
      wFatigue,
      contis,
      sweat,
      tinglingNumbness;

    if (frequentUrination) {
      fUrination = 2;
    } else {
      fUrination = 0;
    }
    if (excessiveHunger) {
      eHunger = 2;
    } else {
      eHunger = 0;
    }
    if (woundNotHealing) {
      woundNotHeal = 2;
    } else {
      woundNotHeal = 0;
    }
    if (weightLoss) {
      wLoss = 2;
    } else {
      wLoss = 0;
    }
    if (weightGain) {
      wGain = 2;
    } else {
      wGain = 0;
    }
    if (jointPains) {
      jPain = 2;
    } else {
      jPain = 0;
    }
    if (headache) {
      hache = 2;
    } else {
      hache = 0;
    }
    if (weaknessOfFatigue) {
      wFatigue = 2;
    } else {
      wFatigue = 0;
    }
    if (constipation) {
      contis = 2;
    } else {
      contis = 0;
    }
    if (sweating) {
      sweat = 2;
    } else {
      sweat = 0;
    }
    if (tingling) {
      tinglingNumbness = 2;
    } else {
      tinglingNumbness = 0;
    }

    if (symptomspointVal === "0.00") {
      setSymptomsMsg("Good, No Symptoms!");
    } else {
      setSymptomsMsg(
        "Symptoms detected. It would help if you took actions to improve your health."
      );
    }

    //anginapoint(symptom situation)
    // if(symtomSituations === 5) {
    //   setAginaMsg("Alarming! You are at a very High Heart Health Risk. Get in touch with the doctor immediately.")
    // } else if(symtomSituations === 3) {
    //   setAginaMsg("Do not Neglect! You are at significant Heart Health Risk. Get in touch with the doctor.")
    // } else if(symtomSituations === 2) {
    //   setAginaMsg("Need to evaluate heart disease? Get in touch with the doctor and perform tests.")
    // } else if(symtomSituations === 0) {
    //   setAginaMsg("Good, No Cardiac Symptoms!")
    // }

    //familyhistory message
    if (familyhistorypointsVal === 0) {
      setFamilyhistoryMsg("Good. No family History!");
    } else {
      setFamilyhistoryMsg(
        "Family history increases chances of developing a heart condition"
      );
    }

    //sugarf message
    if (sugarfpointVal === 0) {
      setFastingSugarMsg(
        "Your Fasting Sugar Reading is in Normal Range! No Action Needed, Normal Fasting Sugar Range is 70mg/dL to 100mg/dL"
      );
    } else if (sugarfpointVal == 5) {
      setFastingSugarMsg(
        "Your Fasting Sugar Reading is in the Hypoglycemia Range - Be Cautious! We recommend Doctor's Check-up, Normal Fasting Sugar Range is 70mg/dL to 100mg/dL"
      );
    } else if (sugarfpointVal >= 3 && sugarfpointVal <= 5) {
      setFastingSugarMsg(
        "Your Fasting Sugar Reading is in the Prediabetic Range - Be Cautious! We recommend Doctor's Check-up, Normal Fasting Sugar Range is 70mg/dL to 100mg/dL"
      );
    } else if (sugarfpointVal >= 5.5 && sugarfpointVal <= 10) {
      setFastingSugarMsg(
        "Diabetic Range: Your Fasting Sugar Reading is Very High - Be Cautious! We recommend an immediate Doctor Check-up to Keep Your Score in Check!, Normal Fasting Sugar Range is 70mg/dL to 100mg/dL"
      );
    }

    //sugarpp message
    if (sugarpppointVal === 0) {
      setPostFastingSugarMsg(
        "Your Post Meal Sugar Reading is in Normal Range! No Action is Needed, Normal Post Meal Sugar Range is 70mg/dL to 140mg/dL"
      );
    } else if (sugarpppointVal == 5) {
      setPostFastingSugarMsg(
        "Your Post Meal Sugar Reading is in the Hypoglycemia Range - Be Cautious! We recommend Doctor's Check-up, Normal Post Meal Sugar Range is 70mg/dL to 140mg/dL"
      );
    } else if (sugarpppointVal >= 3 && sugarpppointVal <= 8.5) {
      setPostFastingSugarMsg(
        "Your Post Meal Sugar Reading is in the Prediabetic Range - Be Cautious! We recommend Doctor's Check-up, Normal Post Meal Sugar Range is 70mg/dL to 140mg/dL"
      );
    } else if (sugarpppointVal >= 9 && sugarpppointVal <= 10) {
      setPostFastingSugarMsg(
        "Diabetic Range: Your Post Meal Sugar Reading is Very High - Be Cautious! Doctor Check-up Highly Recommended to Keep Your Score in Check!, Normal Post Meal Sugar Range is 70mg/dL to 140mg/dL"
      );
    }

    //sugarhbpoint
    if (hbac1 > "15.1" && sugarhbpointVal === "10") {
      setHba1cMsg(
        "Diabetic Range: Your Hba1c Reading is at a Dangerous Range - Be Cautious! Doctor Check-up Highly Recommended to Keep Your Score in Check!, Normal Hba1c Range is 3.0mg/dL to 5.7mg/dL"
      );
    } else if (sugarhbpointVal == "0") {
      setHba1cMsg(
        "Your Hba1c Reading is in the Normal Range! No Action is Needed, Normal Hba1c Range is 3.0mg/dL to 5.7mg/dL"
      );
    } else if (sugarhbpointVal >= "3" && sugarhbpointVal <= "4.5") {
      setHba1cMsg(
        "Your Hba1c Reading is in the Prediabetic Range - Be Cautious! We recommend Doctor's Check-up, Normal Hba1c Range is 3.0mg/dL to 5.7mg/dL"
      );
    } else if (sugarhbpointVal >= "5" && sugarhbpointVal <= "8") {
      setHba1cMsg(
        "Your Hba1c Reading is in Diabetic Range - Be Cautious! Doctor Check-up Highly Recommended to Keep your Score in Check!, Normal Hba1c Range is 3.0mg/dL to 5.7mg/dL"
      );
    } else if (sugarhbpointVal >= "8.5" && sugarhbpointVal <= "10") {
      setHba1cMsg(
        "Diabetic Range: Your Hba1c Reading is at Very High - Be Cautious! Doctor Check-up Highly Recommended to Keep Your Score in Check!, Normal Hba1c Range is 3.0mg/dL to 5.7mg/dL"
      );
    }

    //totcholpoint
    if (totcholpointVal >= 3 && totcholpointVal <= 5) {
      setTotalCholestrolMsg(
        "Your Total Cholesterol Values are in Borderline. We recommend Doctor's Check-up, Normal Total cholesterol Range is 40mg/dL to 200mg/dL"
      );
    } else if (totcholpointVal >= 6 && totcholpointVal <= 7.5) {
      setTotalCholestrolMsg(
        "Your Total Cholesterol Values are in Very High Range, and Your Heart is at Risk. We recommend Doctor's Check-up, Normal Total cholesterol Range is 40mg/dL to 200mg/dL"
      );
    } else if (totcholpointVal > 7.5 && totcholpointVal <= 10) {
      setTotalCholestrolMsg(
        "Your Total Cholesterol value is in the Dangerous Range, Major Risk for your Heart. We recommend Doctor's Check-up, Normal Total cholesterol Range is 40mg/dL to 200mg/dL"
      );
    } else if (totcholpointVal == 0) {
      setTotalCholestrolMsg(
        "Your Total Cholesterol Values are in good Heart Healthy Range, Normal Total cholesterol is 40mg/dL to 200mg/dL"
      );
    }

    //ldlpoint
    if (ldlpointVal >= "0.5" && ldlpointVal <= "3") {
      setLdlMsg(
        "Your LDL Values are in Borderline. We recommend Doctor's Check-up!, Normal LDL Range is 20mg/dL to 130mg/dL"
      );
    } else if (ldlpointVal >= "3.5" && ldlpointVal <= "6") {
      setLdlMsg(
        "Your LDL Values are in a Very High Range, and Your Heart is at Risk. We recommend Doctor's Check-up!, Normal LDL Range is 20mg/dL to 130mg/dL"
      );
    } else if (ldlpointVal >= "6.5" && ldlpointVal <= "10") {
      setLdlMsg(
        "Your LDL Values are in the Dangerous Range, Major Risk for your Heart. We recommend Doctor's Check-up!, Normal LDL Range is 20mg/dL to 130mg/dL"
      );
    } else if (ldlpointVal == "0") {
      setLdlMsg(
        "Your LDL Values are in Good Heart Healthy Range, Normal LDL Range is 20mg/dL to 130mg/dL"
      );
    }

    // hdlpoint
    if (hdl >= "151" && hdlpointVal === "0") {
      setHdlMsg("Your HDL Values are in Very Good Heart Healthy Range");
    } else if (hdlpointVal >= "5" && hdlpointVal <= "7") {
      setHdlMsg(
        "Your HDL Values are in a Very Low Range, and Your Heart is at Risk. We recommend Doctor's Check-up!, Normal HDL Range should be Above 60"
      );
    } else if (hdlpointVal >= "2" && hdlpointVal <= "4.5") {
      setHdlMsg(
        "Your HDL Values are in Borderline. We recommend Doctor's Check-up!, Normal HDL Range should be Above 60"
      );
    } else if (hdlpointVal >= "7.5" && hdlpointVal <= "10") {
      setHdlMsg(
        "Your HDL Values are in the Dangerous Range, Major Risk for your Heart. We recommend Doctor's Check-up!, Normal HDL Range should be Above 60"
      );
    } else if (hdlpointVal == "0") {
      setHdlMsg(
        "Your HDL Values are in Good Heart Healthy Range, Normal HDL Range should be Above 60"
      );
    }

    //bppoints(Systolic (Upper BP))
    if (
      systolicUpperBp >= 176 &&
      systolicUpperBp <= 220 &&
      bppointsVal === "10"
    ) {
      setSystolicUpperBpMsg(
        "Alarming Systolic Reading! We recommend an immediate Doctor Check-up!, Normal BP Systolic Range is between 90mmHg to 120mmHg"
      );
    } else if (
      systolicUpperBp >= 70 &&
      systolicUpperBp <= 79 &&
      bppointsVal === "6"
    ) {
      setSystolicUpperBpMsg(
        "Very Low Systolic Reading! Doctor Check-up Recommended!, Normal BP Systolic Range is between 90mmHg to 120mmHg"
      );
    } else if (
      systolicUpperBp >= 80 &&
      systolicUpperBp <= 89 &&
      bppointsVal == "4"
    ) {
      setSystolicUpperBpMsg(
        "Very Low Systolic Reading! Doctor Check-up Recommended!, Normal BP Systolic Range is between 90mmHg to 120mmHg"
      );
    } else if (systolicUpperBp >= 221 && bppointsVal === "10") {
      setSystolicUpperBpMsg(
        "Dangerous High Blood Pressure! We recommend an immediate Doctor Check-up!, Normal BP Systolic Range is between 90mmHg to 120mmHg"
      );
    } else if (systolicUpperBp <= 69 && bppointsVal === "10") {
      setSystolicUpperBpMsg(
        "Dangerous Low Blood Pressure! We recommend an immediate Doctor check-up!, Normal BP Systolic Range is between 90mmHg to 120mmHg"
      );
    } else if (bppointsVal >= "3" && bppointsVal <= "5") {
      setSystolicUpperBpMsg(
        "Elevated Systolic Reading! Doctor Check-up Recommended!, Normal BP Systolic Range is between 90mmHg to 120mmHg"
      );
    } else if (bppointsVal >= "5.5" && bppointsVal <= "10") {
      setSystolicUpperBpMsg(
        "Very High Systolic Reading! Doctor Check-up Recommended!, Normal BP Systolic Range is between 90mmHg to 120mmHg"
      );
    } else if (bppointsVal == "0") {
      setSystolicUpperBpMsg(
        "Normal Range, Normal BP Systolic Range is between 90mmHg to 120mmHg"
      );
    }

    //bplowerpoints
    if (
      (diastolicLowerBp >= 40 &&
        diastolicLowerBp <= 49 &&
        bplowerpointsVal === "6") ||
      (diastolicLowerBp >= 50 &&
        diastolicLowerBp <= 59 &&
        bplowerpointsVal === "4")
    ) {
      setDiastolicLowerBp(
        "Very Low Diastolic Reading! Doctor Check-up Recommended!, Normal BP Diastolic Range is between 60mmHg to 80mmHg"
      );
    } else if (diastolicLowerBp <= 39 && bplowerpointsVal == "10") {
      setDiastolicLowerBp(
        "Dangerous Low Blood Pressure! We recommend an immediate Doctor Check-up!, Normal BP Diastolic Range is between 60mmHg to 80mmHg"
      );
    } else if (diastolicLowerBp >= 141 && (bplowerpointsVal = "10")) {
      setDiastolicLowerBp(
        "Dangerous High Blood Pressure! We recommend an immediate Doctor Check-up!, Normal BP Diastolic Range is between 60mmHg to 80mmHg"
      );
    } else if (bplowerpointsVal == "0") {
      setDiastolicLowerBp(
        "Normal Range., Normal BP Diastolic Range is between 60mmHg to 80mmHg"
      );
    } else if (bplowerpointsVal >= "3" && bplowerpointsVal <= "5") {
      setDiastolicLowerBp(
        "Elevated Diastolic Reading! Doctor Check-up Recommended!, Normal BP Diastolic Range is between 60mmHg to 80mmHg"
      );
    } else if (bplowerpointsVal >= "5.5" && bplowerpointsVal <= "9") {
      setDiastolicLowerBp(
        "High Diastolic Reading! Doctor Check-up Recommended!, Normal BP Diastolic Range is between 60mmHg to 80mmHg"
      );
    } else if (bplowerpointsVal >= "9.5" && bplowerpointsVal <= "10") {
      setDiastolicLowerBp(
        "Very High Diastolic Reading! Doctor Check-up Recommended!, Normal BP Diastolic Range is between 60mmHg to 80mmHg"
      );
    }

    if (lifestylepointVal <= 3.9) {
      setLifestyleMsg("Good! Keep it Up");
    } else if (lifestylepointVal >= 4 && lifestylepointVal <= 8.9) {
      setLifestyleMsg("Fair! Lifestyle Improvement Needed");
    } else if (lifestylepointVal >= 9) {
      setLifestyleMsg("Poor Lifestyle! Major Lifestyle Improvement Needed");
    }

    // console.log("lifestylepointVal", lifestylepointVal);

    if (finalPoint <= 39 && finalPoint <= 49) {
      setHealthStatus("Critical");
      setScoreMeter(poor);
      setFinalMsg("Watch Out! Your Heart is at very High Risk");
      setMessageDesc(
        `As per your current Heart Health Assessment your Heart is not strong enough to fight Heart Attack! Strongly consider taking important steps to immediately work on severe and moderate Risk Factors affecting your Heart Health

        Improvements in your Lifestyle, Diet, Exercise, Disease Reversal Therapies with Continuous Monitoring can go a long way in improving your Heart Health, follow below guided steps to improve your score and act for your Healthier Future.`
      );
      setImgClass("bg-1");
    } else if (finalPoint >= 50 && finalPoint <= 74) {
      setHealthStatus("FAIR");
      setScoreMeter(fair);
      setFinalMsg("Watch Out! Your Heart is at Risk");
      setMessageDesc(
        `As per your current Heart Health Assessment your Heart is not strong enough to fight Heart Attack! Strongly consider taking important steps to immediately work on severe and moderate Risk Factors affecting your Heart Health

        Improvements in your Lifestyle, Diet, Exercise, Disease Reversal Therapies with Continuous Monitoring can go a long way in improving your Heart Health, follow below guided steps to improve your score and act for your Healthier Future.`
      );
      setImgClass("bg-2");
    } else if (finalPoint >= 75 && finalPoint <= 84) {
      setHealthStatus("GOOD");
      setScoreMeter(good);
      setFinalMsg("HEALTHY! with Risks");
      setMessageDesc(
        `Your Heart Health score is in the healthy spectrum now, but you have risk factors which may put you at a Higher Risk in the future.

        Improvements in your Lifestyle such as Stress, Sleep, Good Diet, Exercise can go a long way in improving your Heart Health, follow below guided steps to manage your risk factors to act for your Healthier Future.`
      );
      setImgClass("bg-3");
    } else if (finalPoint >= 85 && finalPoint <= 94) {
      setHealthStatus("VERY GOOD");
      setScoreMeter(veryGood);
      setFinalMsg("HEALTHY! with Minor Risks");
      setMessageDesc(
        `Your Heart Health score is in the healthy spectrum now, but you have few risk factors which may put you at a Higher Risk in the future.

        Improvements in your Lifestyle such as Stress, Sleep, Good Diet, Exercise can go a long way in improving your Heart Health, follow below guided steps to manage your risk factors to act for your Healthier Future.`
      );
      setImgClass("bg-4");
    } else if (finalPoint >= 95) {
      setHealthStatus("EXCELLENT");
      setScoreMeter(excellent);
      setFinalMsg("Your Heart is HEALTHY! Keep it up");
      setMessageDesc(
        `Congratulations, your Heart is in Excellent condition, with no future risks.
         
        Your heart is beating full of life. Whatever you are doing, it can't be more right, just don’t fall off the track. You are an admiration for all of us. Keep it up! `
      );
      setImgClass("bg-5");
    }

    if (
      isNaN(finalPoint) ||
      finalPoint == null ||
      finalPoint == undefined ||
      finalPoint == ""
    ) {
      setShowScore("Loading");
    } else if (finalPoint <= 39) {
      setShowScore(39);
    } else if (finalPoint >= 95) {
      setShowScore(95);
    } else {
      setShowScore(finalPoint);
    }
  };


  const plan_one_order = async (total, discount, subtotal) => {
    
    setLoading(true)
    if (diseasePriority == "") {
      setLoading(false)
      alert("Please select disease first");
    } else {
      try {
        setPlanName("SMH Basic");
        if (discountAmount > 0) {
          subtotal = Math.round( subtotal - discountAmount);
        } else {
          subtotal = Math.round(subtotal - (subtotal * discountPercent) / 100);
        }
        // setPlanAmount(subtotal);
        console.log("subtotal value is >>", subtotal);

        if(subtotal == 0)
        {
          let razorpay_payment_id="";
          let orderId = Math.random().toString(36).slice(4, 12);
          store_user_plan_one_details(
            razorpay_payment_id,
            orderId,
            total,
            discount,
            subtotal
          );
        }
        else
        {
          const formData = new FormData();
          console.log("on buy amount is",subtotal);
          formData.append("amount", subtotal * 100);
          const url1 = createOrder;
          const response = await axios({
            method: "post",
            url: url1,
            data: formData,
          });
          console.log("order response is 1>>", response);
          if (response.data.status == 200) {
            console.log("order id is >>", response.data.orderId);
            setOrderId(response.data.orderId);
            setTimeout(() => {
              plan_one_payment(response.data.orderId, total, discount, subtotal);
            }, 1000);
          } else {
            setLoading(false)
            alert("Error in payment");
          }
        }

        
      } catch (error) {
        alert("Error in payment");
        setLoading(false)
        console.log("Error is >>", error);
      }
    }
    console.log("priority disease is >>");
  };

  const plan_one_payment = async (orderId, total, discount, subtotal) => {
    try {
      console.log("Order id in planAmount>>", orderId,subtotal);
    let plan_amount = subtotal;

    const options = {
      key: "rzp_live_L0BFMeR1p1eBLT", // Enter the Key ID generated from the Dashboard
      amount: subtotal * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Madhavbaug",
      description: "Heart Health Meter Program",
      image: hhmImage,
      // order_id: orderId, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        console.log(" option handler Response is >>", response);
        setTimeout(() => {
          store_user_plan_one_details(
            response.razorpay_payment_id,
            orderId,
            total,
            discount,
            subtotal
          );
        }, 1000);
      },
      prefill: {
        name: firstName,
        email: email,
        contact: mobile,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      console.log("Response is >>", response);
      alert(response.error.code);
      setLoading(false);
    });

    rzp1.open();
    } catch (error) {
      console.log("Error is >>",error);
      setLoading(false)
    }
    
  };

  const store_user_plan_one_details = async (
    razorpay_payment_id,
    orderId,
    total,
    discount,
    subtotal
  ) => {
    try {
      console.log("user value is >>>>", userValue);
      const formData = new FormData();
      formData.append("amount", subtotal);
      formData.append("user_id", userValue);
      formData.append("disease_name", diseasePriority);
      formData.append("payment_gateway_id", razorpay_payment_id);
      formData.append("order_id", orderId);
      formData.append("plan_name", "SMH Basic");
      formData.append("coupon_code", couponCode);
      formData.append("total", total);
      formData.append("discount", discount);
      formData.append("subtotal", subtotal);
      formData.append("status", "yes");
      const url1 = payment;
      const post_payment = await axios({
        method: "post",
        url: payment,
        data: formData,
      });
      console.log("post payment data is >>>",post_payment)
      if (post_payment.data.status == 200) {
        setTimeout(() => {
          let plan = "SMH Basic";
          sendWhatsapp(plan);
        }, 1000);
      }
      else {
        setLoading(false);
        toastr.options = {
          positionClass: 'toast-top-full-width',
          hideDuration: 300,
          timeOut: 2000
        }
        toastr.clear()
        setLoading(false)
        setTimeout(() => toastr.error('Something went wrong'), 200)
      }
      console.log("post payment reposense >>", post_payment.data.status);
    } catch (error) {
      // alert("Error while data processing");
      console.log("Error is >>>",error)
      setLoading(false);
    }
    
  };

  const plan_two_order = async (total, discount, subtotal) => {
    
    try {
    setLoading(true)
    if (diseasePriority == "") {
      setLoading(false)
      alert("Please select disease first");
    } else {
        if (discountAmount > 0) {
          subtotal = Math.round(subtotal - discountAmount);
        } else {
          subtotal = Math.round(subtotal - (subtotal * discountPercent) / 100);
        }
        // subtotal = subtotal - (subtotal * discountPercent / 100);
        setPlanName("SMH Plus");
        if(subtotal == 0)
        {
          let razorpay_payment_id="";
          let orderId = Math.random().toString(36).slice(4, 12);
          store_user_plan_two_details(
            razorpay_payment_id,
            orderId,
            total,
            discount,
            subtotal
          );
        }
        else
        {
          const formData = new FormData();
          console.log("on buy show amout ", subtotal);
          formData.append("amount", subtotal * 100);
          const url1 = createOrder;
          const response = await axios({
            method: "post",
            url: url1,
            data: formData,
          });
          console.log("order response is 1>>", response);
          if (response.data.status == 200) {
            console.log("order id is >>", response.data.orderId);
            setOrderId(response.data.orderId);
            setTimeout(() => {
              plan_two_payment(response.data.orderId, total, discount, subtotal);
            }, 1000);
          } else {
            setLoading(false);
            alert("Error in payment");
          }
        }
      } }catch (error) {
        setLoading(false);
        alert("Error in payment");
        console.log("Error is >>", error);
      }
    
  };

  const plan_two_payment = async (orderId, total, discount, subtotal) => {
    try {
      console.log("subtotal::::", subtotal);
      const options = {
        key: "rzp_live_L0BFMeR1p1eBLT", // Enter the Key ID generated from the Dashboard
        amount: subtotal * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Madhavbaug",
        description: "Heart Health Meter Program",
        image: hhmImage,
        order_id: orderId, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
        handler: function (response) {
          console.log(" option handler Response is >>", response);
          setTimeout(() => {
            store_user_plan_two_details(
              response.razorpay_payment_id,
              orderId,
              total,
              discount,
              subtotal
            );
          }, 500);
        },
        prefill: {
          name: firstName,
          email: email,
          contact: mobile,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        setLoading(false)
        console.log("Response is >>1", response);
        alert(response.error.code);
      });

      rzp1.open();
    } catch (error) {
        console.log("Plan two error is >>",error);
        setLoading(false);
    }
    
  };

  const store_user_plan_two_details = async (
    razorpay_payment_id,
    orderId,
    total,
    discount,
    subtotal
  ) => {
    try {
      const formData = new FormData();
      formData.append("amount", subtotal);
      formData.append("user_id", userValue);
      formData.append("disease_name", diseasePriority);
      formData.append("payment_gateway_id", razorpay_payment_id);
      formData.append("order_id", orderId);
      formData.append("plan_name", "SMH Plus");
      formData.append("coupon_code", couponCode);
      formData.append("total", total);
      formData.append("discount", discount);
      formData.append("subtotal", subtotal);
      formData.append("status", "yes");
      const url1 = payment;
      const post_payment = await axios({
        method: "post",
        url: url1,
        data: formData,
      });
      if (post_payment.data.status == 200) {
        setTimeout(() => {
          let plan = "SMH Plus";
          sendWhatsapp(plan);
        }, 1000);
        // navigate(`${homePageUrl}payment-successful`,{
        //   state:{userValue}
        // });
      } else {
        setLoading(false)
        // navigate(`${homePageUrl}payment-failure`);
      }
      console.log("post payment reposense >>", post_payment.data.status);
    } catch (error) {
      console.log("Error in store plan two details >>",error)
      setLoading(false);
    }
    
  };

  const sendWhatsapp = async (plan) => {
    try {
      console.log("Plan name is >>", plan);
      let plan_name = plan;
      const formData = new FormData();
      formData.append("planName", plan);
      formData.append("uid", userValue);
      const response = await axios({
        method: "POST",
        url: subscriptionHome,
        data: formData,
      });
      if (response.status == 200) {
        // sendWhatsapp();
        setLoading(true);
        navigate(`${homePageUrl}payment-successful`, {
          state: { userValue, plan },
        });
      }
      else {
        setLoading(false);
        toastr.options = {
          positionClass: 'toast-top-full-width',
          hideDuration: 300,
          timeOut: 2000
        }
        toastr.clear()
        setLoading(false)
        setTimeout(() => toastr.error('Something went wrong'), 200)
      }
    } catch (error) {
      setLoading(false)
      console.log("error is ", error)
    }
  };

  const getUserPlanDetails = async () => {
    try {
      let uid;
      if (searchParams.get("value") != null) {
        uid = searchParams.get("value");
        setUserValue(uid);
        console.log("user id value is >>", searchParams.get("value"));
      } else {
        uid = location.state.id;
        setUserValue(location.state.id);
      }
      // console.log("Plan Details is >>", PlanDetails);
      const response = await axios({
        method: "GET",
        url: getUserLatestPlanDetails + uid,
      });
      if (response.data.statusCode == 200) {
        setUserLatestPlan(response.data.plan);
        console.log("Your plan is>>>", response.data.plan);
      }
    } catch (error) {
      // console.log("error is ", error)
    }
  };

  const getPlanDetails = async () => {
    try {
      console.log("Plan Details is >>", PlanDetails);
      const response = await axios({
        method: "GET",
        url: PlanDetails,
      });
      if (response.status == 200) {
        setPlanDetail(response.data.plans);
        console.log("## planDetail ", planDetail);
        console.log("subtotal ", planDetail[0].subtotal);
        setPlan1Subtotal(planDetail[0].subtotal);
        setPlan2Subtotal(planDetail[0].subtotal2);
      }
    } catch (error) {
      // console.log("error is ", error)
    }
  };

  const getCouponsForPlan = async () => {
    try {
      console.log("Coupon code is >>", couponCode);
      if (couponCode.length === 0) {
        toastr.options = {
          positionClass: "toast-top-full-width",
          hideDuration: 300,
          timeOut: 2000,
        };
        toastr.clear();
        setTimeout(() => toastr.error("Coupon code cannot be empty"), 200);
      } else {
        const formData = new FormData();
        formData.append("couponcode", couponCode);
        const response = await axios({
          method: "POST",
          url: getCoupons,
          data: formData,
        });
        console.log("formData of coupon ", formData);
        if (response.status == 200) {
          setCoupon(response.data.coupons);
          console.log("Coupon code response >>", response.data);
          if (response.data.coupons == "Invalid coupon") {
            setDiscountPercent(0);
            setDiscountAmount(0);
            setCouponCode("");
            document.getElementById("plan1-subtotal").innerHTML =
              Math.round(plan1Subtotal);
            document.getElementById("plan2-subtotal").innerHTML =
              Math.round(plan2Subtotal);
            toastr.options = {
              positionClass: "toast-top-full-width",
              hideDuration: 300,
              timeOut: 2000,
            };
            toastr.clear();
            setTimeout(() => toastr.error("Invalid Coupon"), 200);
          } else {
            if (response.data.coupons[0].discount_percent == null) {
              setDiscountAmount(response.data.coupons[0].discount_amount);
              console.log(
                "Match Coupon code amount is  >>",
                response.data.coupons[0].discount_amount
              );
              stockPriceElement1 =
                plan1Subtotal - response.data.coupons[0].discount_amount;
              stockPriceElement2 =
                plan2Subtotal - response.data.coupons[0].discount_amount;
              document.getElementById("plan1-subtotal").innerHTML =
                stockPriceElement1;
              document.getElementById("plan2-subtotal").innerHTML =
                stockPriceElement2;
              setDiscountPercent(0);
            } else {
              setDiscountPercent(response.data.coupons[0].discount_percent);
              stockPriceElement1 =
                plan1Subtotal -
                (plan1Subtotal * response.data.coupons[0].discount_percent) / 100;
              stockPriceElement2 =
                plan2Subtotal -
                (plan2Subtotal * response.data.coupons[0].discount_percent) / 100;
              document.getElementById("plan1-subtotal").innerHTML =
              Math.round(stockPriceElement1);
              document.getElementById("plan2-subtotal").innerHTML =
              Math.round(stockPriceElement2);
              setDiscountAmount(0);
            }
            toastr.options = {
              positionClass: "toast-top-full-width",
              hideDuration: 300,
              timeOut: 2000,
            };
            toastr.clear();
            setTimeout(() => toastr.success("Coupon Applied"), 200);
          }
          // setTimeout(()=>{
          //   checkDiscountPrice()
          // },500);
        }
      }

    } catch (error) {
      // console.log("error is ", error)
    }
  };

  //Readmore function
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const getUserAssessmentLogs = async () => {
    try {
      const userId = JSON.parse(window.localStorage.getItem('userId'));
      console.log("userId >>", userId)
      const formData = new FormData();
      formData.append("userId", userId);
      const response = await axios({
        method: "post",
        url: getAssessmentLogs,
        data: formData,
      });
      if (response.status == 200) {
        console.log("Response is >>", response);
        setUserAssessmentLog(response.data);
      }
    } catch (error) {
      // console.log("error is ", error)
    }
  };

  return (
    <div className="main no-mb">
      {/* <Header2 /> */}
      <Container>
        {
          console.log("disease priority value is >>", diseasePriority)
        }
        <div className="bs-card typ-mob-height-auto typ-pl-on typ-mb-on">
          <Row className="align-items-center">
            <Col
              xl={{ offset: 6, size: 6 }}
              lg={{ offset: 6, size: 6 }}
              md="12"
            >
              <div className="bs-heading typ-center">
                <h2 className="heading">Your Heart Health Score</h2>
                <p className="subheading">Detailed analysis of your report</p>
              </div>
            </Col>

            <Col xl="6" lg="6" md="12">
              <div className="bs-lottifiles typ-healthscore">
                {/* <img className='multi-color-bg' src={MultiColorBand} alt="multi-color-band" /> */}
                <Player
                  className="multi-color-bg"
                  autoplay
                  speed={1.5}
                  loop
                  src={scoreMeter}
                // style={{ height: "300px", width: "300px" }}
                ></Player>
                {/* <img
                  className="band-girl"
                  src={BandGirl}
                  alt="multi-color-band"
                /> */}
                <div
                  className={`bs-img-sprite band-girl typ-band-girl ${gender} ${imgClass}`}
                ></div>
                {console.log("Health status is >>", healthStatus)}
                <div className="lotti-content-box">
                  <p className="result-txt">
                    {showScore === "Loading" ? "Loading" : healthStatus}
                  </p>
                  {showScore && <p className="result-no">{showScore}</p>}
                  {/* <img className='indicator' src={Indicator} alt="multi-color-band" /> */}
                </div>
              </div>
            </Col>

            <Col xl="6" lg="6" md="12">
              <div className="bs-heading typ-center">
                <h2 className="heading">
                  {showScore === "Loading" ? "Loading" : healthStatus}
                </h2>
                <p className="subheading">
                  {showScore === "Loading" ? "Loading" : finalMsg}
                </p>
              </div>
              {/* <p style={{ whiteSpace: "pre-line" }}>{Parser(defaultMsg)}</p> */}
              <p
                className="cm-para typ-grey6 typ-center typ-max-wid"
                style={{ whiteSpace: "pre-line" }}
              >
                {showScore === "Loading" ? (
                  "Loading"
                ) : isReadMore ? (
                  <React.Fragment>
                    {messageDesc.slice(0, 5000)}
                    {/* <button onClick={toggleReadMore} className="red">
                      ...Read more
                    </button> */}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {messageDesc}
                    <button onClick={toggleReadMore} className="red">
                      {/* Show less */}
                    </button>
                  </React.Fragment>
                )}
              </p>
              {
                console.log("userLatestPlan is >>", userLatestPlan)
              }
              {
                userLatestPlan == null ?
                  diseasecalpointsVal >= 27 ||
                    bmiVal >= 25.1 ||
                    heartDiseasepointVal == "true" ||
                    bppointsVal >= 5 ||
                    bplowerpointsVal >= 5 ||
                    totcholpointVal >= 5 ||
                    ldlpointVal >= 5 ||
                    hdlpointVal >= 5 ||
                    bppointsVal >= 5 ||
                    bplowerpointsVal >= 5 ?
                    <Button href="#subscription-plan" className='bs-btn typ-check-score typ-full-width'>
                      Enroll me to Improve My Score
                    </Button>
                    : null : null
              }

            </Col>
          </Row>
        </div>
      </Container>
      {
        console.log("Assessment value is >>", assessment)
      }
      {assessment && (
        <Container>
          <div className="bs-card typ-height-auto typ-price-table typ-pl-on typ-mb-on typ-score-analysis">
            <Row>
              <Col xl="12" lg="12" md="12">
                <div class="bs-heading typ-full-width typ-mob-center">
                  <h2 class="heading">Your report logs</h2>
                  <p className="subheading">
                    Here are all your previous test reports
                  </p>
                </div>
                <div className="bs-table">
                  <Table borderless responsive size="">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Report Status</th>
                        <th>Score</th>
                        <th>View Reports</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userAssessmentLog.usersData != undefined &&
                        userAssessmentLog.usersData.map((data) => (
                          <React.Fragment>
                            <tr>
                              <td scope="row">{data.assessment_date}</td>

                              {(data.score <= 39 && data.score <= 49 && (
                                <td>POOR</td>
                              )) ||
                                (data.score >= 50 && data.score <= 74 && (
                                  <td>FAIR</td>
                                )) ||
                                (data.score >= 75 && data.score <= 84 && (
                                  <td>GOOD</td>
                                )) ||
                                (data.score >= 85 && data.score <= 94 && (
                                  <td>VERY GOOD</td>
                                )) ||
                                (data.score >= 95 && "EXCELLENT")}
                              <td>{data.score}</td>
                              <td>
                                <Link
                                  to={`${homePageUrl}reports`}
                                  state={{ id: data.assessment_id }}
                                  className="cm-para typ-link"
                                >
                                  View Report
                                </Link>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      )}

      <Container>
        <div className="bs-card typ-height-auto typ-pl-on typ-mb-on typ-score-analysis">
          <Row>
            <Col xl="12" lg="12" md="12">
              <div className="bs-heading typ-full-width typ-mob-center">
                <h2 className="heading">Your Score Analysis</h2>
                <p className="subheading typ-20">
                  Strongly consider taking important steps toward better
                  lifestyle choices and immediately work on below mentioned
                  severe and moderate Risk Factors to improve your Heart Health,
                  remember even small steps can help.
                </p>
                <div className="bs-tabs">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames([
                          { active: activeTab === "1" },
                          "typ-red",
                        ])}
                        onClick={() => toggle("1")}
                      >
                        <div>({(Math.round(severe))}%)</div>
                        Severe Risk Factors
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames([
                          { active: activeTab === "2" },
                          "typ-yellow",
                        ])}
                        onClick={() => toggle("2")}
                      >
                        <div>({(Math.round(moderate))}%)</div>
                        Moderate Risk Factors
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames([
                          { active: activeTab === "3" },
                          "typ-green",
                        ])}
                        onClick={() => toggle("3")}
                      >
                        {
                          console.log("mild value is >>", mild)
                        }
                        <div>({Math.round(mild)}%)</div>
                        Mild Risk Factors
                      </NavLink>
                    </NavItem>
                  </Nav>
                  {
                    console.log("diseasecalpointsVal is >>1", diseasecalpointsVal)
                  }
                  <TabContent activeTab={activeTab}>
                    <TabPane className="typ-red" tabId="1" id="severeZone">
                      <div className="bs-accordion">
                        <Accordion open={open} toggle={accordion}>
                          {/* Disease History severe start */}
                          {diseasecalpointsVal >= 27 && (
                            <AccordionItem>
                              <AccordionHeader targetId="1">
                                <span className="icon icon-history"></span>
                                <span className="acc-title">
                                  Disease History
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="1">
                                <p className="acc-body-title">
                                  You have been detected with following history
                                  of disease:{" "}
                                </p>
                                <ul>
                                  {hCholesterol == "true" && <li>High Cholestrol</li>}
                                  {hBbpHypertension == "true" && (
                                    <li>High BP / Hypertension</li>
                                  )}
                                  {diabete == "true" && <li>Diabetes</li>}
                                  {hDisease == "true" && <li>Heart Disease</li>}
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Disease History severe end */}
                          {
                            console.log("symptomspointVal is >>12", symptomspointVal)
                          }
                          {/* Diagnosis of Symptoms and Health Issues severe start */}
                          {symptomspointVal >= 2 && (
                            <AccordionItem>
                              <AccordionHeader targetId="2">
                                <span className="icon icon-diagonisis"></span>
                                <span className="acc-title">
                                  Diagnosis of Symptoms and Health Issues
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="2">
                                <p className="acc-body-title">
                                  You have been detected with following Symptoms
                                  and Health Issues:
                                </p>
                                {
                                  console.log("frequentUrination value is >>>1", frequentUrination)
                                }
                                <ul>
                                  {frequentUrination != "null" && frequentUrination == "true" && (
                                    <li>Frequent Urination</li>
                                  )}
                                  {excessiveHunger != "null" && excessiveHunger == "true" && (
                                    <li>Excessive Hunger / Thirst</li>
                                  )}
                                  {woundNotHealing != "null" && woundNotHealing == "true" && (
                                    <li>Wound Not Healing</li>
                                  )}
                                  {weightLoss != "null" && weightLoss == "true" && <li>Weight Loss</li>}
                                  {weightGain != "null" && weightGain == "true" && <li>Weight Gain</li>}
                                  {jointPains != "null" && jointPains == "true" && <li>Joint Pains</li>}
                                  {headache != "null" && headache == "true" && <li>Headache</li>}
                                  {weaknessOfFatigue != "null" && weaknessOfFatigue == "true" && (
                                    <li>Weakness or Fatigue</li>
                                  )}
                                  {constipation != "null" && constipation == "true" && <li>Constipation</li>}
                                  {sweating != "null" && sweating == "true" && <li>Sweating</li>}
                                  {tingling != "null" && tingling == "true" && <li>Tingling or Numbness</li>}
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Diagnosis of Symptoms and Health Issues severe end */}
                          {/* Heart Disease Symptoms severe start */}
                          {
                            console.log("heartDiseasepointVal is >111>", heartDiseasepointVal)
                          }
                          {heartDiseasepointVal != "null" && heartDiseasepointVal != "" && heartDiseasepointVal == "true" && (
                            <AccordionItem>
                              <AccordionHeader targetId="3">
                                <span className="icon icon-heart-disease"></span>
                                <span className="acc-title">
                                  Heart Disease Symptoms
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="3">
                                <ul>
                                  <li>
                                    Symptoms of Heart Disease have been
                                    Detected, Immediate Doctor Check-up
                                    recommended.
                                  </li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Heart Disease Symptoms severe end */}
                          {
                            console.log("diseasecalpointsVal is >>here", diseasecalpointsVal)
                          }
                          {/* Medicine History severe start */}
                          {((diseasecalpointsVal >= 27 &&
                            medicine == "Yes" &&
                            irregualrMedicine === "Yes") ||
                            (diseasecalpointsVal >= 27 &&
                              medicine == "No")) && (
                              <AccordionItem>
                                <AccordionHeader targetId="4">
                                  <span className="icon icon-medicine-history"></span>
                                  <span className="acc-title">
                                    Medicine History
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="4">
                                  <ul>
                                    {medicine === "Yes" ? (
                                      <li>
                                        Having {medicineCount} & taking Medicines{" "}
                                        {irregualrMedicine === "Yes"
                                          ? "Irregularly"
                                          : "Regularly"}
                                      </li>
                                    ) : (
                                      <li>
                                        You have been Detected with{" "}
                                        {hCholesterol == "true" && "High Cholestrol, "}{" "}
                                        {hBbpHypertension == "true" &&
                                          "High BP / Hypertension, "}{" "}
                                        {diabete == "true" && "Diabetes, "}{" "}
                                        {hDisease == "true" && "Heart Disease, "}But have
                                        not been prescribed any Medication. We
                                        recommend an immediate Doctor Check-up to
                                        Keep your Score in Check!
                                      </li>
                                    )}
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/* Medicine History severe end */}

                          {/* Cholesterol Vitals severe start */}

                          {((totcholpointVal >= 6.00 && totcholpointVal <= 10) ||
                            (ldlpointVal >= 3.5 && ldlpointVal <= 10) ||
                            (hdlpointVal >= 5 && hdlpointVal <= 10)) && (
                              <AccordionItem>
                                <AccordionHeader targetId="5">
                                  <span className="icon icon-cholesterol-vitals"></span>
                                  <span className="acc-title">
                                    Cholesterol Vitals
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="5">
                                  <ul>
                                    {bloodCholesterol === "Yes" ? (
                                      <>
                                        {totalCholestrol && (
                                          <li>
                                            Total Cholesterol - {totalCholestrol}{" "}
                                            mg/dL: {totalCholestrolMsg}
                                          </li>
                                        )}
                                        {ldl && (
                                          <li>
                                            LDL - {ldl} mg/dL: {ldlMessage}
                                          </li>
                                        )}
                                        {hdl && (
                                          <li>
                                            HDL - {hdl} mmol/L: {hdlMessage}
                                          </li>
                                        )}
                                      </>
                                    ) : (
                                      <li>
                                        Your Score maybe affected due to missing
                                        Vitals, Doctor Check-up is Recommended!
                                      </li>
                                    )}
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/* Cholesterol Vitals severe end */}

                          {/* Blood Sugar Vitals severe start */}
                          {((sugarfpointVal >= 5.5 && sugarfpointVal <= 10) ||
                            (sugarpppointVal >= 5.5 && sugarpppointVal <= 10) ||
                            (sugarhbpointVal >= 4.5 &&
                              sugarhbpointVal <= 10)) && (
                              <AccordionItem>
                                <AccordionHeader targetId="6">
                                  <span className="icon icon-blood-sugar-vitals"></span>
                                  <span className="acc-title">
                                    Blood Sugar Vitals
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="6">
                                  <ul>
                                    {bloodSugar === "Yes" ? (
                                      <>
                                        {fastingSugar && (
                                          <li>
                                            Fasting Sugar - {fastingSugar} mg/dL:{" "}
                                            {fastingSugarMsg}
                                          </li>
                                        )}
                                        {postFastingSugar && (
                                          <li>
                                            Post Fasting Sugar -{" "}
                                            {postFastingSugar} mg/dL:{" "}
                                            {postFastingSugarMsg}
                                          </li>
                                        )}
                                        {hbac1 && (
                                          <li>
                                            HBA1C - {hbac1} mmol/L: {hba1cMsg}
                                          </li>
                                        )}
                                      </>
                                    ) : (
                                      <li>
                                        Your Score maybe affected due to missing
                                        Vitals, Doctor Check-up is Recommended!
                                      </li>
                                    )}
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/* Blood Sugar Vitals severe end */}
                          {
                            console.log("bplowerpointsVal is >>", bplowerpointsVal)
                          }
                          {/* BP Vitals severe start */}
                          {((bppointsVal >= 6 && bppointsVal <= 10) ||
                            (bplowerpointsVal >= 5.5 && bppointsVal <= 10)) && (
                              <AccordionItem>
                                <AccordionHeader targetId="7">
                                  <span className="icon icon-bp-vitals"></span>
                                  <span className="acc-title">
                                    BP Vitals
                                    {bloodPressure === "Yes" && (
                                      <>
                                        : {systolicUpperBp} / {diastolicLowerBp}
                                      </>
                                    )}
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="7">
                                  <ul>
                                    {bloodPressure === "Yes" ? (
                                      <>
                                        {systolicUpperBp && (
                                          <li>
                                            Systolic (Upper BP) -{" "}
                                            {systolicUpperBp} mg/dL:{" "}
                                            {systolicUpperBpMsg}
                                          </li>
                                        )}
                                        {diastolicLowerBp && (
                                          <li>
                                            Diastolic (Lower BP) -{" "}
                                            {diastolicLowerBp} mg/dL:{" "}
                                            {diastolicLowerBpMsg}
                                          </li>
                                        )}
                                      </>
                                    ) : (
                                      <li>
                                        Your Score maybe affected due to missing
                                        Vitals, Doctor Check-up is Recommended!
                                      </li>
                                    )}
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/* BP Vitals severe end */}
                          {/* Age severe start */}
                          {agepointsVal >= 9 && (
                            <AccordionItem>
                              <AccordionHeader targetId="8">
                                <span className="icon icon-age"></span>
                                <span className="acc-title">
                                  Age: {age} years
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="8">
                                <ul>
                                  {age > 61 && agepointsVal === 15 ? (
                                    <li>
                                      Research says being at an age greater than
                                      60 is in itself a risk factor for heart
                                      diseases and associated risk factors.
                                    </li>
                                  ) : agepointsVal >= 12.6 <= 15 ? (
                                    <li>
                                      Research says that age {age} may bring
                                      moderate risk factors for heart diseases
                                      and associated risk factors.
                                    </li>
                                  ) : (
                                    <li>
                                      Research says that age {age} may bring
                                      mild risk factors for heart diseases and
                                      associated risk factors.
                                    </li>
                                  )}
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Age severe end */}
                          {/* Weight & BMI severe start */}
                          {bmipointsVal >= 12.00 && bmipointsVal <= 20 && (
                            <AccordionItem>
                              <AccordionHeader targetId="9">
                                <span className="icon icon-weight"></span>
                                <span className="acc-title">
                                  Weight: {weight}, BMI: {bmiVal}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="9">
                                <ul>
                                  <li>{bmiMessage}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Weight & BMI severe end */}
                          {/* Diet severe start */}
                          {mealpointsVal === 2.00 && (
                            <AccordionItem>
                              <AccordionHeader targetId="10">
                                <span className="icon icon-diet"></span>
                                <span className="acc-title">Diet</span>
                              </AccordionHeader>
                              <AccordionBody accordionId="10">
                                <ul>
                                  <li>{mealMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Diet severe end */}
                          {/*  Rest & Sleep severe start */}
                          {sleepingpointsVal >= 1.75 &&
                            sleepingpointsVal <= 2 && (
                              <AccordionItem>
                                <AccordionHeader targetId="11">
                                  <span className="icon icon-rest-sleep"></span>
                                  <span className="acc-title">
                                    Rest & Sleep: {sleephour}
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="11">
                                  <ul>
                                    <li>{sleepMsg}</li>
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/*  Rest & Sleep severe end */}
                          {/* Exercise severe start */}
                          {exercisepointsVal === 2 && (
                            <AccordionItem>
                              <AccordionHeader targetId="12">
                                <span className="icon icon-excercise"></span>
                                <span className="acc-title">
                                  Exercise: {excercise}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="12">
                                <ul>
                                  <li>{excerciseMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Exercise severe end */}
                          {/* Stress severe start */}
                          {stresspointsVal === 3 && (
                            <AccordionItem>
                              <AccordionHeader targetId="13">
                                <span className="icon icon-stress"></span>
                                <span className="acc-title">
                                  Stress: {stress}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="13">
                                <ul>
                                  <li>{stressMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Stress severe end */}
                          {/* Smoking & Tobacco severe start */}
                          {tobaccopointsVal >= 1.5 && tobaccopointsVal <= 3 && (
                            <AccordionItem>
                              <AccordionHeader targetId="14">
                                <span className="icon icon-smoking"></span>
                                <span className="acc-title">
                                  Smoking & Tobacco: {tobacco}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="14">
                                <ul>
                                  <li>{tobaccoMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Smoking & Tobacco severe end */}
                          {/* Alcohol severe start */}
                          {alcoholpointsVal >= 1 && alcoholpointsVal <= 2 && (
                            <AccordionItem>
                              <AccordionHeader targetId="15">
                                <span className="icon icon-alcohol"></span>
                                <span className="acc-title">
                                  Alcohol: {alcohol}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="15">
                                <ul>
                                  <li>{alcoholMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Alcohol severe end */}
                          {/* Lifestyle severe start */}
                          {lifestylepointVal >= 9 && (
                            <AccordionItem>
                              <AccordionHeader targetId="16">
                                <span className="icon icon-lifestyle"></span>
                                <span className="acc-title">
                                  Lifestyle: {lifestyleMsg}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="16">
                                <ul>
                                  <li>{lifestyleMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Lifestyle severe end */}
                        </Accordion>
                      </div>
                    </TabPane>
                    <TabPane className="typ-yellow" tabId="2" id="moderateZone">
                      <div className="bs-accordion">
                        <Accordion open={open} toggle={accordion}>
                          {/* Medicine History moderate start*/}
                          {diseasecalpointsVal >= 27 &&
                            medicine == "Yes" &&
                            irregualrMedicine === "No" && (
                              <AccordionItem>
                                <AccordionHeader targetId="1">
                                  <span className="icon icon-medicine-history"></span>
                                  <span className="acc-title">
                                    Medicine History
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="1">
                                  <ul>
                                    <li>
                                      Having {medicineCount} & taking Medicines{" "}
                                      {irregualrMedicine === "Yes"
                                        ? "Irregularly"
                                        : "Regularly"}
                                    </li>
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/* Medicine History moderate end*/}

                          {/* Cholesterol Vitals moderate start*/}
                          {
                            //moderate
                            (totcholpointVal >= 0.1 && totcholpointVal <= 5) ||
                              (ldlpointVal >= 0.1 && ldlpointVal <= 4) ||
                              (hdlpointVal >= 0.1 && hdlpointVal <= 4.5) ? (
                              (totcholpointVal >= 6 && totcholpointVal <= 10) ||
                                (ldlpointVal >= 3.5 && ldlpointVal <= 10) ||
                                (hdlpointVal >= 5 && hdlpointVal <= 10) ? null : (
                                <AccordionItem>
                                  <AccordionHeader targetId="2">
                                    <span className="icon icon-cholesterol-vitals"></span>
                                    <span className="acc-title">
                                      Cholesterol Vitals
                                    </span>
                                  </AccordionHeader>
                                  <AccordionBody accordionId="2">
                                    <ul>
                                      {bloodCholesterol === "Yes" ? (
                                        <>
                                          {totalCholestrol && (
                                            <li>
                                              Total Cholesterol -{" "}
                                              {totalCholestrol} mg/dL:{" "}
                                              {totalCholestrolMsg}
                                            </li>
                                          )}
                                          {ldl && (
                                            <li>
                                              LDL - {ldl} mg/dL: {ldlMessage}
                                            </li>
                                          )}
                                          {hdl && (
                                            <li>
                                              HDL - {hdl} mmol/L: {hdlMessage}
                                            </li>
                                          )}
                                        </>
                                      ) : (
                                        <li>
                                          Your Score maybe affected due to
                                          missing Vitals, Doctor Check-up is
                                          Recommended!
                                        </li>
                                      )}
                                    </ul>
                                  </AccordionBody>
                                </AccordionItem>
                              )
                            ) : null
                          }
                          {/* Cholesterol Vitals moderate end*/}

                          {/* Blood Sugar Vitals moderate start*/}
                          {
                            //moderate
                            (sugarfpointVal >= 0.1 && sugarfpointVal <= 5) ||
                              (sugarpppointVal >= 0.1 && sugarpppointVal <= 5) ||
                              (sugarhbpointVal >= 0.1 && sugarhbpointVal <= 5) ? (
                              (sugarfpointVal >= 5.5 && sugarfpointVal <= 10) ||
                                (sugarpppointVal >= 5.5 &&
                                  sugarpppointVal <= 10) ||
                                (sugarhbpointVal >= 4.5 &&
                                  sugarhbpointVal <= 10) ? null : (
                                <AccordionItem>
                                  <AccordionHeader targetId="3">
                                    <span className="icon icon-blood-sugar-vitals"></span>
                                    <span className="acc-title">
                                      Blood Sugar Vitals
                                    </span>
                                  </AccordionHeader>
                                  <AccordionBody accordionId="3">
                                    <ul>
                                      {bloodSugar === "Yes" ? (
                                        <>
                                          {fastingSugar && (
                                            <li>
                                              Fasting Sugar - {fastingSugar}{" "}
                                              mg/dL: {fastingSugarMsg}
                                            </li>
                                          )}
                                          {postFastingSugar && (
                                            <li>
                                              Post Fasting Sugar -{" "}
                                              {postFastingSugar} mg/dL:{" "}
                                              {postFastingSugarMsg}
                                            </li>
                                          )}
                                          {hbac1 && (
                                            <li>
                                              HBA1C - {hbac1} mmol/L: {hba1cMsg}
                                            </li>
                                          )}
                                        </>
                                      ) : (
                                        <li>
                                          Your Score maybe affected due to
                                          missing Vitals, Doctor Check-up is
                                          Recommended!
                                        </li>
                                      )}
                                    </ul>
                                  </AccordionBody>
                                </AccordionItem>
                              )
                            ) : null
                          }
                          {/* Blood Sugar Vitals moderate end*/}
                          { }
                          {/* BP Vitals moderate start */}
                          {
                            //moderate
                            (bppointsVal >= 0.1 && bppointsVal <= 5.5) ||
                              (bplowerpointsVal >= 0.1 && bppointsVal <= 5) ? (
                              (bppointsVal >= 5.6 && bppointsVal <= 10) ||
                                (bplowerpointsVal >= 5.1 &&
                                  bppointsVal <= 10) ? null : (
                                <AccordionItem>
                                  <AccordionHeader targetId="4">
                                    <span className="icon icon-bp-vitals"></span>
                                    <span className="acc-title">
                                      BP Vitals
                                      {bloodPressure === "Yes" && (
                                        <>
                                          : {systolicUpperBp} /{" "}
                                          {diastolicLowerBp}
                                        </>
                                      )}
                                    </span>
                                  </AccordionHeader>
                                  <AccordionBody accordionId="4">
                                    <ul>
                                      {bloodPressure === "Yes" ? (
                                        <>
                                          {systolicUpperBp && (
                                            <li>
                                              Systolic (Upper BP) -{" "}
                                              {systolicUpperBp} mg/dL:{" "}
                                              {systolicUpperBpMsg}
                                            </li>
                                          )}
                                          {diastolicLowerBp && (
                                            <li>
                                              Diastolic (Lower BP) -{" "}
                                              {diastolicLowerBp} mg/dL:{" "}
                                              {diastolicLowerBpMsg}
                                            </li>
                                          )}
                                        </>
                                      ) : (
                                        <li>
                                          Your Score maybe affected due to
                                          missing Vitals, Doctor Check-up is
                                          Recommended!
                                        </li>
                                      )}
                                    </ul>
                                  </AccordionBody>
                                </AccordionItem>
                              )
                            ) : null
                          }
                          {/* BP Vitals moderate end */}
                          {/* Age moderate start */}
                          {agepointsVal >= 2.5 && agepointsVal <= 8.5 && (
                            <AccordionItem>
                              <AccordionHeader targetId="5">
                                <span className="icon icon-age"></span>
                                <span className="acc-title">
                                  Age: {age} yrs
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="5">
                                <ul>
                                  <ul>
                                    {agepointsVal >= 2.6 &&
                                      agepointsVal <= 7.5 ? (
                                      <li>
                                        Research says that age {age} may bring
                                        some risk factors for heart diseases and
                                        associated risk factors.
                                      </li>
                                    ) : (
                                      <li>
                                        Research says that age {age} may bring
                                        mild risk factors for heart diseases and
                                        associated risk factors.
                                      </li>
                                    )}
                                  </ul>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Age moderate end */}
                          {/*  Weight & Bmi moderate start */}
                          {bmipointsVal >= 5 && bmipointsVal <= 11 && (
                            <AccordionItem>
                              <AccordionHeader targetId="6">
                                <span className="icon icon-weight"></span>
                                <span className="acc-title">
                                  Weight: {weight}, BMI: {bmiVal}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="6">
                                <ul>
                                  <li>{bmiMessage}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/*  Weight & Bmi moderate end */}
                          {/*  Diet moderate start */}
                          {mealpointsVal >= 1.25 && mealpointsVal <= 1.5 && (
                            <AccordionItem>
                              <AccordionHeader targetId="7">
                                <span className="icon icon-diet"></span>
                                <span className="acc-title">Diet</span>
                              </AccordionHeader>
                              <AccordionBody accordionId="7">
                                <ul>
                                  <li>{mealMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/*  Diet moderate end */}
                          {/*  Rest & Sleep moderate start */}
                          {sleepingpointsVal >= 0.5 &&
                            sleepingpointsVal <= 1 && (
                              <AccordionItem>
                                <AccordionHeader targetId="8">
                                  <span className="icon icon-rest-sleep"></span>
                                  <span className="acc-title">
                                    Rest & Sleep: {sleephour}
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="8">
                                  <ul>
                                    <li>{sleepMsg}</li>
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/*  Rest & Sleep moderate end */}
                          {/*  Exercise moderate start */}
                          {exercisepointsVal >= 1 &&
                            exercisepointsVal <= 1.5 && (
                              <AccordionItem>
                                <AccordionHeader targetId="9">
                                  <span className="icon icon-excercise"></span>
                                  <span className="acc-title">
                                    Exercise: {excercise}
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="9">
                                  <ul>
                                    <li>{excerciseMsg}</li>
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/*  Exercise moderate end */}
                          {/*  Stress moderate start */}
                          {stresspointsVal === 2 && (
                            <AccordionItem>
                              <AccordionHeader targetId="10">
                                <span className="icon icon-stress"></span>
                                <span className="acc-title">
                                  Stress: {stress}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="10">
                                <ul>
                                  <li>{stressMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/*  Stress moderate end */}
                          {/*  Smoking & Tobacco moderate start */}
                          {tobaccopointsVal === 1 && (
                            <AccordionItem>
                              <AccordionHeader targetId="11">
                                <span className="icon icon-smoking"></span>
                                <span className="acc-title">
                                  Smoking & Tobacco: {tobacco}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="11">
                                <ul>
                                  <li>{tobaccoMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/*  Smoking & Tobacco moderate end */}
                          {/*  Alcohol moderate start */}
                          {alcoholpointsVal === 0.5 && (
                            <AccordionItem>
                              <AccordionHeader targetId="12">
                                <span className="icon icon-alcohol"></span>
                                <span className="acc-title">
                                  Alcohol: {alcohol}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="12">
                                <ul>
                                  <li>{alcoholMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/*  Alcohol moderate end */}
                          {/*  Lifestyle moderate start */}
                          {lifestylepointVal >= 4 &&
                            lifestylepointVal <= 8.9 && (
                              <AccordionItem>
                                <AccordionHeader targetId="13">
                                  <span className="icon icon-lifestyle"></span>
                                  <span className="acc-title">
                                    Lifestyle: {lifestyleMsg}
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="13">
                                  <ul>
                                    <li>{lifestyleMsg}</li>
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/*  Lifestyle moderate end */}
                        </Accordion>
                      </div>
                    </TabPane>
                    <TabPane className="typ-green" tabId="3" id="mildZone">
                      <div className="bs-accordion">
                        <Accordion open={open} toggle={accordion}>
                          {/* Disease History mild start */}
                          {diseasecalpointsVal === 0 && (
                            <AccordionItem>
                              <AccordionHeader targetId="1">
                                <span className="icon icon-history"></span>
                                <span className="acc-title">
                                  Disease History
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="1">
                                <ul>
                                  <li>
                                    You have not been diagnosed with any Chronic
                                    Disease such as Heart Disease, Diabetes,
                                    Hypertension or Cholesterol.
                                  </li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Disease History mild end */}
                          {/* Diagnosis of Symptoms and Health Issues mild start */}
                          {symptomspointVal === 0 && (
                            <AccordionItem>
                              <AccordionHeader targetId="2">
                                <span className="icon icon-diagonisis"></span>
                                <span className="acc-title">
                                  Diagnosis of Symptoms and Health Issues
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="2">
                                <ul>
                                  <li>
                                    You have not been diagnosed with any
                                    symptoms related to Chronic Disease such as
                                    Heart Disease, Diabetes, Hypertension or
                                    Cholesterol.
                                  </li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Diagnosis of Symptoms and Health Issues mild end */}
                          {/* Heart Disease Symptoms mild start */}
                          {heartDiseasepointVal == "false" && (
                            <AccordionItem>
                              <AccordionHeader targetId="3">
                                <span className="icon icon-heart-disease"></span>
                                <span className="acc-title">
                                  Heart Disease Symptoms
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="3">
                                <ul>
                                  <li>
                                    No Symptoms of Heart Disease have been
                                    Detected
                                  </li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Heart Disease Symptoms mild end */}
                          {/* Medicine History mild start */}
                          {diseasecalpointsVal < 27 && (
                            <AccordionItem>
                              <AccordionHeader targetId="4">
                                <span className="icon icon-medicine-history"></span>
                                <span className="acc-title">
                                  Medicine History
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="4">
                                <ul>
                                  <li>No Medicine History</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Medicine History mild end */}
                          {/* Cholesterol Vitals mild start */}
                          {totcholpointVal === 0 &&
                            ldlpointVal === 0 &&
                            hdlpointVal === 0 && (
                              <AccordionItem>
                                <AccordionHeader targetId="5">
                                  <span className="icon icon-cholesterol-vitals"></span>
                                  <span className="acc-title">
                                    Cholesterol Vitals
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="5">
                                  <ul>
                                    {bloodCholesterol === "Yes" ? (
                                      <>
                                        {totalCholestrol && (
                                          <li>
                                            Total Cholesterol -{" "}
                                            {totalCholestrol} mg/dL:{" "}
                                            {totalCholestrolMsg}
                                          </li>
                                        )}
                                        {ldl && (
                                          <li>
                                            LDL - {ldl} mg/dL: {ldlMessage}
                                          </li>
                                        )}
                                        {hdl && (
                                          <li>
                                            HDL - {hdl} mmol/L: {hdlMessage}
                                          </li>
                                        )}
                                      </>
                                    ) : (
                                      <li>
                                        Your Score maybe affected due to missing
                                        Vitals, Doctor Check-up is Recommended!
                                      </li>
                                    )}
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/* Cholesterol Vitals mild end */}

                          {/* Blood Sugar Vitals start */}
                          {sugarfpointVal === 0 &&
                            sugarpppointVal === 0 &&
                            sugarhbpointVal === 0 && (
                              <AccordionItem>
                                <AccordionHeader targetId="6">
                                  <span className="icon icon-blood-sugar-vitals"></span>
                                  <span className="acc-title">
                                    Blood Sugar Vitals
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="6">
                                  <ul>
                                    {bloodSugar === "Yes" ? (
                                      <>
                                        {fastingSugar && (
                                          <li>
                                            Fasting Sugar - {fastingSugar}{" "}
                                            mg/dL: {fastingSugarMsg}
                                          </li>
                                        )}
                                        {postFastingSugar && (
                                          <li>
                                            Post Fasting Sugar -{" "}
                                            {postFastingSugar} mg/dL:{" "}
                                            {postFastingSugarMsg}
                                          </li>
                                        )}
                                        {hbac1 && (
                                          <li>
                                            HBA1C - {hbac1} mmol/L: {hba1cMsg}
                                          </li>
                                        )}
                                      </>
                                    ) : (
                                      <li>
                                        Your Score maybe affected due to missing
                                        Vitals, Doctor Check-up is Recommended!
                                      </li>
                                    )}
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/* Blood Sugar Vitals mild end */}
                          {/* BP Vitals mild start */}
                          {bppointsVal === 0 && bplowerpointsVal === 0 && (
                            <AccordionItem>
                              <AccordionHeader targetId="7">
                                <span className="icon icon-bp-vitals"></span>
                                <span className="acc-title">
                                  BP Vitals
                                  {bloodPressure === "Yes" && (
                                    <>
                                      : {systolicUpperBp} / {diastolicLowerBp}
                                    </>
                                  )}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="7">
                                <ul>
                                  {bloodPressure === "Yes" ? (
                                    <>
                                      {systolicUpperBp && (
                                        <li>
                                          Systolic (Upper BP) -{" "}
                                          {systolicUpperBp} mg/dL:{" "}
                                          {systolicUpperBpMsg}
                                        </li>
                                      )}
                                      {diastolicLowerBp && (
                                        <li>
                                          Diastolic (Lower BP) -{" "}
                                          {diastolicLowerBp} mg/dL:{" "}
                                          {diastolicLowerBpMsg}
                                        </li>
                                      )}
                                    </>
                                  ) : (
                                    <li>
                                      Your Score maybe affected due to missing
                                      Vitals, Doctor Check-up is Recommended!
                                    </li>
                                  )}
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* BP Vitals mild end */}
                          {/* Age mild start */}
                          {agepointsVal === 2 && (
                            <AccordionItem>
                              <AccordionHeader targetId="8">
                                <span className="icon icon-age"></span>
                                <span className="acc-title">
                                  Age: {age} yrs
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="8">
                                <ul>
                                  <li>
                                    Your age works as a shield against heart
                                    diseases and risk factors.
                                  </li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Age mild end */}

                          {/* Weight & Bmi mild start */}
                          {bmipointsVal >= 0 && bmipointsVal <= 2 && (
                            <AccordionItem>
                              <AccordionHeader targetId="9">
                                <span className="icon icon-weight"></span>
                                <span className="acc-title">
                                  Weight: {weight}, BMI: {bmiVal}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="9">
                                <ul>
                                  <li>{bmiMessage}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Weight & Bmi mild end */}

                          {/* Diet mild start */}
                          {mealpointsVal === 0 && (
                            <AccordionItem>
                              <AccordionHeader targetId="10">
                                <span className="icon icon-diet"></span>
                                <span className="acc-title">Diet</span>
                              </AccordionHeader>
                              <AccordionBody accordionId="10">
                                <ul>
                                  <li>{mealMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Diet mild end */}

                          {/* Rest & Sleep mild start */}
                          {sleepingpointsVal === 0 && (
                            <AccordionItem>
                              <AccordionHeader targetId="11">
                                <span className="icon icon-rest-sleep"></span>
                                <span className="acc-title">
                                  Rest & Sleep: {sleephour}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="11">
                                <ul>
                                  <li>{sleepMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Rest & Sleep mild end */}

                          {/* Exercise mild start */}
                          {exercisepointsVal >= 0 &&
                            exercisepointsVal <= 0.5 && (
                              <AccordionItem>
                                <AccordionHeader targetId="12">
                                  <span className="icon icon-excercise"></span>
                                  <span className="acc-title">
                                    Exercise: {excercise}
                                  </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="12">
                                  <ul>
                                    <li>{excerciseMsg}</li>
                                  </ul>
                                </AccordionBody>
                              </AccordionItem>
                            )}
                          {/* Exercise mild end */}

                          {/* Stress mild start */}
                          {stresspointsVal === 0 && (
                            <AccordionItem>
                              <AccordionHeader targetId="13">
                                <span className="icon icon-stress"></span>
                                <span className="acc-title">
                                  Stress: {stress}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="13">
                                <ul>
                                  <li>{stressMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Stress mild end */}

                          {/* Smoking & Tobacco mild start */}
                          {tobaccopointsVal === 0 && (
                            <AccordionItem>
                              <AccordionHeader targetId="14">
                                <span className="icon icon-smoking"></span>
                                <span className="acc-title">
                                  Smoking & Tobacco: {tobacco}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="14">
                                <ul>
                                  <li>{tobaccoMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Smoking & Tobacco mild end */}

                          {/* Alcohol mild start */}
                          {alcoholpointsVal === 0 && (
                            <AccordionItem>
                              <AccordionHeader targetId="15">
                                <span className="icon icon-alcohol"></span>
                                <span className="acc-title">
                                  Alcohol: {alcohol}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="15">
                                <ul>
                                  <li>{alcoholMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Alcohol mild end */}

                          {/* Lifestyle mild start */}
                          {lifestylepointVal <= 3.9 && (
                            <AccordionItem>
                              <AccordionHeader targetId="16">
                                <span className="icon icon-lifestyle"></span>
                                <span className="acc-title">
                                  Lifestyle: {lifestyleMsg}
                                </span>
                              </AccordionHeader>
                              <AccordionBody accordionId="16">
                                <ul>
                                  <li>{lifestyleMsg}</li>
                                </ul>
                              </AccordionBody>
                            </AccordionItem>
                          )}
                          {/* Lifestyle mild end */}
                        </Accordion>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      {userLatestPlan != null && userLatestPlan != "" ? (
        <Container>
          <div className="bs-card typ-height-auto typ-price-table typ-pl-on typ-mb-on typ-score-analysis">
            <Row className="align-items-center justify-content-between">
              <Col xl="12" lg="12" md="12">
                <div class="bs-heading typ-center typ-full-width">
                  <h2 class="heading">
                    Your {userLatestPlan.plan_name} is activated from{" "}
                    {userLatestPlan.subscription_startDate} to{" "}
                    {userLatestPlan.subscription_endDate}
                  </h2>
                  {/* <p className="subheading">Your Customised Save My Heart Program will resolve your health concerns as per below order, you are also allowed to alter your program order as per your requirement</p> */}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      ) : (
        ""
      )}
      {(diseasecalpointsVal >= 27 ||
        bmiVal >= 25.1 ||
        heartDiseasepointVal == "true" ||
        bppointsVal >= 5 ||
        bplowerpointsVal >= 5 ||
        totcholpointVal >= 5 ||
        ldlpointVal >= 5 ||
        hdlpointVal >= 5 ||
        bppointsVal >= 5 ||
        bplowerpointsVal >= 5) && (
          <>
            {
              assessment == false &&
                userLatestPlan == null || userLatestPlan == "" ? (
                <Container>
                  <div className="bs-card typ-height-auto typ-price-table typ-pl-on typ-mb-on typ-score-analysis">
                    <Row className="align-items-center justify-content-between">
                      <Col xl="12" lg="12" md="12">
                        <div class="bs-heading typ-center">
                          <h2 class="heading mb-40">
                            Learn how to Improve your score in just 90 days through
                            Ayurveda
                          </h2>
                        </div>
                      </Col>
                      <Col xl="12" lg="12" md="12">
                        <Row className="align-items-center justify-content-between sec-yt">
                          <Col xl="5" lg="5" md="12">
                            <div className="vimeo-player">
                              <Vimeo
                                video="https://player.vimeo.com/video/768170272?h=9ecfae64f4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                responsive={true}
                              />
                            </div>
                          </Col>
                          {
                            console.log("Heart blokage value is >>", typeof heartBlockage)
                          }
                          <Col xl="6" lg="6" md="12">
                            <ul className="counter-increment">
                              <li>
                                <p>
                                  As per your Assessment your score is {showScore}{" "}
                                  and you have been diagnosed and detected with
                                </p>
                                {
                                  hDisease != "" && hDisease == "true" || heartBlockage != "" && heartBlockage == "Yes" ? <p>• Heart Disease</p> : null
                                }
                                {
                                  diabete == "null" || diabete != "" && diabete == "true" ? <p>. Diabetes</p> : null
                                }

                                {hBbpHypertension == "null" || hBbpHypertension != "" && hBbpHypertension == "true" ? (
                                  <p>• High BP / Hypertension</p>
                                ) : null}


                                {hCholesterol == "null" || hCholesterol != "" && hCholesterol == "true" ? <p>• High Cholestrol</p> : null}



                                {
                                  bmiVal >= 25.1 && <p>. Obesity</p>
                                }
                                {
                                  heartBlockage != "" && heartBlockage == "No" ?
                                    hDisease == "null" || hDisease == "" || hDisease == "false" ? heartDiseasepointVal == "true" && <p>• Symptoms & Risk of Heart Disease</p> : null : null
                                }
                                {
                                  diabete == "false" || diabete == "" ?
                                    frequentUrination == "true" || excessiveHunger == "true" || woundNotHealing == "true" || weightLoss == "true" || weightGain == "true" ||
                                      tingling == "true" ?
                                      <p>. Symptoms & Risk of Diabetes</p> : null : null
                                }
                                {
                                  hBbpHypertension == null ||
                                    hBbpHypertension == false ? sweating != "null" && sweating != "" && sweating == "true" || headache != "null" && headache != "" && headache == "true" ? <p>. Symptoms & Risk of Hypertension</p> : null : null
                                }
                              </li>
                              <li>
                                To Improve your Heart Health Score, We have created
                                a customized Save My Heart Homebased Program, that
                                will teach you techniques to manage and even reverse
                                your diagnosed disease in 90 Days, simply by
                                following age old Ayurveda Knowledge.
                              </li>
                              <li>
                                Save My Heart Program is customized for every
                                individual, as no two people are the same, Watch the
                                Video to understand how the Program Works to Improve
                                your Heart Health score by following simple steps to
                                live a disease-free life.
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Container>
              ) : (
                ""
              )}
            {assessment == false &&
              userLatestPlan == null ? (
              <Container>
                <div className="bs-card typ-height-auto typ-price-table typ-pl-on typ-mb-on typ-score-analysis">
                  <Row className="align-items-center justify-content-between">
                    <Col xl="12" lg="12" md="12">
                      <div class="bs-heading typ-center">
                        <h2 class="heading mb-40">
                          How does Save My Heart Plan work?
                        </h2>
                      </div>
                    </Col>
                    <Col xl="12" lg="12" md="12">
                      <Row className="align-items-center justify-content-between sec-save-my-heart-plan">
                        <Col xl="4" lg="4" md="12">
                          <div className="bs-card typ-icn-txt typ-height-auto mob-space-on">
                            <img src={exersice_img} alt="exersice" />
                            <p className="desc">
                              Watch and follow the videos on Exercise, Disease
                              Education, and Lifestyle Modification.
                            </p>
                          </div>
                        </Col>
                        <Col xl="4" lg="4" md="12">
                          <div className="bs-card typ-icn-txt typ-height-auto mob-space-on">
                            <img src={calender_img} alt="calender" />
                            <p className="desc">
                              Schedule Disease Reversal Consultation Appointment
                              with our Network of 450 Doctors.
                            </p>
                          </div>
                        </Col>
                        <Col xl="4" lg="4" md="12">
                          <div className="bs-card typ-icn-txt typ-height-auto mob-space-on">
                            <img src={monitoring_img} alt="monitoring" />
                            <p className="desc">
                              Continuous Monitoring and Tracking of your Vitals
                              and Heart Health.
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Container>
            ) : (
              ""
            )}
            {assessment == false &&
              userExist == false && userLatestPlan == null ? (
              <Container>
                <div className="bs-card typ-height-auto typ-price-table typ-pl-on typ-mb-on typ-score-analysis">
                  <Row className="align-items-center justify-content-between">
                    <Col xl="12" lg="12" md="12">
                      <div class="bs-heading typ-center typ-full-width">
                        <h2 class="heading">
                          Select disease you want to manage first
                        </h2>
                        <p className="subheading">
                          Your Customised Save My Heart Program will resolve your
                          health concerns as per below order, you are also allowed
                          to alter your program order as per your requirement
                        </p>
                      </div>
                    </Col>
                    <Col xl="12" lg="12" md="12">
                      <div className="bs-form typ-list mt-0">
                        <FormGroup
                          className="radio-box typ-timeline"
                          tag="fieldset"
                        >
                          {/* Heart Disease */}
                          {hDisease != "" || hDisease == "true" ?
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="sleep"
                                  // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                  onClick={() =>
                                    setDiseasePriority("Heart-Disease")
                                  }
                                  // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                  value={"HeartDisease"}
                                  defaultChecked={diseasePriority === "Heart-Disease" ? "true" : null}
                                />{" "}
                                <div className="check-box-label">
                                  <div className="circle-box">
                                    <div className="circle"></div>
                                  </div>
                                  <p className="radio-txt">Heart Disease</p>
                                </div>
                              </Label>
                            </FormGroup>
                            : heartBlockage == "" || heartBlockage == "No" ? null :
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="sleep"
                                    // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                    onClick={() =>
                                      setDiseasePriority("Heart-Disease")
                                    }
                                    // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                    value={"HeartDisease"}
                                    defaultChecked={diseasePriority === "Heart-Disease" ? "true" : null}
                                  />{" "}
                                  <div className="check-box-label">
                                    <div className="circle-box">
                                      <div className="circle"></div>
                                    </div>
                                    <p className="radio-txt">Heart Disease</p>
                                  </div>
                                </Label>
                              </FormGroup>
                          }
                          {/* Diabetes */}
                          {diabete == "null" || diabete == "" || diabete == "false" ? null : (
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="sleep"
                                  // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                  onClick={() => setDiseasePriority("Diabetes")}
                                  // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                  value={"Diabetes"}
                                  defaultChecked={diseasePriority == "Diabetes" ? "true" : "false"}
                                />{" "}
                                <div className="check-box-label">
                                  <div className="circle-box">
                                    <div className="circle"></div>
                                  </div>
                                  <p className="radio-txt">Diabetes</p>
                                </div>
                              </Label>
                            </FormGroup>
                          )}
                          {/* Hypertension */}
                          {hBbpHypertension == "null" || hBbpHypertension == "" ||
                            hBbpHypertension == "false" ? null : (
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="sleep"
                                  // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                  onClick={() =>
                                    setDiseasePriority("Hypertension")
                                  }
                                  // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                  value={"Hypertension"}
                                  defaultChecked={diseasePriority == "Hypertension" ? "true" : null}
                                />{" "}
                                <div className="check-box-label">
                                  <div className="circle-box">
                                    <div className="circle"></div>
                                  </div>
                                  <p className="radio-txt">
                                    High BP / Hypertension
                                  </p>
                                </div>
                              </Label>
                            </FormGroup>
                          )}
                          {/* High Cholesterol */}
                          {
                            hCholesterol == "null" || hCholesterol == "" ||
                              hCholesterol == "false" ? null : (
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="sleep"
                                    // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                    onClick={() =>
                                      setDiseasePriority("Cholestrol")
                                    }
                                    // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                    value={"Cholestrol"}
                                    defaultChecked={diseasePriority === "Cholestrol" ? "true" : null}
                                  />{" "}
                                  <div className="check-box-label">
                                    <div className="circle-box">
                                      <div className="circle"></div>
                                    </div>
                                    <p className="radio-txt">High Cholestrol</p>
                                  </div>
                                </Label>
                              </FormGroup>
                            )}
                          {/* Obesity */}
                          {bmiVal >= 25.1 && (
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="sleep"
                                  // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                  onClick={() => setDiseasePriority("Obesity")}
                                  // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                  value={"obesity"}
                                  defaultChecked={diseasePriority == "Obesity" ? "true" : null}
                                />{" "}
                                <div className="check-box-label">
                                  <div className="circle-box">
                                    <div className="circle"></div>
                                  </div>
                                  <p className="radio-txt">Obesity</p>
                                </div>
                              </Label>
                            </FormGroup>
                          )}
                          {/* symptoms and risks of heart dieases */}
                          {
                            heartBlockage == "" || heartBlockage == "No" ?
                              hDisease == "null" || hDisease == "" || hDisease == "false" ?
                                heartDiseasepointVal != "null" && heartDiseasepointVal != "" && heartDiseasepointVal == "true" &&
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="sleep"
                                      // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                      onClick={() => setDiseasePriority("Heart-Disease")}
                                      // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                      value={"SymptomsofHeartDisease"}
                                      defaultChecked={diseasePriority == null || diseasePriority == undefined ?
                                        agepointsVal > 0 || diseasecalpointsVal > 0 || sugarfpointVal >= 4.1 || sugarhbpointVal >= 4.1 || sugarpppointVal >= 4.1 || ldlpointVal >= 4.1
                                        || hdlpointVal >= 4.1 || bppointsVal >= 4.1 && "true" : null}
                                    />{" "}
                                    <div className="check-box-label">
                                      <div className="circle-box">
                                        <div className="circle"></div>
                                      </div>
                                      <p className="radio-txt">Symptoms & Risk of Heart Disease</p>
                                    </div>
                                  </Label>
                                </FormGroup> : null : null
                          }

                          {/* symptoms of diabetes */}
                          {diabete == "false" || diabete == "" ?
                            frequentUrination == "true" || excessiveHunger == "true" || woundNotHealing == "true" || weightLoss == "true" || weightGain == "true" || tingling == "true" ?
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="sleep"
                                    // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                    onClick={() => setDiseasePriority("Diabetes")}
                                    // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                    value={"SymptomsofDiabetes"}
                                    defaultChecked={diseasePriority == null || diseasePriority == undefined ? hDisease == "false" && diabete == "false" && hBbpHypertension == "false" && hCholesterol == "false" &&

                                      bmiVal <= 25.1 && symptomsSituation != "While at Rest" && "true" : null}
                                  />{" "}
                                  <div className="check-box-label">
                                    <div className="circle-box">
                                      <div className="circle"></div>
                                    </div>
                                    <p className="radio-txt">Symptoms & Risk of Diabetes</p>
                                  </div>
                                </Label>
                              </FormGroup> : console.log("Errr here")
                            : null
                          }

                          {/* symptoms of Hypertension */}
                          {hBbpHypertension == null ||
                            hBbpHypertension == false ? sweating != "null" && sweating != "" && sweating == "true" || headache != "null" && headache != "" && headache == "true" ?
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="sleep"
                                  // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                  onClick={() => setDiseasePriority("Hypertension")}
                                  // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                  value={"SymptomsofHypertension"}
                                  defaultChecked={diseasePriority == null || diseasePriority == undefined ? sweating == "true" || headache == "true" && "true" : null}
                                />{" "}
                                <div className="check-box-label">
                                  <div className="circle-box">
                                    <div className="circle"></div>
                                  </div>
                                  <p className="radio-txt">Symptoms & Risk of Hypertension</p>
                                </div>
                              </Label>
                            </FormGroup> : console.log("Errr here") : null
                          }
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            ) : (
              ""
            )}
            {assessment == false &&
              userExist == false && userLatestPlan == null && (
                <Container>
                  <div id='subscription-plan' className="bs-card typ-height-auto typ-price-table typ-pl-on typ-mb-on-30 typ-score-analysis">
                    <Row className="align-items-center justify-content-between">
                      <Col xl="12" lg="12" md="12">
                        <div class="bs-heading mb-0 typ-center typ-full-width">
                          <h2 class="heading">
                            Select a Save My Heart Program that fits you best
                          </h2>
                        </div>
                      </Col>

                      <Col xl="12" lg="12" md="12">
                        <div className="bs-heart-plan-box">
                          {planDetail.map((item, i) => {
                            return i == 0 ? (
                              <div className="top-header tab-row">
                                <div className="tab-col tab-col-1">
                                  <p className="title">
                                    Save My Heart Plan for 90 Days
                                  </p>
                                </div>
                                {isBrowser && item.plan_id && (
                                  // 
                                  <div className="tab-col tab-col-2">
                                    {item.is_popular == 1 ?
                                      <img
                                        src={popular}
                                        alt="popular"
                                        className="popular"
                                      /> : ''}
                                    <p className="title">{item.plan_name}</p>
                                    <p className="enrolled-count">6812+Enrolled</p>
                                  </div>

                                )}

                                {isMobile && item.plan_id && (
                                  <div className="tab-col tab-col-2">
                                    {item.is_popular == 1 ?
                                      <img
                                        src={popular}
                                        alt="popular"
                                        className="popular"
                                      /> : ''}
                                    <p className="title">Plan 1</p>
                                  </div>
                                )}

                                {isBrowser && item.plan_id2 && (
                                  <div className="tab-col tab-col-3">
                                    {item.is_popular2 == 1 ?
                                      <img
                                        src={popular}
                                        alt="popular"
                                        className="popular"
                                      /> : ''}
                                    <p className="title">{item.plan_name2}</p>
                                    <p className="enrolled-count">
                                      12612+ Enrolled
                                    </p>
                                  </div>
                                )}
                                {isMobile && item.plan_id2 && (
                                  <div className="tab-col tab-col-3">
                                    {item.is_popular2 == 1 ? <img
                                      src={popular}
                                      alt="popular"
                                      className="popular"
                                    /> : ''}
                                    <p className="title">Plan 2</p>
                                  </div>
                                )}
                              </div>
                            ) : (
                              ""
                            );
                          })}
                          {planDetail.map((item, index) => {
                            return (
                              <div className="tab-row mid-row">
                                {item.name && (
                                  <div
                                    className="tab-col tab-col-1 typ-green"
                                    key={item}
                                  >
                                    <p className="plan-name">{index + 1}. {item.name}</p>
                                  </div>
                                )}
                                {item.plan_id && (
                                  <div
                                    className={`tab-col tab-col-2 ${item.section_id == 1
                                      ? "typ-green"
                                      : item.section_id == 2
                                        ? "typ-blue"
                                        : item.section_id == 3
                                          ? "typ-pink"
                                          : item.section_id == 4
                                            ? "typ-orange"
                                            : "typ-orange"
                                      }`}
                                  >
                                    {/* {item.show_plan_detail == 1 ? (
                                      <span class="icon icon-right-table" />
                                    ) : (
                                      <span class="icon icon-minus-table" />
                                    )} */}
                                    {(index+1)== 1 || (index+1)== 2 ? '30 days' :
                                     item.show_plan_detail == 1 ? (
                                      <span class="icon icon-right-table" />
                                    ) : (
                                      <span class="icon icon-minus-table" />
                                    )}
                                  </div>
                                )}
                                {item.plan_id2 && (
                                  <div
                                    className={`tab-col tab-col-2 ${item.section_id2 == 1
                                      ? "typ-green"
                                      : item.section_id2 == 2
                                        ? "typ-blue"
                                        : item.section_id2 == 3
                                          ? "typ-pink"
                                          : item.section_id2 == 4
                                            ? "typ-orange"
                                            : "typ-orange"
                                      }`}
                                  >
                                    {/* {item.id == item.plan_detail_id2 ? ( */}
                                    {/* {item.show_plan_detail2 == 1 ? (
                                      <span class="icon icon-right-table" />
                                    ) : (
                                      <span class="icon icon-minus-table" />
                                    )} */}
                                    {(index+1)== 1 || (index+1)== 2 ? '90 days' :
                                     item.show_plan_detail2 == 1 ? (
                                      <span class="icon icon-right-table" />
                                    ) : (
                                      <span class="icon icon-minus-table" />
                                    )}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                          {planDetail.map((item, i) => {
                            return i == 0 ? (
                              <div className="bottom-footer tab-row" key={item}>
                                <div className="tab-col tab-col-1">
                                  <p className="title">Offer Price</p>
                                </div>
                                {item.plan_id && (
                                  <div className="tab-col tab-col-2">
                                    <div className="price-box">
                                      <p className="mrp">
                                        ₹{Math.round(item.price)}
                                      </p>
                                      <p className="price" id="plan1-subtotal">
                                        {Math.round(item.subtotal)}
                                      </p>
                                    </div>
                                    <p className="dicount">
                                      {Math.round(item.discount)}%
                                    </p>
                                    {paymentStatus === "No" ? (
                                      <Button
                                        onClick={() =>
                                          plan_one_order(
                                            Math.round(item.price),
                                            Math.round(item.discount),
                                            Math.round(item.subtotal)
                                          )
                                        }
                                        className="bs-btn typ-check-score"
                                      >
                                        Enroll
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )}
                                {item.plan_id2 && (
                                  <div className="tab-col tab-col-3">
                                    <div className="price-box">
                                      <p className="mrp">
                                        ₹{Math.round(item.price2)}
                                      </p>
                                      <p className="price" id="plan2-subtotal">
                                        {Math.round(item.subtotal2)}
                                      </p>
                                    </div>
                                    <p className="dicount">
                                      {Math.round(item.discount2)}%
                                    </p>
                                    {paymentStatus === "No" ? (
                                      <Button
                                        onClick={() =>
                                          plan_two_order(
                                            Math.round(item.price2),
                                            Math.round(item.discount2),
                                            Math.round(item.subtotal2)
                                          )
                                        }
                                        className="bs-btn typ-check-score"
                                      >
                                        Enroll
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )}
                              </div>
                            ) : (
                              ""
                            );
                          })}
                        </div>
                        <div className="bs-form typ-coupon-code">
                          <form>
                            <FormGroup className="text-box">
                              <Label htmlFor="name">Apply Coupon Code : </Label>
                              <Input
                                type="text"
                                className="couponcode"
                                autoComplete="off"
                                placeholder="Enter Coupon"
                                onChange={(e) => {
                                  setCouponCode(e.target.value);
                                }}
                              />
                              {/* <Input type="text" className='couponcode' autoComplete="off" placeholder="Enter Coupon"   onChange={(coupon) => handleChange(coupon)} /> */}

                              {/* <Link className="cm-para typ-link typ-underline" onClick={formPopup}>
          Feedback & Complaints
        </Link> */}
                              <Button
                                className="bs-btn"
                                onClick={() => getCouponsForPlan()}
                              >
                                Apply
                              </Button>
                            </FormGroup>
                          </form>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {/* <Row className="mob-mb-80">
                    <Button className='bs-btn typ-check-score typ-fix-width'>
                      Enroll me to Improve My Score
                    </Button>
                  </Row> */}
                </Container>
              )}
            <Modal
              isOpen={modal}
              toggle={formPopup}
              centered={true}
              backdrop={true}
            >
              <div className="bs-custom-popup typ-no-bg show">
                <div className="bs-card typ-popup">
                  <div className="bs-heading typ-full-width typ-center">
                    <h2 className="heading">Update Contact Details</h2>
                    <p className="subheading">
                      Looks like you’ve filled the assessment for your relative.
                      Please enter your relatives contact details.
                    </p>
                  </div>
                  <form className="bs-form">
                    <FormGroup className="text-box">
                      <Label htmlFor="relative">Phone number</Label>
                      <Input
                        type="text"
                        name="name"
                        autoComplete="off"
                        placeholder="Enter Relative's Number"
                      />
                      {/* <Link to={{homePageUrl}} className={'small'}>Edit</Link> */}
                    </FormGroup>
                    <Button className="bs-btn">Submit</Button>
                  </form>
                  <a className="close close-popup" onClick={formPopup}>
                    <span class="icon icon-check-close"></span>
                  </a>
                </div>
              </div>
            </Modal>
          </>
        )}

      {/* <Container>
        <div className="bs-card typ-mob-height-auto typ-pl-on">
          <Row className="align-items-center">
            <Col
              xl={{ offset: 6, size: 6 }}
              lg={{ offset: 6, size: 6 }}
              md="12"
            >
              <div className="bs-heading typ-center">
                <h2 className="heading typ-full-width">
                  How can I improve my score?
                </h2>
              </div>
            </Col>
            <Col xl="6" lg="6" md="12">
              <div className="bs-popup typ-max-wid">
                <img
                  className="popup-img"
                  src={MadhavBaug}
                  alt="multi-color-band"
                />
                <span className="icon icon-yt-play"></span>
              </div>
            </Col>
            <Col xl="6" lg="6" md="12">
              <p className="cm-para typ-bold typ-center">
                We will create a customised plan to help you improve your score
              </p>
              <div className="bs-heading typ-center">
                <p className="subheading">
                  We will be focusing on Heart disease at the start of your
                  plan, you can rearrange it if you wish to
                </p>
              </div>
              <div className="bs-drag">
                <ul>
                  <li>
                    <span className="icon icon-heart-disease"></span>
                    Heart disease
                  </li>
                  <li>
                    <span className="icon icon-iron-man"></span>
                    Breathlessness and Heavy chest pain
                  </li>
                  <li>
                    <span className="icon icon-band-aid"></span>
                    Wound not healing
                  </li>
                  <li>
                    <span className="icon icon-diabetes"></span>
                    Diabetes
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container className="d-none">
        <Row>
          <Col xl="12" lg="12" md="12">
            <div className="text-center">
              <Button className="bs-btn typ-fix-width mt-25 mob-mb-25">
                Start my Free Plan now
              </Button>
            </div>
          </Col>
        </Row>
      </Container> */}
      {/* <div className="only-desk"> */}
      <Footer />
      {console.log(loading)}
      {loading ? (
        <div className={`bs-spinner show`}>
          <Spinner>
            Loading...
          </Spinner>
        </div>
      ) : null}
      {/* </div> */}
      {/* <RoutingArrow prev={`${homePageUrl}`} next={`${homePageUrl}select-patient`} /> */}
    </div>
  );
}

export default HealthScore;
