import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import OtpInput from "react-otp-input";
import axios from 'axios';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';


//Constant
import { homePageUrl, smsApi, addUserApi, sendWhatsappApi ,saveGeneralDetails ,saveOtpVerfication,userDetailApi, getUserDetailByActivityId} from '../../../helper/ContentConstants';
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';
// Import Swiper styles
import 'swiper/css';

// css
import '../../../assets/sass/custom.scss';


// components
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useLocation,useNavigate } from "react-router-dom";
// import fair from "../../../assets/images/whatsapp-images/Fair.jpg";
// import poor from "../../../assets/images/whatsapp-images/Poor.jpg";
// import good from "../../../assets/images/whatsapp-images/Good.jpg";
// import veryGood from "../../../assets/images/whatsapp-images/VeryGood.jpg";
// import excellent from "../../../assets/images/whatsapp-images/Excellent.jpg";

import fair from "../../../assets/images/whatsapp-images/Fair.png";
import poor from "../../../assets/images/whatsapp-images/Poor.png";
import good from "../../../assets/images/whatsapp-images/Good.png";
import veryGood from "../../../assets/images/whatsapp-images/VeryGood.png";
import excellent from "../../../assets/images/whatsapp-images/Excellent.png";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'

function MeditationVerify() {
    const location = useLocation();
    let navigate = useNavigate();
    let [otp, setOtp] = useState('');
    let [ui, setUi] = useState('');
    let [data, setData] = useState(0)
    let [errorMessage, setErrorMessage] = useState('');
    let [mobileNumber, setMobileNumber] = useState('')
    let [imagePath, setImagePath] = useState('')
    let [showEdit, setShowEdit] = useState(false)
    let [numbererrorMessage, setNumberErrorMessage] = useState('');
    let [success,setSuccess] = useState(false);
    let [userMobileNumber,setUserMobileNumber] = useState('');
    const handleChange = (otp1) => {
        // setErrorMessage('');
        console.log("value is >>",otp1)
        setUi(otp1);
        
    };
    useEffect(() => {
        // return ()=>{
        // sendOtp();
        // }
        getUserData();
    }, []);

    const getUserData = async () => {
        try {
          let uid;
            uid = location.state.activityId;
          // console.log("params value is >>",location.state);
          console.log("params value is >>",location.state.activityId);
          // setUserValue(2751000);
          const formData = new FormData();
          formData.append("id", location.state.activityId);
          const response = await axios({
            method: "post",
            url: getUserDetailByActivityId,
            data: formData,
          });
          if (response.data.statusCode == 200) {
            setTimeout(() => {
                console.log("Response is >>",response.data);
                setUserMobileNumber(response.data.users.mobile);
                sendOtp(response.data.users.mobile);
            }, 3000);
          }
        } catch (error) {
          // console.log("error is ", error);
        }
      };
    const validateNext = () =>{
        console.log("Otp is >>",otp)
        if(ui.length == 4)
        {
            if(ui != otp)
            {
                toastr.options = {
                    positionClass : 'toast-top-full-width',
                    hideDuration: 300,
                    timeOut: 2000
                  }
                  toastr.clear()
                  setTimeout(() => toastr.error('Invalid otp'), 200)
            }
            else {
                toastr.options = {
                    positionClass : 'toast-top-full-width',
                    hideDuration: 300,
                    timeOut: 2000
                  }
                  toastr.clear()
                  setTimeout(() => toastr.success('otp verification done'), 200)
                  window.localStorage.setItem("meditation_verify_user", JSON.stringify(true));
                  navigate(`${homePageUrl}meditation-video`);
                // SaveGeneralDetail()
                // OtpVerfication()
            }
        }
        else if(ui == "")
        {
            console.log("Step2");
            toastr.options = {
                positionClass : 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 2000
              }
              toastr.clear()
              setTimeout(() => toastr.error('OTP field cannot be blank'), 200)
        }
        else if(ui.length < 4)
        {
            toastr.options = {
                positionClass : 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 2000
            }
            toastr.clear()
            setTimeout(() => toastr.error('Please enter proper otp'), 200)
        }
        else
        {
            toastr.options = {
                positionClass : 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 2000
              }
              toastr.clear()
              setTimeout(() => toastr.error('Invalid otp'), 200)
        }
    }
    const sendOtp = async (mobileNumber) => {
        
        const formatted_mobile_number = `91${mobileNumber}`
        // const formatted_mobile_number = "919892716501"
        try {
            const formData = new FormData()
            formData.append('mobile', formatted_mobile_number)
            const response = await axios({
                method: "post",
                url: smsApi,
                data: formData,
            });
            // console.log("Response is >>", response);
            if (response.data.statusCode == 200) {
                setTimeout(()=>{
                    setOtp(response.data.otp);
                    setSuccess(true)
                toastr.options = {
                    positionClass : 'toast-top-full-width',
                    hideDuration: 300,
                    timeOut: 2000
                  }
                  toastr.clear()
                  setTimeout(() => toastr.success('OTP Send Successfully'), 200)
                });
                
                //   sendWhatsapp();
                //   navigate(`${homePageUrl}health-score`);
            }
        } catch (error) {
            // console.log("error is ", error)
        }
    }


    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{x: "-100%", opacity: 0 }} className='bs-card'>
                    <Row className='align-items-center'>
                        <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                            {
                                isMobile &&
                                <div className="bs-heading typ-center">
                                    <h2 className="heading">
                                        OTP Verification
                                    </h2>
                                </div>
                            }
                        </Col>
                        <Col xl="6" lg="6" md="12">
                            <div className="bs-img-sprite typ-otp-verification bg-1">
                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="12">
                            {
                                isBrowser &&
                                <div className="bs-heading typ-center">
                                    <h2 className="heading">
                                        OTP Verification
                                    </h2>
                                </div>
                            }

                            {showEdit ?
                                <form className="bs-form">
                                    <FormGroup className='text-box'>
                                        <Label htmlFor="relative">Enter Your Whatsapp No.</Label>
                                        {/* <div className="input-with-btn">
                                            <Input  onChange={(number) => handleNumberChange(number)} type="text" name='relative' />
                                            <span onClick={() => updateNumber()} class="icon icon-next-arrow"></span>
                                        </div> */}
                                        {numbererrorMessage && (
                                            <p className="error"> {numbererrorMessage} </p>
                                        )}

                                        {/* <p className='error-message'>{formErrors.relative}</p> */}
                                    </FormGroup>
                                </form>
                                :
                                <div>
                                    <p className="cm-para typ-medium typ-center typ-mob-left typ-max-wid mb-10">
                                        Enter 4-digit OTP sent to +91-{userMobileNumber != null && userMobileNumber}
                                    </p>
                                    <div className="bs-form typ-otp">
                                        <OtpInput className='otp-input'
                                            value={ui}
                                            onChange={(otp12) => handleChange(otp12)}
                                            inputStyle={{
                                                width: "40px",
                                                height: "40px",
                                            }}
                                        />
                                    </div>
                                    {errorMessage && (
                                        <p className="error"> {errorMessage} </p>
                                    )}
                                    <p className="cm-para typ-medium typ-center typ-mob-left typ-max-wid mb-10">
                                        Didn’t receive an OTP?
                                    </p>
                                    {/* <a href="#" className="cm-para typ-center typ-mob-left typ-link">
                                        Resend OTP
                                    </a> */}
                                    <button className='cm-para typ-center typ-mob-left typ-link' onClick={() => sendOtp()}>Resend OTP</button>
                                    <button className='bs-btn' onClick={() => validateNext()}>Verify</button>
                                </div>
                            }
                        </Col>
                    </Row>
                </motion.div>
                </AnimationWrapper>
            </Container>
            <Footer />
        </div>
    )
}


export default MeditationVerify;