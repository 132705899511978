import React, { useState, useEffect } from 'react';
import { Container, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// css
import '../../../assets/sass/custom.scss';

// helper 
import handleLottie from '../../../helper/lottieFunction';

// json 
// male below 35 
import defaultExersiceMaleBelow35 from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import neverExerciseMaleBelow35 from '../../../assets/json/exercise/male/below35/never.json';
import twoInWeekExerciseMaleBelow35 from '../../../assets/json/exercise/male/below35/2-times-a-week.json';
import threeInWeekExerciseMaleBelow35 from '../../../assets/json/exercise/male/below35/3-times-a-week.json';
import fourInWeekExerciseMaleBelow35 from '../../../assets/json/exercise/male/below35/4-times-a-week.json';
import fiveInWeekExerciseMaleBelow35 from '../../../assets/json/exercise/male/below35/5-6-times-a-week.json';
import dailyExerciseMaleBelow35 from '../../../assets/json/exercise/male/below35/daily.json';

// male above 35 
import defaultExersiceMaleAbove35 from '../../../assets/json/default/male/above35/none-of-the-option-is-selected.json';
import neverExerciseMaleAbove35 from '../../../assets/json/exercise/male/above35/never.json';
import twoInWeekExerciseMaleAbove35 from '../../../assets/json/exercise/male/above35/2-times-a-week.json';
import threeInWeekExerciseMaleAbove35 from '../../../assets/json/exercise/male/above35/3-times-a-week.json';
import fourInWeekExerciseMaleAbove35 from '../../../assets/json/exercise/male/above35/4-times-a-week.json';
import fiveInWeekExerciseMaleAbove35 from '../../../assets/json/exercise/male/above35/5-6-times-a-week.json';
import dailyExerciseMaleAbove35 from '../../../assets/json/exercise/male/above35/daily.json';

// male above 72 
import defaultExersiceMaleAbove72 from '../../../assets/json/default/male/above72/none-of-the-option-is-selected.json';
import neverExerciseMaleAbove72 from '../../../assets/json/exercise/male/above72/never.json';
import twoInWeekExerciseMaleAbove72 from '../../../assets/json/exercise/male/above72/2-times-a-week.json';
import threeInWeekExerciseMaleAbove72 from '../../../assets/json/exercise/male/above72/3-times-a-week.json';
import fourInWeekExerciseMaleAbove72 from '../../../assets/json/exercise/male/above72/4-times-a-week.json';
import fiveInWeekExerciseMaleAbove72 from '../../../assets/json/exercise/male/above72/5-6-times-a-week.json';
import dailyExerciseMaleAbove72 from '../../../assets/json/exercise/male/above72/daily.json';

// // female below 35 
import defaultExersiceFemaleBelow35 from '../../../assets/json/default/female/below35/none-of-the-option-is-selected.json';
import neverDrinkFemaleBelow35 from '../../../assets/json/exercise/female/below35/never.json';
import twoInWeekExerciseFemaleBelow35 from '../../../assets/json/exercise/female/below35/2-times-a-week.json';
import threeInWeekExerciseFemaleBelow35 from '../../../assets/json/exercise/female/below35/3-times-a-week.json';
import fourInWeekExerciseFemaleBelow35 from '../../../assets/json/exercise/female/below35/4-times-a-week.json';
import fiveInWeekExerciseFemaleBelow35 from '../../../assets/json/exercise/female/below35/5-6-times-a-week.json';
import dailyExerciseFemaleBelow35 from '../../../assets/json/exercise/female/below35/daily.json';

// // female above 35 
import defaultExersiceFemaleAbove35 from '../../../assets/json/default/female/above35/none-of-the-option-is-selected.json';
import neverDrinkFemaleAbove35 from '../../../assets/json/exercise/female/above35/never.json';
import twoInWeekExerciseFemaleAbove35 from '../../../assets/json/exercise/female/above35/2-times-a-week.json';
import threeInWeekExerciseFemaleAbove35 from '../../../assets/json/exercise/female/above35/3-times-a-week.json';
import fourInWeekExerciseFemaleAbove35 from '../../../assets/json/exercise/female/above35/4-times-a-week.json';
import fiveInWeekExerciseFemaleAbove35 from '../../../assets/json/exercise/female/above35/5-6-times-a-week.json';
import dailyExerciseFemaleAbove35 from '../../../assets/json/exercise/female/above35/daily.json';

// // female above 72
import defaultExersiceFemaleAbove72 from '../../../assets/json/default/female/above72/none-of-the-option-is-selected.json';
import neverDrinkFemaleAbove72 from '../../../assets/json/exercise/female/above72/never.json';
import twoInWeekExerciseFemaleAbove72 from '../../../assets/json/exercise/female/above72/2-times-a-week.json';
import threeInWeekExerciseFemaleAbove72 from '../../../assets/json/exercise/female/above72/3-times-a-week.json';
import fourInWeekExerciseFemaleAbove72 from '../../../assets/json/exercise/female/above72/4-times-a-week.json';
import fiveInWeekExerciseFemaleAbove72 from '../../../assets/json/exercise/female/above72/5-6-times-a-week.json';
import dailyExerciseFemaleAbove72 from '../../../assets/json/exercise/female/above72/daily.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
function Exercise() {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate()

    let [paginationNo, setPaginationNo] = useState(1)

    let [averageSleep, setAverageSleep] = useState('')
    let [averageExcercise, setAverageExcercise] = useState('')
    let [averageVegetableIntake, setAverageVegetableIntake] = useState('')
    let [language, setLanguage] = useState("")
    const [lottiePlayer, setLottiePlayer] = useState("");

    let [errorMsg, setErrorMsg] = useState({ sleephour: "", excercise: "", vegetableInTake: "" })

    let contentData = {
        qId: 5,
        question_text: t('LifeStyleAssessment.Exercise.question_text'),
        descriptions: t('LifeStyleAssessment.Exercise.descriptions'),
        options: [
            {
                qId: 5,
                option_id: 1,
                option_text: t('LifeStyleAssessment.Exercise.option_text'),
                imgClass: 'bg-2',
                radio_button: [
                    {
                        rId: 1, radio_text: t('LifeStyleAssessment.Exercise.rId1'), gender: {
                            Male: {
                                default: {
                                    below35: defaultExersiceMaleBelow35,
                                    above35: defaultExersiceMaleAbove35,
                                    above72: defaultExersiceMaleAbove72,
                                },
                                below35: neverExerciseMaleBelow35,
                                above35: neverExerciseMaleAbove35,
                                above72: neverExerciseMaleAbove72
                            },
                            Female: {
                                default: {
                                    below35: defaultExersiceFemaleBelow35,
                                    above35: defaultExersiceFemaleAbove35,
                                    above72: defaultExersiceFemaleAbove72,
                                },
                                below35: neverDrinkFemaleBelow35,
                                above35: neverDrinkFemaleAbove35,
                                above72: neverDrinkFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 2, radio_text: t('LifeStyleAssessment.Exercise.rId2'), gender: {
                            Male: {
                                below35: twoInWeekExerciseMaleBelow35,
                                above35: twoInWeekExerciseMaleAbove35,
                                above72: twoInWeekExerciseMaleAbove72
                            },
                            Female: {
                                below35: twoInWeekExerciseFemaleBelow35,
                                above35: twoInWeekExerciseFemaleAbove35,
                                above72: twoInWeekExerciseFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 3, radio_text: t('LifeStyleAssessment.Exercise.rId3'), gender: {
                            Male: {
                                below35: threeInWeekExerciseMaleBelow35,
                                above35: threeInWeekExerciseMaleAbove35,
                                above72: threeInWeekExerciseMaleAbove72
                            },
                            Female: {
                                below35: threeInWeekExerciseFemaleBelow35,
                                above35: threeInWeekExerciseFemaleAbove35,
                                above72: threeInWeekExerciseFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 4, radio_text: t('LifeStyleAssessment.Exercise.rId4'), gender: {
                            Male: {
                                below35: fourInWeekExerciseMaleBelow35,
                                above35: fourInWeekExerciseMaleAbove35,
                                above72: fourInWeekExerciseMaleAbove72
                            },
                            Female: {
                                below35: fourInWeekExerciseFemaleBelow35,
                                above35: fourInWeekExerciseFemaleAbove35,
                                above72: fourInWeekExerciseFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 5, radio_text: t('LifeStyleAssessment.Exercise.rId5'), gender: {
                            Male: {
                                below35: fiveInWeekExerciseMaleBelow35,
                                above35: fiveInWeekExerciseMaleAbove35,
                                above72: fiveInWeekExerciseMaleAbove72
                            },
                            Female: {
                                below35: fiveInWeekExerciseFemaleBelow35,
                                above35: fiveInWeekExerciseFemaleAbove35,
                                above72: fiveInWeekExerciseFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 6, radio_text: t('LifeStyleAssessment.Exercise.rId6'), gender: {
                            Male: {
                                below35: dailyExerciseMaleBelow35,
                                above35: dailyExerciseMaleAbove35,
                                above72: dailyExerciseMaleAbove72
                            },
                            Female: {
                                below35: dailyExerciseFemaleBelow35,
                                above35: dailyExerciseFemaleAbove35,
                                above72: dailyExerciseFemaleAbove72
                            }
                        }
                    },
                ]
            }
        ]
    }

    const handleExercise = (e, key) => {
        console.log("key is here >>", key);
        let ha;
        if (key == 1) {
            ha = "Never";
        }
        else if (key == 2) {
            ha = "2 times a week";
        }
        else if (key == 3) {
            ha = "3 times a week"
        }
        else if (key == 4) {
            ha = "4 times a week"
        }
        else if (key == 5) {
            ha = "5 to 6 times a week";
        }
        else if (key == 6) {
            ha = "Daily";
        }
        averageExcercise = contentData.options[0].radio_button[key - 1].radio_text;
        setAverageExcercise(averageExcercise)
        setErrorMsg({ excercise: "" })
        window.localStorage.setItem('excercise', JSON.stringify(ha))
        window.localStorage.setItem('excercise1', JSON.stringify(averageExcercise))
        let lottie = handleLottie(key, contentData)
        setLottiePlayer(lottie);

    }

    const handleSubmit = () => {
        const excercise = window.localStorage.getItem('excercise')
        if (excercise) {
            setErrorMsg({ excercise: "" })
            navigate(`${homePageUrl}vegetable-in-take`)
        }
        else {
            setErrorMsg({ excercise: t('Messages.LifestyleAssessment.1') })
        }

    }

    const handleNavigationLottie = () => {
        let excercise = window.localStorage.getItem('excercise')
        let excercise1 = window.localStorage.getItem('excercise1')
        setAverageExcercise(JSON.parse(excercise))

        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        if (excercise == null) {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            console.log(lottie)
            setLottiePlayer(lottie);
        } else {
            excercise = excercise.replaceAll('"', '')
            excercise1 = excercise1.replaceAll('"', '')
            if (JSON.parse(window.localStorage.getItem('lang')) == "en") {
                let id = contentData.options[0].radio_button.find((rep) => rep.radio_text == excercise).rId
                handleExercise(1, id)
            }
            else {
                let id = contentData.options[0].radio_button.find((rep) => rep.radio_text == excercise1).rId
                handleExercise(1, id)
            }
        }
    }

    //get local storage
    useEffect(() => {
        const excercise = window.localStorage.getItem('excercise')
        setAverageExcercise(JSON.parse(excercise))
        if (JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en") {
            let lang = "en";
            handleClick(lang);
        }
        else {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
        handleNavigationLottie()
    }, [])

    const handleClick = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t)
        setLanguage(lang);
    }


    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className="align-items-center">
                            <Col xl={{ offset: 6, size: 6 }} lg={{ offset: 6, size: 6 }} md="12">
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }

                                <div>
                                    <p className='cm-para typ-medium typ-center typ-form-title typ-mob-left typ-max-wid'>
                                        {contentData.options[0].option_text}
                                    </p>
                                    <div className='bs-form mt-0'>
                                        <FormGroup className='radio-box typ-timeline' tag="fieldset">
                                            {contentData.options[0].radio_button.map((item) => {
                                                return (
                                                    <React.Fragment key={item.rId}>
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input type="radio"
                                                                    name="exercise"
                                                                    checked={item.radio_text === averageExcercise}
                                                                    value={item.radio_text}
                                                                    onChange={(e) => handleExercise(e, item.rId)} />{' '}
                                                                <div className='check-box-label'>
                                                                    <div className='circle-box'>
                                                                        <div className='circle'></div>
                                                                    </div>
                                                                    <p className='radio-txt'>
                                                                        {item.radio_text}
                                                                    </p>
                                                                </div>
                                                            </Label>
                                                        </FormGroup>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </FormGroup>
                                        {errorMsg.excercise && <p className='error-message'>{errorMsg.excercise}</p>}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <Footer />
            <RoutingArrow prev={`${homePageUrl}sleep-hours`} submit next={handleSubmit} />
        </div>
    )
}

export default Exercise;