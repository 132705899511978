import React, { useState, useEffect } from "react";
import _range from "lodash/range";
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images
import Right from '../../../assets/images/select-language/right.svg';

// css
import '../../../assets/sass/custom.scss';

// helper 
import handleLottie from '../../../helper/lottieFunction';

// json 
// male below 35 
import defaultExersiceMaleBelow35 from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import waist1MaleBelow35 from '../../../assets/json/waist/male/below35/waist-1.json';
import waist2MaleBelow35 from '../../../assets/json/waist/male/below35/waist-2.json';
import waist3MaleBelow35 from '../../../assets/json/waist/male/below35/waist-3.json';

// male above 35 
import defaultExersiceMaleAbove35 from '../../../assets/json/default/male/above35/none-of-the-option-is-selected.json';
import waist1MaleAbove35 from '../../../assets/json/waist/male/above35/waist-1.json';
import waist2MaleAbove35 from '../../../assets/json/waist/male/above35/waist-2.json';
import waist3MaleAbove35 from '../../../assets/json/waist/male/above35/waist-3.json';

// male above 72 
import defaultExersiceMaleAbove72 from '../../../assets/json/default/male/above72/none-of-the-option-is-selected.json';
import waist1MaleAbove72 from '../../../assets/json/waist/male/above72/waist-1.json';
import waist2MaleAbove72 from '../../../assets/json/waist/male/above72/waist-1.json';
import waist3MaleAbove72 from '../../../assets/json/waist/male/above72/waist-1.json';

// // female below 35 
import defaultExersiceFemaleBelow35 from '../../../assets/json/default/female/below35/none-of-the-option-is-selected.json';
import waist1FemaleBelow35 from '../../../assets/json/waist/female/below35/waist-1.json';
import waist2FemaleBelow35 from '../../../assets/json/waist/female/below35/waist-2.json';
import waist3FemaleBelow35 from '../../../assets/json/waist/female/below35/waist-3.json';

// // female above 35 
import defaultExersiceFemaleAbove35 from '../../../assets/json/default/female/above35/none-of-the-option-is-selected.json';
import waist1FemaleAbove35 from '../../../assets/json/waist/female/above35/waist-1.json';
import waist2FemaleAbove35 from '../../../assets/json/waist/female/above35/waist-2.json';
import waist3FemaleAbove35 from '../../../assets/json/waist/female/above35/waist-3.json';

// // female above 72
import defaultExersiceFemaleAbove72 from '../../../assets/json/default/female/above72/none-of-the-option-is-selected.json';
import waist1FemaleAbove72 from '../../../assets/json/waist/female/above72/waist-1.json';
import waist2FemaleAbove72 from '../../../assets/json/waist/female/above72/waist-2.json';
import waist3FemaleAbove72 from '../../../assets/json/waist/female/above72/waist-3.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function WaistSize() {
    const { t, i18n } = useTranslation();

    let navigate = useNavigate()
    let [language, setLanguage] = useState("")
    let [averageWaistSize, setAverageWaistSize] = useState('')
    let [errorMsg, setErrorMsg] = useState("")
    const [lottiePlayer, setLottiePlayer] = useState("");

    let contentData = {
        qId: 6,
        question_text: t('WaistSize.question_text'),
        descriptions: [t('WaistSize.descriptions')],
        options: [
            {
                qId: 6,
                option_id: 1,
                option_text: t('WaistSize.option_text'),
                imgClass: 'bg-1',
                radio_button: [
                    {
                        rId: 1, condition: "under 28", radio_text: t('WaistSize.rId1'), gender: {
                            Male: {
                                default: {
                                    below35: defaultExersiceMaleBelow35,
                                    above35: defaultExersiceMaleAbove35,
                                    above72: defaultExersiceMaleAbove72,
                                },
                                below35: waist1MaleBelow35,
                                above35: waist1MaleAbove35,
                                above72: waist1MaleAbove72
                            },
                            Female: {
                                default: {
                                    below35: defaultExersiceFemaleBelow35,
                                    above35: defaultExersiceFemaleAbove35,
                                    above72: defaultExersiceFemaleAbove72,
                                },
                                below35: waist1FemaleBelow35,
                                above35: waist1FemaleAbove35,
                                above72: waist1FemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 2, condition: "32.1-34", radio_text: `32.1” - 34”`, gender: {
                            Male: {
                                below35: waist2MaleBelow35,
                                above35: waist2MaleAbove35,
                                above72: waist2MaleAbove72
                            },
                            Female: {
                                below35: waist2FemaleBelow35,
                                above35: waist2FemaleAbove35,
                                above72: waist2FemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 3, condition: "28.1-30", radio_text: `28.1” - 30”`, gender: {
                            Male: {
                                below35: waist1MaleBelow35,
                                above35: waist1MaleAbove35,
                                above72: waist1MaleAbove72
                            },
                            Female: {
                                below35: waist1FemaleBelow35,
                                above35: waist1FemaleAbove35,
                                above72: waist1FemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 4, condition: "34.1-36", radio_text: `34.1” - 36”`, gender: {
                            Male: {
                                below35: waist3MaleBelow35,
                                above35: waist3MaleAbove35,
                                above72: waist3MaleAbove72
                            },
                            Female: {
                                below35: waist3FemaleBelow35,
                                above35: waist3FemaleAbove35,
                                above72: waist3FemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 5, condition: "30.1-32", radio_text: `30.1” - 32”`, gender: {
                            Male: {
                                below35: waist2MaleBelow35,
                                above35: waist2MaleAbove35,
                                above72: waist2MaleAbove72
                            },
                            Female: {
                                below35: waist2FemaleBelow35,
                                above35: waist2FemaleAbove35,
                                above72: waist2FemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 6, condition: "above 36.1", radio_text: t('WaistSize.rId2'), gender: {
                            Male: {
                                below35: waist3MaleBelow35,
                                above35: waist3MaleAbove35,
                                above72: waist3MaleAbove72
                            },
                            Female: {
                                below35: waist3FemaleBelow35,
                                above35: waist3FemaleAbove35,
                                above72: waist3FemaleAbove72
                            }
                        }
                    }
                ]
            }
        ]
    }

    const handleWaistSize = (e, key) => {
        let hws;
        averageWaistSize = contentData.options[0].radio_button[key-1].condition;
        if(key == 1)
        {
            hws = "under 28";
        }
        else if(key == 6)
        {
            hws = "above 36.1";
        }
        else
        {
            hws = averageWaistSize;
        }
        
        setErrorMsg("")
        window.localStorage.setItem('waist-size', JSON.stringify(hws))
        window.localStorage.setItem('waist-size1', JSON.stringify(averageWaistSize))
        let lottie = handleLottie(key, contentData)
        console.log(lottie);
        setLottiePlayer(lottie);
    }

    const handleSubmit = () => {
        const waistSize = JSON.parse(window.localStorage.getItem('waist-size'))
        if (waistSize) {
            setErrorMsg("")
            navigate(`${homePageUrl}weight`)
        }
        else {
            setErrorMsg(t('Messages.WaistSize.1'))
        }
    }

    const handleNavigationLottie = () => {
        let waistSize = JSON.parse(window.localStorage.getItem('waist-size'))
        let waistSize1 = JSON.parse(window.localStorage.getItem('waist-size1'))
        

        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        if (waistSize == null) {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
        } else {
            if(JSON.parse(window.localStorage.getItem('lang')) == "en")
            {
                setAverageWaistSize(waistSize);
                let id = contentData.options[0].radio_button.find((rep) => rep.condition == waistSize).rId
                handleWaistSize(1, id)
            }
            else
            {
                setAverageWaistSize(waistSize1);
                let id = contentData.options[0].radio_button.find((rep) => rep.condition == waistSize1).rId
                handleWaistSize(1, id)
            }
            waistSize = waistSize.replaceAll('"', '')
            
        }
    }

    useEffect(() => {
        if(JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en")
        {
            let lang = "en";
            handleClick(lang);
        }
        else
        {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
        handleNavigationLottie()
    }, [])

    const handleClick = (lang) => 
    {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>",lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>",t)
        setLanguage(lang);
    }

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card'>
                        <Row>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                                <p className="cm-para typ-medium typ-form-title typ-center typ-max-wid">
                                    {contentData.options[0].option_text}
                                </p>
                                <div className="bs-form mt-0">
                                    <FormGroup className="radio-box">
                                        {contentData.options[0].radio_button.map((item) => {
                                            return (
                                                <React.Fragment key={item.rId}>
                                                    <FormGroup className="check-box typ-btn text-center" check>
                                                        <Label check>
                                                            <Input type="radio" name="waistsize"
                                                                value={item.condition}
                                                                defaultChecked={item.condition === JSON.parse(window.localStorage.getItem('waist-size'))}
                                                                onChange={(e) => handleWaistSize(e, item.rId)}
                                                            />{' '}
                                                            <div className='checkbox-label'>
                                                                <img src={Right} className='right-icon' alt="Next-arrow" />
                                                                {item.radio_text}
                                                            </div>
                                                        </Label>
                                                    </FormGroup>
                                                </React.Fragment>
                                            )
                                        })}
                                    </FormGroup>
                                    {errorMsg && <p className="error-message">{errorMsg}</p>}
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prev={`${homePageUrl}stress`} submit next={handleSubmit} />
            <Footer />
        </div>
    );
}

export default WaistSize;