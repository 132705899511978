import React, { useState, useEffect } from "react";
import _range from "lodash/range";
import Picker from "react-scrollable-picker";
import { Container, Row, Col, Button } from 'reactstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// helper 
import handleLottie from '../../../helper/lottieFunction';

// css
import '../../../assets/sass/custom.scss';

// json 
// male below 35 
import defaultWeightMaleBelow35 from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import weight1MaleBelow35 from '../../../assets/json/weight/male/below35/weight-1.json';
import weight2MaleBelow35 from '../../../assets/json/weight/male/below35/weight-2.json';
import weight3MaleBelow35 from '../../../assets/json/weight/male/below35/weight-3.json';

// male above 35 
import defaultWeightMaleAbove35 from '../../../assets/json/default/male/above35/none-of-the-option-is-selected.json';
import weight1MaleAbove35 from '../../../assets/json/weight/male/above35/weight-1.json';
import weight2MaleAbove35 from '../../../assets/json/weight/male/above35/weight-2.json';
import weight3MaleAbove35 from '../../../assets/json/weight/male/above35/weight-3.json';

// male above 72 
import defaultWeightMaleAbove72 from '../../../assets/json/default/male/above72/none-of-the-option-is-selected.json';
import weight1MaleAbove72 from '../../../assets/json/weight/male/above72/weight-1.json';
import weight2MaleAbove72 from '../../../assets/json/weight/male/above72/weight-2.json';
import weight3MaleAbove72 from '../../../assets/json/weight/male/above72/weight-3.json';

// // female below 35 
import defaultWeightFemaleBelow35 from '../../../assets/json/default/female/below35/none-of-the-option-is-selected.json';
import weight1FemaleBelow35 from '../../../assets/json/weight/female/below35/weight-1.json';
import weight2FemaleBelow35 from '../../../assets/json/weight/female/below35/weight-2.json';
import weight3FemaleBelow35 from '../../../assets/json/weight/female/below35/weight-3.json';

// // female above 35 
import defaultWeightFemaleAbove35 from '../../../assets/json/default/female/above35/none-of-the-option-is-selected.json';
import weight1FemaleAbove35 from '../../../assets/json/weight/female/above35/weight-1.json';
import weight2FemaleAbove35 from '../../../assets/json/weight/female/above35/weight-2.json';
import weight3FemaleAbove35 from '../../../assets/json/weight/female/above35/weight-3.json';

// // female above 72
import defaultWeightFemaleAbove72 from '../../../assets/json/default/female/above72/none-of-the-option-is-selected.json';
import weight1FemaleAbove72 from '../../../assets/json/weight/female/above72/weight-1.json';
import weight2FemaleAbove72 from '../../../assets/json/weight/female/above72/weight-2.json';
import weight3FemaleAbove72 from '../../../assets/json/weight/female/above72/weight-3.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function Weight() {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate()

    const initialValues = { weight: 25 }
    let [contentValues, setContentValues] = useState(initialValues)
    let [errorMsg, setErrorMsg] = useState()
    const [lottiePlayer, setLottiePlayer] = useState("");
    let [events, setEvents] = useState(0)
    let contentData = {
        options: [
            {
                qId: 6,
                option_id: 1,
                option_text: 'How often do you consume alcohol?',
                imgClass: 'bg-2',
                radio_button: [
                    {
                        rId: 1, radio_text: 'Weight1', gender: {
                            Male: {
                                default: {
                                    below35: defaultWeightMaleBelow35,
                                    above35: defaultWeightMaleAbove35,
                                    above72: defaultWeightMaleAbove72,
                                },
                                below35: weight1MaleBelow35,
                                above35: weight1MaleAbove35,
                                above72: weight1MaleAbove72
                            },
                            Female: {
                                default: {
                                    below35: defaultWeightFemaleBelow35,
                                    above35: defaultWeightFemaleAbove35,
                                    above72: defaultWeightFemaleAbove72,
                                },
                                below35: weight1FemaleBelow35,
                                above35: weight1FemaleAbove35,
                                above72: weight1FemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 2, radio_text: 'Weight2', gender: {
                            Male: {
                                below35: weight2MaleBelow35,
                                above35: weight2MaleAbove35,
                                above72: weight2MaleAbove72
                            },
                            Female: {
                                below35: weight2FemaleBelow35,
                                above35: weight2FemaleAbove35,
                                above72: weight2FemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 3, radio_text: 'Weight3', gender: {
                            Male: {
                                below35: weight3MaleBelow35,
                                above35: weight3MaleAbove35,
                                above72: weight3MaleAbove72
                            },
                            Female: {
                                below35: weight3FemaleBelow35,
                                above35: weight3FemaleAbove35,
                                above72: weight3FemaleAbove72
                            }
                        }
                    },
                ]
            }
        ]
    }

    // Slider weight loop
    const createElementsWeight = (number) => {
        var elements = [];
        let i;
        for (i = 25; i < number; i++) {
            elements.push(<SwiperSlide key={i} index={i}>{i}</SwiperSlide>)
        }
        return elements;
    }

    //Swiper slide change for weight 
    const handleWeight = (event) => {
        setEvents(event.realIndex + 1 + 24)
        setErrorMsg("")
        let activeIndex = event.realIndex
        contentValues.userWeight = activeIndex + 1
        setContentValues({ userWeight: contentValues.userWeight })
        window.localStorage.setItem('weight', JSON.stringify(contentValues.userWeight + 24))
        console.log(contentValues.userWeight + 24)
    }

    const handleSubmit = () => {
        const weight = JSON.parse(window.localStorage.getItem('weight'))
        if (!weight) {
            setErrorMsg("Please select weight to continue")
        } else navigate(`${homePageUrl}height`)
    }

    const handleNavigationLottie = () => {
        let weight = JSON.parse(window.localStorage.getItem('weight'))
        setContentValues({ ...contentValues, weight: weight - 24 })

        let age = localStorage.getItem('age')
        let gender = localStorage.getItem('gender').replaceAll('"', '');
        if (weight == null) {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
        } else {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['below35']
            setLottiePlayer(lottie);
            if (contentValues.userWeight + 24 <= 75) {
                let lottie = contentData.options[0].radio_button.find(rep => rep.radio_text == 'Weight1').gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                setLottiePlayer(lottie);
            } else if (contentValues.userWeight + 24 > 75 && contentValues.userWeight + 24 <= 100) {
                console.log("35-45", contentValues.userWeight + 24)
                let lottie = contentData.options[0].radio_button.find(rep => rep.radio_text == 'Weight2').gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                setLottiePlayer(lottie);
            } else if (contentValues.userWeight + 24 > 100
            ) {
                console.log("35-45 above", contentValues.userWeight + 24)
                let lottie = contentData.options[0].radio_button.find(rep => rep.radio_text == 'Weight3').gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                setLottiePlayer(lottie);
            }
        }
    }

    useEffect(() => {
        if (JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en") {
            let lang = "en";
            handleClick(lang);
        }
        else {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
        handleNavigationLottie()
    }, [events])

    const handleClick = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t)
    }

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className='align-items-center'>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('BMIWeight.heading')}
                                        </h2>
                                        <p className="subheading">
                                            {t('BMIWeight.subheading')}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('BMIWeight.heading')}
                                        </h2>
                                        <p className="subheading">
                                            {t('BMIWeight.subheading')}
                                        </p>
                                    </div>
                                }
                                <div>
                                    <p className="cm-para typ-medium typ-form-title typ-center typ-max-wid">
                                        {t('BMIWeight.WhatIsYourWeight')}?
                                    </p>

                                    <div className='bs-swiper typ-weight'>

                                        <div className='swiper-parent'>
                                            <Swiper className='age-swiper'
                                                initialSlide={JSON.parse(window.localStorage.getItem('weight')) === null ? 60 - 25 : JSON.parse(window.localStorage.getItem('weight')) - 25}
                                                spaceBetween={0} grabCursor={false} slidesPerView={7} slideToClickedSlide={true} centeredSlides='true'
                                                breakpoints={{
                                                    767: {
                                                        slidesPerView: 5,
                                                    },
                                                    992: {
                                                        slidesPerView: 5,
                                                    },
                                                    1024: {
                                                        slidesPerView: 5,
                                                    },
                                                    1366: {
                                                        slidesPerView: 9,
                                                    },
                                                }} onSlideChange={e => handleWeight(e)}
                                            // onSwiper={getAge}
                                            >
                                                {createElementsWeight(251)}
                                            </Swiper>
                                        </div>
                                    </div>
                                    {errorMsg && <p className="error-message">{errorMsg}</p>}
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prev={`${homePageUrl}waist-size`} submit next={handleSubmit} />
            <Footer />
        </div>
    );
}

export default Weight;