import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, FormGroup, Input, Label, Button, Modal, ModalBody } from 'reactstrap';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';

// animation 
// import Aos from "aos";
// import "aos/dist/aos.css";

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images 
import MultiColorBand from '../../../assets/images/take-the-test/meter-desk.gif';
import MultiColorBandMob from '../../../assets/images/take-the-test/meter-mob.gif';

// css
import '../../../assets/sass/custom.scss';

// json 
import LottiFiles from '../../../assets/json/start-meter.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { animate } from 'framer-motion';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function ThankYou() {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    let [userId,setUserId] = useState("");
    useEffect(() => {
        console.log("params value is >>",location.state);
        if(location.state != null)
        {
            setUserId(location.state.userValue);
        }

        if(JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en")
        {
            let lang = "en";
            handleClick(lang);
        }
        else
        {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
      }, []);

    const handleClick = (lang) => 
    {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>",lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>",t)
    }

      {
        console.log("User id is >>",userId)
      }
    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-thank-you'>
                        <Row className='text-center'>
                            <Col xl={12} lg={12} md={12} sm={6}>
                                <div className="bs-heading typ-center typ-thank-you">
                                    <h2 className="heading">
                                    {t('ThankYou.ThankYou')}
                                    </h2>
                                        <p className="subheading">
                                        {t('ThankYou.YourSubscription')}
                                        </p>
                                </div>
                                {/* <Link className='bs-btn' to={'/subscription-home'} state= {{ userId: userId}}> */}
                                <Link className='bs-btn' to={`${homePageUrl}subscription-home`} state= {{ userId: userId}}>
                                {t('ThankYou.GoToDashboard')}
                                </Link>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <Footer />
        </div>
    )
}

export default ThankYou;