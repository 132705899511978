import React, { useState, useEffect } from "react";
import _range from "lodash/range";
import { Container, Row, Col, Button } from 'reactstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// css
import '../../../assets/sass/custom.scss';

// json 
// male below 35 
import defaultHeightMaleBelow35 from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import height1MaleBelow35 from '../../../assets/json/height/male/below35/2ft-0-inch.json';
import height2MaleBelow35 from '../../../assets/json/height/male/below35/5ft-3-inch.json';
import height3MaleBelow35 from '../../../assets/json/height/male/below35/8ft-6-inch.json';

// male above 35 
import defaultHeightMaleAbove35 from '../../../assets/json/default/male/above35/none-of-the-option-is-selected.json';
import height1MaleAbove35 from '../../../assets/json/height/male/above35/2ft-0-inch.json';
import height2MaleAbove35 from '../../../assets/json/height/male/above35/5ft-3-inch.json';
import height3MaleAbove35 from '../../../assets/json/height/male/above35/8ft-6-inch.json';

// male above 72 
import defaultHeightMaleAbove72 from '../../../assets/json/default/male/above72/none-of-the-option-is-selected.json';
import height1MaleAbove72 from '../../../assets/json/height/male/above72/2ft-0-inch.json';
import height2MaleAbove72 from '../../../assets/json/height/male/above72/5ft-3-inch.json';
import height3MaleAbove72 from '../../../assets/json/height/male/above72/8ft-6-inch.json';

// // female below 35 
import defaultHeightFemaleBelow35 from '../../../assets/json/default/female/below35/none-of-the-option-is-selected.json';
import height1FemaleBelow35 from '../../../assets/json/height/female/below35/height-2ft-0.json';
import height2FemaleBelow35 from '../../../assets/json/height/female/below35/height-5ft3-inch.json';
import height3FemaleBelow35 from '../../../assets/json/height/female/below35/height-above5-inch.json';

// // female above 35 
import defaultHeightFemaleAbove35 from '../../../assets/json/default/female/above35/none-of-the-option-is-selected.json';
import height1FemaleAbove35 from '../../../assets/json/height/female/above35/height-2ft0.json';
import height2FemaleAbove35 from '../../../assets/json/height/female/above35/height-5ft.json';
import height3FemaleAbove35 from '../../../assets/json/height/female/above35/height-above-5ft.json';

// // female above 72
import defaultHeightFemaleAbove72 from '../../../assets/json/default/female/above72/none-of-the-option-is-selected.json';
import height1FemaleAbove72 from '../../../assets/json/height/female/above72/height-1.json';
import height2FemaleAbove72 from '../../../assets/json/height/female/above72/height-2.json';
import height3FemaleAbove72 from '../../../assets/json/height/female/above72/height-3.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
function Height() {
    const { t, i18n } = useTranslation();

    const initialValues = { weight: 25, heightFt: 5, heightIn: 3 }
    let [contentValues, setContentValues] = useState(initialValues)
    const [lottiePlayer, setLottiePlayer] = useState("");
    const [events, setEvents] = useState(0);
    let contentData = {
        options: [
            {
                radio_button: [
                    {
                        rId: 1, radio_text: 'Short', gender: {
                            Male: {
                                default: {
                                    below35: defaultHeightMaleBelow35,
                                    above35: defaultHeightMaleAbove35,
                                    above72: defaultHeightMaleAbove72,
                                },
                                below35: height1MaleBelow35,
                                above35: height1MaleAbove35,
                                above72: height1MaleAbove72
                            },
                            Female: {
                                default: {
                                    below35: defaultHeightFemaleBelow35,
                                    above35: defaultHeightFemaleAbove35,
                                    above72: defaultHeightFemaleAbove72,
                                },
                                below35: height1FemaleBelow35,
                                above35: height1FemaleAbove35,
                                above72: height1FemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 2, radio_text: 'Medium', gender: {
                            Male: {
                                below35: height2MaleBelow35,
                                above35: height2MaleAbove35,
                                above72: height2MaleAbove72
                            },
                            Female: {
                                below35: height2FemaleBelow35,
                                above35: height2FemaleAbove35,
                                above72: height2FemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 3, radio_text: 'Large', gender: {
                            Male: {
                                below35: height3MaleBelow35,
                                above35: height3MaleAbove35,
                                above72: height3MaleAbove72
                            },
                            Female: {
                                below35: height3FemaleBelow35,
                                above35: height3FemaleAbove35,
                                above72: height3FemaleAbove72
                            }
                        }
                    },
                ]
            }
        ]
    }

    // Slider weight loop
    const createElementsHeightFt = (number) => {
        var elements = [];
        // let startVal = 3;
        // for (let i = (startVal + 0.1); i < number; i += 0.1) {
        //     elements.push(<SwiperSlide key={i} index={i}>{i}</SwiperSlide>)
        // }
        for (let i = 3; i < number; i++) {
            elements.push(<SwiperSlide key={i} index={i}>{i}</SwiperSlide>)
        }
        return elements;
    }
    // Slider weight loop
    const createElementsHeightIn = (number) => {
        var elements = [];
        let i;
        for (i = 0; i < number; i++) {
            elements.push(<SwiperSlide key={i} index={i}>{i}</SwiperSlide>)
        }
        return elements;
    }

    //Swiper slide change for weight 
    const handleHeightFt = (event) => {
        let activeIndex = event.realIndex
        contentValues.heightFt = activeIndex + 1
        setContentValues({ heightFt: contentValues.heightFt })
        window.localStorage.setItem('height-ft', JSON.stringify(contentValues.heightFt + 2))
        console.log((contentValues.heightFt + 2), "hi")
        setEvents((contentValues.heightFt + 2));
    }
    //Swiper slide change for weight 
    const handleHeightIn = (event) => {
        let activeIndex = event.realIndex
        contentValues.heightIn = activeIndex + 1
        setContentValues({ heightIn: contentValues.heightIn })
        window.localStorage.setItem('height-in', JSON.stringify(contentValues.heightIn - 1))
    }

    const handleNavigationLottie = () => {
        const heightFt = JSON.parse(window.localStorage.getItem('height-ft'))
        const heightIn = JSON.parse(window.localStorage.getItem('height-in'))
        setContentValues({ ...contentValues, heightFt: heightFt - 2, heightIn: heightIn })

        let age = localStorage.getItem('age');
        let gender = localStorage.getItem('gender').replaceAll('"', '');
        if (heightFt == null) {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['below35']
            setLottiePlayer(lottie);
        } else {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['below35']
            console.log(contentValues.heightFt + 2 <= 4, ((contentValues.heightFt + 2) >= 5) && ((contentValues.heightFt + 2) <= 6), (contentValues.heightFt + 2) > 6, contentValues.heightFt + 2)
            if (contentValues.heightFt + 2 <= 4) {
                console.log("-4", contentValues.heightFt + 2)
                let lottie = contentData.options[0].radio_button.find(rep => rep.radio_text == 'Short').gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                setLottiePlayer(lottie);
            } else if (((contentValues.heightFt + 2) >= 5) && ((contentValues.heightFt + 2) < 6)) {
                console.log("5-6", contentValues.heightFt + 2)
                let lottie = contentData.options[0].radio_button.find(rep => rep.radio_text == 'Medium').gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                setLottiePlayer(lottie);
            } else if ((contentValues.heightFt + 2) >= 6) {
                console.log("6+", contentValues.heightFt + 2)
                let lottie = contentData.options[0].radio_button.find(rep => rep.radio_text == 'Large').gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                setLottiePlayer(lottie);
            }
        }
    }


    useEffect(() => {
        if (JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en") {
            let lang = "en";
            handleClick(lang);
        }
        else {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
        handleNavigationLottie()
    }, [events])

    const handleClick = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t)
    }

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className='align-items-center'>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('BMIHeight.heading')}
                                        </h2>
                                        <p className="subheading">
                                            {t('BMIHeight.subheading')}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                    loop
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('BMIHeight.heading')}
                                        </h2>
                                        <p className="subheading">
                                            {t('BMIHeight.subheading')}
                                        </p>
                                    </div>
                                }
                                <div>
                                    <p className="cm-para typ-medium typ-form-title typ-center typ-max-wid">
                                        {t('BMIHeight.WhatIsYourHeight')}?
                                    </p>
                                    <div className='bs-swiper typ-height typ-feet mb-40'>
                                        <p className='slider-title'>
                                            {t('BMIHeight.Feet')}
                                        </p>
                                        <div className='bs-swiper typ-height typ-feet mb-40'>

                                            <div className='swiper-parent'>
                                                <Swiper
                                                    initialSlide={JSON.parse(window.localStorage.getItem('height-ft')) === null ? 5 - 3 : JSON.parse(window.localStorage.getItem('height-ft')) - 3}
                                                    className='age-swiper' spaceBetween={0} grabCursor={false} slidesPerView={7} slideToClickedSlide={true} centeredSlides='true'
                                                    breakpoints={{
                                                        767: {
                                                            slidesPerView: 5,
                                                        },
                                                        992: {
                                                            slidesPerView: 5,
                                                        },
                                                        1024: {
                                                            slidesPerView: 5,
                                                        },
                                                        1366: {
                                                            slidesPerView: 9,
                                                        },
                                                    }} onSlideChange={e => handleHeightFt(e)}
                                                // onSwiper={getAge}
                                                >
                                                    {createElementsHeightFt(8)}
                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bs-swiper typ-inch'>
                                        <p className='slider-title'>
                                            {t('BMIHeight.Inches')}
                                        </p>
                                        <div className='swiper-parent'>
                                            <Swiper className='age-swiper '
                                                initialSlide={JSON.parse(window.localStorage.getItem('height-in')) === null ? 12 - 6 : JSON.parse(window.localStorage.getItem('height-in'))}
                                                spaceBetween={0} grabCursor={false} slidesPerView={7} slideToClickedSlide={true} centeredSlides='true'
                                                breakpoints={{
                                                    767: {
                                                        slidesPerView: 5,
                                                    },
                                                    992: {
                                                        slidesPerView: 5,
                                                    },
                                                    1024: {
                                                        slidesPerView: 5,
                                                    },
                                                    1366: {
                                                        slidesPerView: 9,
                                                    },
                                                }} onSlideChange={e => handleHeightIn(e)}
                                            // onSwiper={getAge}
                                            >
                                                {createElementsHeightIn(13)}
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prev={`${homePageUrl}weight`} next={`${homePageUrl}halfway-done`} />
            <Footer />
        </div>
    );
}

export default Height;