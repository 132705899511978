import React, { useEffect, useState } from 'react';
import { Container, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// Import Swiper styles
import 'swiper/css';

// css
import '../../../assets/sass/custom.scss';

// json 
// male below 35 
import defaultDiseaseMaleBelow35 from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import happyMaleBelow35 from '../../../assets/json/expression/male/below35/happy.json';
import veryHappyMaleBelow35 from '../../../assets/json/expression/male/below35/very-happy.json';
import sadMaleBelow35 from '../../../assets/json/expression/male/below35/sad.json';
import verySadMaleBelow35 from '../../../assets/json/expression/male/below35/very-sad.json';

// male above 35 
import defaultDiseaseMaleAbove35 from '../../../assets/json/default/male/above35/none-of-the-option-is-selected.json';
import happyMaleAbove35 from '../../../assets/json/expression/male/above35/happy.json';
import veryHappyMaleAbove35 from '../../../assets/json/expression/male/above35/very-happy.json';
import sadMaleAbove35 from '../../../assets/json/expression/male/above35/sad.json';
import verySadMaleAbove35 from '../../../assets/json/expression/male/above35/very-sad.json';

// male above 72 
import defaultDiseaseMaleAbove72 from '../../../assets/json/default/male/above72/none-of-the-option-is-selected.json';
import happyMaleAbove72 from '../../../assets/json/expression/male/above72/happy.json';
import veryHappyMaleAbove72 from '../../../assets/json/expression/male/above72/very-happy.json';
import sadMaleAbove72 from '../../../assets/json/expression/male/above72/sad.json';
import verySadMaleAbove72 from '../../../assets/json/expression/male/above72/very-sad.json';

// // female below 35 
import defaultDiseaseFemaleBelow35 from '../../../assets/json/default/female/below35/none-of-the-option-is-selected.json';
import happyFemaleBelow35 from '../../../assets/json/expression/female/below35/happy.json';
import veryHappyFemaleBelow35 from '../../../assets/json/expression/female/below35/very-happy.json';
import sadFemaleBelow35 from '../../../assets/json/expression/female/below35/sad.json';
import verySadFemaleBelow35 from '../../../assets/json/expression/female/below35/v-sad.json';

// // female above 35 
import defaultDiseaseFemaleAbove35 from '../../../assets/json/default/female/above35/none-of-the-option-is-selected.json';
import happyFemaleAbove35 from '../../../assets/json/expression/female/above35/happy.json';
import veryHappyFemaleAbove35 from '../../../assets/json/expression/female/above35/very-happy.json';
import sadFemaleAbove35 from '../../../assets/json/expression/female/above35/sad.json';
import verySadFemaleAbove35 from '../../../assets/json/expression/female/above35/very-sad.json';

// // female above 72
import defaultDiseaseFemaleAbove72 from '../../../assets/json/default/female/above72/none-of-the-option-is-selected.json';
import happyFemaleAbove72 from '../../../assets/json/expression/female/above72/happy.json';
import veryHappyFemaleAbove72 from '../../../assets/json/expression/female/above72/very-happy.json';
import sadFemaleAbove72 from '../../../assets/json/expression/female/above72/sad.json';
import verySadFemaleAbove72 from '../../../assets/json/expression/female/above72/very-sad.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { calcLength } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function Symptoms() {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate()

    let [showModal, setShowModal] = useState(false)
    let [errorMsg, setErrorMsg] = useState("")

    let [frequentUrination, setFrequentUrination] = useState(false)
    let [excessiveHunger, setExcessiveHunger] = useState(false)
    let [woundNotHealing, setWoundNotHealing] = useState(false)
    let [weightLoss, setWeightLoss] = useState(false)
    let [weightGain, setWeightGain] = useState(false)
    let [jointPains, setJointPains] = useState(false)
    let [headache, setHeadache] = useState(false)
    let [weaknessOfFatigue, setWeaknessOfFatigue] = useState(false)
    let [constipation, setConstipation] = useState(false)
    let [sweating, setSweating] = useState(false)
    let [tingling, setTingling] = useState("")
    let [noSymptoms, setNoSymptoms] = useState(false)
    let [events, setEvents] = useState("")
    const [lottiePlayer, setLottiePlayer] = useState("");

    let contentData = {
        options: [
            {
                radio_button: [
                    {
                        rId: 1, radio_text: 'Happy', gender: {
                            Male: {
                                default: {
                                    below35: defaultDiseaseMaleBelow35,
                                    above35: defaultDiseaseMaleAbove35,
                                    above72: defaultDiseaseMaleAbove72,
                                },
                                below35: happyMaleBelow35,
                                above35: happyMaleAbove35,
                                above72: happyMaleAbove72
                            },
                            Female: {
                                default: {
                                    below35: defaultDiseaseFemaleBelow35,
                                    above35: defaultDiseaseFemaleAbove35,
                                    above72: defaultDiseaseFemaleAbove72,
                                },
                                below35: happyFemaleBelow35,
                                above35: happyFemaleAbove35,
                                above72: happyFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 2, radio_text: 'Very Happy', gender: {
                            Male: {
                                below35: veryHappyMaleBelow35,
                                above35: veryHappyMaleAbove35,
                                above72: veryHappyMaleAbove72
                            },
                            Female: {
                                below35: veryHappyFemaleBelow35,
                                above35: veryHappyFemaleAbove35,
                                above72: veryHappyFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 3, radio_text: 'Sad', gender: {
                            Male: {
                                below35: sadMaleBelow35,
                                above35: sadMaleAbove35,
                                above72: sadMaleAbove72
                            },
                            Female: {
                                below35: sadFemaleBelow35,
                                above35: sadFemaleAbove35,
                                above72: sadFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 4, radio_text: 'Very Sad', gender: {
                            Male: {
                                below35: verySadMaleBelow35,
                                above35: verySadMaleAbove35,
                                above72: verySadMaleAbove72
                            },
                            Female: {
                                below35: verySadFemaleBelow35,
                                above35: verySadFemaleAbove35,
                                above72: verySadFemaleAbove72
                            }
                        }
                    },
                ]
            }
        ]
    }

    const handleFrequentUrination = () => {
        setErrorMsg("");
        window.localStorage.setItem('frequent-urination', JSON.stringify(!frequentUrination))
        setFrequentUrination(!frequentUrination)
        setEvents(!events)
        if (!frequentUrination) {
            setNoSymptoms(false)
            window.localStorage.setItem('no-above-symptoms', JSON.stringify(false))
        }
    }
    const handleExcessiveHunger = () => {
        setErrorMsg("");
        window.localStorage.setItem('excessive-hunger', JSON.stringify(!excessiveHunger))
        setExcessiveHunger(!excessiveHunger)
        setEvents(!events)
        if (!excessiveHunger) {
            setNoSymptoms(false)
            window.localStorage.setItem('no-above-symptoms', JSON.stringify(false))
        }

    }
    const handleWoundNotHealing = () => {
        setErrorMsg("");
        window.localStorage.setItem('wound-not-healing', JSON.stringify(!woundNotHealing))
        setWoundNotHealing(!woundNotHealing)
        setEvents(!events)
        if (!woundNotHealing) {
            setNoSymptoms(false)
            window.localStorage.setItem('no-above-symptoms', JSON.stringify(false))
        }
    }
    const handleWeightLoss = () => {
        setErrorMsg("");
        window.localStorage.setItem('weight-loss', JSON.stringify(!weightLoss))
        setWeightLoss(!weightLoss)
        setEvents(!events)
        if (!weightLoss) {
            setNoSymptoms(false)
            window.localStorage.setItem('no-above-symptoms', JSON.stringify(false))
        }
    }

    const handleWeightGain = () => {
        setErrorMsg("");
        window.localStorage.setItem('weight-gain', JSON.stringify(!weightGain))
        setWeightGain(!weightGain)
        setEvents(!events)
        if (!weightGain) {
            setNoSymptoms(false)
            window.localStorage.setItem('no-above-symptoms', JSON.stringify(false))
        }
    }
    const handleJointPains = () => {
        setErrorMsg("");
        window.localStorage.setItem('joint-pains', JSON.stringify(!jointPains))
        setJointPains(!jointPains)
        setEvents(!events)
        if (!jointPains) {
            setNoSymptoms(false)
            window.localStorage.setItem('no-above-symptoms', JSON.stringify(false))
        }
    }
    const handleHeadache = () => {
        setErrorMsg("");
        window.localStorage.setItem('headache', JSON.stringify(!headache))
        setHeadache(!headache)
        setEvents(!events)
        if (!headache) {
            setNoSymptoms(false)
            window.localStorage.setItem('no-above-symptoms', JSON.stringify(false))
        }
    }
    const handleWeaknessOfFatigue = () => {
        setErrorMsg("");
        window.localStorage.setItem('weakness-of-fatigue', JSON.stringify(!weaknessOfFatigue))
        setWeaknessOfFatigue(!weaknessOfFatigue)
        setEvents(!events)
        if (!weaknessOfFatigue) {
            setNoSymptoms(false)
            window.localStorage.setItem('no-above-symptoms', JSON.stringify(false))
        }
    }
    const handleConstipation = () => {
        setErrorMsg("");
        window.localStorage.setItem('constipation', JSON.stringify(!constipation))
        setConstipation(!constipation)
        setEvents(!events)
        if (!constipation) {
            setNoSymptoms(false)
            window.localStorage.setItem('no-above-symptoms', JSON.stringify(false))
        }
    }
    const handleSweating = () => {
        setErrorMsg("");
        window.localStorage.setItem('sweating', JSON.stringify(!sweating))
        setSweating(!sweating)
        setEvents(!events)
        if (!sweating) {
            setNoSymptoms(false)
            window.localStorage.setItem('no-above-symptoms', JSON.stringify(false))
        }
    }
    const handleTingling = () => {
        setErrorMsg("");
        setEvents(!events)
        window.localStorage.setItem('tingling-numbness', JSON.stringify(!tingling))
        setTingling(!tingling)
        if (!tingling) {
            setNoSymptoms(false)
            window.localStorage.setItem('no-above-symptoms', JSON.stringify(false))
        }
    }

    const handleNoSymptoms = () => {
        setErrorMsg("");
        setEvents(!events)
        window.localStorage.setItem('no-above-symptoms', JSON.stringify(!noSymptoms))
        setNoSymptoms(!noSymptoms)
        setShowModal(true)
        if (!noSymptoms) {
            setFrequentUrination(false)
            setExcessiveHunger(false)
            setWoundNotHealing(false)
            setWeightLoss(false)
            setWeightGain(false)
            setJointPains(false)
            setHeadache(false)
            setWeaknessOfFatigue(false)
            setConstipation(false)
            setSweating(false)
            setTingling(false)
            window.localStorage.setItem('frequent-urination', JSON.stringify(false))
            window.localStorage.setItem('excessive-hunger', JSON.stringify(false))
            window.localStorage.setItem('wound-not-healing', JSON.stringify(false))
            window.localStorage.setItem('weight-loss', JSON.stringify(false))
            window.localStorage.setItem('weight-gain', JSON.stringify(false))
            window.localStorage.setItem('joint-pains', JSON.stringify(false))
            window.localStorage.setItem('headache', JSON.stringify(false))
            window.localStorage.setItem('weakness-of-fatigue', JSON.stringify(false))
            window.localStorage.setItem('constipation', JSON.stringify(false))
            window.localStorage.setItem('sweating', JSON.stringify(false))
            window.localStorage.setItem('tingling-numbness', JSON.stringify(false))
        }

    }

    const handleModal = () => { setShowModal(!showModal) }

    const handleSelect = () => {
        window.localStorage.setItem('frequent-urination', frequentUrination)
        window.localStorage.setItem('excessive-hunger', excessiveHunger)
        window.localStorage.setItem('wound-not-healing', woundNotHealing)
        window.localStorage.setItem('weight-loss', weightLoss)
        window.localStorage.setItem('weight-gain', weightGain)
        window.localStorage.setItem('joint-pains', jointPains)
        window.localStorage.setItem('headache', headache)
        window.localStorage.setItem('weakness-of-fatigue', weaknessOfFatigue)
        window.localStorage.setItem('constipation', constipation)
        window.localStorage.setItem('sweating', sweating)
        window.localStorage.setItem('tingling-numbness', tingling)
        window.localStorage.setItem('no-above-symptoms', noSymptoms)
        setShowModal(false)

    }

    const handlePrevious = () => {
        if (JSON.parse(window.localStorage.getItem('assessment')) != null) {
            navigate(`${homePageUrl}heart-disease`)
        }
        else {
            const noDisease = JSON.parse(window.localStorage.getItem('none-of-the-above'))
            if (!noDisease) { navigate(`${homePageUrl}heart-disease`) }
            else if (noDisease) { navigate(`${homePageUrl}family-history`) }
        }
    }

    const handleSubmit = () => {
        let frequentUrination = window.localStorage.getItem('frequent-urination')
        let excessiveHunger = window.localStorage.getItem('excessive-hunger')
        let woundNotHealing = window.localStorage.getItem('wound-not-healing')
        let weightLoss = window.localStorage.getItem('weight-loss')
        let weightGain = window.localStorage.getItem('weight-gain')
        let jointPains = window.localStorage.getItem('joint-pains')
        let headache = window.localStorage.getItem('headache')
        let weaknessOfFatigue = window.localStorage.getItem('weakness-of-fatigue')
        let constipation = window.localStorage.getItem('constipation')
        let sweating = window.localStorage.getItem('sweating')
        let tingling = window.localStorage.getItem('tingling-numbness')
        let noSymptoms = window.localStorage.getItem('no-above-symptoms')

        if (frequentUrination || excessiveHunger || woundNotHealing || weightLoss || weightGain || jointPains || headache || weaknessOfFatigue || constipation || sweating || tingling || noSymptoms) {
            if (JSON.parse(frequentUrination) || JSON.parse(excessiveHunger) || JSON.parse(woundNotHealing) || JSON.parse(weightLoss) || JSON.parse(weightGain) || JSON.parse(jointPains) || JSON.parse(headache) || JSON.parse(weaknessOfFatigue) || JSON.parse(constipation) || JSON.parse(sweating) || JSON.parse(tingling) || (JSON.parse(noSymptoms))) {
                navigate(`${homePageUrl}symtom-situations`)
            }
            else setErrorMsg(t('Messages.Symptoms.1'))
        }
        else setErrorMsg(t('Messages.Symptoms.1'))
    }


    useEffect(() => {
        let frequentUrination = window.localStorage.getItem('frequent-urination')
        let excessiveHunger = window.localStorage.getItem('excessive-hunger')
        let woundNotHealing = window.localStorage.getItem('wound-not-healing')
        let weightLoss = window.localStorage.getItem('weight-loss')
        let weightGain = window.localStorage.getItem('weight-gain')
        let jointPains = window.localStorage.getItem('joint-pains')
        let headache = window.localStorage.getItem('headache')
        let weaknessOfFatigue = window.localStorage.getItem('weakness-of-fatigue')
        let constipation = window.localStorage.getItem('constipation')
        let sweating = window.localStorage.getItem('sweating')
        let tingling = window.localStorage.getItem('tingling-numbness')
        let noAboveSymptoms = window.localStorage.getItem('no-above-symptoms')
        setFrequentUrination(frequentUrination)
        setExcessiveHunger(excessiveHunger)
        setWoundNotHealing(woundNotHealing)
        setWeightLoss(weightLoss)
        setWeightGain(weightGain)
        setJointPains(jointPains)
        setHeadache(headache)
        setWeaknessOfFatigue(weaknessOfFatigue)
        setConstipation(constipation)
        setSweating(sweating)
        setTingling(tingling)
        setNoSymptoms(noAboveSymptoms)
        if (JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en") {
            let lang = "en";
            handleClick(lang);
        }
        else {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
        const data = [frequentUrination, excessiveHunger, woundNotHealing, weightLoss, weightGain, jointPains, headache, weaknessOfFatigue, constipation, sweating, tingling, noAboveSymptoms]
        console.log(typeof noAboveSymptoms)
        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        if (noAboveSymptoms == null) {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
        }
        else {
            let count = data.filter(rep => rep == 'true').length
            if (noAboveSymptoms == 'false') {
                let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log('innder 0')
                setLottiePlayer(lottie);
            }
            else if (count == 0) {
                let lottie = contentData.options[0].radio_button[1].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log('innder 0')
                setLottiePlayer(lottie);
            } else if (count == 1 || count == 2 || count == 3) {
                let lottie = contentData.options[0].radio_button[2].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log('innder 1')
                setLottiePlayer(lottie);
            } else if (count == 4 || count == 5 || count == 6 || count == 7) {
                let lottie = contentData.options[0].radio_button[3].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log('innder 2-3')
                setLottiePlayer(lottie);
            } else if (count >= 8) {
                let lottie = contentData.options[0].radio_button[3].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log('innder 4')
                setLottiePlayer(lottie);
            }
        }
    }, [])

    const handleClick = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t)
    }

    useEffect(() => {
        let frequentUrination = window.localStorage.getItem('frequent-urination')
        let excessiveHunger = window.localStorage.getItem('excessive-hunger')
        let woundNotHealing = window.localStorage.getItem('wound-not-healing')
        let weightLoss = window.localStorage.getItem('weight-loss')
        let weightGain = window.localStorage.getItem('weight-gain')
        let jointPains = window.localStorage.getItem('joint-pains')
        let headache = window.localStorage.getItem('headache')
        let weaknessOfFatigue = window.localStorage.getItem('weakness-of-fatigue')
        let constipation = window.localStorage.getItem('constipation')
        let sweating = window.localStorage.getItem('sweating')
        let tingling = window.localStorage.getItem('tingling-numbness')
        let noAboveSymptoms = window.localStorage.getItem('no-above-symptoms')
        const data = [frequentUrination, excessiveHunger, woundNotHealing, weightLoss, weightGain, jointPains, headache, weaknessOfFatigue, constipation, sweating, tingling, noAboveSymptoms]
        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        let count = data.filter(rep => rep == 'true' || rep == true).length
        console.log(frequentUrination, excessiveHunger, woundNotHealing, weightLoss, weightGain, jointPains, headache, weaknessOfFatigue, constipation, sweating, tingling, noAboveSymptoms)
        if (noAboveSymptoms == 'true') {
            let lottie = contentData.options[0].radio_button[0].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
        }
        else {
            let count = data.filter(rep => rep == 'true').length
            if (noAboveSymptoms == 'true') {
                console.log("first")
                let lottie = contentData.options[0].radio_button[0].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                setLottiePlayer(lottie);
            }
            else if (count == 0) {
                let lottie = contentData.options[0].radio_button[1].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log('innder 0')
                setLottiePlayer(lottie);
            }
            else if (count == 1 || count == 2 || count == 3) {
                let lottie = contentData.options[0].radio_button[2].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log('innder 1')
                setLottiePlayer(lottie);
            } else if (count == 4 || count == 5 || count == 6 || count == 7) {
                let lottie = contentData.options[0].radio_button[3].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log('innder 2-3')
                setLottiePlayer(lottie);
            } else if (count >= 8) {
                let lottie = contentData.options[0].radio_button[3].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log('innder 4')
                setLottiePlayer(lottie);
            }
        }
    }, [events]);

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className='align-items-center'>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('Symptoms.Symptoms')}
                                        </h2>
                                        <p className="subheading">
                                            {t('Symptoms.SelectYourExperiences')}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('Symptoms.Symptoms')}
                                        </h2>
                                        <p className="subheading">
                                            {t('Symptoms.SelectYourExperiences')}
                                        </p>
                                    </div>
                                }
                                <p className="cm-para typ-medium typ-center typ-mob-left typ-max-wid typ-form-title">
                                    {t('Symptoms.SelectSymptoms')}.
                                </p>
                                <div className='cm-selected-checks'>
                                    <p className={window.localStorage.getItem('frequent-urination') === "true" ? 'selected' : "cm-hide"}>{t('Symptoms.FrequentUrination')}
                                        <span className="icon icon-check-close" onClick={handleFrequentUrination}></span>
                                    </p>
                                    <p className={window.localStorage.getItem('excessive-hunger') === "true" ? 'selected' : "cm-hide"}>{t('Symptoms.ExcessiveHunger')}
                                        <span className="icon icon-check-close" onClick={handleExcessiveHunger}></span>
                                    </p>
                                    <p className={window.localStorage.getItem('wound-not-healing') === "true" ? 'selected' : "cm-hide"}>{t('Symptoms.WoundNotHealing')}
                                        <span className="icon icon-check-close" onClick={handleWoundNotHealing}></span>
                                    </p>
                                    <p className={window.localStorage.getItem('weight-loss') === "true" ? 'selected' : "cm-hide"}>{t('Symptoms.WeightLoss')}
                                        <span className="icon icon-check-close" onClick={handleWeightLoss}></span>
                                    </p>
                                    <p className={window.localStorage.getItem('weight-gain') === "true" ? 'selected' : "cm-hide"}>{t('Symptoms.WeightGain')}
                                        <span className="icon icon-check-close" onClick={handleWeightGain}></span>
                                    </p>
                                    <p className={window.localStorage.getItem('joint-pains') === "true" ? 'selected' : "cm-hide"}>{t('Symptoms.JointPains')}
                                        <span className="icon icon-check-close" onClick={handleJointPains}></span>
                                    </p>
                                    <p className={window.localStorage.getItem('headache') === "true" ? 'selected' : "cm-hide"}>{t('Symptoms.Headache')}
                                        <span className="icon icon-check-close" onClick={handleHeadache}></span>
                                    </p>
                                    <p className={window.localStorage.getItem('weakness-of-fatigue') === "true" ? 'selected' : "cm-hide"}>{t('Symptoms.WeaknessOfFatigue')}
                                        <span className="icon icon-check-close" onClick={handleWeaknessOfFatigue}></span>
                                    </p>
                                    <p className={window.localStorage.getItem('constipation') === "true" ? 'selected' : "cm-hide"}>{t('Symptoms.Constipation')}
                                        <span className="icon icon-check-close" onClick={handleConstipation}></span>
                                    </p>
                                    <p className={window.localStorage.getItem('sweating') === "true" ? 'selected' : "cm-hide"}>{t('Symptoms.Sweating')}
                                        <span className="icon icon-check-close" onClick={handleSweating}></span>
                                    </p>
                                    <p className={window.localStorage.getItem('tingling-numbness') === "true" ? 'selected' : "cm-hide"}>{t('Symptoms.TinglingOrNumbness')}
                                        <span className="icon icon-check-close" onClick={handleTingling}></span>
                                    </p>
                                    <p className={window.localStorage.getItem('no-above-symptoms') === "true" ? 'selected' : "cm-hide"}>{t('Symptoms.NoSymptoms')}
                                        <span className="icon icon-check-close" onClick={handleNoSymptoms}></span>
                                    </p>
                                </div>
                                <button className="cm-para typ-center typ-link" onClick={handleModal}>
                                    + {t('Symptoms.AddSymptom')}
                                </button>
                                {errorMsg && <p className='error-message'>{errorMsg}</p>}
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <div className={showModal ? 'bs-custom-popup show' : 'bs-custom-popup'}>
                <div className='bs-card typ-popup'>
                    <p className='cm-para typ-bold typ-small typ-black mb-10'>
                        {t('Symptoms.SelectYourExperiences')}
                    </p>
                    <div className='bs-form typ-form-check'>
                        <FormGroup>
                            <FormGroup className="check-box typ-popup" check>
                                <Label check>
                                    <Input type="checkbox" checked={window.localStorage.getItem('no-above-symptoms') === "true" ? true : false} name="No Symptoms" value="" onChange={handleNoSymptoms} />{' '}
                                    <div className='checkbox-label'>
                                        {t('Symptoms.NoSymptomsFrom')}<span className="icon icon-right"></span>
                                    </div>
                                </Label>
                            </FormGroup>
                            <FormGroup className="check-box typ-popup" check>
                                <Label check>
                                    <Input type="checkbox" checked={window.localStorage.getItem('frequent-urination') === "true" ? true : false} name="Frequent Urination" value="" onChange={handleFrequentUrination} />{' '}
                                    <div className='checkbox-label'>
                                        {t('Symptoms.FrequentUrination')}<span className="icon icon-right"></span>
                                    </div>
                                </Label>
                            </FormGroup>
                            <FormGroup className="check-box typ-popup" check>
                                <Label check>
                                    <Input type="checkbox" checked={window.localStorage.getItem('excessive-hunger') === "true" ? true : false} name="Excessive Hunger / Thirst" value="" onChange={handleExcessiveHunger} />{' '}
                                    <div className='checkbox-label'>
                                        {t('Symptoms.ExcessiveHunger')}<span className="icon icon-right"></span>
                                    </div>
                                </Label>
                            </FormGroup>
                            <FormGroup className="check-box typ-popup" check>
                                <Label check>
                                    <Input type="checkbox" checked={window.localStorage.getItem('wound-not-healing') === "true" ? true : false} name="Wound Not Healing" value="" onChange={handleWoundNotHealing} />{' '}
                                    <div className='checkbox-label'>
                                        {t('Symptoms.WoundNotHealing')}<span className="icon icon-right"></span>
                                    </div>
                                </Label>
                            </FormGroup>
                            <FormGroup className="check-box typ-popup" check>
                                <Label check>
                                    <Input type="checkbox" checked={window.localStorage.getItem('weight-loss') === "true" ? true : false} name="Weight Loss" value="" onChange={handleWeightLoss} />{' '}
                                    <div className='checkbox-label'>
                                        {t('Symptoms.WeightLoss')}<span className="icon icon-right"></span>
                                    </div>
                                </Label>
                            </FormGroup>
                            <FormGroup className="check-box typ-popup" check>
                                <Label check>
                                    <Input type="checkbox" checked={window.localStorage.getItem('weight-gain') === "true" ? true : false} name="Weight Gain" value="" onChange={handleWeightGain} />{' '}
                                    <div className='checkbox-label'>
                                        {t('Symptoms.WeightGain')}<span className="icon icon-right"></span>
                                    </div>
                                </Label>
                            </FormGroup>
                            <FormGroup className="check-box typ-popup" check>
                                <Label check>
                                    <Input type="checkbox" checked={window.localStorage.getItem('joint-pains') === "true" ? true : false} name="Joint Pains" value="" onChange={handleJointPains} />{' '}
                                    <div className='checkbox-label'>
                                        {t('Symptoms.JointPains')}<span className="icon icon-right"></span>
                                    </div>
                                </Label>
                            </FormGroup>
                            <FormGroup className="check-box typ-popup" check>
                                <Label check>
                                    <Input type="checkbox" checked={window.localStorage.getItem('headache') === "true" ? true : false} name="Headache" value="" onChange={handleHeadache} />{' '}
                                    <div className='checkbox-label'>
                                        {t('Symptoms.Headache')}<span className="icon icon-right"></span>
                                    </div>
                                </Label>
                            </FormGroup>
                            <FormGroup className="check-box typ-popup" check>
                                <Label check>
                                    <Input type="checkbox" checked={window.localStorage.getItem('weakness-of-fatigue') === "true" ? true : false} name="Weakness of Fatigue" value="" onChange={handleWeaknessOfFatigue} />{' '}
                                    <div className='checkbox-label'>
                                        {t('Symptoms.WeaknessOfFatigue')}<span className="icon icon-right"></span>
                                    </div>
                                </Label>
                            </FormGroup>
                            <FormGroup className="check-box typ-popup" check>
                                <Label check>
                                    <Input type="checkbox" checked={window.localStorage.getItem('constipation') === "true" ? true : false} name="Constipation" value="" onChange={handleConstipation} />{' '}
                                    <div className='checkbox-label'>
                                        {t('Symptoms.Constipation')}<span className="icon icon-right"></span>
                                    </div>
                                </Label>
                            </FormGroup>
                            <FormGroup className="check-box typ-popup" check>
                                <Label check>
                                    <Input type="checkbox" checked={window.localStorage.getItem('sweating') === "true" ? true : false} name="Sweating" value="" onChange={handleSweating} />{' '}
                                    <div className='checkbox-label'>
                                        {t('Symptoms.Sweating')}<span className="icon icon-right"></span>
                                    </div>
                                </Label>
                            </FormGroup>
                            <FormGroup className="check-box typ-popup" check>
                                <Label check>
                                    <Input type="checkbox" checked={window.localStorage.getItem('tingling-numbness') === "true" ? true : false} name="Tingling" value="" onChange={handleTingling} />{' '}
                                    <div className='checkbox-label'>
                                        {t('Symptoms.TinglingOrNumbness')}<span className="icon icon-right"></span>
                                    </div>
                                </Label>
                            </FormGroup>
                        </FormGroup>
                        <Button type="submit" className='bs-btn' onClick={handleSelect}>
                            {t('Symptoms.Select')}
                        </Button>
                    </div>

                    <span className='close-popup' onClick={handleModal}>
                        <span className="icon icon-check-close"></span>
                    </span>
                </div>
            </div>
            <RoutingArrow prevBtn={handlePrevious} submit next={handleSubmit} />
            <Footer />
        </div>
    )
}


export default Symptoms;