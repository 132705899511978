import React, { useState, useEffect } from "react";
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images
import Right from '../../../assets/images/select-language/right.svg';

// css
import '../../../assets/sass/custom.scss';

// json 

// default 
import defaultLottie from '../../../assets/json/blood-pressure/blood-pressure.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function BloodPressure() {
    let navigate = useNavigate()
    const { t, i18n } = useTranslation();

    let [bloodPressure, setBloodPressure] = useState('')
    let [errorMsg, setErrorMsg] = useState('')
    const [lottiePlayer, setLottiePlayer] = useState(defaultLottie);

    let radioValues = [{
        rId: 1, radio_text: t('BloodPressure.questions.rId1')
    }, {
        rId: 2, radio_text: t('BloodPressure.questions.rId2')
    }]

    const handleBloodPressure = (key) => {
        bloodPressure = radioValues[key - 1].radio_text;
        let bp;
        if (key == 1) {
            bp = "Yes"
        }
        else {
            bp = "No"
        }
        setBloodPressure(bloodPressure)
        setErrorMsg("")
        window.localStorage.setItem('blood-pressure', JSON.stringify(bp))
        window.localStorage.setItem('blood-pressure1', JSON.stringify(bloodPressure))
    }

    const handlePrevious = () => {
        const bloodCholesterol = JSON.parse(window.localStorage.getItem('blood-cholesterol'))
        if (bloodCholesterol == "No") {
            navigate(`${homePageUrl}cholesterol`)
        }
        else navigate(`${homePageUrl}cholesterol2`)
    }

    const handleSubmit = () => {
        const bloodPressure = window.localStorage.getItem('blood-pressure')
        if (!bloodPressure) {
            setErrorMsg(t('Messages.BloodPressure.1'))
        }
        else if (JSON.parse(bloodPressure) == "Yes") {
            navigate(`${homePageUrl}blood-pressure2`)
        }
        else navigate(`${homePageUrl}almost-done`)
    }

    //get local storage
    useEffect(() => {
        const bloodPressure = JSON.parse(window.localStorage.getItem('blood-pressure'))
        const bloodPressure1 = JSON.parse(window.localStorage.getItem('blood-pressure1'))
       
        if (JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en") {
            let lang = "en";
            handleClick(lang);
            setBloodPressure(bloodPressure)
        }
        else {
            setBloodPressure(bloodPressure1)
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }

    }, [])

    const handleClick = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t)
    }


    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className='align-items-center'>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('BloodPressure.heading')}
                                        </h2>
                                        <p className="subheading">
                                            {t('BloodPressure.subheading')}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('BloodPressure.heading')}
                                        </h2>
                                        <p className="subheading">
                                            {t('BloodPressure.subheading')}
                                        </p>
                                    </div>
                                }
                                <p className="cm-para typ-black typ-center typ-mob-left typ-medium typ-max-wid typ-form-title">
                                    {t('BloodPressure.DoYouKnow')}
                                </p>
                                <p className="cm-para typ-black typ-center typ-mob-left typ-medium typ-max-wid typ-form-title typ-grey">
                                    ({t('BloodPressure.PreferablyTested')})
                                </p>
                                <div className="bs-form">
                                    <FormGroup className='radio-box typ-gender' tag="fieldset">
                                        {radioValues.map((item) => {
                                            return (
                                                <React.Fragment key={item.rId}>
                                                    <FormGroup className="check-box typ-btn form-check" check>
                                                        <Label check>
                                                            <Input
                                                                checked={item.radio_text === bloodPressure}
                                                                type="radio" name="radio1" onChange={() => handleBloodPressure(item.rId)}
                                                                value={item.radio_text}
                                                            />{''}
                                                            <div className='radio-label'>{item.radio_text}
                                                                <img src={Right} className='right-icon' alt="Next-arrow" />
                                                            </div>
                                                        </Label>
                                                    </FormGroup>
                                                </React.Fragment>
                                            )
                                        })}
                                        {errorMsg && <p className="error-message">{errorMsg}</p>}
                                    </FormGroup>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prevBtn={handlePrevious} submit next={handleSubmit} />
            <Footer />
        </div>
    );
}

export default BloodPressure;