import React, { useState, useEffect } from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// Import Swiper styles
import 'swiper/css';

// images
import Right from '../../../assets/images/select-language/right.svg';

// css
import '../../../assets/sass/custom.scss';

// json 
// male below 35 
import defaultDiseaseMaleBelow35 from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import happyMaleBelow35 from '../../../assets/json/expression/male/below35/happy.json';
import veryHappyMaleBelow35 from '../../../assets/json/expression/male/below35/very-happy.json';
import sadMaleBelow35 from '../../../assets/json/expression/male/below35/sad.json';
import verySadMaleBelow35 from '../../../assets/json/expression/male/below35/very-sad.json';

// male above 35 
import defaultDiseaseMaleAbove35 from '../../../assets/json/default/male/above35/none-of-the-option-is-selected.json';
import happyMaleAbove35 from '../../../assets/json/expression/male/above35/happy.json';
import veryHappyMaleAbove35 from '../../../assets/json/expression/male/above35/very-happy.json';
import sadMaleAbove35 from '../../../assets/json/expression/male/above35/sad.json';
import verySadMaleAbove35 from '../../../assets/json/expression/male/above35/very-sad.json';

// male above 72 
import defaultDiseaseMaleAbove72 from '../../../assets/json/default/male/above72/none-of-the-option-is-selected.json';
import happyMaleAbove72 from '../../../assets/json/expression/male/above72/happy.json';
import veryHappyMaleAbove72 from '../../../assets/json/expression/male/above72/very-happy.json';
import sadMaleAbove72 from '../../../assets/json/expression/male/above72/sad.json';
import verySadMaleAbove72 from '../../../assets/json/expression/male/above72/very-sad.json';

// // female below 35 
import defaultDiseaseFemaleBelow35 from '../../../assets/json/default/female/below35/none-of-the-option-is-selected.json';
import happyFemaleBelow35 from '../../../assets/json/expression/female/below35/happy.json';
import veryHappyFemaleBelow35 from '../../../assets/json/expression/female/below35/very-happy.json';
import sadFemaleBelow35 from '../../../assets/json/expression/female/below35/sad.json';
import verySadFemaleBelow35 from '../../../assets/json/expression/female/below35/v-sad.json';

// // female above 35 
import defaultDiseaseFemaleAbove35 from '../../../assets/json/default/female/above35/none-of-the-option-is-selected.json';
import happyFemaleAbove35 from '../../../assets/json/expression/female/above35/happy.json';
import veryHappyFemaleAbove35 from '../../../assets/json/expression/female/above35/very-happy.json';
import sadFemaleAbove35 from '../../../assets/json/expression/female/above35/sad.json';
import verySadFemaleAbove35 from '../../../assets/json/expression/female/above35/very-sad.json';

// // female above 72
import defaultDiseaseFemaleAbove72 from '../../../assets/json/default/female/above72/none-of-the-option-is-selected.json';
import happyFemaleAbove72 from '../../../assets/json/expression/female/above72/happy.json';
import veryHappyFemaleAbove72 from '../../../assets/json/expression/female/above72/very-happy.json';
import sadFemaleAbove72 from '../../../assets/json/expression/female/above72/sad.json';
import verySadFemaleAbove72 from '../../../assets/json/expression/female/above72/very-sad.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';


function HeartDisease() {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate()
    let [heartBlockage, setHeartBlockage] = useState('')
    let [heartSurgery, setHeartSurgery] = useState('')
    let [errorMsg, setErrorMsg] = useState({ hrtDisease: "", surgery: "" })
    let [language, setLanguage] = useState("")

    const [lottiePlayer, setLottiePlayer] = useState("");
    let [events, setEvents] = useState("")

    const initialValues = { spriteImg: "bg-1",option_text: "" }
    let [contentValues, setContentValues] = useState(initialValues)

    let contentData = {
        qId: 6,
        question_text: t('HeartDisease.questions.HeartDisease'),
        descriptions: [t('HeartDisease.questions.Let’sGetTo')],
        options: [
            {
                qId: 6,
                option_id: 1,
                option_text: t('HeartDisease.questions.HaveYouBeen'),
                imgClass: 'bg-1',
                radio_button: [
                    {
                        rId: 1, radio_text: t('HeartDisease.questions.Yes')
                    },
                    {
                        rId: 2, radio_text: t('HeartDisease.questions.No')
                    },
                ]
            },
            {
                qId: 6,
                option_id: 2,
                option_text: 'Have you undergone any heart surgeries like Angioplasty, Bypass Surgery, or so?',
                imgClass: 'bg-2',
                radio_button: [
                    { rId: 1, radio_text: t('HeartDisease.questions.Yes') },
                    { rId: 2, radio_text: t('HeartDisease.questions.No') },
                ]
            }
        ]
    }

    let expressionData = {
        options: [
            {
                radio_button: [
                    {
                        rId: 1, radio_text: 'Happy', gender: {
                            Male: {
                                default: {
                                    below35: defaultDiseaseMaleBelow35,
                                    above35: defaultDiseaseMaleAbove35,
                                    above72: defaultDiseaseMaleAbove72,
                                },
                                below35: happyMaleBelow35,
                                above35: happyMaleAbove35,
                                above72: happyMaleAbove72
                            },
                            Female: {
                                default: {
                                    below35: defaultDiseaseFemaleBelow35,
                                    above35: defaultDiseaseFemaleAbove35,
                                    above72: defaultDiseaseFemaleAbove72,
                                },
                                below35: happyFemaleBelow35,
                                above35: happyFemaleAbove35,
                                above72: happyFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 2, radio_text: 'Very Happy', gender: {
                            Male: {
                                below35: veryHappyMaleBelow35,
                                above35: veryHappyMaleAbove35,
                                above72: veryHappyMaleAbove72
                            },
                            Female: {
                                below35: veryHappyFemaleBelow35,
                                above35: veryHappyFemaleAbove35,
                                above72: veryHappyFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 3, radio_text: 'Sad', gender: {
                            Male: {
                                below35: sadMaleBelow35,
                                above35: sadMaleAbove35,
                                above72: sadMaleAbove72
                            },
                            Female: {
                                below35: sadFemaleBelow35,
                                above35: sadFemaleAbove35,
                                above72: sadFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 4, radio_text: 'Very Sad', gender: {
                            Male: {
                                below35: verySadMaleBelow35,
                                above35: verySadMaleAbove35,
                                above72: verySadMaleAbove72
                            },
                            Female: {
                                below35: verySadFemaleBelow35,
                                above35: verySadFemaleAbove35,
                                above72: verySadFemaleAbove72
                            }
                        }
                    },
                ]
            }
        ]
    }

    const handleBlockage = (key) => {
        console.log("key alue is >>", key)
        heartBlockage = contentData.options[0].radio_button[key - 1].radio_text;
        console.log("heartBlockage value is >>", heartBlockage)
        contentValues.spriteImg = contentData.options[key - 1].imgClass
        contentValues.option_text = heartBlockage
        let hb;
        if (key == 1) {
            hb = "Yes"
        }
        else {
            hb = "No"
        }
        setContentValues({
            spriteImg: contentValues.spriteImg,
            option_text: contentValues.option_text,
        })
        console.log("hb value is here >>", heartBlockage);
        setHeartBlockage(heartBlockage)
        window.localStorage.setItem('heart-blockage', JSON.stringify(hb))
        window.localStorage.setItem('heart-blockage1', JSON.stringify(heartBlockage))
        setEvents(!events)

    }
    const handleSurgery = (key) => {
        heartSurgery = contentData.options[1].radio_button[key - 1].radio_text;
        let hs;
        if (key == 1) {
            hs = "Yes"
        }
        else {
            hs = "No"
        }
        console.log("heart surgery key value is >>",key);
        console.log(heartSurgery, "heartSurgery")
        setHeartSurgery(heartSurgery)
        window.localStorage.setItem('heart-surgery', JSON.stringify(hs))
        window.localStorage.setItem('heart-surgery1', JSON.stringify(heartSurgery))
        setEvents(!events)

    }

    const handlePrevious = () => {
        const medicine = JSON.parse(window.localStorage.getItem('medicine'))
        let noneOfTheAbove = JSON.parse(window.localStorage.getItem('none-of-the-above'))
        if (noneOfTheAbove) {
            navigate(`${homePageUrl}family-history`)
        }
        else if (medicine == "No") {
            navigate(`${homePageUrl}medication-details`)
        }
        else navigate(`${homePageUrl}medication-details2`)
    }

    const handleSubmit = () => {
        const heartBlockage = window.localStorage.getItem('heart-blockage')
        const heartSurgery = window.localStorage.getItem('heart-surgery')
        if (heartBlockage) {
            if (JSON.parse(heartBlockage) == "Yes") {
                if (!heartSurgery) {
                    setErrorMsg({ hrtDisease: "" })
                    setErrorMsg({ heartSurgery: t('Messages.HeartDisease.1') })
                }
                else if (heartSurgery) {
                    setErrorMsg({ heartSurgery: "" })
                    navigate(`${homePageUrl}symptoms`)
                }
            }
            else if (JSON.parse(heartBlockage) == "No") {
                navigate(`${homePageUrl}symptoms`)
            }
        } else {
            setErrorMsg({ hrtDisease: t('Messages.HeartDisease.1') })
        }
    }

    //get local storage
    useEffect(() => {
        const heartBlockage = JSON.parse(window.localStorage.getItem('heart-blockage'))
        const heartBlockage1 = JSON.parse(window.localStorage.getItem('heart-blockage1'))
        
        console.log("heart disease value is >>", JSON.parse(window.localStorage.getItem('lang')))
        const heartSurgery = JSON.parse(window.localStorage.getItem('heart-surgery'))
        const heartSurgery1 = JSON.parse(window.localStorage.getItem('heart-surgery1'))

        const language = JSON.parse(window.localStorage.getItem('lang')) == "en";
        setLanguage(language);
        

        if (JSON.parse(window.localStorage.getItem('lang')) == null || JSON.parse(window.localStorage.getItem('lang')) == "en") {
            let lang = "en";
            setHeartSurgery(heartSurgery)
            setHeartBlockage(heartBlockage)
            handleClick(lang);
        }
        else {
            setHeartSurgery(heartSurgery1)
            setHeartBlockage(heartBlockage1)
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')

        if (heartBlockage == null && heartSurgery == null) {
            let lottie = expressionData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
            console.log('default')
        }
        else {
            console.log('else')
            const heartSurgery = JSON.parse(window.localStorage.getItem('heart-surgery'))
            if (heartBlockage == 'Yes' && heartSurgery == null) {
                let lottie = expressionData.options[0].radio_button[2].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log(lottie, 'sad')
                setLottiePlayer(lottie)
            }
            else if (heartBlockage == 'No' && heartSurgery == null) {
                let lottie = expressionData.options[0].radio_button[0].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log(lottie, 'Happy')
                setLottiePlayer(lottie)
            }
            else if (heartBlockage == 'Yes' && heartSurgery == 'Yes') {
                let lottie = expressionData.options[0].radio_button[3].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log(lottie, 'v sad')
                setLottiePlayer(lottie)
            }
            else if (heartBlockage == 'Yes' && heartSurgery == 'No') {
                let lottie = expressionData.options[0].radio_button[2].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log(lottie, 'sad')
                setLottiePlayer(lottie)
            }
            else if (heartBlockage == 'No' && heartSurgery == 'No') {
                let lottie = expressionData.options[0].radio_button[0].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log(lottie, 'Happy')
                setLottiePlayer(lottie)
            }
        }

    }, [heartBlockage])

    useEffect(() => {
        const heartBlockage = JSON.parse(window.localStorage.getItem('heart-blockage'))
        const heartBlockage1 = JSON.parse(window.localStorage.getItem('heart-blockage1'))
        
        if(language == "en")
        {
            setHeartBlockage(heartBlockage)
        }
        else
        {
            setHeartBlockage(heartBlockage1)
        }
        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')

        if (heartBlockage == null && heartSurgery == null) {
            console.log('if')
            let lottie = expressionData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
            console.log('default')
        }
        else {
            console.log('else')
            const heartSurgery = JSON.parse(window.localStorage.getItem('heart-surgery'))
            if (heartBlockage == 'Yes' && heartSurgery == null) {
                let lottie = expressionData.options[0].radio_button[2].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log(lottie, 'sad')
                setLottiePlayer(lottie)
            }
            else if (heartBlockage == 'No' && heartSurgery == null) {
                let lottie = expressionData.options[0].radio_button[0].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log(lottie, 'Happy')
                setLottiePlayer(lottie)
            }
            else if (heartBlockage == 'Yes' && heartSurgery == 'Yes') {
                let lottie = expressionData.options[0].radio_button[3].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log(lottie, 'v sad')
                setLottiePlayer(lottie)
            }
            else if (heartBlockage == 'Yes' && heartSurgery == 'No') {
                let lottie = expressionData.options[0].radio_button[2].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log(lottie, 'sad')
                setLottiePlayer(lottie)
            }
            else if (heartBlockage == 'No' && heartSurgery == 'No') {
                let lottie = expressionData.options[0].radio_button[0].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log(lottie, 'Happy')
                setLottiePlayer(lottie)
            }
        }

    }, [events])

    const handleClick = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t)
        setLanguage(lang);


    }
    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className='align-items-center'>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('HeartDisease.HeartDisease')}
                                        </h2>
                                        <p className="subheading">
                                            {t('HeartDisease.LetsGetTo')}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('HeartDisease.HeartDisease')}
                                        </h2>
                                        <p className="subheading">
                                            {t('HeartDisease.LetsGetTo')}
                                        </p>
                                    </div>
                                }
                                <p className="cm-para typ-medium typ-center typ-mob-left typ-max-wid typ-form-title">
                                    {t('HeartDisease.HaveYouBeen')}
                                </p>
                                {
                                    console.log("heart blockage value is >>1 ", contentValues.option_text)
                                }
                                <div className="bs-form mt-0">
                                    <FormGroup className='radio-box typ-gender' tag="fieldset">
                                        {contentData.options[0].radio_button.map((item) => {
                                            return (
                                                <React.Fragment key={item.rId}>
                                                    <FormGroup className='check-box typ-btn form-check' check>
                                                        <Label check>
                                                            <Input
                                                                checked={item.radio_text === heartBlockage}
                                                                type="radio" name="radio1" value={item.radio_text} onChange={() => handleBlockage(item.rId)} />{''}
                                                            <div className='radio-label'>{item.radio_text}
                                                                <img src={Right} className='right-icon' alt="Next-arrow" />
                                                            </div>
                                                        </Label>
                                                    </FormGroup>
                                                </React.Fragment>
                                            )
                                        })}
                                    </FormGroup>
                                    {errorMsg.hrtDisease && <p className='error-message'>{errorMsg.hrtDisease}</p>}
                                </div>
                                {
                                    console.log("Heart blocakge value is>>>>", heartBlockage)
                                }
                                {
                                    heartBlockage == t('HeartDisease.questions.Yes') ?
                                    <>
                                        <p className="cm-para typ-medium typ-center typ-mob-left typ-max-wid typ-form-title">
                                            {t('HeartDisease.HaveYouUndergone')}
                                        </p>
                                        <div className="bs-form mt-0">
                                            <FormGroup className='radio-box typ-normal' tag="fieldset">
                                                {contentData.options[1].radio_button.map((item) => {
                                                    return (
                                                        <React.Fragment key={item.rId}>
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        checked={item.radio_text === heartSurgery}
                                                                        type="radio" name="radio2" value={item.radio_text} onChange={() => handleSurgery(item.rId)} />{''}
                                                                    <div className='radio-label'>
                                                                        <span className='circle'></span>
                                                                        {item.radio_text}
                                                                    </div>
                                                                </Label>
                                                            </FormGroup>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </FormGroup>
                                            {errorMsg.heartSurgery && <p className='error-message'>{errorMsg.heartSurgery}</p>}
                                        </div>
                                    </>
                                    :null
                                }   
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prevBtn={handlePrevious} submit next={handleSubmit} />
            <Footer />
        </div>
    )
}


export default HeartDisease;