import React from 'react'
import { motion } from 'framer-motion'

function AnimationWrapper(props) {
  return (
    <motion.div
      // initial={{ x: "100%", opacity: 0}}
      // animate={{ x: 0, opacity: 1 }}
      // transition={{ duration: 0.5, delay: 0.5 }}
    >
        {props.children}
    </motion.div>
  )
}

export default AnimationWrapper
