import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Button, Modal, FormGroup, Label, Input, Tooltip } from 'reactstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { motion } from 'framer-motion';
import ModalVideo from 'react-modal-video';
import ReactTooltip from "react-tooltip";
import { pauseWhatsapp, getUserDetailById, destoryWhatappsLink, videoIsWatches, getUserDetailByActivityId } from "../../../helper/ContentConstants";

// Constant ;
import {
    homePageUrl,
    storeContactData, smsApi
} from "../../../helper/ContentConstants";
// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images
import VideoThumb from '../../../assets/images/pause-daily-video/video-thumb.jpg';
import YellowBand from '../../../assets/images/pause-daily-video/yellow-band.svg';

// video 
// import dummyVideo from "../../../assets/videos/test.mp4";

// css
import "../../../assets/sass/custom.scss";
import '../../../../node_modules/react-modal-video/scss/modal-video.scss';

// components
import Footer from "../../layouts/footer/Footer";
import RoutingArrow from "../../shared/RoutingArrow/RoutingArrow";
import { addUserApi } from '../../../helper/ContentConstants';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import axios from 'axios';

// popup 
import FeedbackComplaints from "../../forms/FeedbackComplaints";


// let [videoLinks,setVideoLinks] = useState("")
// let [userId,setUserId] = useState("");
// useEffect(() => {
//     if(location.state == null)
//     {
//         console.log("uid >>",JSON.parse(window.localStorage.getItem('userId')))
//         let uid = JSON.parse(window.localStorage.getItem('userId'));
//         setUserId(uid)
//         console.log("user id >>",userId)
//     }
//     else
//     {
//         console.log("params value is >>",location.state);
//         setVideoLinks(location.state.video_links);
//         let uid = JSON.parse(window.localStorage.getItem('userId'));
//         setUserId(uid)

//         console.log("user id >>",userId)
//     }
//   }, []);

function DiseaseEducationVideo(args) {

    const [modal, setModal] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [modal2, setModal2] = useState(false);

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle2 = () => setTooltipOpen(!tooltipOpen);

    let navigate = useNavigate();
    let [name, setName] = useState("");
    let [mobile, setMobile] = useState("");
    let [email, setEmail] = useState("");
    let [comments, setComments] = useState("");
    let [nameError, setNameError] = useState(false);
    let [mobileError, setMobileError] = useState(false);
    let [emailError, setEmailError] = useState(false);
    let [commentsError, setCommentsError] = useState(false);
    let [emailValidation, setEmailValidation] = useState(false);
    let [mobileMinValidation, setMobileMinValidation] = useState(false);
    let [mobileMaxValidation, setMobileMaxValidation] = useState(false);
    let [days, setDays] = useState(false);
    let [success, setSuccess] = useState(false);
    let [pauseUserCount, setPauseUserCount] = useState("");
    let [close, setClose] = useState(false);
    let [time, setTime] = useState("");
    let [otp, setOtp] = useState(true)
    let [userMobileNumber, setUserMobileNumber] = useState("")
    let [searchParams, setSearchParams] = useSearchParams();
    const randomId = useMemo(() => Math.floor(Math.random() * 100), []);
    const [seconds, setSeconds] = useState(0);
    let [videoLinks, setVideoLinks] = useState("");
    let [day, setDay] = useState("");
    let [diseaseName, setDiseaseName] = useState("");
    let [activityId, setActivityId] = useState("");
    let [adBanner, setAdBanner] = useState("")
    let [adLink, setAdLink] = useState("")
    let [videoType, setVideoType] = useState("")

    let interval;
    useEffect(() => {

        let verify_user = JSON.parse(window.localStorage.getItem('de_verify_user'));
        console.log("Verify user >>", verify_user)
        if (searchParams.get('activityId') != null) {
            window.localStorage.setItem('activityId', JSON.stringify(searchParams.get('activityId')))
            setActivityId(searchParams.get('activityId'))
        }
        else {
            setActivityId(JSON.parse(window.localStorage.getItem('activityId')))
        }
        getUserDetails();
        distoryLink();
        setInterval(() => {
            setSeconds(seconds => seconds + 1)
        }, 1000)

        return () => {
            clearInterval(interval);
            getUserDetails();
            distoryLink();
        };
    }, [success]);

    const videoWatched = async () => {
        setSeconds('');
        const video_watched = await axios({
            method: "get",
            url: videoIsWatches + activityId,
        });
        if (video_watched.data.statusCode == 200) {
            // console.log("value updated successfully");
        }
    };

    const distoryLink = async () => {
        const formData = new FormData();
        console.log("  distory whatapp link >>", destoryWhatappsLink + 10);
        const whatapplink_activate = await axios({
            method: "get",
            url: destoryWhatappsLink + 20,
        });
        if (whatapplink_activate.data.statusCode == 200) {
            if (whatapplink_activate.data.activate === false) {
                navigate(`${homePageUrl}whatsapp-link-failure`);
                console.log("distory whatsapp link response >>", whatapplink_activate.data.activate);
            }
        }
    };


    const storeData = async () => {
        try {
            console.log("name value is >>", name);
            console.log("mobile value is >>", mobile);
            console.log("email value is >>", email);
            console.log("commenst value is >>", comments);
            if (name == "" || name == undefined) {
                setNameError(true);
            } else if (email == "" || email == undefined) {
                setEmailError(true);
            } else if (mobile == "" || mobile == undefined) {
                setMobileError(true);
            } else if (comments == "" || comments == undefined) {
                setCommentsError(true);
            } else {
                const formData = new FormData();
                formData.append("name", name);
                formData.append("mobile", mobile);
                formData.append("email", email);
                formData.append("comments", comments);

                const response = await axios({
                    method: "post",
                    url: storeContactData,
                    data: formData,
                });
                if (response.data.status == 200) {
                    setName("");
                    setEmail("");
                    setMobile("");
                    setComments("");
                    alert("Form sent successfully");
                    setModal(!modal)
                }
            }
        } catch (error) {
            // console.log("error is ", error);
        }
    };

    const hangdleChange = async (e) => {
        console.log("e", e);
        if (e.target.name == "name") {
            setNameError(false);
            setName(e.target.value);
        }
        if (e.target.name == "mobile") {
            if (e.target.value.length > 10) {
                setMobile();
                setMobileMaxValidation(true);
            } else {
                setMobileError(false);
                setMobile(e.target.value);
                setMobileMaxValidation(false);
            }
        }
        if (e.target.name == "email") {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
                console.log("Invalid email");
                setEmailValidation(true);
            } else {
                setEmailError(false);
                setEmail(e.target.value);
                setEmailValidation(false);
                console.log("Valid email");
            }
        }
        if (e.target.name == "comments") {
            setCommentsError(false);
            setComments(e.target.value);
        }
    }
    const toggle = () => {
        setModal(!modal)
    };
    const toggle3 = () => {
        setModal2(!modal2)
    };

    const getUserDetails = async () => {
        let a_id = JSON.parse(window.localStorage.getItem('activityId'));
        console.log("first", a_id)
        const formData = new FormData();
        console.log("  video at >>", getUserDetailById);
        formData.append("id", a_id);
        const pause_count = await axios({
            method: "post",
            url: getUserDetailByActivityId,
            data: formData,
        });

        console.log("response is >>2", pause_count)
        if (pause_count.data.statusCode == 200) {
            window.localStorage.setItem('userId', JSON.stringify(pause_count.data.users.id))
            setPauseUserCount(pause_count.data.users.pause_count);
            setUserMobileNumber(pause_count.data.users.mobile)
            setVideoLinks(pause_count.data.users.video_links);
            setDay(pause_count.data.users.u_day);
            setDiseaseName(pause_count.data.disease_name[0]);
            setAdLink(pause_count.data.users.add_link);
            setAdBanner(pause_count.data.users.ad_banner);
            setVideoType(pause_count.data.users.video_type);
        }
    };

    const pauseUserWhatsapp = async () => {
        const formData = new FormData();
        formData.append("pause_days", days);
        formData.append("id", activityId);
        console.log("  video at >>", pauseWhatsapp);
        const pause_days = await axios({
            method: "post",
            url: pauseWhatsapp,
            data: formData,
        });
        if (pause_days.data.statusCode == 200) {

            setTimeout(() => {
                toastr.options = {
                    positionClass: 'toast-top-full-width',
                    hideDuration: 300,
                    timeOut: 2000
                }
                toastr.clear()
                //   navigate(`${homePageUrl}subscription-home`);
                toggle3();

                //   setTimeout(() => toastr.error('Video is paused'), 200)
            }, 1000);
            //   navigate(`${homePageUrl}subscription-home`,{
            //   state:{userValue}
            //    });
            console.log("post payment reposense >>", pause_days.data.status);

        }
        console.log("post payment reposense >>", pause_days.data.status);
    };

    const sendOtp = async () => {
        try {
            const formData = new FormData()
            formData.append('mobile', userMobileNumber)
            const response = await axios({
                method: "post",
                url: smsApi,
                data: formData,
            });
            // console.log("Response is >>", response);
            if (response.data.statusCode == 200) {
                setTimeout(() => {
                    setOtp(response.data.otp);
                    setSuccess(true)
                    toastr.options = {
                        positionClass: 'toast-top-full-width',
                        hideDuration: 300,
                        timeOut: 2000
                    }
                    toastr.clear()
                    setTimeout(() => toastr.success('OTP Send Successfully'), 200)
                });

                //   sendWhatsapp();
                //   navigate(`${homePageUrl}health-score`);
            }
        } catch (error) {
            // console.log("error is ", error)
        }
    }

    return (


        <div className="main" >
            {seconds == 15 && videoWatched()}
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className="align-items-center justify-content-center">
                            <Col xl={7} lg={7} md={12} className="text-center">
                                <div className="bs-heading typ-full-width typ-center">
                                    <h2 className="heading">Today is your Day {day} of Excercise Program</h2>
                                    <p className="green">Madhavbaug Save My Heart Program</p>
                                    <p className="cm-para mb-40">We will focus on “{videoType}” for your {diseaseName}</p>
                                </div>
                                <iframe
                                    className='iframe'
                                    title="vimeo-player"
                                    src={videoLinks}
                                    width="100%"
                                    height="360"
                                    frameborder="0"
                                    webkitAllowFullScreen mozallowfullscreen allowFullScreen
                                ></iframe>
                                <picture onClick={() => { window.open(adLink, '_blank') }} className="mb-on w-100 c-pointer">
                                    <source media="(max-width:992px)" srcSet={adBanner} />
                                    <img src={adBanner} alt="band" />
                                </picture>


                                {pauseUserCount < 3 ?
                                    <div className="bs-tooltip">
                                        <div className="tooltip-click">
                                            <Link to="" onClick={toggle3}>
                                                Pause Daily Video
                                            </Link>
                                            <a data-tip data-for="registerTip">
                                                <span class="icon icon-tooltip"></span>
                                            </a>
                                        </div>
                                    </div> : ''}
                                <FeedbackComplaints />
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            {/* <RoutingArrow
                submit
                prevBtn={handlePrevious}
                next={handleSubmit}
            /> */}
            <Footer />
            <ModalVideo channel='vimeo' autoplay isOpen={isOpen} videoId="76979871?h=8272103f6e" onClose={() => setOpen(false)} />
            <Modal isOpen={modal2} toggle={toggle3} centered={true} backdrop={true}>
                <div className='bs-custom-popup typ-no-bg show'>
                    <div className='bs-card typ-popup'>
                        <p class="cm-para typ-bold typ-small typ-black mb-10">Select how many days you want to pause videos?</p>
                        <div className='bs-form typ-form-check typ-auto-height'>
                            <FormGroup>
                                <FormGroup className="check-box typ-popup" check>
                                    <Label check>
                                        <Input type="radio" name="days" onChange={() => setDays(1)} />
                                        <div className='checkbox-label'>
                                            1 day<span className="icon icon-right"></span>
                                        </div>
                                    </Label>
                                </FormGroup>
                                <FormGroup className="check-box typ-popup" check>
                                    <Label check>
                                        <Input type="radio" name="days" onChange={() => setDays(2)} />
                                        <div className='checkbox-label'>
                                            2 days<span className="icon icon-right"></span>
                                        </div>
                                    </Label>
                                </FormGroup>
                                <FormGroup className="check-box typ-popup" check>
                                    <Label check>
                                        <Input type="radio" name="days" onChange={() => setDays(3)} />
                                        <div className='checkbox-label'>
                                            3 days<span className="icon icon-right"></span>
                                        </div>
                                    </Label>
                                </FormGroup>
                            </FormGroup>
                            <Button type="submit" className='bs-btn'
                                onClick={() => pauseUserWhatsapp()}
                            >
                                Select
                            </Button>
                        </div>
                        <a className="close close-popup" onClick={toggle3}>
                            <span class="icon icon-check-close"></span>
                        </a>
                    </div>
                </div>
            </Modal>
            <div className="bs-tooltip">
                <ReactTooltip id="registerTip" className="tooltip-content" place="right" effect="solid">
                    <div className="tooltip-content">
                        <p>
                            You can pause the daily videos for a maximum of 3 days, after which it will resume automaticaly
                        </p>
                    </div>
                </ReactTooltip>
            </div>
        </div>
    );
}

export default DiseaseEducationVideo;
