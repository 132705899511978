import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

//constant
import { homePageUrl,addVitalDetails } from "../../../helper/ContentConstants";
// animation
import AnimationWrapper from "../../shared/animation-wrapper/AnimationWrapper";

// images

// css
import "../../../assets/sass/custom.scss";

// components
import Footer from "../../layouts/footer/Footer";
import RoutingArrow from "../../shared/RoutingArrow/RoutingArrow";
import { addUserApi } from "../../../helper/ContentConstants";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import axios from "axios";

function RecordSubmitted() {
  let navigate = useNavigate();

  const handlePrevious = () => {
    navigate(`${homePageUrl}blood-pressure3`);
  };

  //Get all local stored value

  //BMI detail
  const weight = JSON.parse(window.localStorage.getItem("weight"));

  const fastingSugar = JSON.parse(window.localStorage.getItem("fasting-sugar"));
  const postFastingSugar = JSON.parse(
    window.localStorage.getItem("post-fasting-sugar")
  );
  const hbac1 = JSON.parse(window.localStorage.getItem("hbac1"));

  //blood pressure
  const systolicUpperBp = JSON.parse(
    window.localStorage.getItem("systolic-upper-bp")
  );
  const diastolicLowerBp = JSON.parse(
    window.localStorage.getItem("diastolic-lower-bp")
  );

  const userId = JSON.parse(
    window.localStorage.getItem("userId")
  );

  useEffect(() => {
    console.log("weight vallue is >>", weight);
    console.log("fasting sugar value is >>", fastingSugar);
    console.log("postfastingsugar value is >>", postFastingSugar);
    console.log("hbac1 value is >>", hbac1);
    console.log("systolic upperbp value is >>", systolicUpperBp);
    console.log("diastolicLowerBp value is >>", diastolicLowerBp);
    saveVitalDetails();
  });

  const saveVitalDetails = async () => {
    try {
      const formData = new FormData();
        formData.append("user_id", userId);
        formData.append("bp_high", systolicUpperBp);
        formData.append("bp_low", diastolicLowerBp);
        formData.append("sugar_fasting", fastingSugar);
        formData.append("sugar_after_meal", postFastingSugar);
        formData.append("hbac1", hbac1);
        formData.append("weight", weight);


      const response = await axios({
        method: "post",
        url: addVitalDetails,
        data: formData,
      });
      console.log("Response is >>", response);
      if (response.data.status == 200) {
        console.log(response.data);
        toastr.options = {
          positionClass : 'toast-top-full-width',
          hideDuration: 300,
          timeOut: 2000
        }
        toastr.clear()
        setTimeout(() => toastr.success('Data saved Successfully'), 200)
        // window.localStorage.clear();
      }
    } catch (error) {
      toastr.options = {
        positionClass: "toast-top-full-width",
        hideDuration: 300,
        timeOut: 2000,
      };
      toastr.clear();
      setTimeout(() => toastr.error("Something Went Wrong"), 200);
    }
  };

  return (
    <div className="main">
      <Container>
        <AnimationWrapper>
          <motion.div exit={{ x: "-100%", opacity: 0 }} className="bs-card">
            <Row className="align-items-center">
              <Col
                xl={{ size: 6, offset: 6 }}
                lg={{ size: 6, offset: 6 }}
                md={12}
                sm={6}
              >
                {isMobile && (
                  <div className="bs-heading typ-center">
                    <h2 className="heading typ-max-width mb-40">
                      Record Submitted Successfully
                    </h2>
                    <p className="subheading">
                      The record has been successfully submitted
                    </p>
                  </div>
                )}
              </Col>
              <Col xl="6" lg="6" md="12">
                <div className="bs-img-sprite typ-hafway-done bg-1"></div>
              </Col>
              <Col xl="6" lg="6" md="12">
                {isBrowser && (
                  <div className="bs-heading typ-center">
                    <h2 className="heading typ-max-width mb-40">
                      Record Submitted Successfully
                    </h2>
                    <p className="subheading">
                      The record has been successfully submitted
                    </p>
                  </div>
                )}
                {/* <p className="cm-para typ-center typ-max-wid">
                  Continue filling up the details and we will take care of your
                  comfort and healthy lifestyle.
                </p> */}
              </Col>
            </Row>
          </motion.div>
        </AnimationWrapper>
      </Container>
      <RoutingArrow submit prevBtn={handlePrevious} />
      <Footer />
    </div>
  );
}

export default RecordSubmitted;
