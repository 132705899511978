import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from 'reactstrap';
import _range from "lodash/range";
import Picker from "react-scrollable-picker";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';


//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// css
import '../../../assets/sass/custom.scss';

// json 
// male below 35 
import defaultDiseaseMaleBelow35 from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import happyMaleBelow35 from '../../../assets/json/expression/male/below35/happy.json';
import veryHappyMaleBelow35 from '../../../assets/json/expression/male/below35/very-happy.json';
import sadMaleBelow35 from '../../../assets/json/expression/male/below35/sad.json';
import verySadMaleBelow35 from '../../../assets/json/expression/male/below35/very-sad.json';

// male above 35 
import defaultDiseaseMaleAbove35 from '../../../assets/json/default/male/above35/none-of-the-option-is-selected.json';
import happyMaleAbove35 from '../../../assets/json/expression/male/above35/happy.json';
import veryHappyMaleAbove35 from '../../../assets/json/expression/male/above35/very-happy.json';
import sadMaleAbove35 from '../../../assets/json/expression/male/above35/sad.json';
import verySadMaleAbove35 from '../../../assets/json/expression/male/above35/very-sad.json';

// male above 72 
import defaultDiseaseMaleAbove72 from '../../../assets/json/default/male/above72/none-of-the-option-is-selected.json';
import happyMaleAbove72 from '../../../assets/json/expression/male/above72/happy.json';
import veryHappyMaleAbove72 from '../../../assets/json/expression/male/above72/very-happy.json';
import sadMaleAbove72 from '../../../assets/json/expression/male/above72/sad.json';
import verySadMaleAbove72 from '../../../assets/json/expression/male/above72/very-sad.json';

// // female below 35 
import defaultDiseaseFemaleBelow35 from '../../../assets/json/default/female/below35/none-of-the-option-is-selected.json';
import happyFemaleBelow35 from '../../../assets/json/expression/female/below35/happy.json';
import veryHappyFemaleBelow35 from '../../../assets/json/expression/female/below35/very-happy.json';
import sadFemaleBelow35 from '../../../assets/json/expression/female/below35/sad.json';
import verySadFemaleBelow35 from '../../../assets/json/expression/female/below35/v-sad.json';

// // female above 35 
import defaultDiseaseFemaleAbove35 from '../../../assets/json/default/female/above35/none-of-the-option-is-selected.json';
import happyFemaleAbove35 from '../../../assets/json/expression/female/above35/happy.json';
import veryHappyFemaleAbove35 from '../../../assets/json/expression/female/above35/very-happy.json';
import sadFemaleAbove35 from '../../../assets/json/expression/female/above35/sad.json';
import verySadFemaleAbove35 from '../../../assets/json/expression/female/above35/very-sad.json';

// // female above 72
import defaultDiseaseFemaleAbove72 from '../../../assets/json/default/female/above72/none-of-the-option-is-selected.json';
import happyFemaleAbove72 from '../../../assets/json/expression/female/above72/happy.json';
import veryHappyFemaleAbove72 from '../../../assets/json/expression/female/above72/very-happy.json';
import sadFemaleAbove72 from '../../../assets/json/expression/female/above72/sad.json';
import verySadFemaleAbove72 from '../../../assets/json/expression/female/above72/very-sad.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function BloodPressure2() {
    const { t, i18n } = useTranslation();

    let navigate = useNavigate()

    const initialValues = { systolicUpperBp: 5, diastolicLowerBp: 3 }
    let [contentValues, setContentValues] = useState(initialValues)
    let [events, setEvents] = useState("")
    const [lottiePlayer, setLottiePlayer] = useState("");

    let contentData = {
        options: [
            {
                radio_button: [
                    {
                        rId: 1, radio_text: 'Happy', gender: {
                            Male: {
                                default: {
                                    below35: defaultDiseaseMaleBelow35,
                                    above35: defaultDiseaseMaleAbove35,
                                    above72: defaultDiseaseMaleAbove72,
                                },
                                below35: happyMaleBelow35,
                                above35: happyMaleAbove35,
                                above72: happyMaleAbove72
                            },
                            Female: {
                                default: {
                                    below35: defaultDiseaseFemaleBelow35,
                                    above35: defaultDiseaseFemaleAbove35,
                                    above72: defaultDiseaseFemaleAbove72,
                                },
                                below35: happyFemaleBelow35,
                                above35: happyFemaleAbove35,
                                above72: happyFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 2, radio_text: 'Very Happy', gender: {
                            Male: {
                                below35: veryHappyMaleBelow35,
                                above35: veryHappyMaleAbove35,
                                above72: veryHappyMaleAbove72
                            },
                            Female: {
                                below35: veryHappyFemaleBelow35,
                                above35: veryHappyFemaleAbove35,
                                above72: veryHappyFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 3, radio_text: 'Sad', gender: {
                            Male: {
                                below35: sadMaleBelow35,
                                above35: sadMaleAbove35,
                                above72: sadMaleAbove72
                            },
                            Female: {
                                below35: sadFemaleBelow35,
                                above35: sadFemaleAbove35,
                                above72: sadFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 4, radio_text: 'Very Sad', gender: {
                            Male: {
                                below35: verySadMaleBelow35,
                                above35: verySadMaleAbove35,
                                above72: verySadMaleAbove72
                            },
                            Female: {
                                below35: verySadFemaleBelow35,
                                above35: verySadFemaleAbove35,
                                above72: verySadFemaleAbove72
                            }
                        }
                    },
                ]
            }
        ]
    }


    // Slider weight loop
    const createElementsSystolicUpperBp = (number) => {
        var elements = [];
        let i;
        for (i = 49; i < number; i++) {
            elements.push(<SwiperSlide key={i} index={i}>{i}</SwiperSlide>)
        }
        return elements;
    }
    // Slider weight loop
    const createElementsDiastolicLowerBp = (number) => {
        var elements = [];
        let i;
        for (i = 29; i < number; i++) {
            elements.push(<SwiperSlide key={i} index={i}>{i}</SwiperSlide>)
        }
        return elements;
    }

    //Swiper slide change for weight 
    const handleSystolicUpperBp = (event) => {
        let activeIndex = event.realIndex
        contentValues.systolicUpperBp = activeIndex + 1
        setContentValues({ systolicUpperBp: contentValues.systolicUpperBp })
        window.localStorage.setItem('systolic-upper-bp', JSON.stringify(contentValues.systolicUpperBp + 48))
        setEvents(!events)
    }
    //Swiper slide change for weight 
    const handleDiastolicLowerBp = (event) => {
        let activeIndex = event.realIndex
        contentValues.diastolicLowerBp = activeIndex + 1
        setContentValues({ diastolicLowerBp: contentValues.diastolicLowerBp })
        window.localStorage.setItem('diastolic-lower-bp', JSON.stringify(contentValues.diastolicLowerBp + 28))
        setEvents(!events)
    }

    useEffect(() => {
        const systolicUpperBp = JSON.parse(window.localStorage.getItem('systolic-upper-bp'))
        const diastolicLowerBp = JSON.parse(window.localStorage.getItem('diastolic-lower-bp'))
        console.log("systolic upper bp value is >>", systolicUpperBp);
        console.log("diastolc lower bp value is >>", diastolicLowerBp);
        setContentValues({ systolicUpperBp: systolicUpperBp, diastolicLowerBp: diastolicLowerBp })
        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        let sadData = [(systolicUpperBp >= 80 && systolicUpperBp >= 89), (systolicUpperBp >= 121 && systolicUpperBp >= 132), (diastolicLowerBp >= 50 && diastolicLowerBp >= 59), (diastolicLowerBp >= 81 && diastolicLowerBp >= 89)]
        let vSadData = [(systolicUpperBp <= 79), (systolicUpperBp >= 133 && systolicUpperBp >= 221)]
        if (JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en") {
            let lang = "en";
            handleClick(lang);
        }
        else {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }

        if (systolicUpperBp == null && diastolicLowerBp == null) {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
            console.log('default')
        }

    }, [])

    useEffect(() => {
        const systolicUpperBp = JSON.parse(window.localStorage.getItem('systolic-upper-bp'))
        const diastolicLowerBp = JSON.parse(window.localStorage.getItem('diastolic-lower-bp'))
        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        let sadData = [(systolicUpperBp >= 80 && systolicUpperBp <= 89), (systolicUpperBp >= 121 && systolicUpperBp <= 132), (diastolicLowerBp >= 50 && diastolicLowerBp <= 59), (diastolicLowerBp >= 81 && diastolicLowerBp <= 89)]
        let vSadData = [(systolicUpperBp <= 79), (systolicUpperBp >= 133 && systolicUpperBp <= 221)]

        let sadCount = sadData.filter(rep => rep == true).length
        let vSadCount = vSadData.filter(rep => rep == true).length
        console.log(sadCount, 'sad')
        console.log(vSadCount, 'v sad')

        if (systolicUpperBp == null && diastolicLowerBp == null) {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
            console.log('default')
        } else if (sadCount == 0 && vSadCount == 0) {
            let lottie = contentData.options[0].radio_button[0].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            console.log(lottie, 'happy')
            setLottiePlayer(lottie)
        } else if ((sadCount >= 1) && (vSadCount == 0)) {
            let lottie = contentData.options[0].radio_button[2].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            console.log(lottie, 'sad')
            setLottiePlayer(lottie)
        } else if (vSadCount >= 1) {
            let lottie = contentData.options[0].radio_button[3].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            console.log(lottie, 'v sad')
            setLottiePlayer(lottie)
        }

    }, [events])

    const handleClick = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t)
    }


    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className='align-items-center'>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('BloodPressure2.heading')}
                                        </h2>
                                        <p className="subheading">
                                            {t('BloodPressure2.subheading')}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                    loop
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('BloodPressure2.heading')}
                                        </h2>
                                        <p className="subheading">
                                            {t('BloodPressure2.subheading')}
                                        </p>
                                    </div>
                                }
                                <div className='bs-swiper mb-40'>
                                    <p className='slider-title'>
                                        {t('BloodPressure2.Systolic')}
                                    </p>
                                    <div className='swiper-parent'>
                                        <Swiper
                                            initialSlide={JSON.parse(window.localStorage.getItem('systolic-upper-bp')) === null ? 71 : JSON.parse(window.localStorage.getItem('systolic-upper-bp')) - 49}
                                            className='age-swiper' spaceBetween={0} grabCursor={false} slidesPerView={7} slideToClickedSlide={true} centeredSlides='true'
                                            breakpoints={{
                                                767: {
                                                    slidesPerView: 5,
                                                },
                                                992: {
                                                    slidesPerView: 5,
                                                },
                                                1024: {
                                                    slidesPerView: 5,
                                                },
                                                1366: {
                                                    slidesPerView: 9,
                                                },
                                            }} onSlideChange={e => handleSystolicUpperBp(e)}
                                        // onSwiper={getAge}
                                        >
                                            {createElementsSystolicUpperBp(251)}
                                        </Swiper>
                                    </div>
                                </div>
                                <div className='bs-swiper'>
                                    <p className='slider-title'>
                                        {t('BloodPressure2.Diastolic')}
                                    </p>
                                    <div className='swiper-parent'>
                                        <Swiper className='age-swiper'
                                            initialSlide={JSON.parse(window.localStorage.getItem('diastolic-lower-bp')) === null ? 51 : JSON.parse(window.localStorage.getItem('diastolic-lower-bp')) - 29}
                                            spaceBetween={0} grabCursor={false} slidesPerView={7} slideToClickedSlide={true} centeredSlides='true'
                                            breakpoints={{
                                                767: {
                                                    slidesPerView: 5,
                                                },
                                                992: {
                                                    slidesPerView: 5,
                                                },
                                                1024: {
                                                    slidesPerView: 5,
                                                },
                                                1366: {
                                                    slidesPerView: 9,
                                                },
                                            }} onSlideChange={e => handleDiastolicLowerBp(e)}
                                        // onSwiper={getAge}
                                        >
                                            {createElementsDiastolicLowerBp(161)}
                                        </Swiper>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prev={`${homePageUrl}blood-pressure`} next={`${homePageUrl}almost-done`} />
            <Footer />
        </div>
    );
}

export default BloodPressure2;