import React, { useState, useEffect } from 'react';
import { Container, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// css
import '../../../assets/sass/custom.scss';

// helper 
import handleLottie from '../../../helper/lottieFunction';

// json 
// male below 35 
import defaultSleepMaleBelow35 from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import lessFourSleepMaleBelow35 from '../../../assets/json/sleep/male/below35/less-than-4-hrs.json';
import fourFiveSleepMaleBelow35 from '../../../assets/json/sleep/male/below35/4-5-hrs.json';
import fiveSixSleepMaleBelow35 from '../../../assets/json/sleep/male/below35/5-6-hrs.json';
import sixSevenSleepMaleBelow35 from '../../../assets/json/sleep/male/below35/6-7-hrs.json';
import sevenMoreMaleBelow35 from '../../../assets/json/sleep/male/below35/7-hrs-or-more.json';

// male above 35 
import defaultSleepMaleAbove35 from '../../../assets/json/default/male/above35/none-of-the-option-is-selected.json';
import lessFourSleepMaleAbove35 from '../../../assets/json/sleep/male/above35/less-than-4-hrs.json';
import fourFiveSleepMaleAbove35 from '../../../assets/json/sleep/male/above35/4-5-hrs.json';
import fiveSixSleepMaleAbove35 from '../../../assets/json/sleep/male/above35/5-6-hrs.json';
import sixSevenSleepMaleAbove35 from '../../../assets/json/sleep/male/above35/6-7-hrs.json';
import sevenMoreMaleAbove35 from '../../../assets/json/sleep/male/above35/7-hrs-or-more.json';

// male above 72 
import defaultSleepMaleAbove72 from '../../../assets/json/default/male/above72/none-of-the-option-is-selected.json';
import lessFourSleepMaleAbove72 from '../../../assets/json/sleep/male/above72/less-than-4-hrs.json';
import fourFiveSleepMaleAbove72 from '../../../assets/json/sleep/male/above72/4-5-hrs.json';
import fiveSixSleepMaleAbove72 from '../../../assets/json/sleep/male/above72/5-6-hrs.json';
import sixSevenSleepMaleAbove72 from '../../../assets/json/sleep/male/above72/6-7-hrs.json';
import sevenMoreMaleAbove72 from '../../../assets/json/sleep/male/above72/7-hrs-or-more.json';

// // female below 35 
import defaultSleepFemaleBelow35 from '../../../assets/json/default/female/below35/none-of-the-option-is-selected.json';
import lessFourSleepFemaleBelow35 from '../../../assets/json/sleep/female/below35/sleep-less-than-4-hrs.json';
import fourFiveSleepFemaleBelow35 from '../../../assets/json/sleep/female/below35/4-5hrs.json';
import fiveSixSleepFemaleBelow35 from '../../../assets/json/sleep/female/below35/5-6-hrs-lottie.json';
import sixSevenSleepFemaleBelow35 from '../../../assets/json/sleep/female/below35/6-7-hrs-lottie.json';
import sevenMoreFemaleBelow35 from '../../../assets/json/sleep/female/below35/more-than-7hrs.json';

// // female above 35 
import defaultSleepFemaleAbove35 from '../../../assets/json/default/female/above35/none-of-the-option-is-selected.json';
import lessFourSleepFemaleAbove35 from '../../../assets/json/sleep/female/above35/less-than-4hrs.json';
import fourFiveSleepFemaleAbove35 from '../../../assets/json/sleep/female/above35/4-5-hrs.json';
import fiveSixSleepFemaleAbove35 from '../../../assets/json/sleep/female/above35/5-6-hrs.json';
import sixSevenSleepFemaleAbove35 from '../../../assets/json/sleep/female/above35/6-7hrs.json';
import sevenMoreFemaleAbove35 from '../../../assets/json/sleep/female/above35/more-then-7hrs.json';

// // female above 72
import defaultSleepFemaleAbove72 from '../../../assets/json/default/female/above72/none-of-the-option-is-selected.json';
import lessFourSleepFemaleAbove72 from '../../../assets/json/sleep/female/above72/less-than-4hrs.json';
import fourFiveSleepFemaleAbove72 from '../../../assets/json/sleep/female/above72/4-5hrs-sleeping.json';
import fiveSixSleepFemaleAbove72 from '../../../assets/json/sleep/female/above72/5-6hrs-sleeping.json';
import sixSevenSleepFemaleAbove72 from '../../../assets/json/sleep/female/above72/6-7hrs-sleeping.json';
import sevenMoreFemaleAbove72 from '../../../assets/json/sleep/female/above72/more-than-7hrs.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
function SleepHours() {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate()
    let [language, setLanguage] = useState("")
    let [averageSleep, setAverageSleep] = useState('')
    let [errorMsg, setErrorMsg] = useState({ sleephour: "", excercise: "", vegetableInTake: "" })
    const [lottiePlayer, setLottiePlayer] = useState("");

    let contentData = {
        qId: 5,
        question_text: t('LifeStyleAssessment.SleepHours.question_text'),
        descriptions: t('LifeStyleAssessment.SleepHours.descriptions'),
        options: [
            {
                qId: 5,
                option_id: 1,
                option_text: t('LifeStyleAssessment.SleepHours.option_text'),
                imgClass: 'bg-1',
                radio_button: [
                    {
                        rId: 1, radio_text: t('LifeStyleAssessment.SleepHours.rId1'), gender: {
                            Male: {
                                default: {
                                    below35: defaultSleepMaleBelow35,
                                    above35: defaultSleepMaleAbove35,
                                    above72: defaultSleepMaleAbove72,
                                },
                                below35: lessFourSleepMaleBelow35,
                                above35: lessFourSleepMaleAbove35,
                                above72: lessFourSleepMaleAbove72
                            },
                            Female: {
                                default: {
                                    below35: defaultSleepFemaleBelow35,
                                    above35: defaultSleepFemaleAbove35,
                                    above72: defaultSleepFemaleAbove72,
                                },
                                below35: lessFourSleepFemaleBelow35,
                                above35: lessFourSleepFemaleAbove35,
                                above72: lessFourSleepFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 2, radio_text: t('LifeStyleAssessment.SleepHours.rId2'), gender: {
                            Male: {
                                below35: fourFiveSleepMaleBelow35,
                                above35: fourFiveSleepMaleAbove35,
                                above72: fourFiveSleepMaleAbove72
                            },
                            Female: {
                                below35: fourFiveSleepFemaleBelow35,
                                above35: fourFiveSleepFemaleAbove35,
                                above72: fourFiveSleepFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 3, radio_text: t('LifeStyleAssessment.SleepHours.rId3'), gender: {
                            Male: {
                                below35: fiveSixSleepMaleBelow35,
                                above35: fiveSixSleepMaleAbove35,
                                above72: fiveSixSleepMaleAbove72
                            },
                            Female: {
                                below35: fiveSixSleepFemaleBelow35,
                                above35: fiveSixSleepFemaleAbove35,
                                above72: fiveSixSleepFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 4, radio_text: t('LifeStyleAssessment.SleepHours.rId4'), gender: {
                            Male: {
                                below35: sixSevenSleepMaleBelow35,
                                above35: sixSevenSleepMaleAbove35,
                                above72: sixSevenSleepMaleAbove72
                            },
                            Female: {
                                below35: sixSevenSleepFemaleBelow35,
                                above35: sixSevenSleepFemaleAbove35,
                                above72: sixSevenSleepFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 5, radio_text: t('LifeStyleAssessment.SleepHours.rId5'), gender: {
                            Male: {
                                below35: sevenMoreMaleBelow35,
                                above35: sevenMoreMaleAbove35,
                                above72: sevenMoreMaleAbove72
                            },
                            Female: {
                                below35: sevenMoreFemaleBelow35,
                                above35: sevenMoreFemaleAbove35,
                                above72: sevenMoreFemaleAbove72
                            }
                        }
                    }
                ]
            }
        ]
    }

    const handleSleep = (e, key) => {
        let ha;
        if (key == 1) {
            ha = "Less than 4 hours";
        }
        else if (key == 2) {
            ha = "4 to 5 hours";
        }
        else if (key == 3) {
            ha = "5 to 6 hours"
        }
        else if (key == 4) {
            ha = "6 to 7 hours"
        }
        else if (key == 5) {
            ha = "7 hours or More";
        }
        averageSleep = contentData.options[0].radio_button[key - 1].radio_text;
        setAverageSleep(averageSleep)
        setErrorMsg({ sleephour: "" })
        window.localStorage.setItem('sleephour', JSON.stringify(ha))
        window.localStorage.setItem('sleephour1', JSON.stringify(averageSleep))
        let lottie = handleLottie(key, contentData)
        setLottiePlayer(lottie);
    }

    const handleSubmit = () => {
        const sleephour = window.localStorage.getItem('sleephour')
        if (sleephour) {
            setErrorMsg({ sleephour: "" })
            navigate(`${homePageUrl}exercise`)
        }
        else {
            setErrorMsg({ sleephour: t('Messages.LifestyleAssessment.1') })
        }
    }

    const handleNavigationLottie = () => {
        let sleephour = window.localStorage.getItem('sleephour')
        let sleephour1 = window.localStorage.getItem('sleephour1')
        setAverageSleep(JSON.parse(sleephour))

        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        if (sleephour == null) {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            console.log(lottie)
            setLottiePlayer(lottie);
        } else {
            sleephour = sleephour.replaceAll('"', '')
            sleephour1 = sleephour1.replaceAll('"', '')
            if (JSON.parse(window.localStorage.getItem('lang')) == "en") {
                let id = contentData.options[0].radio_button.find((rep) => rep.radio_text == sleephour).rId
                handleSleep(1, id)
            }
            else {
                let id = contentData.options[0].radio_button.find((rep) => rep.radio_text == sleephour1).rId
                handleSleep(1, id)
            }

        }
    }

    //get local storage
    useEffect(() => {
        const sleephour = window.localStorage.getItem('sleephour')
        setAverageSleep(JSON.parse(sleephour))
        if (JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en") {
            let lang = "en";
            handleClick(lang);
        }
        else {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
        handleNavigationLottie()
    }, [])

    const handleClick = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t)
        setLanguage(lang);
    }

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row>
                            <Col xl={{ offset: 6, size: 6 }} lg={{ offset: 6, size: 6 }} md="12">
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                                <div>
                                    <p className='cm-para typ-medium typ-center typ-form-title typ-mob-left typ-max-wid'>
                                        {contentData.options[0].option_text}
                                    </p>
                                </div>

                                <div>
                                    <div className='bs-form mt-0'>
                                        <FormGroup className='radio-box typ-timeline' tag="fieldset">
                                            {contentData.options[0].radio_button.map((item) => {
                                                return (
                                                    <React.Fragment key={item.rId}>
                                                        {
                                                            console.log("rd1 value is >>", item.radio_text)
                                                        }
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input type="radio"
                                                                    name="sleep"
                                                                    // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                                                    value={item.radio_text}
                                                                    checked={item.radio_text === averageSleep}
                                                                    onChange={(e) => handleSleep(e, item.rId)} />{' '}
                                                                <div className='check-box-label'>
                                                                    <div className='circle-box'>
                                                                        <div className='circle'></div>
                                                                    </div>
                                                                    <p className='radio-txt'>
                                                                        {item.radio_text}
                                                                    </p>
                                                                </div>
                                                            </Label>
                                                        </FormGroup>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </FormGroup>
                                        {errorMsg.sleephour && <p className='error-message'>{errorMsg.sleephour}</p>}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <Footer />
            <RoutingArrow prev={`${homePageUrl}general-details2`} submit next={handleSubmit} />
        </div>
    )
}

export default SleepHours;