import React, { useState, useEffect } from 'react';
import { Container, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { useNavigate,useSearchParams } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';

//constant
import { homePageUrl,getUserDetailById, getUserDetailByUserId } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// css
import '../../../assets/sass/custom.scss';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import axios from "axios";

function Assessment() {

    let navigate = useNavigate()

    let [averageSleep, setAverageSleep] = useState('')
    let [ errorMsg, setErrorMsg ] = useState({sleephour: "", excercise: "", vegetableInTake: ""})
    let [searchParams, setSearchParams] = useSearchParams();

    let contentData = {
        qId: 5,
        question_text: 'Health Analysis',
        descriptions: ['Mapping your daily habits for a better health analysis'],
        options: [
            {
                qId: 5,
                option_id: 1,
                option_text: 'On an average basis how many hours do you sleep at night?',
                imgClass: 'bg-1',
                radio_button: [
                    { rId: 1, radio_text: 'Less than 4 hours' },
                    { rId: 2, radio_text: '4 to 5 hours' },
                    { rId: 3, radio_text: '5 to 6 hours' },
                    { rId: 4, radio_text: '6 to 7 hours' },
                    { rId: 5, radio_text: '7 hours or More' }
                ]
            }
        ]
    }

    const handleSleep = (e, key) => {
        averageSleep = contentData.options[0].radio_button[key-1].radio_text;
        setAverageSleep(averageSleep)
        setErrorMsg({ sleephour: "" })
        window.localStorage.setItem('sleephour', JSON.stringify(averageSleep))
    }

    const handleSubmit = () => {
        const sleephour = window.localStorage.getItem('sleephour')
        if (sleephour) {
            setErrorMsg({ sleephour: "" })
            navigate(`${homePageUrl}exercise`)
        }
        else {
            setErrorMsg({ sleephour: "Choose any one option to continue" })
        }
    }

    //get local storage
    useEffect(() => {
        localStorage.setItem('assessment',true)
        const sleephour = window.localStorage.getItem('sleephour')
        setAverageSleep(JSON.parse(sleephour))
        if(searchParams.get('userId')!= null || searchParams.get('userId') != undefined)
        {
            console.log("Here")
            const id = searchParams.get('userId') 
            window.localStorage.setItem('userId', JSON.stringify(id))
            console.log("user id 1>>",JSON.parse(window.localStorage.getItem('userId')))
            getUserData(id);
        }
    }, [])

    const getUserData = async (id) => {
        const formData = new FormData()
        formData.append('userId', id)
        // formData.append('uid', "12456")
        const response = await axios({
            method: "post",
            url: getUserDetailByUserId,
            data: formData,
        });
        console.log("response is >>",response.data.users)
        if(response.status == 200)
        {
            window.localStorage.setItem('mobile', JSON.stringify(response.data.users.mobile))
            window.localStorage.setItem('lastID', JSON.stringify(response.data.users.id))
            window.localStorage.setItem('name', JSON.stringify(response.data.users.firstName))
            window.localStorage.setItem('email', JSON.stringify(response.data.users.email))
            window.localStorage.setItem('age', JSON.stringify(response.data.users.age))
            window.localStorage.setItem('gender', JSON.stringify(response.data.users.gender))
            window.localStorage.setItem('assessment', JSON.stringify(true))
        }
}

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{x: "-100%", opacity: 0 }} className='bs-card'>
                    <Row>
                        <Col xl={{ offset: 6, size: 6 }} lg={{ offset: 6, size: 6 }} md="12">
                            {
                                isMobile &&
                                <div className="bs-heading typ-center">
                                    <h2 className="heading">
                                        {contentData.question_text}
                                    </h2>
                                    <p className="subheading">
                                        {contentData.descriptions}
                                    </p>
                                </div>
                            }
                        </Col>
                        <Col xl="6" lg="6" md="12">
                            <div className={`bs-img-sprite typ-lifestyle-assessment ${contentData.options[0].imgClass}`}>
                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="12">
                            {
                                isBrowser &&
                                <div className="bs-heading typ-center">
                                    <h2 className="heading">
                                        {contentData.question_text}
                                    </h2>
                                    <p className="subheading">
                                        {contentData.descriptions}
                                    </p>
                                </div>
                            }
                            <div>
                                <p className='cm-para typ-medium typ-center typ-form-title typ-mob-left typ-max-wid'>
                                    {contentData.options[0].option_text}
                                </p>
                                <div className='bs-form mt-0'>
                                    <FormGroup className='radio-box typ-timeline' tag="fieldset">
                                        {contentData.options[0].radio_button.map((item) => {
                                            return (
                                                <React.Fragment key={item.rId}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="radio"
                                                                name="sleep"
                                                                // value= {window.localStorage.getItem('sleephour') != "" ? window.localStorage.getItem('sleephour') :item.radio_text}
                                                                value={item.radio_text}
                                                                checked={item.radio_text === JSON.parse(window.localStorage.getItem('sleephour'))}
                                                                onChange={(e) => handleSleep(e, item.rId)} />{' '}
                                                            <div className='check-box-label'>
                                                                <div className='circle-box'>
                                                                    <div className='circle'></div>
                                                                </div>
                                                                <p className='radio-txt'>
                                                                    {item.radio_text}
                                                                </p>
                                                            </div>
                                                        </Label>
                                                    </FormGroup>
                                                </React.Fragment>
                                            )
                                        })}
                                    </FormGroup>
                                    {errorMsg.sleephour && <p className='error-message'>{errorMsg.sleephour}</p>}
                                </div>
                            </div>               
                        </Col>
                    </Row>
                </motion.div>
                </AnimationWrapper>
            </Container>
            <Footer />
            <RoutingArrow submit next={handleSubmit} />
        </div>
    )
}

export default Assessment;