import React, { useState, useEffect } from 'react';
import { Container, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// css
import '../../../assets/sass/custom.scss';

// json 
// male below 35 
import defaultExersiceMaleBelow35 from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import noVeggiesMaleBelow35 from '../../../assets/json/vegetable/male/below35/never.json';
import twoTimesVeggiesMaleBelow35 from '../../../assets/json/vegetable/male/below35/2-3-times-a-week.json';
import threeTimesVeggiesMaleBelow35 from '../../../assets/json/vegetable/male/below35/2-3-times-a-week.json';
import fourTimesVeggiesMaleBelow35 from '../../../assets/json/vegetable/male/below35/4-5-6-times-a-week.json';
import fiveSixTimesVeggiesMaleBelow35 from '../../../assets/json/vegetable/male/below35/4-5-6-times-a-week.json';
import dailyVeggiesMaleBelow35 from '../../../assets/json/vegetable/male/below35/daily.json';

// male above 35 
import defaultExersiceMaleAbove35 from '../../../assets/json/default/male/above35/none-of-the-option-is-selected.json';
import noVeggiesMaleAbove35 from '../../../assets/json/vegetable/male/above35/never.json';
import twoTimesVeggiesMaleAbove35 from '../../../assets/json/vegetable/male/above35/2-3-times-a-week.json';
import threeTimesVeggiesMaleAbove35 from '../../../assets/json/vegetable/male/above35/2-3-times-a-week.json';
import fourTimesVeggiesMaleAbove35 from '../../../assets/json/vegetable/male/above35/4-5-6-times-a-week.json';
import fiveSixTimesVeggiesMaleAbove35 from '../../../assets/json/vegetable/male/above35/4-5-6-times-a-week.json';
import dailyVeggiesMaleAbove35 from '../../../assets/json/vegetable/male/above35/daily.json';

// male above 72 
import defaultExersiceMaleAbove72 from '../../../assets/json/default/male/above72/none-of-the-option-is-selected.json';
import noVeggiesMaleAbove72 from '../../../assets/json/vegetable/male/above72/never.json';
import twoTimesVeggiesMaleAbove72 from '../../../assets/json/vegetable/male/above72/2-3-times-a-week.json';
import threeTimesVeggiesMaleAbove72 from '../../../assets/json/vegetable/male/above72/2-3-times-a-week.json';
import fourTimesVeggiesMaleAbove72 from '../../../assets/json/vegetable/male/above72/4-5-6-times-a-week.json';
import fiveSixTimesVeggiesMaleAbove72 from '../../../assets/json/vegetable/male/above72/4-5-6-times-a-week.json';
import dailyVeggiesMaleAbove72 from '../../../assets/json/vegetable/male/above72/daily.json';

// // female below 35 
import defaultExersiceFemaleBelow35 from '../../../assets/json/default/female/below35/none-of-the-option-is-selected.json';
import noVeggiesFemaleBelow35 from '../../../assets/json/vegetable/female/below35/never.json';
import twoTimesVeggiesFemaleBelow35 from '../../../assets/json/vegetable/female/below35/2-3-times-a-week.json';
import threeTimesVeggiesFemaleBelow35 from '../../../assets/json/vegetable/female/below35/2-3-times-a-week.json';
import fourTimesVeggiesFemaleBelow35 from '../../../assets/json/vegetable/female/below35/4-5-6-times-a-week.json';
import fiveSixTimesVeggiesFemaleBelow35 from '../../../assets/json/vegetable/female/below35/4-5-6-times-a-week.json';
import dailyVeggiesFemaleBelow35 from '../../../assets/json/vegetable/female/below35/daily.json';

// // female above 35 
import defaultExersiceFemaleAbove35 from '../../../assets/json/default/female/above35/none-of-the-option-is-selected.json';
import noVeggiesFemaleAbove35 from '../../../assets/json/vegetable/female/above35/never.json';
import twoTimesVeggiesFemaleAbove35 from '../../../assets/json/vegetable/female/above35/2-3-times-a-week.json';
import threeTimesVeggiesFemaleAbove35 from '../../../assets/json/vegetable/female/above35/2-3-times-a-week.json';
import fourTimesVeggiesFemaleAbove35 from '../../../assets/json/vegetable/female/above35/4-5-6-times-a-week.json';
import fiveSixTimesVeggiesFemaleAbove35 from '../../../assets/json/vegetable/female/above35/4-5-6-times-a-week.json';
import dailyVeggiesFemaleAbove35 from '../../../assets/json/vegetable/female/above35/daily.json';

// // female above 72
import defaultExersiceFemaleAbove72 from '../../../assets/json/default/female/above72/none-of-the-option-is-selected.json';
import noVeggiesFemaleAbove72 from '../../../assets/json/vegetable/female/above72/never.json';
import twoTimesVeggiesFemaleAbove72 from '../../../assets/json/vegetable/female/above72/2-3-times-a-week.json';
import threeTimesVeggiesFemaleAbove72 from '../../../assets/json/vegetable/female/above72/2-3-times-a-week.json';
import fourTimesVeggiesFemaleAbove72 from '../../../assets/json/vegetable/female/above72/4-5-6-times-a-week.json';
import fiveSixTimesVeggiesFemaleAbove72 from '../../../assets/json/vegetable/female/above72/4-5-6-times-a-week.json';
import dailyVeggiesFemaleAbove72 from '../../../assets/json/vegetable/female/above72/daily.json';

// helper 
import handleLottie from '../../../helper/lottieFunction';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function VegetableInTake() {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate()
    let [language, setLanguage] = useState("")
    let [averageVegetableIntake, setAverageVegetableIntake] = useState('')
    const [lottiePlayer, setLottiePlayer] = useState("");

    let [errorMsg, setErrorMsg] = useState({ sleephour: "", excercise: "", vegetableInTake: "" })

    let contentData = {
        qId: 5,
        question_text:  t('LifeStyleAssessment.VegetableInTake.question_text'),
        descriptions: [ t('LifeStyleAssessment.VegetableInTake.descriptions')],
        options: [
            {
                qId: 5,
                option_id: 1,
                option_text: t('LifeStyleAssessment.VegetableInTake.option_text'),
                imgClass: 'bg-3',
                radio_button: [
                    {
                        rId: 1, radio_text: t('LifeStyleAssessment.VegetableInTake.rId1'), gender: {
                            Male: {
                                default: {
                                    below35: defaultExersiceMaleBelow35,
                                    above35: defaultExersiceMaleAbove35,
                                    above72: defaultExersiceMaleAbove72,
                                },
                                below35: noVeggiesMaleBelow35,
                                above35: noVeggiesMaleAbove35,
                                above72: noVeggiesMaleAbove72
                            },
                            Female: {
                                default: {
                                    below35: defaultExersiceFemaleBelow35,
                                    above35: defaultExersiceFemaleAbove35,
                                    above72: defaultExersiceFemaleAbove72,
                                },
                                below35: noVeggiesFemaleBelow35,
                                above35: noVeggiesFemaleAbove35,
                                above72: noVeggiesFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 2, radio_text: t('LifeStyleAssessment.VegetableInTake.rId2'), gender: {
                            Male: {
                                below35: twoTimesVeggiesMaleBelow35,
                                above35: twoTimesVeggiesMaleAbove35,
                                above72: twoTimesVeggiesMaleAbove72
                            },
                            Female: {
                                below35: twoTimesVeggiesFemaleBelow35,
                                above35: twoTimesVeggiesFemaleAbove35,
                                above72: twoTimesVeggiesFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 3, radio_text: t('LifeStyleAssessment.VegetableInTake.rId3'), gender: {
                            Male: {
                                below35: threeTimesVeggiesMaleBelow35,
                                above35: threeTimesVeggiesMaleAbove35,
                                above72: threeTimesVeggiesMaleAbove72
                            },
                            Female: {
                                below35: threeTimesVeggiesFemaleBelow35,
                                above35: threeTimesVeggiesFemaleAbove35,
                                above72: threeTimesVeggiesFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 4, radio_text: t('LifeStyleAssessment.VegetableInTake.rId4'), gender: {
                            Male: {
                                below35: fourTimesVeggiesMaleBelow35,
                                above35: fourTimesVeggiesMaleAbove35,
                                above72: fourTimesVeggiesMaleAbove72
                            },
                            Female: {
                                below35: fourTimesVeggiesFemaleBelow35,
                                above35: fourTimesVeggiesFemaleAbove35,
                                above72: fourTimesVeggiesFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 5, radio_text: t('LifeStyleAssessment.VegetableInTake.rId5'), gender: {
                            Male: {
                                below35: fiveSixTimesVeggiesMaleBelow35,
                                above35: fiveSixTimesVeggiesMaleAbove35,
                                above72: fiveSixTimesVeggiesMaleAbove72
                            },
                            Female: {
                                below35: fiveSixTimesVeggiesFemaleBelow35,
                                above35: fiveSixTimesVeggiesFemaleAbove35,
                                above72: fiveSixTimesVeggiesFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 6, radio_text: t('LifeStyleAssessment.VegetableInTake.rId6'), gender: {
                            Male: {
                                below35: dailyVeggiesMaleBelow35,
                                above35: dailyVeggiesMaleAbove35,
                                above72: dailyVeggiesMaleAbove72
                            },
                            Female: {
                                below35: dailyVeggiesFemaleBelow35,
                                above35: dailyVeggiesFemaleAbove35,
                                above72: dailyVeggiesFemaleAbove72
                            }
                        }
                    },
                ]
            }
        ]
    }


    const handleVegetableIntake = (e, key) => {
        let hvit;
        if(key == 1)
        {
            hvit = "Never";
        }
        else if (key == 2)
        {
            hvit = "2 times a week";
        }
        else if ( key == 3)
        {
            hvit = "3 times a week"
        }
        else if ( key == 4)
        {
            hvit  = "4 times a week"
        }
        else if ( key == 5)
        {
            hvit = "5 to 6 times a week";
        }
        else if ( key == 6)
        {
            hvit = "Daily";
        }
        averageVegetableIntake = contentData.options[0].radio_button[key-1].radio_text;
        setAverageVegetableIntake(averageVegetableIntake)
        setErrorMsg({ vegetableInTake: "" })
        window.localStorage.setItem('vegetable-in-take', JSON.stringify(hvit))
        window.localStorage.setItem('vegetable-in-take1', JSON.stringify(averageVegetableIntake))
        let lottie = handleLottie(key, contentData)
        setLottiePlayer(lottie);
    }

    const handleSubmit = () => {
        const vegetableInTake = window.localStorage.getItem('vegetable-in-take')
        if (vegetableInTake) {
            setErrorMsg({ vegetableInTake: "" })
            navigate(`${homePageUrl}tobacco`)
        }
        else {
            setErrorMsg({ vegetableInTake: t('Messages.LifestyleAssessment.1') })
        }

    }

    const handleNavigationLottie = () => {
        let vegetableInTake = window.localStorage.getItem('vegetable-in-take')
        let vegetableInTake1 = window.localStorage.getItem('vegetable-in-take1')
        

        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        if (vegetableInTake == null) {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 35 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
        } else {
            vegetableInTake = vegetableInTake.replaceAll('"', '')
            vegetableInTake1 = vegetableInTake1.replaceAll('"', '')
            if(JSON.parse(window.localStorage.getItem('lang')) == "en")
            {
                setAverageVegetableIntake(vegetableInTake)
                let id = contentData.options[0].radio_button.find((rep) => rep.radio_text == vegetableInTake).rId
                handleVegetableIntake(1, id)
            }
            else
            {
                // setAverageVegetableIntake(JSON.parse(vegetableInTake1))
                let id = contentData.options[0].radio_button.find((rep) => rep.radio_text == vegetableInTake1).rId
                handleVegetableIntake(1, id)
            }
        }
    }

    //get local storage
    useEffect(() => {
        console.log("JSON.parse(window.localStorage.getItem('lang')) value >>",JSON.parse(window.localStorage.getItem('lang')))
        // const vegetableInTake = window.localStorage.getItem('vegetable-in-take')
        // setAverageVegetableIntake(JSON.parse(vegetableInTake))
        if(JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en")
        {
            let lang = "en";
            handleClick(lang);
        }
        else
        {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
        handleNavigationLottie()
    },[])

    const handleClick = (lang) => 
    {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>",lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>",t)
        setLanguage(lang);
    }

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row>
                            <Col xl={{ offset: 6, size: 6 }} lg={{ offset: 6, size: 6 }} md="12">
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }

                            <div>
                                <p className='cm-para typ-medium typ-center typ-form-title typ-mob-left typ-max-wid'>
                                    {contentData.options[0].option_text}
                                </p>
                                <div className='bs-form mt-0'>
                                    <FormGroup className='radio-box typ-timeline' tag="fieldset">
                                        {contentData.options[0].radio_button.map((item) => {
                                            return (
                                                <React.Fragment key={item.rId}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="radio"
                                                                name="vegetable_in_take"
                                                                checked={item.radio_text === averageVegetableIntake}
                                                                value={item.radio_text}
                                                                onChange={(e) => handleVegetableIntake(e, item.rId)} />{' '}
                                                            <div className='check-box-label'>
                                                                <div className='circle-box'>
                                                                    <div className='circle'></div>
                                                                </div>
                                                                <p className='radio-txt'>
                                                                    {item.radio_text}
                                                                </p>
                                                            </div>
                                                            </Label>
                                                        </FormGroup>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </FormGroup>
                                        {errorMsg.vegetableInTake && <p className='error-message'>{errorMsg.vegetableInTake}</p>}
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <Footer />
            <RoutingArrow prev={`${homePageUrl}exercise`} submit next={handleSubmit} />
        </div>
    )
}

export default VegetableInTake;