import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Modal } from 'reactstrap';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { useLocation, useNavigate, Link, useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import ModalVideo from 'react-modal-video';

// animation 
// import Aos from "aos";
// import "aos/dist/aos.css";

//constant
import { homePageUrl, getSingleUserDataFromApi } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images 
import VideoThumb from '../../../assets/images/subscription/yt1.jpg';

// css
import '../../../assets/sass/custom.scss';

// json 
import LottiFiles from '../../../assets/json/start-meter.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { animate } from 'framer-motion';
import Exersice from '../../../assets/images/subscription/exersice.png';
import DiseaseEducation from '../../../assets/images/subscription/disease-education.png';
import Meditation from '../../../assets/images/subscription/meditation.png';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
function SubscriptionHome() {
    const { t, i18n } = useTranslation();
    const [modal, setModal] = useState(false);
    const [isOpen, setOpen] = useState(false);

    const location = useLocation();
    let [userId, setUserId] = useState("");
    let [diseaseName, setDiseaseName] = useState("");
    let [planName, setPlanName] = useState("")
    let [searchParams, setSearchParams] = useSearchParams();
    let [videoId,setVideoId] = useState("")
    useEffect(() => {
        if (location.state != null) {
            console.log("Here", location)
            setUserId(location.state.userId);
            window.localStorage.setItem('userId', JSON.stringify(location.state.userId))
            console.log("user id >>",userId)
            getUserData(location.state.userId);
        }
        else
        {
            if(searchParams.get('userId')!= null || searchParams.get('userId') != undefined)
            {
                console.log("Here")
                const id =searchParams.get('userId') 
                setUserId(id);
                setTimeout(() => {
                    window.localStorage.setItem('userId', JSON.stringify(id))
                    console.log("user id is here >>",id);
                    getUserData(id);
                },500);
                
            }
            else {
                let uid = JSON.parse(window.localStorage.getItem('userId'));
                setUserId(uid);
                getUserData(uid);
            }

        }

        if(JSON.parse(window.localStorage.getItem('lang')) != "")
        {
            handleClick("en");
        }
        else if(JSON.parse(window.localStorage.getItem('lang')) == "mr")
        {
            handleClick("mr");
        }
        else
        {
            handleClick("hi");
        }


        // if(searchParams.get('userId'))
        // {
        //     console.log("There")
        //     setUserId(location.state.userId);
        // }

        // setVideoLinks(location.state.video_links);
    }, []);

    const handleClick = (lang) => 
    {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>",lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>",t)
    }

    const getUserData = async (id) => {
        if(userId != null || userId != undefined)
        {
            console.log("id is >>",id);
            const formData = new FormData()
            formData.append('userId', id)
            // formData.append('uid', "12456")
            const response = await axios({
                method: "post",
                url: getSingleUserDataFromApi,
                data: formData,
            });
            console.log("response is >>",response.data.users.plan_name)
            if(response.status == 200)
            {
                setTimeout(()=>{
                    if(response.data.users.plan_name == "SMH Basic")
                    {
                        setVideoId('768131982?h=1f84077dca')
                    }
                    else
                    {
                        setVideoId('768137860?h=f237f7b2d2')
                    }
                },1000);
                console.log("Plan name is >>",response.data.users.plan_name)
                setPlanName(response.data.users.plan_name);
                setDiseaseName(response.data.users.disease_name);
            }
        }
    }

    return (
        <div className='main'>
            <AnimationWrapper>
                <motion.div exit={{ x: "-100%", opacity: 0 }}>
                    <Container>
                        {
                            console.log("video id",videoId)
                        }
                        <Row className='text-center'>
                            <Col xl="12" lg="12" md="12">
                                <div className='bs-card typ-pl-pr-add'>
                                    <Row className="align-items-center justify-content-center sec-save-my-heart-plan">
                                        <Col xl="12" lg="12" md="12">
                                            <div className="bs-heading typ-center typ-full-width">
                                                <h2 className="heading">
                                                {t('SubscriptionHome.1')}
                                                </h2>
                                                <p className="subheading  mb-20">
                                                {t('SubscriptionHome.2')}.
                                                {t('SubscriptionHome.3')} {planName != null && planName} {t('SubscriptionHome.4')} {diseaseName}. 
                                                
                                                
                                                </p>
                                                <p className="subheading  mb-20">
                                                {t('SubscriptionHome.5')}.
                                                </p>
                                            </div>
                                            <div className="bs-player typ-popup typ-overlay" onClick={() => setOpen(true)}>
                                                <picture className="w-100">
                                                    <source media="(max-width:992px)" srcSet={VideoThumb} />
                                                    <img src={VideoThumb} alt="band" />
                                                    <div className='player-content'>
                                                        <div class="bs-heading typ-white typ-center typ-full-width">
                                                            <h2 class="heading">{t('SubscriptionHome.1')}</h2>
                                                        </div>
                                                        <span className="icon icon-yt-play"></span>
                                                    </div>
                                                </picture>
                                            </div>
                                        </Col>
                                        <Col xl="4" lg="4" md="12">
                                            <Link to={`${homePageUrl}subscription-video-list`} state={{ userId: userId }} >
                                                <div className="bs-card typ-icn-txt typ-height-auto typ-subscription">
                                                    <img src={Exersice} className='avatar' />
                                                    <p className="desc"> 
                                                    {t('SubscriptionVideo.5')}
                                                    </p>
                                                    <span className='clickHere'>Click Here</span>
                                                </div>
                                            </Link>
                                        </Col>

                                        <Col xl="4" lg="4" md="12">
                                            <Link to={`${homePageUrl}subscription-education-video-list`} state={{ userId: userId }}>
                                                <div className="bs-card typ-icn-txt typ-height-auto typ-subscription">
                                                    <img src={DiseaseEducation} className='avatar' />
                                                    <p className="desc">
                                                    {t('SubscriptionVideo.6')}
                                                    </p>
                                                    <span className='clickHere'>Click Here</span>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xl="4" lg="4" md="12">
                                            <Link to={`${homePageUrl}subscription-meditation-video-list`} state={{ userId: userId }}>
                                                {/* <Link to={'/score/subscription-meditation-video-list'} state= {{ userId: userId}}> */}
                                                <div className="bs-card typ-icn-txt typ-height-auto typ-subscription">
                                                    <img src={Meditation} className='avatar' />
                                                    <p className="desc">
                                                    {t('SubscriptionVideo.7')}
                                                    </p>
                                                       <span className='clickHere'>Click Here</span>
                                                </div>
                                            </Link>
                                        </Col>
                                        {/* <Col xl="4" lg="4" md="12">
                                            <Link to={`${homePageUrl}subscription-video`} state={{ userId: userId }}>
                                                <div className="bs-card typ-icn-txt typ-height-auto typ-subscription">
                                                    <span class="icon icon-videos"></span>
                                                    <p className="desc">
                                                    {t('SubscriptionHome.6')}
                                                    </p>
                                                </div>
                                            </Link>
                                        </Col> */}
                                        <Col xl="4" lg="4" md="12">
                                            <Link to={`${homePageUrl}subscription-med`}>
                                                <div className="bs-card typ-icn-txt typ-height-auto typ-subscription">
                                                    <span class="icon icon-test-consultation"></span>
                                                    <p className="desc">
                                                    {t('SubscriptionHome.7')}
                                                    </p>
                                                </div>
                                            </Link>
                                        </Col>
                                        {/* <Col xl="4" lg="4" md="12">
                                            <Link to={`${homePageUrl}subscription-discount-coupons`} state= {{ userId: userId}}>
                                                <div className="bs-card typ-icn-txt typ-height-auto typ-subscription">
                                                    <span class="icon icon-discount"></span>
                                                    <p className="desc">
                                                    {t('SubscriptionHome.8')}
                                                    </p>
                                                </div>
                                            </Link>
                                        </Col> */}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </motion.div>
            </AnimationWrapper>
            <Footer />
            <ModalVideo channel='vimeo' autoplay isOpen={isOpen} videoId={videoId} onClose={() => setOpen(false)} />
        </div>
    )
}

export default SubscriptionHome;