import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, FormGroup, Label, Input, Spinner } from 'reactstrap';
import OtpInput from "react-otp-input";
import axios from 'axios';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';


//Constant
import { homePageUrl, smsApi, addUserApi, sendWhatsappApi, saveGeneralDetails, saveOtpVerfication, saveAssessmentData } from '../../../helper/ContentConstants';
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// Import Swiper styles
import 'swiper/css';

// json 
import defaultHeightMaleBelow35 from '../../../assets/json/gender/male/below35/hello.json';
import defaultHeightMaleAbove35 from '../../../assets/json/gender/male/above35/hello.json';
import defaultHeightMaleAbove72 from '../../../assets/json/gender/male/above72/hello.json';
import defaultHeightFemaleBelow35 from '../../../assets/json/gender/female/below35/hello.json';
import defaultHeightFemaleAbove35 from '../../../assets/json/gender/female/above35/hello.json';
import defaultHeightFemaleAbove72 from '../../../assets/json/gender/female/above72/hello.json';

// css
import '../../../assets/sass/custom.scss';


// components
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useNavigate } from "react-router-dom";
// import fair from "../../../assets/images/whatsapp-images/Fair.jpg";
// import poor from "../../../assets/images/whatsapp-images/Poor.jpg";
// import good from "../../../assets/images/whatsapp-images/Good.jpg";
// import veryGood from "../../../assets/images/whatsapp-images/VeryGood.jpg";
// import excellent from "../../../assets/images/whatsapp-images/Excellent.jpg";

import fair from "../../../assets/images/whatsapp-images/Fair.png";
import poor from "../../../assets/images/whatsapp-images/Poor.png";
import good from "../../../assets/images/whatsapp-images/Good.png";
import veryGood from "../../../assets/images/whatsapp-images/VeryGood.png";
import excellent from "../../../assets/images/whatsapp-images/Excellent.png";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function OtpVerification() {
    const { t, i18n } = useTranslation();

    let navigate = useNavigate();
    let [otp, setOtp] = useState('');
    let [ui, setUi] = useState('');
    let [data, setData] = useState(0)
    let [errorMessage, setErrorMessage] = useState('');
    let [mobileNumber, setMobileNumber] = useState('')
    let [imagePath, setImagePath] = useState('')
    let [showEdit, setShowEdit] = useState(false)
    let [numbererrorMessage, setNumberErrorMessage] = useState('');
    let [success, setSuccess] = useState(false)
    let [userId, setUserId] = useState("")
    let [error,setError] = useState(false)

    const [loader, setLoader] = useState(false);
    const [loading, setLoading] = useState(false);

    const [lottiePlayer, setLottiePlayer] = useState("");
    let contentData = {
        options: [
            {
                radio_button: [
                    {
                        rId: 1, radio_text: 'text', gender: {
                            Male: {
                                default: {
                                    below35: defaultHeightMaleBelow35,
                                    above35: defaultHeightMaleAbove35,
                                    above72: defaultHeightMaleAbove72,
                                },
                            },
                            Female: {
                                default: {
                                    below35: defaultHeightFemaleBelow35,
                                    above35: defaultHeightFemaleAbove35,
                                    above72: defaultHeightFemaleAbove72,
                                },
                            }
                        }
                    }
                ]
            }
        ]
    }

    const handleNavigationLottie = () => {
        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
        console.log(lottie)
        setLottiePlayer(lottie);
    }

    const handleClick = event => {
        // 👇️ toggle isActive state on click
        setLoader(current => !current);
    };

    const handleChange = (otp1) => {
        // setErrorMessage('');
        setUi(otp1);

    };
    useEffect(() => {
        // return ()=>{
        const mobile = JSON.parse(window.localStorage.getItem('mobile'))
        const userId = JSON.parse(window.localStorage.getItem('uniqueId'));
        setUserId(userId)
        console.log("User id is 1>>", userId)
        sendOtp();

        if (JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en") {
            let lang = "en";
            handleClick1(lang);
        }
        else {
            handleClick1(JSON.parse(window.localStorage.getItem('lang')));
        }
        // }
        handleNavigationLottie()
    }, []);


    const handleClick1 = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t)
    }
    const handleNumberChange = (number) => {
        setNumberErrorMessage('');
        let mobileNumberValue = number.target.value;
        if(mobileNumberValue.length == 10)
        {
            setError(false)
            setMobileNumber(number.target.value)
        }
        else
        {
            setError(true)
        }
    }


    // const getUserIp = async() =>{
    //     axios('https://api.ipify.org?format=json').then(response => {
    //         console.log("api resposne is >> ",response);
    //       }).then((res) => {
    //         // console.log("Api response is >>",res);
    //       }).catch((err) => console.error('Problem fetching my IP', err))
    // }

    const sendWhatsapp = async (id) => {
        let status = JSON.parse(window.localStorage.getItem('healthStatus'))
        let AbsoluteimagePath = '';
        if (status == "fair") {
            AbsoluteimagePath = `https://hearthealthmeter.com${fair}`
            console.log("path is", AbsoluteimagePath);
        }
        if (status == "poor") {
            AbsoluteimagePath = `https://hearthealthmeter.com${poor}`
            // console.log(AbsoluteimagePath);
        }
        if (status == "good") {
            AbsoluteimagePath = `https://hearthealthmeter.com${good}`
            // console.log(AbsoluteimagePath);
        }
        if (status == "veryGood") {
            AbsoluteimagePath = `https://hearthealthmeter.com${veryGood}`
            // console.log(AbsoluteimagePath);
        }
        if (status == "excellent") {
            AbsoluteimagePath = `https://hearthealthmeter.com${excellent}`
            // console.log(AbsoluteimagePath);
        }
        console.log("Name is >>", JSON.parse(window.localStorage.getItem('name')))
        console.log("Mobile number is >>", JSON.parse(window.localStorage.getItem('mobile')))
        let score = JSON.parse(window.localStorage.getItem('score'))
        let name = JSON.parse(window.localStorage.getItem('name'))
        let mobile = JSON.parse(window.localStorage.getItem('mobile'))
        let email = JSON.parse(window.localStorage.getItem('email'))
        let formatted_mobile_number = `91${mobile}`
        console.log("formatted name is >>", name);
        // console.log("formatted status is >>", status);
        try {
            const formData = new FormData()
            formData.append('mobile', formatted_mobile_number)
            formData.append('score', score)
            formData.append('image', AbsoluteimagePath)
            formData.append('name', name)
            formData.append('status', status)
            formData.append('uid', id)
            formData.append('email', email)
            // formData.append('uid', "12456")
            const response = await axios({
                method: "post",
                url: sendWhatsappApi,
                data: formData,
            });
            console.log("Response is >>", response);
            if (response.status == 200) {

                navigate(`${homePageUrl}health-score`, {
                    state: { id }
                });
            }
        } catch (error) {
            // console.log("error is ", error)
        }

    }
    const validateNext = () => {

        setLoading(true)
        console.log("hiiiiii", loading)
        console.log("Otp is >>", otp)
        if (ui.length == 4) {
            if (ui != otp) {
                toastr.options = {
                    positionClass: 'toast-top-full-width',
                    hideDuration: 300,
                    timeOut: 2000
                }
                toastr.clear()
                setLoading(false)
                setTimeout(() => toastr.error(t('Messages.OtpVerification.1')), 200)
            }
            else {
                // toastr.options = {
                //     positionClass : 'toast-top-full-width',
                //     hideDuration: 300,
                //     timeOut: 2000
                //   }
                //   toastr.clear()
                //   setTimeout(() => toastr.success('otp verification done'), 200)
                if (JSON.parse(window.localStorage.getItem('assessment')) == false) {
                    OtpVerfication()
                    SaveDetail()
                    SaveGeneralDetail()
                }
                else {
                    SaveDetail()
                    OtpVerfication()
                }

            }
        }
        else if (ui == "") {
            console.log("Step2");
            toastr.options = {
                positionClass: 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 2000
            }
            toastr.clear()
            setLoading(false)
            setTimeout(() => toastr.error(t('Messages.OtpVerification.2')), 200)
        }
        else if (ui.length < 4) {
            toastr.options = {
                positionClass: 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 2000
            }
            toastr.clear()
            setLoading(false)
            setTimeout(() => toastr.error(t('Messages.OtpVerification.3')), 200)
        }
        else {
            toastr.options = {
                positionClass: 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 2000
            }
            toastr.clear()
            setLoading(false)
            setTimeout(() => toastr.error(t('Messages.OtpVerification.1')), 200)
        }

    }
    const sendOtp = async () => {
        const mobile = JSON.parse(window.localStorage.getItem('mobile'))
        console.log("user id is here>>", userId)
        console.log("Mobile number is >>", mobile)
        setMobileNumber(mobile);
        const formatted_mobile_number = `91${mobile}`
        // const formatted_mobile_number = "919892716501"
        try {
            const formData = new FormData()
            formData.append('mobile', formatted_mobile_number)
            const response = await axios({
                method: "post",
                url: smsApi,
                data: formData,
            });
            // console.log("Response is >>", response);
            if (response.data.statusCode == 200) {
                setTimeout(() => {
                    setOtp(response.data.otp);
                    setSuccess(true)
                    toastr.options = {
                        positionClass: 'toast-top-full-width',
                        hideDuration: 300,
                        timeOut: 2000
                    }
                    toastr.clear()
                    setTimeout(() => toastr.success(t('Messages.OtpVerification.4')), 200)
                });

                //   sendWhatsapp();
                //   navigate(`${homePageUrl}health-score`);
            }
        } catch (error) {
            // console.log("error is ", error)
        }
    }

    const updateNumber = async () => {
        // console.log("mobile number is >>", mobileNumber)
        if (mobileNumber.length == 10) {
            // console.log("matched");
            window.localStorage.setItem("mobile", JSON.stringify(mobileNumber));
            setShowEdit(false)
            setTimeout(() => {
                sendOtp();
            }, 2000)
        }
        else {
            // setNumberErrorMessage(t('Messages.OtpVerification.5'));
            setError(true)
        }
    }

    const SaveGeneralDetail = async () => {
        const allKeys = JSON.parse(JSON.stringify(window.localStorage));
        // const lastid = window.localStorage.getItem("lastID");
        // const uniqueid = window.localStorage.getItem("uniqueId");
        console.log("All keys >>", allKeys);

        try {
            const response = await axios({
                method: "post",
                url: saveGeneralDetails,
                data: allKeys,
            });
            // console.log("Response is >>", response);
            if (response.data.statusCode == 200) {
                console.log(response.data)

                // let uid = response.data.uniqueId;
                // sendWhatsapp(uid);
                // navigate(`${homePageUrl}sleep-hours`)
            }
        } catch (error) {
            toastr.options = {
                positionClass: 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 2000
            }
            toastr.clear()
            setTimeout(() => toastr.error(t('Messages.OtpVerification.6')), 200)
        }
    }


    const OtpVerfication = async () => {
        const allKeys = JSON.parse(JSON.stringify(window.localStorage));
        // console.log("All keys >>", allKeys);
        try {
            const response = await axios({
                method: "post",
                url: saveOtpVerfication,
                data: allKeys,
            });
            // console.log("Response is >>", response);
            if (response.data.statusCode == 200) {
                let uid = response.data.uniqueId;

            }
        } catch (error) {
            // console.log("error is ", error)
        }
    }

    const SaveDetail = async () => {
        const allKeys = JSON.parse(JSON.stringify(window.localStorage));
        // console.log("All keys >>", allKeys);
        try {

            console.log("local value is >>", JSON.parse(window.localStorage.getItem('assessment')))
            let api;
            if (JSON.parse(window.localStorage.getItem('assessment')) != null) {
                api = saveAssessmentData;
            }
            else {
                api = addUserApi
            }
            const response = await axios({
                method: "post",
                url: api,
                data: allKeys,
            });
            console.log("Response is >>", response);
            if (response.data.statusCode == 200) {
                let uid = response.data.userId;
                console.log("SaveDetail uid is >>", uid)
                sendWhatsapp(uid);
                // navigate(`${homePageUrl}health-score`, {
                //     state: { uid }
                // });
            }
        } catch (error) {
            // console.log("error is ", error)
        }
    }

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className='align-items-center'>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('OtpVerification.OTPVerification')}
                                        </h2>
                                        <p className="subheading">
                                            {t('OtpVerification.YourHealthAnalysis')} <br />
                                            {t('OtpVerification.EnterTheOTP')}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('OtpVerification.OTPVerification')}
                                        </h2>
                                        <p className="subheading">
                                            {t('OtpVerification.YourHealthAnalysis')} <br />
                                            {t('OtpVerification.EnterTheOTP')}
                                        </p>
                                    </div>
                                }

                                {showEdit ?
                                    <form className="bs-form">
                                        <FormGroup className='text-box'>
                                            <Label htmlFor="relative">{t('OtpVerification.EnterYourWhatsappNo')}</Label>
                                            <div className="input-with-btn">
                                                <Input onChange={(number) => handleNumberChange(number)} type="text" name='relative' />
                                                <span onClick={() => updateNumber()} class="icon icon-next-arrow"></span>
                                            </div>
                                            {error && (
                                                <p className="error" style={{color: "red"}}>Number length should be 10 </p>
                                            )}

                                            {/* <p className='error-message'>{formErrors.relative}</p> */}
                                        </FormGroup>
                                    </form>
                                    :
                                    <div>
                                        <p className="cm-para typ-medium typ-center typ-mob-left typ-max-wid mb-10">
                                            {t('OtpVerification.Enter4-digit')} +91-{mobileNumber}
                                        </p>
                                        <button className='cm-para typ-center typ-mob-left typ-link' onClick={() => setShowEdit(true)}>
                                            {t('OtpVerification.EditPhoneNumber')}
                                        </button>
                                        <div className="bs-form typ-otp">
                                            <OtpInput className='otp-input'
                                                value={ui}
                                                onChange={(otp12) => handleChange(otp12)}
                                                inputStyle={{
                                                    width: "40px",
                                                    height: "40px",
                                                }}
                                            />
                                        </div>
                                        {errorMessage && (
                                            <p className="error"> {errorMessage} </p>
                                        )}
                                        <p className="cm-para typ-medium typ-center typ-mob-left typ-max-wid mb-10">
                                            {t('OtpVerification.DidntReceive')}?
                                        </p>
                                        {/* <a href="#" className="cm-para typ-center typ-mob-left typ-link">
                                        Resend OTP
                                    </a> */}
                                        <button className='cm-para typ-center typ-mob-left typ-link' onClick={() => sendOtp()}>{t('OtpVerification.ResendOTP')}</button>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prev={`${homePageUrl}almost-done`} />
            <Link to="" className={'cm-next-arrow'} onClick={() => validateNext()}>
                <span className={'icon icon-next-arrow'}></span>
            </Link>
            <Footer />
            {console.log(loading)}
            {loading ? (
                <div className={`bs-spinner show`}>
                    <Spinner>
                        Loading...
                    </Spinner>
                </div>
            ) : null}
            {/* <div className={`bs-spinner show`}>
                <Spinner>
                    Loading...
                </Spinner>
            </div> */}

        </div>
    )
}


export default OtpVerification;