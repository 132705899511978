import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, FormGroup, Input, Label, Button, Modal, ModalHeader, ModalFooter, ModalBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Vimeo from '@u-wave/react-vimeo';

// animation 
// import Aos from "aos";
// import "aos/dist/aos.css";

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images 
import ytThumbnail from "../../../assets/images/subscription/yt1.jpg";

// css
import '../../../assets/sass/custom.scss';

// json 
import LottiFiles from '../../../assets/json/start-meter.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { animate } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
var ytBgImg = {
    backgroundImage: 'url(' + ytThumbnail + ')',
    backgroundSize: "Cover",
    backgroundPosition: "center center",
};


function SubscriptionMeditationVideosPlayer() {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const inputRef = useRef(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const toggle = () => setModalIsOpen(!modalIsOpen);
    const handleOpen = () => inputRef.current.focus();

    const [myclass, changeclass] = useState("");

    const addclass = () => {
        console.log(12);
        changeclass(`active`)
    }
    const removeClass = () => {
        console.log(12);
        changeclass(`inactive`)
    }

    let [videoLinks,setVideoLinks] = useState("")
    let [videoTopics, setVideoTopics] = useState("");
    let [userId,setUserId] = useState("");
    const [dayNumber,setDayNumber] = useState("")
    const [adBanner,setAdBanner] = useState("")
    const [adLink,setAdLink] = useState("")
    useEffect(() => {
        if(location.state == null)
        {
            console.log("uid >>",JSON.parse(window.localStorage.getItem('userId')))
            let uid = JSON.parse(window.localStorage.getItem('userId'));
            setUserId(uid)
            console.log("user id >>",userId)
        }
        else
        {
            console.log("params value is >>",location.state);
            setVideoLinks(location.state.video_links);
            setVideoTopics(location.state.video_topics)
            setDayNumber(location.state.day_number)
            setAdBanner(location.state.ad_banner)
            setAdLink(location.state.ad_link)
            let uid = JSON.parse(window.localStorage.getItem('userId'));
            setUserId(uid)
            
            console.log("user id >>",userId)
        }

        if(JSON.parse(window.localStorage.getItem('lang')) != "")
        {
            handleClick("en");
        }
        else if(JSON.parse(window.localStorage.getItem('lang')) == "mr")
        {
            handleClick("mr");
        }
        else
        {
            handleClick("hi");
        }
      }, []);

    const handleClick = (lang) => 
    {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>",lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>",t)
    }

    return (
        <div className='main' >
            <AnimationWrapper>
                <motion.div exit={{ x: "-100%", opacity: 0 }}>
                    <Container>
                        <Row className='text-center'>
                            <Col xl="12" lg="12" md="12">
                                <div className='bs-card typ-pl-on typ-subscription-main'>
                                    <Breadcrumb className='bs-breadcrumb'>
                                        <BreadcrumbItem><Link to={`${homePageUrl}subscription-home`}>Home</Link></BreadcrumbItem>
                                        {/* <BreadcrumbItem><Link to={`${homePageUrl}subscription-video`} state= {{ userId: userId}}>Videos</Link></BreadcrumbItem> */}
                                        <BreadcrumbItem><Link to={`${homePageUrl}subscription-meditation-video-list`} state= {{ userId: userId}}>Meditation Videos</Link></BreadcrumbItem>
                                        <BreadcrumbItem active>Day {dayNumber}</BreadcrumbItem>
                                    </Breadcrumb>
                                    <div className="bs-heading typ-full-width typ-center">
                                        <h2 className="heading">Today is your Day {dayNumber} of Meditation Program</h2>
                                        <p className="green">Madhavbaug Save My Heart Program</p>
                                        <p className="cm-para mb-40">{videoTopics}</p>
                                    </div>
                                    <iframe
                                        className='iframe'
                                        title="vimeo-player"
                                        src={videoLinks}
                                        width="100%"
                                        height="360"
                                        frameborder="0"
                                        webkitAllowFullScreen mozallowfullscreen allowFullScreen
                                    ></iframe>
                                    <picture onClick={() => { window.open(adLink, '_blank') }} className="mb-on w-100 c-pointer video-banner">
                                        <source media="(max-width:992px)" srcSet={adBanner} />
                                        <img src={adBanner} alt="band" />
                                    </picture>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </motion.div>
            </AnimationWrapper>
            <Footer />
        </div>
    )
}

export default SubscriptionMeditationVideosPlayer;