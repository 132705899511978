import React, { useState, useEffect } from 'react';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// Import Swiper styles
import 'swiper/css';

// json 
// male below 35
import defaultTobaccoMaleBelow35 from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import neverTobaccoMaleBelow35 from '../../../assets/json/cigarette/male/below35/never.json';
import onceMonthTobaccoMaleBelow35 from '../../../assets/json/cigarette/male/below35/daily.json';
import twoToTenTobaccoMaleBelow35 from '../../../assets/json/cigarette/male/below35/daily.json';
import elevenToFifteenTobaccoMaleBelow35 from '../../../assets/json/cigarette/male/below35/daily.json';
import moreThanFifteenTobaccoMaleBelow35 from '../../../assets/json/cigarette/male/below35/daily.json';
import dailyTobaccoMaleBelow35 from '../../../assets/json/cigarette/male/below35/daily.json';

// male above 35 
import defaultTobaccoMaleAbove35 from '../../../assets/json/default/male/above35/none-of-the-option-is-selected.json';
import neverTobaccoMaleAbove35 from '../../../assets/json/cigarette/male/above35/never.json';
import onceMonthTobaccoMaleAbove35 from '../../../assets/json/cigarette/male/above35/daily.json';
import twoToTenTobaccoMaleAbove35 from '../../../assets/json/cigarette/male/above35/daily.json';
import elevenToFifteenTobaccoMaleAbove35 from '../../../assets/json/cigarette/male/above35/daily.json';
import moreThanFifteenTobaccoMaleAbove35 from '../../../assets/json/cigarette/male/above35/daily.json';
import dailyTobaccoMaleAbove35 from '../../../assets/json/cigarette/male/above35/daily.json';

// male above 72 
import defaultTobaccoMaleAbove72 from '../../../assets/json/default/male/above72/none-of-the-option-is-selected.json';
import neverTobaccoMaleAbove72 from '../../../assets/json/cigarette/male/above72/never.json';
import onceMonthTobaccoMaleAbove72 from '../../../assets/json/cigarette/male/above72/daily.json';
import twoToTenTobaccoMaleAbove72 from '../../../assets/json/cigarette/male/above72/daily.json';
import elevenToFifteenTobaccoMaleAbove72 from '../../../assets/json/cigarette/male/above72/daily.json';
import moreThanFifteenTobaccoMaleAbove72 from '../../../assets/json/cigarette/male/above72/daily.json';
import dailyTobaccoMaleAbove72 from '../../../assets/json/cigarette/male/above72/daily.json';

// // female below 35 
import defaultTobaccoFemaleBelow35 from '../../../assets/json/default/female/below35/none-of-the-option-is-selected.json';
import neverTobaccoFemaleBelow35 from '../../../assets/json/cigarette/female/below35/never.json';
import onceMonthTobaccoFemaleBelow35 from '../../../assets/json/cigarette/female/below35/daily.json';
import twoToTenTobaccoFemaleBelow35 from '../../../assets/json/cigarette/female/below35/daily.json';
import elevenToFifteenTobaccoFemaleBelow35 from '../../../assets/json/cigarette/female/below35/daily.json';
import moreThanFifteenTobaccoFemaleBelow35 from '../../../assets/json/cigarette/female/below35/daily.json';
import dailyTobaccoFemaleBelow35 from '../../../assets/json/cigarette/female/below35/daily.json';

// // female above 35 
import defaultTobaccoFemaleAbove35 from '../../../assets/json/default/female/above35/none-of-the-option-is-selected.json';
import neverTobaccoFemaleAbove35 from '../../../assets/json/cigarette/female/above35/never.json';
import onceMonthTobaccoFemaleAbove35 from '../../../assets/json/cigarette/female/above35/daily.json';
import twoToTenTobaccoFemaleAbove35 from '../../../assets/json/cigarette/female/above35/daily.json';
import elevenToFifteenTobaccoFemaleAbove35 from '../../../assets/json/cigarette/female/above35/daily.json';
import moreThanFifteenTobaccoFemaleAbove35 from '../../../assets/json/cigarette/female/above35/daily.json';
import dailyTobaccoFemaleAbove35 from '../../../assets/json/cigarette/female/above35/daily.json';

// // female above 72
import defaultTobaccoFemaleAbove72 from '../../../assets/json/default/female/above72/none-of-the-option-is-selected.json';
import neverTobaccoFemaleAbove72 from '../../../assets/json/cigarette/female/above72/never.json';
import onceMonthTobaccoFemaleAbove72 from '../../../assets/json/cigarette/female/above72/daily.json';
import twoToTenTobaccoFemaleAbove72 from '../../../assets/json/cigarette/female/above72/daily.json';
import elevenToFifteenTobaccoFemaleAbove72 from '../../../assets/json/cigarette/female/above72/daily.json';
import moreThanFifteenTobaccoFemaleAbove72 from '../../../assets/json/cigarette/female/above72/daily.json';
import dailyTobaccoFemaleAbove72 from '../../../assets/json/cigarette/female/above72/daily.json';

// helper 
import handleLottie from '../../../helper/lottieFunction';

// images
import Right from '../../../assets/images/select-language/right.svg';

// css
import '../../../assets/sass/custom.scss';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function Tobacco() {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate()
    let [language, setLanguage] = useState("")
    let [tobaccoConsumption, setTobaccoConsumption] = useState('')
    let [errorMsg, setErrorMsg] = useState({ tobacco: "" })
    const [lottiePlayer, setLottiePlayer] = useState("");

    let contentData = {
        qId: 6,
        question_text: t('LifeStyleAssessment.Tobacco.question_text'),
        descriptions: t('LifeStyleAssessment.Tobacco.descriptions'),
        options: [
            {
                qId: 6,
                option_id: 1,
                option_text: t('LifeStyleAssessment.Tobacco.option_text'),
                imgClass: 'bg-1',
                radio_button: [
                    {
                        rId: 1, radio_text: t('LifeStyleAssessment.Tobacco.rId1'), gender: {
                            Male: {
                                default: {
                                    below35: defaultTobaccoMaleBelow35,
                                    above35: defaultTobaccoMaleAbove35,
                                    above72: defaultTobaccoMaleAbove72,
                                },
                                below35: neverTobaccoMaleBelow35,
                                above35: neverTobaccoMaleAbove35,
                                above72: neverTobaccoMaleAbove72
                            },
                            Female: {
                                default: {
                                    below35: defaultTobaccoFemaleBelow35,
                                    above35: defaultTobaccoFemaleAbove35,
                                    above72: defaultTobaccoFemaleAbove72,
                                },
                                below35: neverTobaccoFemaleBelow35,
                                above35: neverTobaccoFemaleAbove35,
                                above72: neverTobaccoFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 2, radio_text: t('LifeStyleAssessment.Tobacco.rId2'), gender: {
                            Male: {
                                below35: onceMonthTobaccoMaleBelow35,
                                above35: onceMonthTobaccoMaleAbove35,
                                above72: onceMonthTobaccoMaleAbove72
                            },
                            Female: {
                                below35: onceMonthTobaccoFemaleBelow35,
                                above35: onceMonthTobaccoFemaleAbove35,
                                above72: onceMonthTobaccoFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 3, radio_text: t('LifeStyleAssessment.Tobacco.rId3'), gender: {
                            Male: {
                                below35: twoToTenTobaccoMaleBelow35,
                                above35: twoToTenTobaccoMaleAbove35,
                                above72: twoToTenTobaccoMaleAbove72
                            },
                            Female: {
                                below35: twoToTenTobaccoFemaleBelow35,
                                above35: twoToTenTobaccoFemaleAbove35,
                                above72: twoToTenTobaccoFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 4, radio_text: t('LifeStyleAssessment.Tobacco.rId4'), gender: {
                            Male: {
                                below35: elevenToFifteenTobaccoMaleBelow35,
                                above35: elevenToFifteenTobaccoMaleAbove35,
                                above72: elevenToFifteenTobaccoMaleAbove72
                            },
                            Female: {
                                below35: elevenToFifteenTobaccoFemaleBelow35,
                                above35: elevenToFifteenTobaccoFemaleAbove35,
                                above72: elevenToFifteenTobaccoFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 5, radio_text: t('LifeStyleAssessment.Tobacco.rId5'), gender: {
                            Male: {
                                below35: moreThanFifteenTobaccoMaleBelow35,
                                above35: moreThanFifteenTobaccoMaleAbove35,
                                above72: moreThanFifteenTobaccoMaleAbove72
                            },
                            Female: {
                                below35: moreThanFifteenTobaccoFemaleBelow35,
                                above35: moreThanFifteenTobaccoFemaleAbove35,
                                above72: moreThanFifteenTobaccoFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 6, radio_text: t('LifeStyleAssessment.Tobacco.rId6'), gender: {
                            Male: {
                                below35: dailyTobaccoMaleBelow35,
                                above35: dailyTobaccoMaleAbove35,
                                above72: dailyTobaccoMaleAbove72
                            },
                            Female: {
                                below35: dailyTobaccoFemaleBelow35,
                                above35: dailyTobaccoFemaleAbove35,
                                above72: dailyTobaccoFemaleAbove72
                            }
                        }
                    },
                ]
            }
        ]
    }

    const handleTobacco = (e, key) => {
        let ha;
        if (key == 1) {
            ha = "Never";
        }
        else if (key == 2) {
            ha = "Once a month or Occasionally";
        }
        else if (key == 3) {
            ha = "2 to 10 times a month"
        }
        else if (key == 4) {
            ha = "11 to 15 times a month"
        }
        else if (key == 5) {
            ha = "More than 15 times a month";
        }
        else if (key == 6) {
            ha = "Daily";
        }
        tobaccoConsumption = contentData.options[0].radio_button[key - 1].radio_text;
        setTobaccoConsumption(tobaccoConsumption)
        setErrorMsg({ tobacco: "" })
        window.localStorage.setItem('tobacco', JSON.stringify(ha));
        window.localStorage.setItem('tobacco1', JSON.stringify(tobaccoConsumption));
        let lottie = handleLottie(key, contentData)
        setLottiePlayer(lottie);
    }

    const handleSubmit = () => {
        const tobacco = JSON.parse(window.localStorage.getItem('tobacco'))
        if (tobacco) {
            setErrorMsg({ tobacco: "" })
            navigate(`${homePageUrl}alcohol`)
        }
        else {
            setErrorMsg({ tobacco: t('Messages.LifestyleAssessment.1') })
        }

    }

    const handleNavigationLottie = () => {
        let tobacco = window.localStorage.getItem('tobacco')
        let tobacco1 = window.localStorage.getItem('tobacco1')


        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        if (tobacco == null) {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
        } else {
            tobacco = tobacco.replaceAll('"', '')
            tobacco1 = tobacco1.replaceAll('"', '')
            if (JSON.parse(window.localStorage.getItem('lang')) == "en") {
                setTobaccoConsumption(tobacco)
                let id = contentData.options[0].radio_button.find((rep) => rep.radio_text == tobacco).rId
                handleTobacco(1, id)
            }
            else {
                let id = contentData.options[0].radio_button.find((rep) => rep.radio_text == tobacco1).rId
                handleTobacco(1, id)
            }

        }
    }

    //get local storage
    useEffect(() => {
        // const tobacco = window.localStorage.getItem('tobacco')
        // setTobaccoConsumption(JSON.parse(tobacco))
        if (JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en") {
            let lang = "en";
            handleClick(lang);
        }
        else {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
        handleNavigationLottie()
    }, [])

    const handleClick = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t)
        setLanguage(lang);
    }

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className='align-items-center'>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                                <p className="cm-para typ-medium typ-center typ-form-title typ-mob-left typ-max-wid">
                                    {contentData.options[0].option_text}
                                </p>
                                <div>
                                    <div className="bs-form mt-0">
                                        <FormGroup className='radio-box typ-timeline' tag="fieldset">
                                            {
                                                contentData.options[0].radio_button.map((item) => {
                                                    return (
                                                        <React.Fragment key={item.rId}>
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input type="radio"
                                                                        checked={item.radio_text === tobaccoConsumption}
                                                                        name="alcohol" value={item.radio_text} onChange={(e) => handleTobacco(e, item.rId)} />
                                                                    <div className='check-box-label'>
                                                                        <div className='circle-box'>
                                                                            <div className='circle'></div>
                                                                        </div>
                                                                        <p className='radio-txt'>
                                                                            {item.radio_text}
                                                                        </p>
                                                                    </div>
                                                                </Label>
                                                            </FormGroup>
                                                        </React.Fragment>
                                                    )
                                                })}
                                        </FormGroup>
                                        {errorMsg.tobacco && <p className='error-message'>{errorMsg.tobacco}</p>}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prev={`${homePageUrl}vegetable-in-take`} submit next={handleSubmit} />
            <Footer />
        </div>
    )
}


export default Tobacco;