import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from 'reactstrap';
import _range from "lodash/range";
import Picker from "react-scrollable-picker";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';


//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// images
import Right from '../../../assets/images/select-language/right.svg';

// css
import '../../../assets/sass/custom.scss';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';


function BloodPressure2() {

    let navigate = useNavigate()

    const initialValues = { systolicUpperBp: 5, diastolicLowerBp: 3 }
    let [contentValues, setContentValues] = useState(initialValues)


    // Slider weight loop
    const createElementsSystolicUpperBp = (number) => {
        var elements = [];
        let i;
        for (i = 49; i < number; i++) {
            elements.push(<SwiperSlide key={i} index={i}>{i}</SwiperSlide>)
        }
        return elements;
    }
    // Slider weight loop
    const createElementsDiastolicLowerBp = (number) => {
        var elements = [];
        let i;
        for (i = 29; i < number; i++) {
            elements.push(<SwiperSlide key={i} index={i}>{i}</SwiperSlide>)
        }
        return elements;
    }

    //Swiper slide change for weight 
    const handleSystolicUpperBp = (event) => {
        let activeIndex = event.realIndex
        contentValues.systolicUpperBp = activeIndex + 1
        setContentValues({ systolicUpperBp: contentValues.systolicUpperBp })
        window.localStorage.setItem('systolic-upper-bp', JSON.stringify(contentValues.systolicUpperBp + 48))
    }
    //Swiper slide change for weight 
    const handleDiastolicLowerBp = (event) => {
        let activeIndex = event.realIndex
        contentValues.diastolicLowerBp = activeIndex + 1
        setContentValues({ diastolicLowerBp: contentValues.diastolicLowerBp })
        window.localStorage.setItem('diastolic-lower-bp', JSON.stringify(contentValues.diastolicLowerBp + 28))
    }

    useEffect(() => {
        const systolicUpperBp = JSON.parse(window.localStorage.getItem('systolic-upper-bp'))
        const diastolicLowerBp = JSON.parse(window.localStorage.getItem('diastolic-lower-bp'))
        setContentValues({ systolicUpperBp: systolicUpperBp, diastolicLowerBp: diastolicLowerBp })
    }, [])

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{x: "-100%", opacity: 0 }} className='bs-card'>
                    <Row className='align-items-center'>
                        <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                            {
                                isMobile &&
                                <div className="bs-heading typ-center">
                                    <h2 className="heading">
                                        Blood Pressure
                                    </h2>
                                    <p className="subheading">
                                        Enter correct information for us to fill into our record books
                                    </p>
                                </div>
                            }
                        </Col>
                        <Col xl="6" lg="6" md="12">
                            <div className="bs-img-sprite typ-blood-pressure bg-1">
                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="12">
                            {
                                isBrowser &&
                                <div className="bs-heading typ-center">
                                    <h2 className="heading">
                                        Blood Pressure
                                    </h2>
                                    <p className="subheading">
                                        Enter correct information for us to fill into our record books
                                    </p>
                                </div>
                            }
                            <div className='bs-swiper mb-40'>
                                <p className='slider-title'>
                                    Systolic (Upper BP)
                                </p>
                                <div className='swiper-parent'>
                                    <Swiper
                                        initialSlide={JSON.parse(window.localStorage.getItem('systolic-upper-bp')) === null ? 71 : JSON.parse(window.localStorage.getItem('systolic-upper-bp')) - 49}
                                        className='age-swiper' spaceBetween={0} grabCursor={false} slidesPerView={7} slideToClickedSlide={true} centeredSlides='true'
                                        breakpoints={{
                                            767: {
                                                slidesPerView: 5,
                                            },
                                            992: {
                                                slidesPerView: 5,
                                            },
                                            1024: {
                                                slidesPerView: 5,
                                            },
                                            1366: {
                                                slidesPerView: 9,
                                            },
                                        }} onSlideChange={e => handleSystolicUpperBp(e)}
                                    // onSwiper={getAge}
                                    >
                                        {createElementsSystolicUpperBp(251)}
                                    </Swiper>
                                </div>
                            </div>
                            <div className='bs-swiper'>
                                <p className='slider-title'>
                                    Diastolic (Lower BP)
                                </p>
                                <div className='swiper-parent'>
                                    <Swiper className='age-swiper'
                                        initialSlide={JSON.parse(window.localStorage.getItem('diastolic-lower-bp')) === null ? 51 : JSON.parse(window.localStorage.getItem('diastolic-lower-bp')) - 29}
                                        spaceBetween={0} grabCursor={false} slidesPerView={7} slideToClickedSlide={true} centeredSlides='true'
                                        breakpoints={{
                                            767: {
                                                slidesPerView: 5,
                                            },
                                            992: {
                                                slidesPerView: 5,
                                            },
                                            1024: {
                                                slidesPerView: 5,
                                            },
                                            1366: {
                                                slidesPerView: 9,
                                            },
                                        }} onSlideChange={e => handleDiastolicLowerBp(e)}
                                    // onSwiper={getAge}
                                    >
                                        {createElementsDiastolicLowerBp(161)}
                                    </Swiper>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prev={`${homePageUrl}sugar-values3`} next={`${homePageUrl}record-submitted`} />
            <Footer />
        </div>
    );
}

export default BloodPressure2;