import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from 'reactstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from "../../../helper/ContentConstants";
// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images

// css
import "../../../assets/sass/custom.scss";

// json 
import defaultHeightMaleBelow35 from '../../../assets/json/gender/male/below35/hello.json';
import defaultHeightMaleAbove35 from '../../../assets/json/gender/male/above35/hello.json';
import defaultHeightMaleAbove72 from '../../../assets/json/gender/male/above72/hello.json';
import defaultHeightFemaleBelow35 from '../../../assets/json/gender/female/below35/hello.json';
import defaultHeightFemaleAbove35 from '../../../assets/json/gender/female/above35/hello.json';
import defaultHeightFemaleAbove72 from '../../../assets/json/gender/female/above72/hello.json';

// components
import Footer from "../../layouts/footer/Footer";
import RoutingArrow from "../../shared/RoutingArrow/RoutingArrow";
import { addUserApi } from '../../../helper/ContentConstants';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function AlmostDone() {
  const { t, i18n } = useTranslation();
  let [language, setLanguage] = useState("")
  let navigate = useNavigate();
  const [lottiePlayer, setLottiePlayer] = useState("");
  let contentData = {
    options: [
      {
        radio_button: [
          {
            rId: 1, radio_text: 'text', gender: {
              Male: {
                default: {
                  below35: defaultHeightMaleBelow35,
                  above35: defaultHeightMaleAbove35,
                  above72: defaultHeightMaleAbove72,
                },
              },
              Female: {
                default: {
                  below35: defaultHeightFemaleBelow35,
                  above35: defaultHeightFemaleAbove35,
                  above72: defaultHeightFemaleAbove72,
                },
              }
            }
          }
        ]
      }
    ]
  }

  const handleNavigationLottie = () => {
    let gender = localStorage.getItem('gender').replaceAll('"', '');
    let age = localStorage.getItem('age')
    let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
    console.log(lottie)
    setLottiePlayer(lottie);
  }

  const handlePrevious = () => {
    const noSymptoms = JSON.parse(window.localStorage.getItem('no-above-symptoms'))
    const noDisease = JSON.parse(window.localStorage.getItem('none-of-the-above'))
    const bloodPressure = JSON.parse(window.localStorage.getItem('blood-pressure'))
    const systolicUpperBp = JSON.parse(window.localStorage.getItem('systolic-upper-bp'))
    const diastolicLowerBp = JSON.parse(window.localStorage.getItem('diastolic-lower-bp'))

    if (systolicUpperBp && diastolicLowerBp) { navigate(`${homePageUrl}blood-pressure2`) }
    else if (noSymptoms && noDisease) { navigate(`${homePageUrl}symptoms`) }
    else if (noSymptoms && !noDisease) { navigate(`${homePageUrl}blood-pressure`) }
    else if (bloodPressure === "No") { navigate(`${homePageUrl}blood-pressure`) }
    else navigate(`${homePageUrl}symtom-situations`)
  }

  //Get all local stored value

  //Patient and detail
  const patient = JSON.parse(window.localStorage.getItem("patient"));
  const name = JSON.parse(window.localStorage.getItem("name"));
  const mobile = JSON.parse(window.localStorage.getItem("mobile"));
  const relative = JSON.parse(window.localStorage.getItem("relative"));
  const age = JSON.parse(window.localStorage.getItem("age"));
  const gender = JSON.parse(window.localStorage.getItem("gender"));

  //lifestyle details
  const sleephour = JSON.parse(window.localStorage.getItem("sleephour"));
  const excercise = JSON.parse(window.localStorage.getItem("excercise"));
  const vegetableInTake = JSON.parse(
    window.localStorage.getItem("vegetable-in-take")
  );
  const tobacco = JSON.parse(window.localStorage.getItem("tobacco"));
  const alcohol = JSON.parse(window.localStorage.getItem("alcohol"));

  //Stress
  const stress = JSON.parse(window.localStorage.getItem("stress"));

  //BMI detail
  const weight = JSON.parse(window.localStorage.getItem("weight"));
  const heightFt = JSON.parse(window.localStorage.getItem("height-ft"));
  const heightIn = JSON.parse(window.localStorage.getItem("height-in"));

  //Disease Details
  const hCholesterol = JSON.parse(
    window.localStorage.getItem("high-cholesterol")
  );
  const hBbpHypertension = JSON.parse(
    window.localStorage.getItem("highbp-hypertension")
  );
  const diabete = JSON.parse(window.localStorage.getItem("diabetes"));
  const hDisease = JSON.parse(window.localStorage.getItem("heart-disease"));
  const noDisease = JSON.parse(
    window.localStorage.getItem("none-of-the-above")
  );

  //Family history
  const famHBbpHypertension = JSON.parse(
    window.localStorage.getItem("fam-highbp-hypertension")
  );
  const famDiabete = JSON.parse(window.localStorage.getItem("fam-diabetes"));
  const famHDisease = JSON.parse(
    window.localStorage.getItem("fam-heart-disease")
  );
  const famNoDisease = JSON.parse(
    window.localStorage.getItem("fam-none-of-the-above")
  );

  //Medication Details
  const medicine = JSON.parse(window.localStorage.getItem("medicine"));
  const medicineCount = JSON.parse(
    window.localStorage.getItem("medicine-count")
  );
  const irregualrMedicine = JSON.parse(
    window.localStorage.getItem("irregular-medine")
  );

  //heart condition
  const heartBlockage = JSON.parse(
    window.localStorage.getItem("heart-blockage")
  );
  const heartSurgery = JSON.parse(window.localStorage.getItem("heart-surgery"));

  //symptoms
  const frequentUrination = JSON.parse(window.localStorage.getItem("frequent-urination"));
  const excessiveHunger = JSON.parse(window.localStorage.getItem("excessive-hunger"));
  const woundNotHealing = JSON.parse(window.localStorage.getItem("wound-not-healing"));
  const weightLoss = JSON.parse(window.localStorage.getItem("weight-loss"));
  const weightGain = JSON.parse(window.localStorage.getItem("weight-gain"));
  const jointPains = JSON.parse(window.localStorage.getItem("joint-pains"));
  const headache = JSON.parse(window.localStorage.getItem("headache"));
  const weaknessOfFatigue = JSON.parse(window.localStorage.getItem("weakness-of-fatigue"));
  const constipation = JSON.parse(window.localStorage.getItem("constipation"));
  const sweating = JSON.parse(window.localStorage.getItem("sweating"));
  const tingling = JSON.parse(window.localStorage.getItem("tingling-numbness"));
  const noAboveSymptoms = JSON.parse(window.localStorage.getItem("no-above-symptoms"));

  //symtom situations
  const symtomSituations = JSON.parse(window.localStorage.getItem("symptom-situation"));

  //sugar values
  const bloodSugar = JSON.parse(window.localStorage.getItem("blood-sugar"));
  const fastingSugar = JSON.parse(window.localStorage.getItem("fasting-sugar"));
  const postFastingSugar = JSON.parse(window.localStorage.getItem("post-fasting-sugar"));
  const hbac1 = JSON.parse(window.localStorage.getItem("hbac1"));

  //cholesterol
  const bloodCholesterol = JSON.parse(
    window.localStorage.getItem("blood-cholesterol")
  );
  const totalCholestrol = JSON.parse(
    window.localStorage.getItem("total-cholestrol")
  );
  const ldl = JSON.parse(window.localStorage.getItem("ldl"));
  const hdl = JSON.parse(window.localStorage.getItem("hdl"));

  //blood pressure
  const bloodPressure = JSON.parse(
    window.localStorage.getItem("blood-pressure")
  );
  const systolicUpperBp = JSON.parse(
    window.localStorage.getItem("systolic-upper-bp")
  );
  const diastolicLowerBp = JSON.parse(
    window.localStorage.getItem("diastolic-lower-bp")
  );

  //waist size
  const waistSize = JSON.parse(window.localStorage.getItem("waist-size"));

  useEffect(() => {
    //calculate BMI
    let hmt = Number(heightFt) * 0.3048 + Number(heightIn) * 0.0254;
    let bmi = Number(weight) / (hmt * hmt);
    bmi = Math.round(bmi * 10) / 10;

    //bmipoints
    let bmipoints;
    if (bmi <= 15.9) {
      bmipoints = 20;
    } else if (bmi >= 16.0 && bmi <= 16.9) {
      bmipoints = 15;
    } else if (bmi >= 17.0 && bmi <= 17.9) {
      bmipoints = 10;
    } else if (bmi >= 18.0 && bmi <= 18.4) {
      bmipoints = 5;
    } else if (bmi >= 18.5 && bmi <= 24.7) {
      bmipoints = 0;
    } else if (bmi >= 24.8 && bmi <= 24.9) {
      bmipoints = 2;
    } else if (bmi >= 25.0 && bmi <= 25.2) {
      bmipoints = 7;
    } else if (bmi >= 25.3 && bmi <= 25.5) {
      bmipoints = 8;
    } else if (bmi >= 25.6 && bmi <= 25.8) {
      bmipoints = 9;
    } else if (bmi >= 25.9 && bmi <= 26.1) {
      bmipoints = 10;
    } else if (bmi >= 26.2 && bmi <= 26.45) {
      bmipoints = 11;
    } else if (bmi >= 26.5 && bmi <= 26.7) {
      bmipoints = 12;
    } else if (bmi >= 26.8 && bmi <= 26.9) {
      bmipoints = 13;
    } else if (bmi >= 27.0 && bmi <= 27.5) {
      bmipoints = 14;
    } else if (bmi >= 27.6 && bmi <= 27.9) {
      bmipoints = 15;
    } else if (bmi >= 28.0 && bmi <= 28.5) {
      bmipoints = 16;
    } else if (bmi >= 28.6 && bmi <= 28.9) {
      bmipoints = 17;
    } else if (bmi >= 29.0 && bmi <= 29.5) {
      bmipoints = 18;
    } else if (bmi >= 29.6 && bmi <= 29.9) {
      bmipoints = 19;
    } else if (
      (bmi >= 30.0 && bmi <= 34.9) ||
      (bmi >= 35.0 && bmi <= 39.9) ||
      bmi >= 40.0
    ) {
      bmipoints = 20;
    }

    window.localStorage.setItem('bmipoints', JSON.stringify(bmipoints))
    window.localStorage.setItem('bmi', JSON.stringify(bmi))




    //agepointer	
    let agepoints;
    if (age <= 34) {
      agepoints = 2;
    } else if (age === 35) {
      agepoints = 2.5;
    } else if (age === 36) {
      agepoints = 3;
    } else if (age === 37) {
      agepoints = 3.5;
    } else if (age === 38) {
      agepoints = 4;
    } else if (age === 39) {
      agepoints = 4.5;
    } else if (age === 40) {
      agepoints = 5;
    } else if (age === 41) {
      agepoints = 5.5;
    } else if (age === 42) {
      agepoints = 6;
    } else if (age === 43) {
      agepoints = 6.5;
    } else if (age === 44) {
      agepoints = 7;
    } else if (age === 45) {
      agepoints = 7.5;
    } else if (age === 46) {
      agepoints = 8;
    } else if (age === 47) {
      agepoints = 8.5;
    } else if (age === 48) {
      agepoints = 9;
    } else if (age === 49) {
      agepoints = 9.5;
    } else if (age === 50) {
      agepoints = 10;
    } else if (age === 51) {
      agepoints = 10.5;
    } else if (age === 52) {
      agepoints = 11;
    } else if (age === 53) {
      agepoints = 11.5;
    } else if (age === 54) {
      agepoints = 12;
    } else if (age === 55) {
      agepoints = 12.5;
    } else if (age === 56) {
      agepoints = 13;
    } else if (age === 57) {
      agepoints = 13.5;
    } else if (age === 58) {
      agepoints = 14;
    } else if (age === 59) {
      agepoints = 14.5;
    } else if (age >= 60) {
      agepoints = 15;
    }
    window.localStorage.setItem('agepoints', JSON.stringify(agepoints))

    // sleepingpoints
    let sleepingpoints;

    if (sleephour === "Less than 4 hours") {
      sleepingpoints = 2;
    } else if (sleephour === "4 to 5 hours") {
      sleepingpoints = 1.75;
    } else if (sleephour === "5 to 6 hours") {
      sleepingpoints = 1;
    } else if (sleephour === "6 to 7 hours") {
      sleepingpoints = 0.5;
    } else if (sleephour === "7 hours or More") {
      sleepingpoints = 0;
    }
    window.localStorage.setItem('sleepingpoints', JSON.stringify(sleepingpoints))

    // mealpoints
    let mealpoints;
    if (vegetableInTake === "Never") {
      mealpoints = 2;
    } else if (vegetableInTake === "2 times a week") {
      mealpoints = 2;
    } else if (vegetableInTake === "3 times a week") {
      mealpoints = 1.5;
    } else if (vegetableInTake === "4 times a week") {
      mealpoints = 1.25;
    } else if (vegetableInTake === "5 to 6 times a week") {
      mealpoints = 0;
    } else if (vegetableInTake === "Daily") {
      mealpoints = 0;
    }
    window.localStorage.setItem('mealpoints', JSON.stringify(mealpoints))


    //exercisepoints
    let exercisepoints;

    if (excercise === "Never") {
      exercisepoints = 2;
    } else if (excercise === "2 times a week") {
      exercisepoints = 1.5;
    } else if (excercise === "3 times a week") {
      exercisepoints = 1;
    } else if (excercise === "4 times a week") {
      exercisepoints = 0.5;
    } else if (excercise === "5 to 6 times a week") {
      exercisepoints = 0;
    } else if (excercise === "Daily") {
      exercisepoints = 0;
    }
    window.localStorage.setItem('exercisepoints', JSON.stringify(exercisepoints))

    //alcoholpoints
    let alcoholpoints;
    if (alcohol === "Never") {
      alcoholpoints = 0;
    } else if (alcohol === "Once a month or Occasionally") {
      alcoholpoints = 0.5;
    } else if (alcohol === "2 to 10 times a month") {
      alcoholpoints = 1;
    } else if (alcohol === "11 to 15 times a month") {
      alcoholpoints = 1.5;
    } else if (alcohol === "More than 15 times a month") {
      alcoholpoints = 2;
    } else if (alcohol === "Daily") {
      alcoholpoints = 2;
    }
    window.localStorage.setItem('alcoholpoints', JSON.stringify(alcoholpoints))

    //smokepoints
    let tobaccopoints;
    if (tobacco === "Never") {
      tobaccopoints = 0;
    } else if (tobacco === "Once a month or Occasionally") {
      tobaccopoints = 1;
    } else if (tobacco === "2 to 10 times a month") {
      tobaccopoints = 1.5;
    } else if (tobacco === "11 to 15 times a month") {
      tobaccopoints = 2;
    } else if (tobacco === "More than 15 times a month") {
      tobaccopoints = 3;
    } else if (tobacco === "Daily") {
      console.log("hello tobacco")
      tobaccopoints = 3;
    }
    window.localStorage.setItem('tobaccopoints', JSON.stringify(tobaccopoints))

    //bppoints(Systolic (Upper BP))
    let bppoints;
    if (bloodPressure === "No") {
      bppoints = 2;
      window.localStorage.removeItem('systolic-upper-bp')
      window.localStorage.removeItem('diastolic-lower-bp')
    } else if (
      (systolicUpperBp >= 171 && systolicUpperBp <= 175) ||
      (systolicUpperBp >= 176 && systolicUpperBp <= 180) ||
      (systolicUpperBp >= 181 && systolicUpperBp <= 185) ||
      (systolicUpperBp >= 186 && systolicUpperBp <= 190) ||
      (systolicUpperBp >= 191 && systolicUpperBp <= 220) ||
      systolicUpperBp >= 221
    ) {
      bppoints = 10;
    } else if (systolicUpperBp >= 70 && systolicUpperBp <= 79) {
      bppoints = 6;
    } else if (systolicUpperBp >= 80 && systolicUpperBp <= 89) {
      bppoints = 4;
    } else if (systolicUpperBp >= 90 && systolicUpperBp <= 120) {
      bppoints = 0;
    } else if (systolicUpperBp >= 121 && systolicUpperBp <= 124) {
      bppoints = 3;
    } else if (systolicUpperBp >= 125 && systolicUpperBp <= 128) {
      bppoints = 4;
    } else if (systolicUpperBp >= 129 && systolicUpperBp <= 130) {
      bppoints = 5;
    } else if (systolicUpperBp >= 131 && systolicUpperBp <= 132) {
      bppoints = 5.5;
    } else if (systolicUpperBp >= 133 && systolicUpperBp <= 136) {
      bppoints = 6;
    } else if (systolicUpperBp >= 137 && systolicUpperBp <= 140) {
      bppoints = 6.5;
    } else if (systolicUpperBp >= 141 && systolicUpperBp <= 145) {
      bppoints = 7;
    } else if (systolicUpperBp >= 146 && systolicUpperBp <= 150) {
      bppoints = 7.5;
    } else if (systolicUpperBp >= 151 && systolicUpperBp <= 155) {
      bppoints = 8;
    } else if (systolicUpperBp >= 156 && systolicUpperBp <= 160) {
      bppoints = 8.5;
    } else if (systolicUpperBp >= 161 && systolicUpperBp <= 165) {
      bppoints = 9;
    } else if (systolicUpperBp >= 166 && systolicUpperBp <= 170) {
      bppoints = 9.5;
    } else if (systolicUpperBp <= 69) {
      bppoints = 10;
    }
    window.localStorage.setItem('bppoints', JSON.stringify(bppoints))

    //bplowerpoints
    let bplowerpoints;
    if (bloodPressure === "No") {
      bplowerpoints = 2;
      window.localStorage.removeItem('systolic-upper-bp')
      window.localStorage.removeItem('diastolic-lower-bp')
    } else if (diastolicLowerBp <= 39) {
      bplowerpoints = 10;
    } else if (diastolicLowerBp >= 40 && diastolicLowerBp <= 49) {
      bplowerpoints = 6;
    } else if (diastolicLowerBp >= 50 && diastolicLowerBp <= 59) {
      bplowerpoints = 4;
    } else if (diastolicLowerBp >= 60 && diastolicLowerBp <= 80) {
      bplowerpoints = 0;
    } else if (diastolicLowerBp >= 81 && diastolicLowerBp <= 83) {
      bplowerpoints = 3;
    } else if (diastolicLowerBp >= 84 && diastolicLowerBp <= 86) {
      bplowerpoints = 4;
    } else if (diastolicLowerBp >= 87 && diastolicLowerBp <= 89) {
      bplowerpoints = 5;
    } else if (diastolicLowerBp >= 90 && diastolicLowerBp <= 92) {
      bplowerpoints = 5.5;
    } else if (diastolicLowerBp >= 93 && diastolicLowerBp <= 95) {
      bplowerpoints = 6;
    } else if (diastolicLowerBp >= 96 && diastolicLowerBp <= 98) {
      bplowerpoints = 6.5;
    } else if (diastolicLowerBp >= 99 && diastolicLowerBp <= 101) {
      bplowerpoints = 7;
    } else if (diastolicLowerBp >= 102 && diastolicLowerBp <= 105) {
      bplowerpoints = 7.5;
    } else if (diastolicLowerBp >= 106 && diastolicLowerBp <= 110) {
      bplowerpoints = 8;
    } else if (diastolicLowerBp >= 111 && diastolicLowerBp <= 115) {
      bplowerpoints = 8.5;
    } else if (diastolicLowerBp >= 116 && diastolicLowerBp <= 120) {
      bplowerpoints = 9;
    } else if (diastolicLowerBp >= 121 && diastolicLowerBp <= 125) {
      bplowerpoints = 9.5;
    } else if (diastolicLowerBp >= 126 && diastolicLowerBp <= 130) {
      bplowerpoints = 10;
    } else if (diastolicLowerBp >= 131 && diastolicLowerBp <= 140) {
      bplowerpoints = 10;
    } else if (diastolicLowerBp >= 141) {
      bplowerpoints = 10;
    }
    window.localStorage.setItem('bplowerpoints', JSON.stringify(bplowerpoints))


    //sugarfpoint
    let sugarfpoint;
    if (bloodSugar === "No") {
      sugarfpoint = 2;
      window.localStorage.removeItem('fasting-sugar')
      window.localStorage.removeItem('post-fasting-sugar')
      window.localStorage.removeItem('hbac1')
    } else if (fastingSugar >= 40 && fastingSugar <= 69) {
      sugarfpoint = 5;
    } else if (fastingSugar >= 70 && fastingSugar <= 100) {
      sugarfpoint = 0;
    } else if (fastingSugar >= 101 && fastingSugar <= 105) {
      sugarfpoint = 3;
    } else if (fastingSugar >= 106 && fastingSugar <= 110) {
      sugarfpoint = 3.5;
    } else if (fastingSugar >= 111 && fastingSugar <= 115) {
      sugarfpoint = 4;
    } else if (fastingSugar >= 116 && fastingSugar <= 120) {
      sugarfpoint = 4.5;
    } else if (fastingSugar >= 121 && fastingSugar <= 125) {
      sugarfpoint = 5;
    } else if (fastingSugar >= 126 && fastingSugar <= 130) {
      sugarfpoint = 5.5;
    } else if (fastingSugar >= 131 && fastingSugar <= 135) {
      sugarfpoint = 6;
    } else if (fastingSugar >= 136 && fastingSugar <= 140) {
      sugarfpoint = 6.5;
    } else if (fastingSugar >= 141 && fastingSugar <= 145) {
      sugarfpoint = 7;
    } else if (fastingSugar >= 146 && fastingSugar <= 150) {
      sugarfpoint = 7.5;
    } else if (fastingSugar >= 151 && fastingSugar <= 160) {
      sugarfpoint = 8;
    } else if (fastingSugar >= 161 && fastingSugar <= 170) {
      sugarfpoint = 8.5;
    } else if (fastingSugar >= 171 && fastingSugar <= 180) {
      sugarfpoint = 9;
    } else if (fastingSugar >= 181 && fastingSugar <= 190) {
      sugarfpoint = 9.5;
    } else if (fastingSugar >= 191 && fastingSugar <= 200) {
      sugarfpoint = 10;
    } else if (fastingSugar >= 201 && fastingSugar <= 250) {
      sugarfpoint = 10;
    } else if (fastingSugar >= 251 && fastingSugar <= 400) {
      sugarfpoint = 10;
    }
    window.localStorage.setItem('sugarfpoint', JSON.stringify(sugarfpoint))


    //sugarpppoint
    let sugarpppoint;
    if (bloodSugar === "No") {
      sugarpppoint = 2;
      window.localStorage.removeItem('fasting-sugar')
      window.localStorage.removeItem('post-fasting-sugar')
      window.localStorage.removeItem('hbac1')
    } else if (postFastingSugar >= 40 && postFastingSugar <= 69) {
      sugarpppoint = 5;
    } else if (postFastingSugar >= 70 && postFastingSugar <= 140) {
      sugarpppoint = 0;
    } else if (postFastingSugar >= 141 && postFastingSugar <= 145) {
      sugarpppoint = 3;
    } else if (postFastingSugar >= 146 && postFastingSugar <= 150) {
      sugarpppoint = 4;
    } else if (postFastingSugar >= 151 && postFastingSugar <= 155) {
      sugarpppoint = 4.5;
    } else if (postFastingSugar >= 156 && postFastingSugar <= 160) {
      sugarpppoint = 5;
    } else if (postFastingSugar >= 161 && postFastingSugar <= 165) {
      sugarpppoint = 5.5;
    } else if (postFastingSugar >= 166 && postFastingSugar <= 170) {
      sugarpppoint = 6;
    } else if (postFastingSugar >= 171 && postFastingSugar <= 175) {
      sugarpppoint = 6.5;
    } else if (postFastingSugar >= 176 && postFastingSugar <= 180) {
      sugarpppoint = 7;
    } else if (postFastingSugar >= 181 && postFastingSugar <= 185) {
      sugarpppoint = 7.5;
    } else if (postFastingSugar >= 186 && postFastingSugar <= 190) {
      sugarpppoint = 8;
    } else if (postFastingSugar >= 191 && postFastingSugar <= 199) {
      sugarpppoint = 8.5;
    } else if (postFastingSugar >= 200 && postFastingSugar <= 210) {
      sugarpppoint = 9;
    } else if (postFastingSugar >= 211 && postFastingSugar <= 220) {
      sugarpppoint = 9.5;
    } else if (postFastingSugar >= 221 && postFastingSugar <= 230) {
      sugarpppoint = 10;
    } else if (postFastingSugar >= 231 && postFastingSugar <= 240) {
      sugarpppoint = 10;
    } else if (postFastingSugar >= 241 && postFastingSugar <= 250) {
      sugarpppoint = 10;
    } else if (postFastingSugar >= 251 && postFastingSugar <= 440) {
      sugarpppoint = 10;
    }
    window.localStorage.setItem('sugarpppoint', JSON.stringify(sugarpppoint))


    //sugarhbpoint
    let sugarhbpoint;
    if (bloodSugar === "No") {
      sugarhbpoint = 2;
      window.localStorage.removeItem('fasting-sugar')
      window.localStorage.removeItem('post-fasting-sugar')
      window.localStorage.removeItem('hbac1')
    } else if (hbac1 >= 3.0 && hbac1 <= 5.7) {
      sugarhbpoint = 0;
    } else if (hbac1 >= 5.8 && hbac1 <= 5.9) {
      sugarhbpoint = 3;
    } else if (hbac1 >= 6.0 && hbac1 <= 6.2) {
      sugarhbpoint = 3.5;
    } else if (hbac1 >= 6.3 && hbac1 <= 6.5) {
      sugarhbpoint = 4;
    } else if (hbac1 >= 6.6 && hbac1 <= 6.8) {
      sugarhbpoint = 4.5;
    } else if (hbac1 >= 6.9 && hbac1 <= 7) {
      sugarhbpoint = 5;
    } else if (hbac1 >= 7.1 && hbac1 <= 7.4) {
      sugarhbpoint = 5.5;
    } else if (hbac1 >= 7.5 && hbac1 <= 7.8) {
      sugarhbpoint = 6;
    } else if (hbac1 >= 7.9 && hbac1 <= 8.2) {
      sugarhbpoint = 6.5;
    } else if (hbac1 >= 8.3 && hbac1 <= 8.6) {
      sugarhbpoint = 7;
    } else if (hbac1 >= 8.7 && hbac1 <= 9) {
      sugarhbpoint = 7.5;
    } else if (hbac1 >= 9.1 && hbac1 <= 9.5) {
      sugarhbpoint = 8;
    } else if (hbac1 >= 9.6 && hbac1 <= 10) {
      sugarhbpoint = 8.5;
    } else if (hbac1 >= 10.1 && hbac1 <= 11) {
      sugarhbpoint = 9;
    } else if (hbac1 >= 11.1 && hbac1 <= 12) {
      sugarhbpoint = 9.5;
    } else if (
      (hbac1 >= 12.1 && hbac1 <= 13) ||
      (hbac1 >= 13.1 && hbac1 <= 15) ||
      hbac1 > 15.1
    ) {
      sugarhbpoint = 10;
    }
    window.localStorage.setItem('sugarhbpoint', JSON.stringify(sugarhbpoint))



    //heartSurgerypoints
    let heartSurgerypoints;
    if (heartBlockage === "Yes" && heartSurgery === "No") {
      heartSurgerypoints = 5;
    } else if (heartBlockage === "Yes" && heartSurgery === "Yes") {
      heartSurgerypoints = 1;
    } else if (!heartSurgerypoints) {
      heartSurgerypoints = 0;
    } else if (heartBlockage === "No" || null || undefined) {
      heartSurgerypoints = 0;
    }
    window.localStorage.setItem('heartSurgerypoints', JSON.stringify(heartSurgerypoints))


    //totcholpoint
    let totcholpoint;
    if (bloodCholesterol === "No") {
      totcholpoint = 2;
      window.localStorage.removeItem('total-cholestrol')
      window.localStorage.removeItem('ldl')
      window.localStorage.removeItem('hdl')
    } else if (totalCholestrol >= 40 && totalCholestrol <= 200) {
      totcholpoint = 0;
    } else if (totalCholestrol >= 201 && totalCholestrol <= 205) {
      totcholpoint = 3;
    } else if (totalCholestrol >= 206 && totalCholestrol <= 210) {
      totcholpoint = 3.5;
    } else if (totalCholestrol >= 211 && totalCholestrol <= 215) {
      totcholpoint = 4;
    } else if (totalCholestrol >= 216 && totalCholestrol <= 220) {
      totcholpoint = 4.5;
    } else if (totalCholestrol >= 221 && totalCholestrol <= 225) {
      totcholpoint = 5;
    } else if (totalCholestrol >= 226 && totalCholestrol <= 230) {
      totcholpoint = 6;
    } else if (totalCholestrol >= 231 && totalCholestrol <= 235) {
      totcholpoint = 6.5;
    } else if (totalCholestrol >= 236 && totalCholestrol <= 239) {
      totcholpoint = 7;
    } else if (totalCholestrol >= 240 && totalCholestrol <= 250) {
      totcholpoint = 7.5;
    } else if (totalCholestrol >= 251 && totalCholestrol <= 260) {
      totcholpoint = 8;
    } else if (totalCholestrol >= 261 && totalCholestrol <= 270) {
      totcholpoint = 8.5;
    } else if (totalCholestrol >= 271 && totalCholestrol <= 280) {
      totcholpoint = 9;
    } else if (totalCholestrol >= 281 && totalCholestrol <= 290) {
      totcholpoint = 9.5;
    } else if (totalCholestrol >= 291 && totalCholestrol <= 300) {
      totcholpoint = 10;
    } else if (totalCholestrol >= 301 && totalCholestrol <= 350) {
      totcholpoint = 10;
    } else if (totalCholestrol >= 351) {
      totcholpoint = 10;
    }
    window.localStorage.setItem('totcholpoint', JSON.stringify(totcholpoint))


    //ldlpoint
    let ldlpoint;
    if (bloodCholesterol === "No") {
      ldlpoint = 2;
      window.localStorage.removeItem('total-cholestrol')
      window.localStorage.removeItem('ldl')
      window.localStorage.removeItem('hdl')
    } else if (ldl >= 20 && ldl <= 130) {
      ldlpoint = 0;
    } else if (ldl >= 131 && ldl <= 135) {
      ldlpoint = 0.5;
    } else if (ldl >= 136 && ldl <= 140) {
      ldlpoint = 1;
    } else if (ldl >= 141 && ldl <= 145) {
      ldlpoint = 1.5;
    } else if (ldl >= 146 && ldl <= 150) {
      ldlpoint = 2;
    } else if (ldl >= 151 && ldl <= 155) {
      ldlpoint = 2.5;
    } else if (ldl >= 156 && ldl <= 160) {
      ldlpoint = 3;
    } else if (ldl >= 161 && ldl <= 165) {
      ldlpoint = 3.5;
    } else if (ldl >= 166 && ldl <= 170) {
      ldlpoint = 4;
    } else if (ldl >= 171 && ldl <= 175) {
      ldlpoint = 4.5;
    } else if (ldl >= 176 && ldl <= 180) {
      ldlpoint = 5;
    } else if (ldl >= 181 && ldl <= 185) {
      ldlpoint = 5.5;
    } else if (ldl >= 186 && ldl <= 190) {
      ldlpoint = 6;
    } else if (ldl >= 191 && ldl <= 200) {
      ldlpoint = 6.5;
    } else if (ldl >= 201 && ldl <= 210) {
      ldlpoint = 7;
    } else if (ldl >= 211 && ldl <= 220) {
      ldlpoint = 7.5;
    } else if (ldl >= 221 && ldl <= 230) {
      ldlpoint = 8;
    } else if (ldl >= 231 && ldl <= 240) {
      ldlpoint = 8.5;
    } else if (ldl >= 241 && ldl <= 250) {
      ldlpoint = 9;
    } else if (ldl >= 251 && ldl <= 260) {
      ldlpoint = 9.5;
    } else if (ldl >= 261) {
      ldlpoint = 10;
    }
    window.localStorage.setItem('ldlpoint', JSON.stringify(ldlpoint))


    // hdlpoint
    let hdlpoint;
    if (bloodCholesterol === "No") {
      hdlpoint = 2;
      window.localStorage.removeItem('total-cholestrol')
      window.localStorage.removeItem('ldl')
      window.localStorage.removeItem('hdl')
    } else if (hdl <= 30) {
      hdlpoint = 10;
    } else if (hdl >= 31 && hdl <= 32) {
      hdlpoint = 9.5;
    } else if (hdl >= 33 && hdl <= 34) {
      hdlpoint = 9;
    } else if (hdl >= 35 && hdl <= 36) {
      hdlpoint = 8.5;
    } else if (hdl >= 37 && hdl <= 38) {
      hdlpoint = 8;
    } else if (hdl >= 39 && hdl <= 40) {
      hdlpoint = 7.5;
    } else if (hdl >= 41 && hdl <= 42) {
      hdlpoint = 7;
    } else if (hdl >= 43 && hdl <= 44) {
      hdlpoint = 6.5;
    } else if (hdl >= 45 && hdl <= 46) {
      hdlpoint = 6;
    } else if (hdl >= 47 && hdl <= 48) {
      hdlpoint = 5.5;
    } else if (hdl >= 49 && hdl <= 50) {
      hdlpoint = 5;
    } else if (hdl >= 51 && hdl <= 52) {
      hdlpoint = 4.5;
    } else if (hdl >= 53 && hdl <= 54) {
      hdlpoint = 4;
    } else if (hdl >= 55 && hdl <= 57) {
      hdlpoint = 3;
    } else if (hdl >= 58 && hdl <= 59) {
      hdlpoint = 2;
    } else if (hdl >= 60 && hdl <= 150) {
      hdlpoint = 0;
    } else if (hdl >= 151) {
      hdlpoint = 0;
    }
    window.localStorage.setItem('hdlpoint', JSON.stringify(hdlpoint))

    //symptomspoint

    let fUrination, eHunger, woundNotHeal, wLoss, wGain, jPain, hache, wFatigue, contis, sweat, tinglingNumbness;

    if (frequentUrination) { fUrination = 2; } else { fUrination = 0; }
    if (excessiveHunger) { eHunger = 2; } else { eHunger = 0; }
    if (woundNotHealing) { woundNotHeal = 2; } else { woundNotHeal = 0; }
    if (weightLoss) { wLoss = 2; } else { wLoss = 0; }
    if (weightGain) { wGain = 2; } else { wGain = 0; }
    if (jointPains) { jPain = 2; } else { jPain = 0; }
    if (headache) { hache = 2; } else { hache = 0; }
    if (weaknessOfFatigue) { wFatigue = 2; } else { wFatigue = 0; }
    if (constipation) { contis = 2; } else { contis = 0; }
    if (sweating) { sweat = 2; } else { sweat = 0; }
    if (tingling) { tinglingNumbness = 2; } else { tinglingNumbness = 0; }

    //symptomspoint
    let symptomspoint;
    if (noAboveSymptoms) { symptomspoint = 0 }
    else if (frequentUrination === null && excessiveHunger === null && woundNotHealing === null && weightLoss === null && weightGain === null && jointPains === null && headache === null && weaknessOfFatigue === null && constipation === null && sweating === null && tingling === null && noAboveSymptoms === null) {
      symptomspoint = 0
    }
    else { symptomspoint = 10 + fUrination + eHunger + woundNotHeal + wLoss + wGain + jPain + hache + wFatigue + contis + sweat + tinglingNumbness }
    window.localStorage.setItem('symptomspoint', JSON.stringify(symptomspoint))

    //anginapoint(symptom situation)
    let anginapoint;
    if (symtomSituations === "While at Rest") {
      anginapoint = 5
    } else if (symtomSituations === "During Routine Workout") {
      anginapoint = 3
    } else if (symtomSituations === "After Heavy Exercise") {
      anginapoint = 2
    } else if (symtomSituations === "No Such Symptoms") {
      anginapoint = 0
    }
    window.localStorage.setItem('anginapoint', JSON.stringify(anginapoint))


    //familyhistorypoints
    let familyhistorypoints;
    if (!famHBbpHypertension || !famDiabete || !famHDisease) {
      familyhistorypoints = 0;
    }
    let famHistory = [famHBbpHypertension, famDiabete, famHDisease];
    let famTrueVal = famHistory.filter((di) => di === true).length;

    if (famNoDisease === true) {
      familyhistorypoints = 0;
    } else {
      familyhistorypoints = famTrueVal * 2;
    }
    window.localStorage.setItem('familyhistorypoints', JSON.stringify(familyhistorypoints))

    console.log("medicine points are >>", medicineCount)
    //noofMedicinepoints
    let noofMedicinepoints;
    if (medicineCount === "1 Medicine") {
      noofMedicinepoints = 1;
    } else if (medicineCount === "2 Medicine") {
      noofMedicinepoints = 1.5;
    } else if (medicineCount === "3 Medicine") {
      noofMedicinepoints = 2;
    } else if (medicineCount === "4 Medicine & above") {
      noofMedicinepoints = 3;
    } else if (medicine === "No") {
      noofMedicinepoints = 3;
      // window.localStorage.removeItem('noofMedicinepoints')
    } else if (noDisease) {
      noofMedicinepoints = 0;
    }
    window.localStorage.setItem('noofMedicinepoints', JSON.stringify(noofMedicinepoints))

    //waist size
    let waistSizePoints;
    if (waistSize === "under 28") {
      waistSizePoints = 0.5;
    } else if (waistSize === "28.1-30") {
      waistSizePoints = 1;
    } else if (waistSize === "30.1-32") {
      waistSizePoints = 2;
    } else if (waistSize === "32.1-34") {
      waistSizePoints = 3;
    } else if (waistSize === "34.1-36") {
      waistSizePoints = 4;
    } else if (waistSize === "above 36.1") {
      waistSizePoints = 5;
    }
    window.localStorage.setItem('waistSizePoints', JSON.stringify(waistSizePoints))

    //Stress size
    let stresspoints;
    if (!stress) {
      stresspoints = 0;
    } else if (stress === "Very Often") {
      stresspoints = 3;
    } else if (stress === "Occasionally") {
      stresspoints = 2;
    } else if (stress === "Never") {
      stresspoints = 0;
    } else if (stress || null || undefined) {
      stresspoints = 0;
    }
    window.localStorage.setItem('stresspoints', JSON.stringify(stresspoints))

    // diseasecalpoints
    let diseasecalpoints;
    if (!hCholesterol || !hBbpHypertension || !diabete || !hDisease) {
      diseasecalpoints = 0;
    }
    let disease = [hCholesterol, hBbpHypertension, diabete, hDisease];
    let trueVal = disease.filter((di) => di === true).length;
    // console.log(typeof noDisease)
    if (noDisease === true) {
      diseasecalpoints = 0;
      noofMedicinepoints = 0;
      heartSurgerypoints = 0;
    } else {
      diseasecalpoints = 25 + trueVal * 2;
    }

    if (trueVal >= 1) {
      if ((medicine === "Yes" && irregualrMedicine === "Yes") || medicine === "No") {
        diseasecalpoints = diseasecalpoints + 3;
      }
    }
    window.localStorage.setItem('diseasecalpoints', JSON.stringify(diseasecalpoints))

    // if ((medicine === "Yes" && irregualrMedicine === "Yes") || medicine === "No") {
    //   diseasecalpoints = diseasecalpoints + 3;
    // }

    //lifestylepoint
    let lifestylepoint = agepoints + bmipoints + exercisepoints + mealpoints + sleepingpoints + tobaccopoints + stresspoints + waistSizePoints + symptomspoint;
    window.localStorage.setItem('lifestylepoint', JSON.stringify(lifestylepoint))

    //heartDiseasepoint
    let heartDiseasepoint = (anginapoint == 5 || diseasecalpoints > 0 || sugarfpoint >= 4.1 || sugarhbpoint >= 4.1 || sugarpppoint >= 4.1 || ldlpoint >= 4.1 || hdlpoint >= 4.1 || bppoints >= 4.1)

    window.localStorage.setItem('heartDiseasepoint', JSON.stringify(heartDiseasepoint))


    let points = agepoints + alcoholpoints + bmipoints + bplowerpoints + bppoints + diseasecalpoints + exercisepoints + familyhistorypoints + hdlpoint + heartSurgerypoints + ldlpoint + mealpoints + noofMedicinepoints + sleepingpoints + tobaccopoints + stresspoints + sugarfpoint + sugarhbpoint + sugarpppoint + symptomspoint + totcholpoint + waistSizePoints + anginapoint;

    console.log("added values", points)
    console.log("agepoints", agepoints, "alcoholpoints", alcoholpoints, "bmipoints", bmipoints, "bplowerpoints", bplowerpoints, "bppoints", bppoints, "diseasecalpoints", diseasecalpoints, "exercisepoints", exercisepoints, "familyhistorypoints", familyhistorypoints, "hdlpoint", hdlpoint, "heartSurgerypoints", heartSurgerypoints, "ldlpoint", ldlpoint, "mealpoints", mealpoints, "noofMedicinepoints", noofMedicinepoints, "sleepingpoints", sleepingpoints, "tobaccopoints", tobaccopoints, "stresspoints", stresspoints, "sugarfpoint", sugarfpoint, "sugarhbpoint", sugarhbpoint, "sugarpppoint", sugarpppoint, "symptomspoint", symptomspoint, "totcholpoint", totcholpoint, "waistSizePoints", waistSizePoints, "anginapoint", anginapoint)

    let fpoints = points / 221;
    fpoints = 1 - fpoints;
    fpoints = fpoints * 100;
    fpoints = Math.round(fpoints, 0);
    window.localStorage.setItem("score", JSON.stringify(fpoints));

    if (fpoints <= 49) {
      window.localStorage.setItem("healthStatus", JSON.stringify("poor"));
    } else if (fpoints >= 50 && fpoints <= 74) {
      window.localStorage.setItem("healthStatus", JSON.stringify("fair"));
    } else if (fpoints >= 75 && fpoints <= 84) {
      window.localStorage.setItem("healthStatus", JSON.stringify("good"));
    } else if (fpoints >= 85 && fpoints <= 94) {
      window.localStorage.setItem("healthStatus", JSON.stringify("veryGood"));
    } else if (fpoints >= 95) {
      window.localStorage.setItem("healthStatus", JSON.stringify("excellent"));
    }

    if(JSON.parse(window.localStorage.getItem('lang')) == null || JSON.parse(window.localStorage.getItem('lang')) == "en")
    {
        let lang = "en";
        handleClick(lang);
    }
    else
    {
        handleClick(JSON.parse(window.localStorage.getItem('lang')));
    }
    handleNavigationLottie();
  },[]);

  const handleClick = (lang) => 
    {
      window.localStorage.setItem('lang', JSON.stringify(lang))
      console.log("Language is >>",lang)
      i18next.changeLanguage(lang)
      console.log("t value is >>",t)
      setLanguage(lang);
    }

  const SaveDetail = async () => {
    const allKeys = JSON.parse(JSON.stringify(window.localStorage));
    console.log("All keys >>", allKeys);
    try {
      const response = await axios({
        method: "post",
        url: addUserApi,
        data: allKeys,
      });
      console.log("Response is >>", response);
      if (response.data.statusCode == 200) {
        let uid = response.data.uniqueId;

        navigate(`${homePageUrl}otp-verification`);
      }

    } catch (error) {
      toastr.options = {
        positionClass: 'toast-top-full-width',
        hideDuration: 300,
        timeOut: 2000
      }
      toastr.clear()
      setTimeout(() => toastr.error('Something Went Wrong'), 200)
    }
  }
  const handleSubmit = () => {
    // console.log("user id from storage is >>",JSON.parse(window.localStorage.getItem('userId')));
    if (JSON.parse(window.localStorage.getItem('uniqueId')) != null) {
      navigate(`${homePageUrl}otp-verification`);
    }
    else {
      SaveDetail();
    }
  }

  return (
    <div className="main">
      <Container>
        <AnimationWrapper>
          <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
            <Row className="align-items-center">
              <Col
                xl={{ size: 6, offset: 6 }}
                lg={{ size: 6, offset: 6 }}
                md={12}
                sm={6}
              >
                {isMobile && (
                  <div className="bs-heading typ-center">
                    <h2 className="heading">{t('AlmostDone.heading')}</h2>
                    <p className="subheading">{t('AlmostDone.subheading')}</p>
                  </div>
                )}
              </Col>
              <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                <Player
                  src={lottiePlayer}
                  autoplay
                ></Player>
              </Col>
              <Col xl="6" lg="6" md="12">
                {isBrowser && (
                  <div className="bs-heading typ-center">
                    <h2 className="heading">{t('AlmostDone.heading')}</h2>
                    <p className="subheading">{t('AlmostDone.subheading')}</p>
                  </div>
                )}
                <p className="cm-para typ-center typ-max-wid">
                {t('AlmostDone.cm-para')}
                </p>
              </Col>
            </Row>
          </motion.div>
        </AnimationWrapper>
      </Container>
      <RoutingArrow
        submit
        prevBtn={handlePrevious}
        next={handleSubmit}
      />
      <Footer />
    </div>
  );
}

export default AlmostDone;
