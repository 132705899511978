import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, FormGroup, Input, Label, Button, Modal, ModalBody } from 'reactstrap';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { useLocation, useNavigate,useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';

// animation 
// import Aos from "aos";
// import "aos/dist/aos.css";

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images 
import MultiColorBand from '../../../assets/images/take-the-test/meter-desk.gif';
import MultiColorBandMob from '../../../assets/images/take-the-test/meter-mob.gif';

// css
import '../../../assets/sass/custom.scss';

// json 
import LottiFiles from '../../../assets/json/start-meter.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { animate } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function TakeTheTest() {
    const { t, i18n } = useTranslation();
    const [modal, setModal] = useState(false);

    // Toggle for Modal
    const toggle = () => setModal(!modal);

    // animation start
    // animaion end 

    let navigate = useNavigate();
    let location = useLocation();

    let [searchParams, setSearchParams] = useSearchParams();
    let [inputValue, setInputValue] = useState("")
    let [isSubmit, setIsSubmit] = useState(false)
    let [errorMsg, setErrorMsg] = useState('')
    let [initAnim, setInitAnim] = useState(false)
    let [status,setStatus] = useState(false)

    const handleCheckbox = () => {
        setIsSubmit(!isSubmit)
        window.localStorage.setItem('agreed', JSON.stringify(!isSubmit))
        if (isSubmit != true) {
            setErrorMsg('')
        }
    }

    const handleSubmit = () => {
        window.localStorage.setItem('previous-page', JSON.stringify(location.pathname))
        setInitAnim(true)
        if (isSubmit) {
            navigate(`${homePageUrl}select-patient`)
        }
        else setErrorMsg(t('Messages.Homepage.1'))
    }

    useEffect(() => {
        
        console.log("localstorage value >>",window.localStorage.length)
        // 
        // if(window.localStorage.length > 0)
        // {
        //     window.localStorage.clear();
        //     console.log("localstorage clear");
        //     setTimeout(()=>{
        //         if(searchParams.get('sid') != null)
        //         {
        //             let sid = searchParams.getAll('sid')
        //             console.log("id is here >>",sid[0].substring(0, 25));
        //             let trim_sid = sid[0].substring(0, 25)
        //             window.localStorage.setItem('sid', JSON.stringify(trim_sid))
        //         }
        //         const name = JSON.parse(window.localStorage.getItem('agreed'))
        //         setIsSubmit(name)
        //         setStatus(true)
        //         if(JSON.parse(window.localStorage.getItem('lang')) == "" || "null")
        //         {
        //             handleClick("en");
        //         }
        //         else if(JSON.parse(window.localStorage.getItem('lang')) == "mr")
        //         {
        //             handleClick("mr");
        //         }
        //         else
        //         {
        //             handleClick("hi");
        //         }
        //     },1000);
        // }
        // else
        // {
            setTimeout(()=>{
                if(searchParams.get('sid') != null)
                {
                    let sid = searchParams.getAll('sid')
                    console.log("id is here >>",sid[0].substring(0, 25));
                    let trim_sid = sid[0].substring(0, 25)
                    window.localStorage.setItem('sid', JSON.stringify(trim_sid))
                }
                const name = JSON.parse(window.localStorage.getItem('agreed'))
                setIsSubmit(name)
                setStatus(true)
                if(JSON.parse(window.localStorage.getItem('lang')) == null || JSON.parse(window.localStorage.getItem('lang')) == "en")
                {
                    let lang = "en";
                    handleClick(lang);
                }
                else
                {
                    handleClick(JSON.parse(window.localStorage.getItem('lang')));
                }
            },1000);
        // }
        // Aos.init()
    },[status])

    const handleClick = (lang) => 
    {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>",lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>",t)
    }

    return (
        <div className='main'>
            {/* <nav style={{ width: '100%', padding: '2rem 0', backgroundColor:'gray' }}>
                <Button onClick={()=>handleClick('en')} >
                    English
                </Button>
                <Button onClick={()=>handleClick('mr')} >
                    marathi
                </Button>
                <Button onClick={()=>handleClick('hi')} >
                    hindi
                </Button>
            </nav> */}
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{x: "-100%", opacity: 0 }} className='bs-card'>
                        <Row className='align-items-center'>
                            <Col xl={{ offset: 6, size: 6 }} lg={{ offset: 6, size: 6 }} md="12">
                                {
                                    isMobile &&
                                    <div id="secHeading" className="bs-heading typ-center"><h2 className="heading">{t('Homepage.1')}</h2><p className="subheading">{t('Homepage.2')}</p></div>
                                }
                            </Col>
                            <Col className='cm-img-col' xl="6" lg="6" md="12">
                                <div className='bs-lottifiles typ-mob-no-space'>
                                    <Player className='multi-color-bg'
                                        autoplay
                                        speed={1.5}
                                        src={LottiFiles}
                                    >
                                    </Player>
                                </div>
                            </Col>
                            <Col id="prepend" xl="6" lg="6" md="12">
                                {
                                    isBrowser && 
                                    <div id="secHeading" className="bs-heading typ-center"><h2 className="heading">{t('Homepage.1')}</h2><p className="subheading">{t('Homepage.2')}</p></div>
                                }
                                <p className='cm-para typ-center typ-black2'>
                                {t('Homepage.3')} <br /> <br />
                                {t('Homepage.4')}
                                </p>
                                <div className='bs-form'>
                                    <FormGroup className='check-box' tag="fieldset">
                                        <FormGroup check>
                                            <Label className='cm-para typ-medium typ-form-title typ-center typ-small typ-black' check>
                                                <Input type="checkbox" checked={isSubmit} value={inputValue} onChange={handleCheckbox} />
                                                <div className='check-box-label'>
                                                    <span className="icon icon-right"></span>
                                                </div>
                                                <span className='check-txt'>{t('Homepage.5')}<Button className='cm-para typ-center typ-mob-left typ-link' onClick={toggle}>{t('Homepage.6')}</Button></span>
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                    {errorMsg && <p className='error-message typ-desk-center'>{errorMsg}</p>}
                                    {
                                        isBrowser &&
                                        <Button className='bs-btn typ-check-score' onClick={handleSubmit}>
                                            {t('Homepage.7')}
                                        </Button>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            {
                isBrowser &&
                <Footer />
            }
            {
                isMobile &&
                <div className='sticky-footer'>
                    <Button className='bs-btn typ-check-score' onClick={handleSubmit}>
                        Check my score
                    </Button>
                </div>
            }
            <Modal isOpen={modal} toggle={toggle}
                modalTransition={{ timeout: 500 }}>
                <ModalBody>
                    <div className='bs-modal'>
                        <div id="secHeading" className="bs-heading typ-center typ-full-width"><h2 className="heading">PRIVACY POLICY AND TERMS OF USAGE</h2><p className="subheading">Please read all the documents carefully.</p></div>
                        <p class="cm-para typ-black2">
                            Effective Date: 1st August 2022 <br />
                            Last Updated on: 1st August 2022
                        </p>
                        <p class="cm-para typ-black2">
                            1. GENERAL:
                        </p>
                        <p class="cm-para typ-black2">
                            a. This website application with name “Heart Health Meter” (HHM) is operated by Vaidya Sane Ayurved Laboratories Limited under its brand Madhavbaug, (hereinafter referred as “We/Our/Us”). We are committed to protecting and respecting your privacy. We collect your personal information including personal data in accordance with IT Act, 2000. And other applicable laws which relate to the processing of personal data. Please read the following carefully to understand our views and practices regarding your data. <br />

                            b. We collect your personal information in order to provide and continually improve our products and services. <br />

                            c. Our Privacy policy is subject to change at any time without notice, to make sure you are aware of any changes, please review this policy periodically, for your convenience, we have arrange latest updated version date at beginning of this page.

                        </p>
                        <p class="cm-para typ-black2">
                            2. COLLECTION OF INFORMATION:
                        </p>
                        <p class="cm-para typ-black2">
                            a. From you directly or through this web application: We may collect information through this web application when you visit, the data we collect depends on the context of your inputs. <br />
                            b. Through interaction: We may collect information through interaction with you or your relative
                        </p>
                        <p class="cm-para typ-black2">
                            3. INFORMATION WE COLLECT:
                        </p>
                        <p class="cm-para typ-black2">
                            a. We collect information primarily to better services to all of our patients/customers <br />

                            b. when you visit HHM, some information is collected through system which you are using, this may include information such as the Operating System (OS) running on your device, IP Address, access times, browser type and language. <br />

                            c. we collect information using “Cookies”. Cookies are small data files stored on your hard drive. Among other things, cookies help us improve our site experience, our marketing activities, we use cookies to see which areas and features are popular and to count visits to our web application. <br />

                            d. Most web browsers are set to accept cookies by default. If you prefer, you can choose to set your browser to remove cookies and to reject cookies. If you set your browser to reject cookies, some features will be unavailable. For more information on how to reject cookies, see your browser’s instructions on changing your cookie settings. <br />

                            e. By using this website, you are agreeing that we may advertise your feedback on the website and marketing materials. <br />

                            f. we will retain your information as long as we require this information to be utilise for our analyse and database purpose. <br />

                            g. if you opt to receive any correspondence from us, subscribe to our mailing list or newsletters, enter into any our competitions or provide us with your details at networking events, we may use your personal data for our legitimate interest in order to provide you with details about products, services, offers, updates or events.
                        </p>
                        <p class="cm-para typ-black2">
                            4. HOW WE USE INFORMATION:
                        </p>
                        <p class="cm-para typ-black2">
                            a. we use the information primarily to provide, maintain, protect and improve our current products and services. <br />

                            b. we use the information collected through this application in this policy and we may use your information to: <br />

                            <div className='pl-15'>
                                i.	Improve our services, site and how we operate our businesses; <br />
                                ii.	Understand and enhance your experience using our site, products and services; <br />
                                iii.	Personalise our products or services and make recommendations; <br />
                                iv.	Provide and deliver products or services you request; <br />
                                v.	Process, manage, complete and account for the outcome of process <br />
                                vi.	Provide customer support and respond to your requests, comments and inquiries; <br />
                                vii.	Create and manage the online accounts you manage on our web application; <br />
                                viii.	Send you related information, including confirmation, invoices, updates, security alerts and support and administrative messages through various means; <br />
                                ix.	Communicate with you about promotions, upcoming offers, and news about products and services; <br />
                                x.	Protect, investigate, and deter against fraudulent, unauthorised or illegal activity. <br />
                                xi.	We may process your information by virtue of any invitation or notice under any application law without your knowledge or consent for the purpose of verification of identity or prevention, detection, or investigation, including of cyber incidents, prosecution and punishment of offences; <br />
                            </div>

                        </p>
                        <p class="cm-para typ-black2">
                            5. DATA TRANSFER:
                        </p>
                        <p class="cm-para typ-black2">
                            a. Information about our patients/ users is an important part of our business and we take due care to protect the same.
                            b. we share your data with your consent or to complete any transaction or provide any product or services you have requested or authorised, we also share data with our affiliates with our vendors working on our behalf. <br />

                            c. we may employ other vendors/companies/ individuals to perform functions on our behalf. The Functions include fulfilling orders for product or service, packages, sending email notification, removing repetitive information from customer lists, providing marketing assistance, providing search results and links, processing payments, transmitting content and providing customer service. <br />

                            d. These third party service providers have access to personal information need to perform their functions assigned by us but may not use it for other purposes. Further, they must process the personal information in accordance with this privacy policy and permitted by applicable data protection laws.
                        </p>
                        <p class="cm-para typ-black2">
                            6. CREATION OF ACCOUNT:
                        </p>
                        <p class="cm-para typ-black2">
                            i.	To use certain features of web application, you must set up an account with web application and provide certain information about yourself as prompted by the Customer Information form, including, your name, gender, email address, account password, mobile phone number and billing/shipping address. All of your registration information is protected by our Privacy Policy. <br />
                            ii.	You represent and warrant that the information you submit during registration is truthful and accurate and that you will maintain the accuracy of such information. <br />
                            iii.	Your HHM username and password are personal to you. You may not transfer your account and you will be responsible for the activities associated with your HHM Account. <br />
                            iv.	HHM will not be liable for any loss or damages as a result of your failure to maintain the confidentiality of your account credentials. If you suspect any unauthorised use of your HHM account, you shall immediately notify the same to the email ID ______________________ <br />
                            v.	It is your responsibility to keep your email address up-to-date on your account setup at web application so that we can communicate with you electronically. <br />
                            vi.	By creating this account you agree to receive communications from us via email, telephone, SMS and chat. If you, at any time, wish to discontinue receiving communications from us, you agree to notify us by email.

                        </p>
                        <p class="cm-para typ-black2">
                            7. USER CONTENT:
                        </p>
                        <p class="cm-para typ-black2">
                            <ul>
                                <li>
                                    The content that you upload or post will become our property and you grant us the worldwide, perpetual and transferable rights in such Content. We shall be entitled to, consistent with our Privacy Policy as adopted in accordance with applicable law, use the Content or any of its elements for any type of use forever, including but not limited to promotional and advertising purposes and in any media whether now known or hereafter devised, including the creation of derivative works that may include any content you provide and are not entitled to any payment or other compensation for such use. You also grant us the right to sub-license these rights, and the right to bring an action for infringement of these rights. We reserve the right to edit or remove any material submitted to this App, or stored on our servers, or hosted or published upon HHM.
                                </li>
                                <li>
                                    Those posting any form of User content on HHM must verify the truthfulness and authenticity of the particulars of the content, including the time, place and nature. Before allowing such content to be posted on HHM, we have the right and authority to verify the accuracy of such particulars related to the content with the respective sources provided by the user posting such content, if we feel that there is a need to check such accuracy. We have the right to upload content on behalf of third parties, subsequent to collecting such information and verifying it if we deem it necessary. Notwithstanding this, we can in no way be held liable for any false or misleading information.
                                </li>
                                <li>
                                    In case any content is considered to be unlawful or against the law within any jurisdiction in which HHM can be seen and accessed, it shall be removed forthwith by us on being intimated by the authorities of the respective jurisdiction that such content is deemed unlawful. We cannot be held liable or questioned for the same.
                                </li>
                            </ul>
                        </p>
                        <p class="cm-para typ-black2">
                            8. USER OBLIGATIONS:
                        </p>
                        <p class="cm-para typ-black2">
                            <ul>
                                <li>
                                    You are bound not to cut, copy, distribute, modify, recreate, reverse engineer, distribute, disseminate post, publish or create derivative works from, transfer, or sell any information or software obtained from HHM. With our prior permission limited use may be allowed. For the removal of doubt, it is clarified that unlimited or wholesale reproduction, copying of the content for commercial or non-commercial purposes and unwarranted modification of data and information within the content of HHM is not permitted. You shall not, nor allow third parties on your behalf to (i) make and distribute copies of HHM (ii) attempt to copy, reproduce, alter, modify, reverse engineer, disassemble, decompile, transfer, exchange or translate HHM or (iii) create derivative works of HHM of any kind whatsoever.
                                </li>
                                <li>
                                    You agree not to access (or attempt to access) HHM and/or the materials or Services by any means other than through the interface that is provided by HHM. The use of deep-link, robot, spider or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of HHM or Content, or in any way reproduce or circumvent the navigational structure or presentation of HHM, materials or any Content, to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through HHM. You acknowledge and agree that by accessing or using HHM or its services; you may be exposed to content from other users that you may consider offensive, indecent or otherwise objectionable. We disclaim all liabilities arising in relation to such offensive content on HHM. Further, you may report such offensive content by sending us an e-mail to our official e-mail address.
                                </li>
                                <li>
                                    You further affirms and agrees not to-
                                    a.	Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity; <br />
                                    b.	Violate any code of conduct or other guidelines, which may be applicable for or to any particular Service; <br />
                                    c.	Violate any applicable laws or regulations for the time being in force within India; <br />
                                    d.	Violate the Terms of Service including but not limited to any applicable Additional Terms of HHM contained herein or elsewhere; <br />
                                    e.	Violate any code of conduct or other guidelines, which may be applicable for or to any particular Service; <br />
                                    f.	Threaten the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.
                                    g.	Publish, post, disseminate information that is false, inaccurate or misleading; vi <br />olate any applicable laws or regulations for the time being in force in or outside India; <br />
                                    h.	Directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force. <br />
                                    i.	Create liability for us or cause us to lose (in whole or in part) the services of our internet service provider (“ISPs”) or other suppliers; <br />
                                    j.	While we shall not be held liable or answerable to Users for any such acts on your part, violation of this clause would lead to potential criminal legal action against you, either by other Users of HHM or by us.
                                </li>
                            </ul>

                        </p>
                        <p class="cm-para typ-black2">
                            9. SECURITY POLICY
                        </p>
                        <p class="cm-para typ-black2">
                            We confirm that all your personal information with us is secure. HHM takes careful precaution to protect our Customer’s/patient’s personal information from unauthorised access, improper use or disclosure or unauthorised modification. To prevent unauthorised access, we have put in place the latest industry-standard security technology and procedures to safeguard the information we collect online. Your personal information is encrypted and is protected with ________-bit Secure Sockets Layer (SSL) software, which encrypts all information you input. We store your personally identifiable information on the computer servers placed in a secure environment. Even though we have taken significant steps to protect your personally identifiable information, no company, including our Website, can fully eliminate security risks associated with Personal Information.
                        </p>
                        <p class="cm-para typ-black2">
                            10. INFORMED CONSENT
                        </p>
                        <p class="cm-para typ-black2">
                            By using this web application, you consent to the collection, use, and sharing of your information as outlined in this Policy.
                        </p>
                        <p class="cm-para typ-black2">
                            CONTACT DETAILS <a className='cm-para typ-link typ-inline' href="mailto:info@madhavbaug.org">info@madhavbaug.org</a>
                        </p>
                        <Button className='close' onClick={toggle} >x</Button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default TakeTheTest;