
// import Aos from "aos";
// import "aos/dist/aos.css"; 
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';
// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// import Aos from "aos";
// import "aos/dist/aos.css";

// images
import Myself from '../../../assets/images/select-patient/mySelf.png';
import FamilyRelative from '../../../assets/images/select-patient/familyRelative.png';

// css
import '../../../assets/sass/custom.scss';

// json 
import defaultThink from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import mySelfLottie from '../../../assets/json/general-detail/my-self.json';
import familyRelativeLottie from '../../../assets/json/general-detail/family-relative.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';



function SelectPatient() {
    const { t, i18n } = useTranslation();
    let location = useLocation()
    let navigate = useNavigate()
    //state
    let [spriteImg, setSpriteImg] = useState("bg-1");
    let [patient, setPatient] = useState('Myself');
    let [errorMsg, setErrorMsg] = useState("")
    let [exitAnim, setExitAnim] = useState(false)
    let [language, setLanguage] = useState("")


    console.log("language value is >>",language)

    // if()
    // {

    // }
    // if(language = "en")
    // {
    //     console.log("ENglish")
    // }
    // else if (language = "mr")
    // {
    //     console.log("marathi")
    // }
    // else
    // {
    //     console.log("Hindi")
    // }
    const [lottiePlayer, setLottiePlayer] = useState("");
    const initialValues = { spriteImg: "bg-1",option_text: "" }
    let [contentValues, setContentValues] = useState(initialValues)
    //Content
    let contentData = {
        qId: 2,
        question_text: t('SelectPatient.question_text'),
        descriptions: [t('SelectPatient.descriptions')],
        options: [{
            qId: 2,
            option_id: 1,
            option_text:  t('SelectPatient.option_text1'),
            option_description: [t('SelectPatient.option_description1')],
            optionImg: Myself,
            imgClass: "bg-1",
            lottieData: mySelfLottie,

        }, {
            qId: 2,
            option_id: 2,
            option_text: t('SelectPatient.option_text2'),
            option_description:  [t('SelectPatient.option_description2')] ,
            optionImg: FamilyRelative,
            imgClass: "bg-2",
            lottieData: familyRelativeLottie,
        },
        ]
    }

    const handleRadioBtn = (e, key) => {
        console.log("Key valu is >>",key )
        patient = contentData.options[key - 1].option_text
        console.log("patient value is >>",patient)
        contentValues.spriteImg = contentData.options[key - 1].imgClass
        contentValues.option_text = contentData.options[key - 1].option_text
        let name;
        if(key == 1)
        {
            name = "Myself";
            setPatient("Myself")
        }
        else
        {   name = "Family/Relative";
            setPatient("Family/Relative")
        }
        setContentValues({
            spriteImg: contentValues.spriteImg,
            option_text: contentValues.option_text,
        })
        setErrorMsg("")
        window.localStorage.setItem('patient', JSON.stringify(name))
        setSpriteImg(contentData.options[key - 1].imgClass)

        let lottie = contentData.options[key - 1].lottieData
        setLottiePlayer(lottie)
    }


    const handlePrev = () => {
        setExitAnim(true)
        navigate(`${homePageUrl}`)
    }
    const handleSubmit = () => {
        window.sessionStorage.setItem('previous-page', JSON.stringify(location.pathname))
        if (patient) {
            setErrorMsg("")
            navigate(`${homePageUrl}general-details`)
        }
        else {
            setErrorMsg(t('Messages.SelectPatient.1'))
        }
    }

    useEffect(() => {
        let patient = JSON.parse(window.localStorage.getItem('patient'))
        setPatient(patient)
        console.log("console called")
        if(JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en")
        {
            let lang = "en";
            handleClick(lang);
        }
        else
        {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
        if (patient == null) {
            setLottiePlayer(defaultThink)
        }
        else {
            if (patient == 'Myself') {
                setLottiePlayer(mySelfLottie)
            }
            if (patient == 'Family/Relative') {
                setLottiePlayer(familyRelativeLottie)
            }
        }
        // Aos.init() 
    },[])

    const handleClick = (lang) => 
    {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>",lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>",t)
        setLanguage(lang);
    }

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&

                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&

                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                                <div className="bs-form typ-patients">
                                    <FormGroup className='radio-box' tag="fieldset">
                                        {
                                            contentData.options.map((item) => {
                                                return (
                                                    <FormGroup check key={item.option_id}>
                                                        <Label check>
                                                            <Input type="radio" name="radio1"
                                                                checked={contentValues.option_text == item.option_text}
                                                                onChange={(e) => handleRadioBtn(e, item.option_id)}
                                                                value={item.option_text}
                                                            />
                                                            <div className='radio-label'>
                                                                <img src={item.optionImg} className='patient-type-img' alt="mySelf" />
                                                                <div className='patient-content'>
                                                                    <p className='patient-type'>
                                                                        {item.option_text}
                                                                    </p>
                                                                    <p className='desc'>
                                                                        {item.option_description}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Label>
                                                    </FormGroup>
                                                )
                                            })
                                        }
                                    </FormGroup>
                                    {errorMsg && <p className="error-message">{errorMsg}</p>}
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prevBtn={handlePrev} submit next={handleSubmit} />
            <Footer />
        </div>
    )
}

export default SelectPatient;