import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, FormGroup, Input, Label, Button, Modal, ModalBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { useLocation, useNavigate, Link,useSearchParams  } from 'react-router-dom';
import { motion } from 'framer-motion';

// animation 
// import Aos from "aos";
// import "aos/dist/aos.css";

//constant
import { homePageUrl,getSingleUserDataFromApi } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images 

// css
import '../../../assets/sass/custom.scss';

// json 
import LottiFiles from '../../../assets/json/start-meter.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { animate } from 'framer-motion';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
var custStyle = {
    minHeight: "920px",
}


function SubscriptionMed() {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    let [userId,setUserId] = useState("");
    let [diseaseName, setDiseaseName] = useState("");
    let [planName,setPlanName] = useState("")
    let [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        if(location.state != null)
        {
            console.log("Here",location)
            setUserId(location.state.userId);
            window.localStorage.setItem('userId', JSON.stringify(location.state.userId))
            console.log("user id >>",userId)
            getUserData(location.state.userId);
        }
        else
        {
            if(searchParams.get('userId')!= null || searchParams.get('userId') != undefined)
            {
                const id =searchParams.get('userId') 
                setUserId(id);
                setTimeout(()=>{
                    window.localStorage.setItem('userId', JSON.stringify(id))
                    console.log("user id is here >>",id);
                },500);
                getUserData(id);
            }
            else
            {
                let uid = JSON.parse(window.localStorage.getItem('userId'));
                setUserId(uid);
                getUserData(uid);
            }
            
        }

        if(JSON.parse(window.localStorage.getItem('lang')) != "")
        {
            handleClick("en");
        }
        else if(JSON.parse(window.localStorage.getItem('lang')) == "mr")
        {
            handleClick("mr");
        }
        else
        {
            handleClick("hi");
        }
        
        // if(searchParams.get('userId'))
        // {
        //     console.log("There")
        //     setUserId(location.state.userId);
        // }
        
        // setVideoLinks(location.state.video_links);
      }, []);

    const handleClick = (lang) => 
    {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>",lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>",t)
    }

      const getUserData = async (id) => {
        if(userId != null || userId != undefined)
        {
            const formData = new FormData()
            formData.append('userId', id)
            // formData.append('uid', "12456")
            const response = await axios({
                method: "post",
                url: getSingleUserDataFromApi,
                data: formData,
            });
            console.log("response is >>",response.data.users.plan_name)
            if(response.status == 200)
            {
                setPlanName(response.data.users.plan_name);
            }
        }
    }
    return (
        <div className='main'>
            <AnimationWrapper>
                <motion.div exit={{ x: "-100%", opacity: 0 }}>
                    <Container>
                        <Row className='text-center'>
                            <Col xl="12" lg="12" md="12">
                                <div className='bs-card typ-pl-on typ-subscription-main'>
                                    <Breadcrumb className='bs-breadcrumb'>
                                        <BreadcrumbItem><Link to={`${homePageUrl}subscription-home`}>Home</Link></BreadcrumbItem>
                                        <BreadcrumbItem active> {t('SubscriptionMed.1')}</BreadcrumbItem>
                                    </Breadcrumb>
                                    <Row className="align-items-center justify-content-center sec-save-my-heart-plan">
                                        <Col xl="12" lg="12" md="12">
                                            <div className="bs-heading typ-center">
                                                <h2 className="heading">
                                                     {t('SubscriptionMed.1')}
                                                </h2>
                                                <p className="subheading">

                                                {t('SubscriptionMed.2')} {planName != undefined && planName} {t('SubscriptionMed.3')}

                                                    


                                                {/* As per the Plan that you have Purchased you can Avail your one time Service of Cardiac Stress Test, Cardiac Risk Assessment with Doctor Consultation request to follow below steps to Book your Appointment at
                                                 nearest Madhavbaug Center. */}
                                                </p>
                                                <p className="subheading">
                                                {t('SubscriptionMed.4')}
                                                </p>
                                            </div>
                                            <div>
                                                <iframe width={"100%"} style={custStyle} src="https://madhavbaugclinics.com/app/locator/?I=DrId0732452189700MBHHM" title="clinics"></iframe>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </motion.div>
            </AnimationWrapper>
            <Footer />
        </div>
    )
}

export default SubscriptionMed;