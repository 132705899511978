import React, { useState, useEffect } from "react";
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images
import Right from '../../../assets/images/select-language/right.svg';

// css
import '../../../assets/sass/custom.scss';

// json 
// male below 35 
import defaultDiseaseMaleBelow35 from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import happyMaleBelow35 from '../../../assets/json/expression/male/below35/happy.json';
import veryHappyMaleBelow35 from '../../../assets/json/expression/male/below35/very-happy.json';
import sadMaleBelow35 from '../../../assets/json/expression/male/below35/sad.json';
import verySadMaleBelow35 from '../../../assets/json/expression/male/below35/very-sad.json';

// male above 35 
import defaultDiseaseMaleAbove35 from '../../../assets/json/default/male/above35/none-of-the-option-is-selected.json';
import happyMaleAbove35 from '../../../assets/json/expression/male/above35/happy.json';
import veryHappyMaleAbove35 from '../../../assets/json/expression/male/above35/very-happy.json';
import sadMaleAbove35 from '../../../assets/json/expression/male/above35/sad.json';
import verySadMaleAbove35 from '../../../assets/json/expression/male/above35/very-sad.json';

// male above 72 
import defaultDiseaseMaleAbove72 from '../../../assets/json/default/male/above72/none-of-the-option-is-selected.json';
import happyMaleAbove72 from '../../../assets/json/expression/male/above72/happy.json';
import veryHappyMaleAbove72 from '../../../assets/json/expression/male/above72/very-happy.json';
import sadMaleAbove72 from '../../../assets/json/expression/male/above72/sad.json';
import verySadMaleAbove72 from '../../../assets/json/expression/male/above72/very-sad.json';

// // female below 35 
import defaultDiseaseFemaleBelow35 from '../../../assets/json/default/female/below35/none-of-the-option-is-selected.json';
import happyFemaleBelow35 from '../../../assets/json/expression/female/below35/happy.json';
import veryHappyFemaleBelow35 from '../../../assets/json/expression/female/below35/very-happy.json';
import sadFemaleBelow35 from '../../../assets/json/expression/female/below35/sad.json';
import verySadFemaleBelow35 from '../../../assets/json/expression/female/below35/v-sad.json';

// // female above 35 
import defaultDiseaseFemaleAbove35 from '../../../assets/json/default/female/above35/none-of-the-option-is-selected.json';
import happyFemaleAbove35 from '../../../assets/json/expression/female/above35/happy.json';
import veryHappyFemaleAbove35 from '../../../assets/json/expression/female/above35/very-happy.json';
import sadFemaleAbove35 from '../../../assets/json/expression/female/above35/sad.json';
import verySadFemaleAbove35 from '../../../assets/json/expression/female/above35/very-sad.json';

// // female above 72
import defaultDiseaseFemaleAbove72 from '../../../assets/json/default/female/above72/none-of-the-option-is-selected.json';
import happyFemaleAbove72 from '../../../assets/json/expression/female/above72/happy.json';
import veryHappyFemaleAbove72 from '../../../assets/json/expression/female/above72/very-happy.json';
import sadFemaleAbove72 from '../../../assets/json/expression/female/above72/sad.json';
import verySadFemaleAbove72 from '../../../assets/json/expression/female/above72/very-sad.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function FamilyHistory() {
    const { t, i18n } = useTranslation();
    let [bpHypertension, setBpHypertension] = useState(false)
    let [diabetes, setDiabetes] = useState(false)
    let [heartDisease, setHeartDisease] = useState(false)
    let [noneOfTheAbove, setNoneOfTheAbove] = useState(false)
    let [errorMsg, setErrorMsg] = useState("")
    let [events, setEvents] = useState("")
    const [lottiePlayer, setLottiePlayer] = useState("");

    let contentData = {
        options: [
            {
                radio_button: [
                    {
                        rId: 1, radio_text: 'Happy', gender: {
                            Male: {
                                default: {
                                    below35: defaultDiseaseMaleBelow35,
                                    above35: defaultDiseaseMaleAbove35,
                                    above72: defaultDiseaseMaleAbove72,
                                },
                                below35: happyMaleBelow35,
                                above35: happyMaleAbove35,
                                above72: happyMaleAbove72
                            },
                            Female: {
                                default: {
                                    below35: defaultDiseaseFemaleBelow35,
                                    above35: defaultDiseaseFemaleAbove35,
                                    above72: defaultDiseaseFemaleAbove72,
                                },
                                below35: happyFemaleBelow35,
                                above35: happyFemaleAbove35,
                                above72: happyFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 2, radio_text: 'Very Happy', gender: {
                            Male: {
                                below35: veryHappyMaleBelow35,
                                above35: veryHappyMaleAbove35,
                                above72: veryHappyMaleAbove72
                            },
                            Female: {
                                below35: veryHappyFemaleBelow35,
                                above35: veryHappyFemaleAbove35,
                                above72: veryHappyFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 3, radio_text: 'Sad', gender: {
                            Male: {
                                below35: sadMaleBelow35,
                                above35: sadMaleAbove35,
                                above72: sadMaleAbove72
                            },
                            Female: {
                                below35: sadFemaleBelow35,
                                above35: sadFemaleAbove35,
                                above72: sadFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 4, radio_text: 'Very Sad', gender: {
                            Male: {
                                below35: verySadMaleBelow35,
                                above35: verySadMaleAbove35,
                                above72: verySadMaleAbove72
                            },
                            Female: {
                                below35: verySadFemaleBelow35,
                                above35: verySadFemaleAbove35,
                                above72: verySadFemaleAbove72
                            }
                        }
                    },
                ]
            }
        ]
    }


    let navigate = useNavigate()

    const handleBpHypertension = () => {
        window.localStorage.setItem('fam-highbp-hypertension', JSON.stringify(!bpHypertension))
        setBpHypertension(!bpHypertension)
        setEvents(!events)
        if (!bpHypertension) {
            setNoneOfTheAbove(false)
            window.localStorage.setItem('fam-none-of-the-above', JSON.stringify(false))
        }
    }
    const handleDiabetes = () => {
        window.localStorage.setItem('fam-diabetes', JSON.stringify(!diabetes))
        setDiabetes(!diabetes)
        setEvents(!events)
        if (!diabetes) {
            setNoneOfTheAbove(false)
            window.localStorage.setItem('fam-none-of-the-above', JSON.stringify(false))
        }
    }
    const handleHeartDisease = () => {
        window.localStorage.setItem('fam-heart-disease', JSON.stringify(!heartDisease))
        setHeartDisease(!heartDisease)
        setEvents(!events)
        if (!heartDisease) {
            setNoneOfTheAbove(false)
            window.localStorage.setItem('fam-none-of-the-above', JSON.stringify(false))
        }
    }
    const handleNoneOfTheAbove = () => {
        window.localStorage.setItem('fam-none-of-the-above', JSON.stringify(!noneOfTheAbove))
        setNoneOfTheAbove(!noneOfTheAbove)
        setEvents(!events)
        if (!noneOfTheAbove) {
            setBpHypertension(false)
            setDiabetes(false)
            setHeartDisease(false)
            window.localStorage.setItem('fam-highbp-hypertension', JSON.stringify(false))
            window.localStorage.setItem('fam-diabetes', JSON.stringify(false))
            window.localStorage.setItem('fam-heart-disease', JSON.stringify(false))
        }
    }


    const handleSubmit = () => {
        let noneOfTheAbove = JSON.parse(window.localStorage.getItem('none-of-the-above'))
        const famHBbpHypertension = JSON.parse(window.localStorage.getItem('fam-highbp-hypertension'))
        const famDiabete = JSON.parse(window.localStorage.getItem('fam-diabetes'))
        const famHDisease = JSON.parse(window.localStorage.getItem('fam-heart-disease'))
        const famNoDisease = JSON.parse(window.localStorage.getItem('fam-none-of-the-above'))
        if (famHBbpHypertension || famDiabete || famHDisease || famNoDisease) {
            if (noneOfTheAbove) {
                navigate(`${homePageUrl}symptoms`)
            }
            else navigate(`${homePageUrl}medication-details`)
        }
        else setErrorMsg(t('Messages.FamilyHistory.1'))
    }

    useEffect(() => {
        const famHBbpHypertension = JSON.parse(window.localStorage.getItem('fam-highbp-hypertension'))
        const famDiabete = JSON.parse(window.localStorage.getItem('fam-diabetes'))
        const famHDisease = JSON.parse(window.localStorage.getItem('fam-heart-disease'))
        const famNoDisease = JSON.parse(window.localStorage.getItem('fam-none-of-the-above'))
        setBpHypertension(famHBbpHypertension)
        setDiabetes(famDiabete)
        setHeartDisease(famHDisease)
        setNoneOfTheAbove(famNoDisease)
        const data = [famHBbpHypertension, famDiabete, famHDisease, famNoDisease]
        console.log(data)

        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        if (famHBbpHypertension != 'true' && famDiabete != 'true' && famHDisease != 'true' && famNoDisease != 'true') {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
            console.log(lottie)
        } else {
            let count = data.filter(rep => rep == 'true').length
            console.log(count)
            if (famNoDisease == 'false') {
                let lottie = contentData.options[0].radio_button[0].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log('innder 0')
                setLottiePlayer(lottie);
            } else if (count == 1) {
                let lottie = contentData.options[0].radio_button[1].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log('innder 1')
                setLottiePlayer(lottie);
            } else if (count == 2 || count == 3) {
                let lottie = contentData.options[0].radio_button[2].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log('innder 2-3')
                setLottiePlayer(lottie);
            } else if (count == 4) {
                let lottie = contentData.options[0].radio_button[3].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log('innder 4')
                setLottiePlayer(lottie);
            }

            if(JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en")
            {
                let lang = "en";
                handleClick(lang);
            }
            else
            {
                handleClick(JSON.parse(window.localStorage.getItem('lang')));
            }

        }
    }, []);

    const handleClick = (lang) => 
    {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>",lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>",t)
    }

    useEffect(() => {
        const famHBbpHypertension = JSON.parse(window.localStorage.getItem('fam-highbp-hypertension'))
        const famDiabete = JSON.parse(window.localStorage.getItem('fam-diabetes'))
        const famHDisease = JSON.parse(window.localStorage.getItem('fam-heart-disease'))
        const famNoDisease = JSON.parse(window.localStorage.getItem('fam-none-of-the-above'))
        const data = [famHBbpHypertension, famDiabete, famHDisease]
        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        let count = data.filter(rep => rep == 'true' || rep == true ).length
        console.log(count,famNoDisease,typeof famNoDisease)
        if (famNoDisease == true) {
            console.log("first")
            let lottie = contentData.options[0].radio_button[0].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
        }
        else if (count == 1) {
            let lottie = contentData.options[0].radio_button[1].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
        } else if (count == 2) {
            let lottie = contentData.options[0].radio_button[2].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
        } else if (count == 3) {
            let lottie = contentData.options[0].radio_button[3].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
        }
    }, [events]);

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                    <Row className='align-items-center'>
                        <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                            {
                                isMobile &&
                                <div className="bs-heading typ-center">
                                    <h2 className="heading">
                                        {t('FamilyHistory.heading')}
                                    </h2>
                                    <p className="subheading">
                                        {t('FamilyHistory.subheading')}
                                    </p>
                                </div>
                            }
                        </Col>
                        <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                        <Col xl="6" lg="6" md="12">
                            {
                                isBrowser &&
                                <div className="bs-heading typ-center">
                                    <h2 className="heading">
                                        {t('FamilyHistory.heading')}
                                    </h2>
                                    <p className="subheading">
                                        {t('FamilyHistory.subheading')}
                                    </p>
                                </div>
                            }
                            <p className="cm-para typ-black typ-center typ-mob-left typ-medium typ-max-wid typ-form-title">
                            {t('FamilyHistory.DoYouHave')}?
                            </p>
                            <div className="bs-form mt-0">
                                <FormGroup className="radio-box typ-disease">
                                    <FormGroup className="check-box typ-btn" check>
                                        <Label check>
                                            <input type="checkbox" value="" checked={window.localStorage.getItem('fam-highbp-hypertension') === 'true' ? true : false} onChange={handleBpHypertension} />{' '}
                                            <div className='checkbox-label'>
                                                <img src={Right} className='right-icon' alt="Next-arrow" />
                                                {t('FamilyHistory.HighBP')}
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="check-box typ-btn" check>
                                        <Label check>
                                            <input type="checkbox" value="" checked={window.localStorage.getItem('fam-diabetes') === 'true' ? true : false} onChange={handleDiabetes} />{' '}
                                            <div className='checkbox-label'>
                                                <img src={Right} className='right-icon' alt="Next-arrow" />
                                                {t('FamilyHistory.Diabetes')}
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="check-box typ-btn" check>
                                        <Label check>
                                            <input type="checkbox" value="" checked={window.localStorage.getItem('fam-heart-disease') === 'true' ? true : false} onChange={handleHeartDisease} />{' '}
                                            <div className='checkbox-label'>
                                                <img src={Right} className='right-icon' alt="Next-arrow" />
                                                {t('FamilyHistory.HeartDisease')}
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="check-box typ-btn" check>
                                        <Label check>
                                            <input type="checkbox" value="" checked={window.localStorage.getItem('fam-none-of-the-above') === 'true' ? true : false} onChange={handleNoneOfTheAbove} />{' '}
                                            <div className='checkbox-label'>
                                                <img src={Right} className='right-icon' alt="Next-arrow" />
                                                {t('FamilyHistory.Noneof')}
                                            </div>
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                                {errorMsg && <p className="error-message">{errorMsg}</p>}
                            </div>
                        </Col>
                    </Row>
                </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prev={`${homePageUrl}disease-details`} submit next={handleSubmit} />
            <Footer />
        </div>
    );
}

export default FamilyHistory;