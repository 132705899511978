import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, FormGroup, Input, Label, Button, Modal, ModalBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { useLocation, useNavigate, Link,useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';

// animation 
// import Aos from "aos";
// import "aos/dist/aos.css";

//constant
import { homePageUrl,getSingleUserDataFromApi } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images 
import Exersice from '../../../assets/images/subscription/exersice.png';
import DiseaseEducation from '../../../assets/images/subscription/disease-education.png';
import Meditation from '../../../assets/images/subscription/meditation.png';

// css
import '../../../assets/sass/custom.scss';

// json 
import LottiFiles from '../../../assets/json/start-meter.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { animate } from 'framer-motion';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function SubscriptionVideo() {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    let [userId,setUserId] = useState("");
    let [diseaseName, setDiseaseName] = useState("");
    let [planName,setPlanName] = useState("")
    let [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        if(location.state != null)
        {
            console.log("Here",location)
            setUserId(location.state.userId);
            window.localStorage.setItem('userId', JSON.stringify(location.state.userId))
            console.log("user id >>",userId)
            getUserData(userId);
        }
        else
        {
            if(searchParams.get('userId')!= null || searchParams.get('userId') != undefined)
            {
                console.log("Here")
                const id =searchParams.get('userId') 
                setUserId(id);
                setTimeout(()=>{
                    window.localStorage.setItem('userId', JSON.stringify(id))
                    console.log("user id is here >>",id);
                    getUserData(userId);
                },500);
                
            }
            else
            {
                let uid = JSON.parse(window.localStorage.getItem('userId'));
                setUserId(uid);
                getUserData(userId);
            }
            
        }

        if(JSON.parse(window.localStorage.getItem('lang')) != "")
        {
            handleClick("en");
        }
        else if(JSON.parse(window.localStorage.getItem('lang')) == "mr")
        {
            handleClick("mr");
        }
        else
        {
            handleClick("hi");
        }
        
        // if(searchParams.get('userId'))
        // {
        //     console.log("There")
        //     setUserId(location.state.userId);
        // }
        
        // setVideoLinks(location.state.video_links);
      }, []);


    const handleClick = (lang) => 
    {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>",lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>",t)
    }

    const getUserData = async (userId) => {
        if(userId != null || userId != undefined)
        {
            const formData = new FormData()
            formData.append('userId', userId)
            // formData.append('uid', "12456")
            const response = await axios({
                method: "post",
                url: getSingleUserDataFromApi,
                data: formData,
            });
            console.log("response is >>",response)
            if(response.data.statusCode)
            {
                setPlanName(response.data.users.plan_name);
                setDiseaseName(response.data.users.disease_name);
                console.log("reponse is >>",response.data.users.plan_name)
            }
            
        }
    }

    return (
        <div className='main'>
            <AnimationWrapper>
                <motion.div exit={{ x: "-100%", opacity: 0 }}>
                    <Container>
                        <Row className='text-center'>
                            <Col xl="12" lg="12" md="12">
                                <div className='bs-card typ-pl-on typ-subscription-main'>
                                    <Breadcrumb className='bs-breadcrumb'>
                                        <BreadcrumbItem><Link to={`${homePageUrl}subscription-home`}>Home</Link></BreadcrumbItem>
                                        <BreadcrumbItem active>Videos</BreadcrumbItem>
                                    </Breadcrumb>
                                    <Row className="justify-content-center sec-save-my-heart-plan">
                                        <Col xl="12" lg="12" md="12">
                                            <div className="bs-heading typ-center">
                                                <h2 className="heading">
                                                    {t('SubscriptionVideo.1')}

                                                </h2>
                                                <p className="subheading">
                                                {t('SubscriptionVideo.2')}
                                                {
                                                    planName == "SMH Basic" ? " "+30+" "  : " "+90+" "
                                                } 
                                                {t('SubscriptionVideo.3')}.
                                                 
                                                </p>
                                                <p className="subheading">
                                                {t('SubscriptionVideo.4')}.
                                                 </p>
                                            </div>
                                        </Col>
                                        <Col xl="4" lg="4" md="12">
                                            <Link to={`${homePageUrl}subscription-video-list`} state={{ userId: userId }} >
                                                <div className="bs-card typ-icn-txt typ-height-auto typ-subscription">
                                                    <img src={Exersice} className='avatar' />
                                                    <p className="desc"> 
                                                    {t('SubscriptionVideo.5')}
                                                    </p>
                                                </div>
                                            </Link>
                                        </Col>

                                        <Col xl="4" lg="4" md="12">
                                            <Link to={`${homePageUrl}subscription-education-video-list`} state={{ userId: userId }}>
                                                <div className="bs-card typ-icn-txt typ-height-auto typ-subscription">
                                                    <img src={DiseaseEducation} className='avatar' />
                                                    <p className="desc">
                                                    {t('SubscriptionVideo.6')}
                                                    </p>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xl="4" lg="4" md="12">
                                            <Link to={`${homePageUrl}subscription-meditation-video-list`} state={{ userId: userId }}>
                                                {/* <Link to={'/score/subscription-meditation-video-list'} state= {{ userId: userId}}> */}
                                                <div className="bs-card typ-icn-txt typ-height-auto typ-subscription">
                                                    <img src={Meditation} className='avatar' />
                                                    <p className="desc">
                                                    {t('SubscriptionVideo.7')}
                                                    </p>
                                                </div>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </motion.div>
            </AnimationWrapper>
            <Footer />
        </div>
    )
}

export default SubscriptionVideo;