import React from 'react';
import { Link } from 'react-router-dom';

// images
import NextArrow from '../../../assets/images/next-arrow.png';
import NextArrowMob from '../../../assets/images/next-arrow-mob.png';
import PrevArrow from '../../../assets/images/prev-arrow.png';
import PrevArrowMob from '../../../assets/images/prev-arrow-mob.png';
import disableArrow from '../../../assets/images/next-arrow-disable.png';
import disableArrowMob from '../../../assets/images/next-arrow-mob-disble.png';


function RoutingArrow(props) {
    const { next, prev, disable, prevBtn, submit } = props
    return (
        <>
            <>
                {prev &&
                <Link className='cm-prev-arrow' to={prev}>
                    <span className="icon icon-next-arrow"></span>
                </Link>}
                
                {prevBtn && 
                <button className="cm-prev-arrow" type="submit" onClick={prevBtn}>
                   <span className="icon icon-next-arrow"></span>
                </button>}
            </>
            
            {next &&
            <>
            {submit ?
                <button className={disable ? 'cm-next-arrow disable' : 'cm-next-arrow'} type="submit" onClick={next}>
                    <span className={disable ? 'icon icon-next-arrow disable' : 'icon icon-next-arrow'}></span>
                </button>
                :
                <Link className={disable ? 'cm-next-arrow disable' : 'cm-next-arrow'} to={next}>
                    <span className={disable ? 'icon icon-next-arrow disable' : 'icon icon-next-arrow'}></span>
                </Link>
            }
            </>
            }
        </>
    );
}

export default RoutingArrow;
