import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
} from "reactstrap";
import axios from "axios";
// popup

// img
import FooterLogo from "../../../assets/images/footer-logo.svg";
import FooterLogoMobile from "../../../assets/images/footer-logo-mob.svg";

// Constant ;
import {
  homePageUrl,
  storeContactData,
} from "../../../helper/ContentConstants";

function Footer() {
  const [modal, setModal] = useState(false);

  let navigate = useNavigate();
  let [name, setName] = useState("");
  let [mobile, setMobile] = useState("");
  let [email, setEmail] = useState("");
  let [comments, setComments] = useState("");
  let [nameError, setNameError] = useState(false);
  let [mobileError, setMobileError] = useState(false);
  let [emailError, setEmailError] = useState(false);
  let [commentsError, setCommentsError] = useState(false);
  let [emailValidation, setEmailValidation] = useState(false);
  let [mobileMinValidation, setMobileMinValidation] = useState(false);
  let [mobileMaxValidation, setMobileMaxValidation] = useState(false);
  let [close, setClose] = useState(false);
  let [PhoneNumberValidationError, setPhoneNumberValidationError] =
    useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const storeData = async () => {
    try {
      console.log("name value is >>", name);
      console.log("mobile value is >>", mobile);
      console.log("email value is >>", email);
      console.log("commenst value is >>", comments);
      if (name == "" || name == undefined) {
        setNameError(true);
      } else if (email == "" || email == undefined) {
        setEmailError(true);
      } else if (mobile == "" || mobile == undefined) {
        setMobileError(true);
      } else if (comments == "" || comments == undefined) {
        setCommentsError(true);
      } else {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("mobile", mobile);
        formData.append("email", email);
        formData.append("comments", comments);

        const response = await axios({
          method: "post",
          url: storeContactData,
          data: formData,
        });
        if (response.data.status == 200) {
          setName("");
          setEmail("");
          setMobile("");
          setComments("");
          alert("Form sent successfully");
          setModal(!modal);
        }
      }
    } catch (error) {
      // console.log("error is ", error);
    }
  };

  const hangdleChange = async (e) => {
    console.log("e", e);
    if (e.target.name == "name") {
      setNameError(false);
      setName(e.target.value);
    }
    if (e.target.name == "mobile") {
      if (e.target.value.length > 10) {
        setMobile();
        setMobileMaxValidation(true);
      } else if (e.target.value.match(/^[0-9]+$/) == null) {
        console.log("Here");
        setPhoneNumberValidationError(true);
      } else {
        setPhoneNumberValidationError(false);
        setMobileError(false);
        setMobile(e.target.value);
        setMobileMaxValidation(false);
      }
    }
    if (e.target.name == "email") {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
        console.log("Invalid email");
        setEmailValidation(true);
      } else {
        setEmailError(false);
        setEmail(e.target.value);
        setEmailValidation(false);
        console.log("Valid email");
      }
    }
    if (e.target.name == "comments") {
      setCommentsError(false);
      setComments(e.target.value);
    }
  };
  return (
    <div className="bs-footer">
      <Container>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <div className="footer-row">
              <div className="quick-links">
                <Link to="" onClick={toggle}>
                  Contact Us
                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    centered={true}
                    backdrop={true}
                  >
                    <div className="bs-custom-popup typ-no-bg show">
                      <div className="bs-card typ-popup">
                        <p class="cm-para typ-bold typ-small typ-black mb-10">
                          Contact Us
                        </p>
                        <form className="bs-form">
                          <FormGroup className="text-box">
                            <Label htmlFor="relative">Name</Label>
                            <Input
                              onChange={(e) => {
                                hangdleChange(e);
                              }}
                              type="text"
                              name="name"
                              autoComplete="off"
                              placeholder="Enter Your Full Name"
                            />
                            {/* <p className='error-message'>{formErrors.relative}</p> */}
                            {nameError && (
                              <p className="error-message">* Required field</p>
                            )}
                          </FormGroup>
                          <FormGroup className="text-box">
                            <Label htmlFor="relative">Email</Label>
                            <Input
                              onChange={(e) => {
                                hangdleChange(e);
                              }}
                              type="email"
                              name="email"
                              autoComplete="off"
                              placeholder="Enter Your Email"
                            />
                            {emailError && (
                              <p className="error-message">* Required field</p>
                            )}
                            {emailValidation && (
                              <p className="error-message">invalid Email</p>
                            )}
                            {/* <p className='error-message'>{formErrors.relative}</p> */}
                          </FormGroup>
                          <FormGroup className="text-box">
                            <Label htmlFor="relative">Phone Number</Label>
                            <Input
                              onChange={(e) => {
                                hangdleChange(e);
                              }}
                              type="email"
                              name="mobile"
                              autoComplete="off"
                              placeholder="Enter Your Number"
                            />
                            {mobileError && (
                              <p className="error-message">* Required field</p>
                            )}
                            {PhoneNumberValidationError && (
                              <p className="error-message">
                                Number should contain only integer value
                              </p>
                            )}
                            {mobileMaxValidation && (
                              <p className="error-message">
                                Mobile number should not be greater than 10
                                digits
                              </p>
                            )}
                            {/* <p className='error-message'>{formErrors.relative}</p> */}
                          </FormGroup>
                          <FormGroup className="text-box">
                            <Label htmlFor="relative">Add Your Comments</Label>
                            <Input
                              onChange={(e) => {
                                hangdleChange(e);
                              }}
                              type="textarea"
                              name="comments"
                              rows="4"
                              autoComplete="off"
                              placeholder="Add Your Comments"
                            />
                            {commentsError && (
                              <p className="error-message">* Required field</p>
                            )}
                            {/* <p className='error-message'>{formErrors.relative}</p> */}
                          </FormGroup>
                          <Button
                            className="bs-btn"
                            onClick={() => {
                              storeData();
                            }}
                          >
                            Submit
                          </Button>
                        </form>
                        <a className="close close-popup" onClick={toggle}>
                          <span class="icon icon-check-close"></span>
                        </a>
                      </div>
                    </div>
                  </Modal>
                </Link>
                <Link to={`${homePageUrl}terms-condition`}>
                  Terms & Condition
                </Link>
                <Link to={`${homePageUrl}privacy-policy`}>Privacy Policy</Link>
              </div>
              <picture
                onClick={() => {
                  window.open("https://madhavbaug.org/", "_blank");
                }}
              >
                <source media="(max-width:992px)" srcSet={FooterLogoMobile} />
                <img src={FooterLogo} alt="footer-logo" />
              </picture>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
