import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal, FormGroup, Label, Input, } from 'reactstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { motion } from 'framer-motion';

// Constant ;
import {
    homePageUrl,
    storeContactData, getUserDetailByActivityId, getUserDetailById
} from "../../../helper/ContentConstants";
// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images

// css
import "../../../assets/sass/custom.scss";

// components
import Footer from "../../layouts/footer/Footer";
import RoutingArrow from "../../shared/RoutingArrow/RoutingArrow";
import { addUserApi } from '../../../helper/ContentConstants';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import axios from 'axios';

// popup 
import FeedbackComplaints from "../../forms/FeedbackComplaints";

function Meditation() {
    const [modal, setModal] = useState(false);
    let navigate = useNavigate();
    let [name, setName] = useState("");
    let [mobile, setMobile] = useState("");
    let [email, setEmail] = useState("");
    let [comments, setComments] = useState("");
    let [nameError, setNameError] = useState(false);
    let [mobileError, setMobileError] = useState(false);
    let [emailError, setEmailError] = useState(false);
    let [commentsError, setCommentsError] = useState(false);
    let [emailValidation, setEmailValidation] = useState(false);
    let [mobileMinValidation, setMobileMinValidation] = useState(false);
    let [mobileMaxValidation, setMobileMaxValidation] = useState(false);
    let [days, setDays] = useState(false);
    let [success, setSuccess] = useState(false);
    let [pauseUserCount, setPauseUserCount] = useState("");
    let [close, setClose] = useState(false);
    let [time, setTime] = useState("");
    let [otp, setOtp] = useState(true)
    let [userMobileNumber, setUserMobileNumber] = useState("")
    let [searchParams, setSearchParams] = useSearchParams();
    const [seconds, setSeconds] = useState(0);
    let [videoLinks, setVideoLinks] = useState("");
    let [day, setDay] = useState("");
    let [diseaseName, setDiseaseName] = useState("");
    let [activityId, setActivityId] = useState("")
    let [videoType, setVideoType] = useState("")

    useEffect(() => {

        let meditation_verify_user = JSON.parse(window.localStorage.getItem('meditation_verify_user'));
        console.log("Verify user >>", meditation_verify_user)
        if (searchParams.get('activityId') != null) {
            window.localStorage.setItem('activityId', JSON.stringify(searchParams.get('activityId')))
            setActivityId(searchParams.get('activityId'))
        }
        else {
            setActivityId(JSON.parse(window.localStorage.getItem('activityId')))
        }
        const activityId = searchParams.get('activityId');

        if (meditation_verify_user != null) {
            console.log("Here")
            setTimeout(() => {
                console.log("user id is here >>", activityId);
                navigate(`${homePageUrl}meditation-video`, {
                    state: { activityId }
                });
            }, 500);
        }
        getUserDetails();
        return () => {
            getUserDetails();
        };
    }, [success]);

    const getUserDetails = async () => {
        let a_id = JSON.parse(window.localStorage.getItem('activityId'));
        console.log("first", a_id)
        const formData = new FormData();
        console.log("  video at >>", getUserDetailById);
        formData.append("id", a_id);
        const user_detail = await axios({
            method: "post",
            url: getUserDetailByActivityId,
            data: formData,
        });

        console.log("response is >>2", user_detail)
        if (user_detail.data.statusCode == 200) {
            window.localStorage.setItem('userId', JSON.stringify(user_detail.data.users.id))
            setPauseUserCount(user_detail.data.users.user_detail);
            setUserMobileNumber(user_detail.data.users.mobile)
            setVideoLinks(user_detail.data.users.video_links);
            setDay(user_detail.data.users.u_day);
            setDiseaseName(user_detail.data.disease_name[0]);
            setVideoType(user_detail.data.users.video_type);
        }
    };

    const hangdleChange = async (e) => {
        console.log("e", e);
        if (e.target.name == "name") {
            setNameError(false);
            setName(e.target.value);
        }
        if (e.target.name == "mobile") {
            if (e.target.value.length > 10) {
                setMobile();
                setMobileMaxValidation(true);
            } else {
                setMobileError(false);
                setMobile(e.target.value);
                setMobileMaxValidation(false);
            }
        }
        if (e.target.name == "email") {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
                console.log("Invalid email");
                setEmailValidation(true);
            } else {
                setEmailError(false);
                setEmail(e.target.value);
                setEmailValidation(false);
                console.log("Valid email");
            }
        }
        if (e.target.name == "comments") {
            setCommentsError(false);
            setComments(e.target.value);
        }
    }

    const storeData = async () => {
        try {
            console.log("name value is >>", name);
            console.log("mobile value is >>", mobile);
            console.log("email value is >>", email);
            console.log("commenst value is >>", comments);
            if (name == "" || name == undefined) {
                setNameError(true);
            } else if (email == "" || email == undefined) {
                setEmailError(true);
            } else if (mobile == "" || mobile == undefined) {
                setMobileError(true);
            } else if (comments == "" || comments == undefined) {
                setCommentsError(true);
            } else {
                const formData = new FormData();
                formData.append("name", name);
                formData.append("mobile", mobile);
                formData.append("email", email);
                formData.append("comments", comments);

                const response = await axios({
                    method: "post",
                    url: storeContactData,
                    data: formData,
                });
                if (response.data.status == 200) {
                    setName("");
                    setEmail("");
                    setMobile("");
                    setComments("");
                    alert("Form sent successfully");
                    setModal(!modal)
                }
            }
        } catch (error) {
            // console.log("error is ", error);
        }
    };

    const toggle = () => {
        setModal(!modal)
    };

    const goToVerifyUser = () => {
        setTimeout(() => {
            console.log("user id is here >>", activityId);
            navigate(`${homePageUrl}user-verification`, {
                state: { activityId }
            });
        }, 500);
    }

    return (
        <div className="main">
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className="align-items-center justify-content-center">
                            <Col xl={7} lg={7} md={12} className="text-center">
                                <div className="bs-heading typ-full-width typ-center">
                                    <h2 className="heading">Today is your Day {day} of {videoType} Program</h2>
                                    <p className="green">Madhavbaug Save My Heart Program</p>
                                    <p className="cm-para mb-40">We will focus on “{videoType}” for your {diseaseName}</p>
                                </div>
                                <div className="bs-img-sprite typ-meditation bg-1 typ-mb-on"></div>
                                <p className="cm-para typ-center">
                                    To View the Video
                                    <span className="block">
                                        Enter your Mobile Number
                                    </span>
                                </p>
                                <Link to="" className="bs-btn typ-mb-on" onClick={() => { goToVerifyUser() }}>
                                    Send OTP
                                </Link>
                                <FeedbackComplaints />
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <Footer />
        </div>
    );
}

export default Meditation;
