// export const homePageUrl = "/clients/madhavbaug-frontend/";

// const baseUrl = "http://127.0.0.1:8000/api/";
const baseUrl = "https://hearthealthmeter.com/score/admin/api/";
// // const baseUrl = "https://uat.hearthealthmeter.com/score/admin/api/";

// export let progressbarValue = 0

// export const homePageUrl = "/"
export const homePageUrl = "/score/";

export const userDetailApi = baseUrl + "getUserDetail/";
export const smsApi = baseUrl + "sendSMS";
export const addUserApi = baseUrl + "addUserData";
export const saveGeneralDetails = baseUrl + "saveGeneralDetails";
export const saveOtpVerfication = baseUrl + "saveOtpVerfication";
export const sendWhatsappApi = baseUrl + "whatapplink";
export const whatsappUser = baseUrl + "getUserDetail/";
export const createOrder = baseUrl + "order";
export const payment = baseUrl + "payment";
export const subscriptionHome = baseUrl + "subscriptionHomeWhatsappLink";
export const getExerciseDataFromApi = baseUrl + "getExerciseData";
export const getMeditationDataFromApi = baseUrl + "getMeditationData";
export const getEducationDataFromApi = baseUrl + "getEducationData";
export const getSingleUserDataFromApi = baseUrl + "getSingleUserDetail";
export const storeContactData = baseUrl + "saveContactUs";
export const PlanDetails = baseUrl + "getPlanDetails";
export const pauseWhatsapp = baseUrl + "pauseUserWhatsapp";
export const getUserDetailById = baseUrl + "getUserDetailById";
export const destoryWhatappsLink = baseUrl + "destoryWhatappsLink/";
export const getUserLatestPlanDetails = baseUrl + "getUserLatestPlanDetails/";
export const videoIsWatches = baseUrl + "videoIsWatches/";
export const addVitalDetails = baseUrl + "addVitalDetails";
export const saveAssessmentData = baseUrl + "saveAssessmentData";
export const getAssessmentLogs = baseUrl + "getAssessmentLogs";
export const getAssessmentDetailsById = baseUrl + "getAssessmentDetailsById";
export const getCoupons = baseUrl + "getCoupons";
export const getUserDetailByActivityId = baseUrl + "getUserDetailByActivityId";
export const getUserDetailByUserId = baseUrl + "getUserDetailsByUserId";

// export const homePageUrl = "/score/";
// export const userDetailApi = "https://hearthealthmeter.com/score/admin/api/getUserDetail/";
// export const smsApi = "https://hearthealthmeter.com/score/admin/api/sendSMS";
// export const addUserApi = "https://hearthealthmeter.com/score/admin/api/addUserData";
// export const saveGeneralDetails = "https://hearthealthmeter.com/score/admin/api/saveGeneralDetails";
// export const saveOtpVerfication = "https://hearthealthmeter.com/score/admin/api/saveOtpVerfication";
// export const sendWhatsappApi = "https://hearthealthmeter.com/score/admin/api/whatapplink";
// export const whatsappUser = "https://hearthealthmeter.com/score/admin/api/getUserDetail/";
// export const createOrder = "https://hearthealthmeter.com/score/admin/api/order";
// export const payment = "https://hearthealthmeter.com/score/admin/api/payment";
// export const subscriptionHome = "https://hearthealthmeter.com/score/admin/api/subscriptionHomeWhatsappLink";
// export const getExerciseDataFromApi = "https://hearthealthmeter.com/score/admin/api/getExerciseData";
// export const getMeditationDataFromApi = "https://hearthealthmeter.com/score/admin/api/getMeditationData";
// export const getEducationDataFromApi = "https://hearthealthmeter.com/score/admin/api/getEducationData";
// export const getSingleUserDataFromApi = "https://hearthealthmeter.com/score/admin/api/getSingleUserDetail;
// export const storeContactData = "https://hearthealthmeter.com/score/admin/api/saveContactUs;
// export const PlanDetails = "https://hearthealthmeter.com/score/admin/api/getPlanDetails;
// export const pauseWhatsapp = "https://hearthealthmeter.com/score/admin/api/pauseUserWhatsapp";
// export const getUserDetailById = "https://hearthealthmeter.com/score/admin/api/getUserDetailById";
// export const destoryWhatappsLink = "https://hearthealthmeter.com/score/admin/api/destoryWhatappsLink/";
// export const getUserLatestPlanDetails = "https://hearthealthmeter.com/score/admin/api/getUserLatestPlanDetails/";
// export const videoIsWatches = "https://hearthealthmeter.com/score/admin/api/videoIsWatches/";
// export const addVitalDetails = "https://hearthealthmeter.com/score/admin/api/addVitalDetails/";
// export const saveAssessmentData = "https://hearthealthmeter.com/score/admin/api/saveAssessmentData/";
// export const getAssessmentLogs = "https://hearthealthmeter.com/score/admin/api/getAssessmentLogs/";
// export const getAssessmentDetailsById = "https://hearthealthmeter.com/score/admin/api/getAssessmentDetailsById/";
// export const getCoupons = "https://hearthealthmeter.com/score/admin/api/getCoupons";
// export const getUserDetailByActivityId = "https://hearthealthmeter.com/score/admin/api/getUserDetailByActivityId";


// export const homePageUrl = "/score/";
// export const userDetailApi = "https://uat.hearthealthmeter.com/score/admin/api/getUserDetail/";
// export const smsApi = "https://uat.hearthealthmeter.com/score/admin/api/sendSMS";
// export const addUserApi = "https://uat.hearthealthmeter.com/score/admin/api/addUserData";
// export const saveGeneralDetails = "https://uat.hearthealthmeter.com/score/admin/api/saveGeneralDetails";
// export const saveOtpVerfication = "https://uat.hearthealthmeter.com/score/admin/api/saveOtpVerfication";
// export const sendWhatsappApi = "https://uat.hearthealthmeter.com/score/admin/api/whatapplink";
// export const whatsappUser = "https://uat.hearthealthmeter.com/score/admin/api/getUserDetail/";
// export const createOrder = "https://uat.hearthealthmeter.com/score/admin/api/order";
// export const payment = "https://uat.hearthealthmeter.com/score/admin/api/payment";
// export const subscriptionHome = "https://uat.hearthealthmeter.com/score/admin/api/subscriptionHomeWhatsappLink";
// export const getExerciseDataFromApi = "https://uat.hearthealthmeter.com/score/admin/api/getExerciseData";
// export const getMeditationDataFromApi = "https://uat.hearthealthmeter.com/score/admin/api/getMeditationData";
// export const getEducationDataFromApi = "https://uat.hearthealthmeter.com/score/admin/api/getEducationData";
// export const getSingleUserDataFromApi = "https://uat.hearthealthmeter.com/score/admin/api/getSingleUserDetail;
// export const storeContactData = "https://uat.hearthealthmeter.com/score/admin/api/saveContactUs;
// export const PlanDetails = "https://uat.hearthealthmeter.com/score/admin/api/getPlanDetails;
// export const pauseWhatsapp = "https://uat.hearthealthmeter.com/score/admin/api/pauseUserWhatsapp";
// export const getUserDetailById = "https://uat.hearthealthmeter.com/score/admin/api/getUserDetailById";
// export const destoryWhatappsLink = "https://uat.hearthealthmeter.com/score/admin/api/destoryWhatappsLink/";
// export const getUserLatestPlanDetails = "https://uat.hearthealthmeter.com/score/admin/api/getUserLatestPlanDetails/";
// export const videoIsWatches = "https://uat.hearthealthmeter.com/score/admin/api/videoIsWatches/";
// export const addVitalDetails = "https://uat.hearthealthmeter.com/score/admin/api/addVitalDetails/";
// export const saveAssessmentData = "https://uat.hearthealthmeter.com/score/admin/api/saveAssessmentData/";
// export const getAssessmentLogs = "https://uat.hearthealthmeter.com/score/admin/api/getAssessmentLogs/";
// export const getCoupons = "https://uat.hearthealthmeter.com/score/admin/api/getCoupons";
// export const getAssessmentDetailsById = "https://uat.hearthealthmeter.com/score/admin/api/getAssessmentDetailsById";
// export const getUserDetailByActivityId = "https://uat.hearthealthmeter.com/score/admin/api/getUserDetailByActivityId";
// export const getUserDetailByUserId = "https://uat.hearthealthmeter.com/score/admin/api/getUserDetailsByUserId";