import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';


// css
import '../../../assets/sass/custom.scss';

// json 
import mySelfLottie from '../../../assets/json/general-detail/my-self.json';
import familyRelativeLottie from '../../../assets/json/general-detail/family-relative.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function GeneralDetails() {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();

    let patient = window.localStorage.getItem('patient')

    const contentData = {
        qId: 3,
        question_text: t('GeneralDetails.contentData.GeneralDetails'),
        descriptions: [t('GeneralDetails.contentData.LetUsGet')]
    }

    const initialFormValues = { relative: "", name: "", mobile: "", emailAdd: "", pincode: "" }

    let [formValues, setFormValues] = useState(initialFormValues)
    let [formErrors, setFormErrors] = useState({})
    let [isSubmit, setIsSubmit] = useState(false)
    let [exitAnim, setExitAnim] = useState(false)
    const [lottiePlayer, setLottiePlayer] = useState("");

    // form field onchange
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value })
    }

    const handlePrev = () => {
        setExitAnim(true)
        navigate(`${homePageUrl}select-patient`)
    }

    //form submit
    const handleSubmit = (e) => {
        e.preventDefault()
        setFormErrors(formValidation(formValues))
        setIsSubmit(true)
    }

    //form validation
    const formValidation = (values) => {
        const errors = {}
        const mobRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/
        const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        const pincodeRegex = /^([0-9]{4}|[0-9]{6})$/
        if (JSON.parse(patient) == "Family/Relative") {
            if (!values.relative) {
                errors.relative = "Relative Name is required"
            }
        }
        if (!values.name) {
            errors.name = t('Messages.GeneralDetails.1')
        }
        if (!values.mobile) {
            errors.mobile = t('Messages.GeneralDetails.2')
        } else if (!mobRegex.test(values.mobile)) {
            errors.mobile = t('Messages.GeneralDetails.3')
        }
        if (!values.emailAdd) {
            errors.emailAdd = t('Messages.GeneralDetails.4')
        } else if (!emailRegex.test(values.emailAdd)) {
            errors.emailAdd = t('Messages.GeneralDetails.5')
        }
        if (!values.pincode) {
            errors.pincode = t('Messages.GeneralDetails.6')
        } else if (!pincodeRegex.test(values.pincode)) {
            errors.pincode = t('Messages.GeneralDetails.7')
        }


        window.localStorage.setItem('name', JSON.stringify(formValues.name))
        window.localStorage.setItem('mobile', JSON.stringify(formValues.mobile))
        window.localStorage.setItem('relative', JSON.stringify(formValues.relative))
        window.localStorage.setItem('email', JSON.stringify(formValues.emailAdd))
        window.localStorage.setItem('pincode', JSON.stringify(formValues.pincode))

        return errors;
    }


    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            // console.log(formValues);
            navigate(`${homePageUrl}general-details2`)
        }
    }, [formErrors]);


    useEffect(() => {
        let patient = JSON.parse(window.localStorage.getItem('patient'))
        
        const name = JSON.parse(window.localStorage.getItem('name'))
        const mobile = JSON.parse(window.localStorage.getItem('mobile'))
        const relative = JSON.parse(window.localStorage.getItem('relative'))
        const emailAdd = JSON.parse(window.localStorage.getItem('email'))
        const pincode = JSON.parse(window.localStorage.getItem('pincode'))
        // console.log(name, mobile, relative)
        setFormValues({
            ...formValues,
            name: name,
            mobile: mobile,
            relative: relative,
            emailAdd: emailAdd,
            pincode: pincode
        })

        if(JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en")
        {
            let lang = "en";
            handleClick(lang);
        }
        else
        {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
        if (patient == 'Myself') {
            setLottiePlayer(mySelfLottie)
        }
        if (patient == 'Family/Relative') {
            setLottiePlayer(familyRelativeLottie)
        }
    }, [])

    const handleClick = (lang) => 
    {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>",lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>",t)
    }

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className='align-items-center'>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                                {/* <pre>{JSON.stringify(formValues, undefined, 2)}</pre> */}
                                <form className="bs-form" onSubmit={handleSubmit}>
                                    {JSON.parse(patient) == "Family/Relative" &&
                                        <FormGroup className='text-box'>
                                            <Label htmlFor="relative">{t('GeneralDetails.RelativeName')}</Label>
                                            <Input type="text" name='relative' value={formValues.relative} autoComplete="off" placeholder={t('GeneralDetails.EnteryourRelative’sFullName')} onChange={handleChange} />
                                            <p className='error-message'>{formErrors.relative}</p>
                                        </FormGroup>
                                    }
                                    <FormGroup className='text-box'>
                                        <Label htmlFor="name">{t('GeneralDetails.Name')}</Label>
                                        <Input type="text" name='name' value={formValues.name} autoComplete="off" placeholder={t('GeneralDetails.EnterYourFullName')} onChange={handleChange} />
                                        <p className='error-message'>{formErrors.name}</p>
                                    </FormGroup>

                                    {JSON.parse(patient) == "Family/Relative" ?
                                        <FormGroup className='text-box'>
                                            <Label htmlFor="mobile">{t('GeneralDetails.EnterRelativeWhatsAppNumber')}</Label>
                                            <Input type="number" name='mobile' value={formValues.mobile} autoComplete="off" placeholder={t('GeneralDetails.EnterRelative10Digit')} onChange={handleChange} />
                                            <p className='error-message'>{formErrors.mobile}</p>
                                        </FormGroup>
                                        :
                                        <FormGroup className='text-box'>
                                            <Label htmlFor="mobile">{t('GeneralDetails.EnterWhatsAppNumber')}</Label>
                                            <Input type="number" name='mobile' value={formValues.mobile} autoComplete="off" placeholder={t('GeneralDetails.Enter10Digit')} onChange={handleChange} />
                                            <p className='error-message'>{formErrors.mobile}</p>
                                        </FormGroup>
                                    }

                                    
                                    <FormGroup className='text-box'>
                                        <Label htmlFor="emailAdd">{t('GeneralDetails.EmailID')}</Label>
                                        <Input type="email" name='emailAdd' value={formValues.emailAdd} autoComplete="off" placeholder={t('GeneralDetails.YourEmail')} onChange={handleChange} />
                                        <p className='error-message'>{formErrors.emailAdd}</p>
                                    </FormGroup>
                                    <FormGroup className='text-box'>
                                        <Label htmlFor="pincode">{t('GeneralDetails.Pincode')}</Label>
                                        <Input type="tel" name='pincode' value={formValues.pincode} autoComplete="off" placeholder={t('GeneralDetails.YourArea')} onChange={handleChange} />
                                        <p className='error-message'>{formErrors.pincode}</p>
                                    </FormGroup>
                                </form>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow submit prevBtn={handlePrev} next={handleSubmit} />
            <Footer />
        </div>
    )
}

export default GeneralDetails;