import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from 'reactstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";

//constant
import { homePageUrl } from "../../../helper/ContentConstants";
// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images

// css
import "../../../assets/sass/custom.scss";

// components
import Footer from "../../layouts/footer/Footer";
import RoutingArrow from "../../shared/RoutingArrow/RoutingArrow";
import { addUserApi } from '../../../helper/ContentConstants';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import axios from 'axios';

function WhatsAppLinkFailure() {

  return (
    <div className="main">
      <Container>
        <AnimationWrapper>
          <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card'>
            <Row className="align-items-center">
              <Col
                xl={{ size: 6, offset: 6 }}
                lg={{ size: 6, offset: 6 }}
                md={12}
                sm={6}
              >
                {isMobile && (
                  <div className="bs-heading typ-center">
                    <h2 className="heading">Video is Destroyed</h2>
                    <p className="subheading">Sorry! We can’t complete the transaction this time. Please return to the merchant and try again.</p>
                  </div>
                )}
              </Col>
              <Col xl="6" lg="6" md="12">
                <div className="bs-img-sprite typ-hafway-done bg-1"></div>
              </Col>
              <Col xl="6" lg="6" md="12">
                {isBrowser && (
                  <div className="bs-heading typ-center">
                    <h2 className="heading">Video is Destroyed</h2>
                    <p className="subheading">Sorry! We can’t complete the transaction this time. Please return to the merchant and try again.</p>
                  </div>
                )}
                <Link to={`${homePageUrl}subscription-home`} className='bs-btn typ-check-score btn btn-secondary'>
                  Return to Dashboard
                </Link>
              </Col>
            </Row>
          </motion.div>
        </AnimationWrapper>
      </Container>
      <Footer />
    </div>
  );
}

export default WhatsAppLinkFailure;
