import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal } from 'reactstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useNavigate, Link ,useLocation } from "react-router-dom";
import { motion } from 'framer-motion';
import ModalVideo from 'react-modal-video';

// Constant ;
import {
    homePageUrl,
    storeContactData,
} from "../../../helper/ContentConstants";
// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images
import VideoThumb from '../../../assets/images/pause-daily-video/video-thumb.jpg';
import YellowBand from '../../../assets/images/pause-daily-video/yellow-band.svg';

// css
import "../../../assets/sass/custom.scss";
import '../../../../node_modules/react-modal-video/scss/modal-video.scss';

// components
import Footer from "../../layouts/footer/Footer";
import RoutingArrow from "../../shared/RoutingArrow/RoutingArrow";
import { addUserApi } from '../../../helper/ContentConstants';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import axios from 'axios';


function PaymentSuccessful(args) {

        const location = useLocation();
        let [userId,setUserId] = useState("");
        let [userPlan,setUserPlan] = useState("");
        let [videoId,setVideoId] = useState("");
        useEffect(() => {
            console.log("params value is >>",location.state);
            if(location.state != null)
            {
                setUserId(location.state.userValue);
                setUserPlan(location.state.plan);
                setTimeout(()=>{
                    if(userPlan == "SMH Basic")
                    {
                        setVideoId('768137860?h=f237f7b2d2')
                    }
                    else
                    {
                        setVideoId('768131982?h=1f84077dca')
                    }
                },1000);
            }
          }, []);
          {
            console.log("User id is >>",userId)
          }

    const [modal, setModal] = useState(false);
    const [isOpen, setOpen] = useState(false);

    let navigate = useNavigate();
    let [name, setName] = useState("");
    let [mobile, setMobile] = useState("");
    let [email, setEmail] = useState("");
    let [comments, setComments] = useState("");
    let [nameError, setNameError] = useState(false);
    let [mobileError, setMobileError] = useState(false);
    let [emailError, setEmailError] = useState(false);
    let [commentsError, setCommentsError] = useState(false);
    let [emailValidation, setEmailValidation] = useState(false);
    let [mobileMinValidation, setMobileMinValidation] = useState(false);
    let [mobileMaxValidation, setMobileMaxValidation] = useState(false);
    let [close, setClose] = useState(false)

    const handlePrevious = () => {
        const noSymptoms = JSON.parse(window.localStorage.getItem('no-above-symptoms'))
        const noDisease = JSON.parse(window.localStorage.getItem('none-of-the-above'))
        const bloodPressure = JSON.parse(window.localStorage.getItem('blood-pressure'))
        const systolicUpperBp = JSON.parse(window.localStorage.getItem('systolic-upper-bp'))
        const diastolicLowerBp = JSON.parse(window.localStorage.getItem('diastolic-lower-bp'))

        if (systolicUpperBp && diastolicLowerBp) { navigate(`${homePageUrl}blood-pressure2`) }
        else if (noSymptoms && noDisease) { navigate(`${homePageUrl}symptoms`) }
        else if (noSymptoms && !noDisease) { navigate(`${homePageUrl}blood-pressure`) }
        else if (bloodPressure === "No") { navigate(`${homePageUrl}blood-pressure`) }
        else navigate(`${homePageUrl}symtom-situations`)
    }
    const handleSubmit = () => {

    }

    return (
        <div className="main">
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className="align-items-center justify-content-center">
                            <Col xl={7} lg={7} md={12} className="text-center">
                                <div className="bs-heading typ-full-width typ-center">
                                    <h2 className="heading">Payment Successful</h2>
                                    <p className="subheading mb-20">
                                        Welcome to Madhavbaug. <br/>
                                        Your payment is completed.</p>
                                    <p className="green">Your {userPlan} is Activated</p>
                                </div>
                                <div className="bs-player typ-popup" onClick={() => setOpen(true)}>
                                    <picture className="w-100">
                                        <source media="(max-width:992px)" srcSet={VideoThumb} />
                                        <img src={VideoThumb} alt="band" />
                                        <span className="icon icon-yt-play"></span>
                                    </picture>
                                </div>
                                <Link className="bs-btn typ-med" to={`${homePageUrl}subscription-home`} state= {{ userId: location.state.userValue,plan: location.state.plan}}>
                                    View Dashboard
                                </Link>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            {/* <RoutingArrow
                submit
                prevBtn={handlePrevious}
                next={handleSubmit}
            /> */}
            <Footer />
            <ModalVideo channel='vimeo' autoplay isOpen={isOpen} videoId={videoId} onClose={() => setOpen(false)} />
        </div>
    );
}

export default PaymentSuccessful;
