import React, { useState, useEffect } from 'react';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';


//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// Import Swiper styles
import 'swiper/css';

// images
import Right from '../../../assets/images/select-language/right.svg';

// css
import '../../../assets/sass/custom.scss';

// json 
// male below 35 
import defaultDiseaseMaleBelow35 from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import happyMaleBelow35 from '../../../assets/json/expression/male/below35/happy.json';
import veryHappyMaleBelow35 from '../../../assets/json/expression/male/below35/very-happy.json';
import sadMaleBelow35 from '../../../assets/json/expression/male/below35/sad.json';
import verySadMaleBelow35 from '../../../assets/json/expression/male/below35/very-sad.json';

// male above 35 
import defaultDiseaseMaleAbove35 from '../../../assets/json/default/male/above35/none-of-the-option-is-selected.json';
import happyMaleAbove35 from '../../../assets/json/expression/male/above35/happy.json';
import veryHappyMaleAbove35 from '../../../assets/json/expression/male/above35/very-happy.json';
import sadMaleAbove35 from '../../../assets/json/expression/male/above35/sad.json';
import verySadMaleAbove35 from '../../../assets/json/expression/male/above35/very-sad.json';

// male above 72 
import defaultDiseaseMaleAbove72 from '../../../assets/json/default/male/above72/none-of-the-option-is-selected.json';
import happyMaleAbove72 from '../../../assets/json/expression/male/above72/happy.json';
import veryHappyMaleAbove72 from '../../../assets/json/expression/male/above72/very-happy.json';
import sadMaleAbove72 from '../../../assets/json/expression/male/above72/sad.json';
import verySadMaleAbove72 from '../../../assets/json/expression/male/above72/very-sad.json';

// // female below 35 
import defaultDiseaseFemaleBelow35 from '../../../assets/json/default/female/below35/none-of-the-option-is-selected.json';
import happyFemaleBelow35 from '../../../assets/json/expression/female/below35/happy.json';
import veryHappyFemaleBelow35 from '../../../assets/json/expression/female/below35/very-happy.json';
import sadFemaleBelow35 from '../../../assets/json/expression/female/below35/sad.json';
import verySadFemaleBelow35 from '../../../assets/json/expression/female/below35/v-sad.json';

// // female above 35 
import defaultDiseaseFemaleAbove35 from '../../../assets/json/default/female/above35/none-of-the-option-is-selected.json';
import happyFemaleAbove35 from '../../../assets/json/expression/female/above35/happy.json';
import veryHappyFemaleAbove35 from '../../../assets/json/expression/female/above35/very-happy.json';
import sadFemaleAbove35 from '../../../assets/json/expression/female/above35/sad.json';
import verySadFemaleAbove35 from '../../../assets/json/expression/female/above35/very-sad.json';

// // female above 72
import defaultDiseaseFemaleAbove72 from '../../../assets/json/default/female/above72/none-of-the-option-is-selected.json';
import happyFemaleAbove72 from '../../../assets/json/expression/female/above72/happy.json';
import veryHappyFemaleAbove72 from '../../../assets/json/expression/female/above72/very-happy.json';
import sadFemaleAbove72 from '../../../assets/json/expression/female/above72/sad.json';
import verySadFemaleAbove72 from '../../../assets/json/expression/female/above72/very-sad.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function MedicationDetails2() {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate()
    let [language, setLanguage] = useState("")
    let [medicineCount, setMedicineCount] = useState('')
    let [regualrMedicine, setRegualrMedicine] = useState('')
    let [errorMsg, setErrorMsg] = useState({ medcount: "", irregular: "" })
    let [events, setEvents] = useState("")
    const [lottiePlayer, setLottiePlayer] = useState("");

    let contentData = {
        qId: 6,
        question_text: t('MedicationDetails2.question_text'),
        descriptions: [t('MedicationDetails2.descriptions')],
        options: [
            {
                qId: 6,
                option_id: 1,
                option_text: t('MedicationDetails2.option_text'),
                imgClass: 'bg-1',
                radio_button: [
                    { rId: 1, radio_text: t('MedicationDetails2.rId1') },
                    { rId: 2, radio_text: t('MedicationDetails2.rId2') },
                    { rId: 3, radio_text: t('MedicationDetails2.rId3') },
                    { rId: 4, radio_text: t('MedicationDetails2.rId4') },
                ]
            }, {
                qId: 6,
                option_id: 2,
                option_text: t('MedicationDetails2.option_text1'),
                imgClass: 'bg-2',
                radio_button: [
                    { rId: 1, radio_text: t('MedicationDetails2.rId11') },
                    { rId: 2, radio_text: t('MedicationDetails2.rId21') }
                ]
            }
        ]
    }

    let expressionData = {
        options: [
            {
                radio_button: [
                    {
                        rId: 1, radio_text: 'Happy', gender: {
                            Male: {
                                default: {
                                    below35: defaultDiseaseMaleBelow35,
                                    above35: defaultDiseaseMaleAbove35,
                                    above72: defaultDiseaseMaleAbove72,
                                },
                                below35: happyMaleBelow35,
                                above35: happyMaleAbove35,
                                above72: happyMaleAbove72
                            },
                            Female: {
                                default: {
                                    below35: defaultDiseaseFemaleBelow35,
                                    above35: defaultDiseaseFemaleAbove35,
                                    above72: defaultDiseaseFemaleAbove72,
                                },
                                below35: happyFemaleBelow35,
                                above35: happyFemaleAbove35,
                                above72: happyFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 2, radio_text: 'Very Happy', gender: {
                            Male: {
                                below35: veryHappyMaleBelow35,
                                above35: veryHappyMaleAbove35,
                                above72: veryHappyMaleAbove72
                            },
                            Female: {
                                below35: veryHappyFemaleBelow35,
                                above35: veryHappyFemaleAbove35,
                                above72: veryHappyFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 3, radio_text: 'Sad', gender: {
                            Male: {
                                below35: sadMaleBelow35,
                                above35: sadMaleAbove35,
                                above72: sadMaleAbove72
                            },
                            Female: {
                                below35: sadFemaleBelow35,
                                above35: sadFemaleAbove35,
                                above72: sadFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 4, radio_text: 'Very Sad', gender: {
                            Male: {
                                below35: verySadMaleBelow35,
                                above35: verySadMaleAbove35,
                                above72: verySadMaleAbove72
                            },
                            Female: {
                                below35: verySadFemaleBelow35,
                                above35: verySadFemaleAbove35,
                                above72: verySadFemaleAbove72
                            }
                        }
                    },
                ]
            }
        ]
    }

    const handleMedicineCount = (key) => {
        medicineCount = contentData.options[0].radio_button[key - 1].radio_text;
        console.log("Medicine count >>",contentData.options[0].radio_button[key - 1].rId);

        const medicineId = contentData.options[0].radio_button[key - 1].rId;
        let value;
        if(medicineId == 1)
        {
            value = "1 Medicine";
        }
        else if(medicineId == 2)
        {
            value = "2 Medicine";
        }
        else if(medicineId == 3)
        {
            value = "3 Medicine";
        }
        else
        {
            value = "4 Medicine & above";
        }
        setMedicineCount(medicineCount)
        window.localStorage.setItem('medicine-count', JSON.stringify(value))
        window.localStorage.setItem('medicine-count1', JSON.stringify(medicineCount))
        setEvents(!events)
    }
    const handleRegularMedicine = (key) => {
        regualrMedicine = contentData.options[1].radio_button[key - 1].radio_text;
        let Id = contentData.options[1].radio_button[key - 1].rId;
        console.log("Id is >>",Id);
        let val;
        if(Id == 1)
        {
            val = "Yes";
        }
        else
        {
            val = "No";
        }
        setRegualrMedicine(regualrMedicine)
        window.localStorage.setItem('irregular-medine', JSON.stringify(val))
        window.localStorage.setItem('irregular-medine1', JSON.stringify(regualrMedicine))
        setEvents(!events)
    }

    const handleSubmit = () => {
        const medicineCount = window.localStorage.getItem('medicine-count')
        const irregualrMedicine = window.localStorage.getItem('irregular-medine')
        if (!medicineCount && irregualrMedicine) {
            setErrorMsg({ medcount: "Choose any one option to continue" })
        }
        else if (medicineCount && !irregualrMedicine) {
            setErrorMsg({ irregular: "Choose any one option to continue" })
        }
        else if (!medicineCount && !irregualrMedicine) {
            setErrorMsg({ medcount: "Choose any one option to continue", irregular: "Choose any one option to continue" })
        } else if (medicineCount && irregualrMedicine) {
            setErrorMsg({ medcount: "", irregular: "" })
            navigate(`${homePageUrl}heart-disease`)
        }
    }

    //get local storage
    useEffect(() => {
        const medicineCount = JSON.parse(window.localStorage.getItem('medicine-count'))
        const irregualrMedicine = JSON.parse(window.localStorage.getItem('irregular-medine'))
        const medicineCount1 = JSON.parse(window.localStorage.getItem('medicine-count1'))
        const irregualrMedicine1 = JSON.parse(window.localStorage.getItem('irregular-medine1'))
       
        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        if (JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en") {
            let lang = "en";
            setMedicineCount(medicineCount)
            setRegualrMedicine(irregualrMedicine)
            handleClick(lang);
        }
        else {
            setMedicineCount(medicineCount1)
            setRegualrMedicine(irregualrMedicine1)
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
        if (medicineCount == null && irregualrMedicine == null) {
            let lottie = expressionData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
        }
    }, [])

    useEffect(() => {
        const medicineCount = JSON.parse(window.localStorage.getItem('medicine-count'))
        const irregualrMedicine = JSON.parse(window.localStorage.getItem('irregular-medine'))
        const medicineCount1 = JSON.parse(window.localStorage.getItem('medicine-count1'))
        const irregualrMedicine1 = JSON.parse(window.localStorage.getItem('irregular-medine1'))
        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        console.log(medicineCount)
        console.log(irregualrMedicine)
        if (medicineCount != null || irregualrMedicine != null) {
            console.log(medicineCount, 'in')
            console.log(irregualrMedicine, 'in')
            if (medicineCount != null && irregualrMedicine == null) {
                let lottie = expressionData.options[0].radio_button[2].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log(lottie, 'sad')
                setLottiePlayer(lottie)
            } else if (medicineCount != null && irregualrMedicine == 'No') {
                let lottie = expressionData.options[0].radio_button[2].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log(lottie, 'sad')
                setLottiePlayer(lottie)
            } else if (medicineCount != null && irregualrMedicine == 'Yes') {
                let lottie = expressionData.options[0].radio_button[3].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
                console.log(lottie, 'v sad')
                setLottiePlayer(lottie)
            }
            
        }


    }, [events])

    const handleClick = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t)
        setLanguage(lang);
    }

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className='align-items-center'>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                                <p className="cm-para typ-medium typ-form-title typ-center typ-mob-left typ-max-wid">
                                    {contentData.options[0].option_text}
                                </p>
                                <div className='bs-form mt-0 mb-30'>
                                    <FormGroup className='radio-box typ-timeline' tag="fieldset">
                                        {
                                            contentData.options[0].radio_button.map((item) => {
                                                return (
                                                    <React.Fragment key={item.rId}>
                                                        <FormGroup className='first-check' check>
                                                            <Label check>
                                                                <Input type="radio"
                                                                    checked={item.radio_text === medicineCount}
                                                                    name="radio1" value={item.radio_text} onChange={() => handleMedicineCount(item.rId)} />{' '}
                                                                <div className='check-box-label'>
                                                                    <div className='circle-box'>
                                                                        <div className='circle'></div>
                                                                    </div>
                                                                    <p className='radio-txt'>
                                                                        {item.radio_text}
                                                                    </p>
                                                                </div>
                                                            </Label>
                                                        </FormGroup>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </FormGroup>
                                    {errorMsg.medcount && <p className='error-message'>{errorMsg.medcount}</p>}
                                </div>
                                <p className="cm-para typ-medium typ-form-title typ-center typ-mob-left typ-max-wid">
                                    {contentData.options[1].option_text}
                                </p>
                                <div className="bs-form mt-0">
                                    <FormGroup className='radio-box typ-gender mb-0' tag="fieldset">
                                        {contentData.options[1].radio_button.map((item) => {
                                            return (
                                                <React.Fragment key={item.rId}>
                                                    <FormGroup className='check-box typ-btn form-check' check>
                                                        <Label check>
                                                            <Input
                                                                checked={item.radio_text === regualrMedicine}
                                                                type="radio" name="radio12" value={item.radio_text} onChange={() => handleRegularMedicine(item.rId)} />{''}
                                                            <div className='radio-label'>{item.radio_text}
                                                                <img src={Right} className='right-icon' alt="Next-arrow" />
                                                            </div>
                                                        </Label>
                                                    </FormGroup>
                                                </React.Fragment>
                                            )
                                        })}
                                        {errorMsg.irregular && <p className='error-message'>{errorMsg.irregular}</p>}
                                    </FormGroup>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prev={`${homePageUrl}medication-details`} submit next={handleSubmit} />
            <Footer />
        </div>
    )
}


export default MedicationDetails2;