import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal, FormGroup, Label, Input, } from 'reactstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useNavigate, Link } from "react-router-dom";
import { motion } from 'framer-motion';

// Constant ;
import {
    homePageUrl,
    storeContactData,
} from "../../../helper/ContentConstants";
// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images

// css
import "../../../assets/sass/custom.scss";

// components
import Footer from "../../layouts/footer/Footer";
import RoutingArrow from "../../shared/RoutingArrow/RoutingArrow";
import { addUserApi } from '../../../helper/ContentConstants';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import axios from 'axios';

// popup 
import FeedbackComplaints from "../../forms/FeedbackComplaints";

function DiseaseEducation() {

    const [modal, setModal] = useState(false);

    let navigate = useNavigate();
    let [name, setName] = useState("");
    let [mobile, setMobile] = useState("");
    let [email, setEmail] = useState("");
    let [comments, setComments] = useState("");
    let [nameError, setNameError] = useState(false);
    let [mobileError, setMobileError] = useState(false);
    let [emailError, setEmailError] = useState(false);
    let [commentsError, setCommentsError] = useState(false);
    let [emailValidation, setEmailValidation] = useState(false);
    let [mobileMinValidation, setMobileMinValidation] = useState(false);
    let [mobileMaxValidation, setMobileMaxValidation] = useState(false);
    let [close, setClose] = useState(false)
    const storeData = async () => {
        try {
            console.log("name value is >>", name);
            console.log("mobile value is >>", mobile);
            console.log("email value is >>", email);
            console.log("commenst value is >>", comments);
            if (name == "" || name == undefined) {
                setNameError(true);
            } else if (email == "" || email == undefined) {
                setEmailError(true);
            } else if (mobile == "" || mobile == undefined) {
                setMobileError(true);
            } else if (comments == "" || comments == undefined) {
                setCommentsError(true);
            } else {
                const formData = new FormData();
                formData.append("name", name);
                formData.append("mobile", mobile);
                formData.append("email", email);
                formData.append("comments", comments);

                const response = await axios({
                    method: "post",
                    url: storeContactData,
                    data: formData,
                });
                if (response.data.status == 200) {
                    setName("");
                    setEmail("");
                    setMobile("");
                    setComments("");
                    alert("Form sent successfully");
                    setModal(!modal)
                }
            }
        } catch (error) {
            // console.log("error is ", error);
        }
    };

    const hangdleChange = async (e) => {
        console.log("e", e);
        if (e.target.name == "name") {
            setNameError(false);
            setName(e.target.value);
        }
        if (e.target.name == "mobile") {
            if (e.target.value.length > 10) {
                setMobile();
                setMobileMaxValidation(true);
            } else {
                setMobileError(false);
                setMobile(e.target.value);
                setMobileMaxValidation(false);
            }
        }
        if (e.target.name == "email") {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
                console.log("Invalid email");
                setEmailValidation(true);
            } else {
                setEmailError(false);
                setEmail(e.target.value);
                setEmailValidation(false);
                console.log("Valid email");
            }
        }
        if (e.target.name == "comments") {
            setCommentsError(false);
            setComments(e.target.value);
        }
    }

    const handlePrevious = () => {
        const noSymptoms = JSON.parse(window.localStorage.getItem('no-above-symptoms'))
        const noDisease = JSON.parse(window.localStorage.getItem('none-of-the-above'))
        const bloodPressure = JSON.parse(window.localStorage.getItem('blood-pressure'))
        const systolicUpperBp = JSON.parse(window.localStorage.getItem('systolic-upper-bp'))
        const diastolicLowerBp = JSON.parse(window.localStorage.getItem('diastolic-lower-bp'))

        if (systolicUpperBp && diastolicLowerBp) { navigate(`${homePageUrl}blood-pressure2`) }
        else if (noSymptoms && noDisease) { navigate(`${homePageUrl}symptoms`) }
        else if (noSymptoms && !noDisease) { navigate(`${homePageUrl}blood-pressure`) }
        else if (bloodPressure === "No") { navigate(`${homePageUrl}blood-pressure`) }
        else navigate(`${homePageUrl}symtom-situations`)
    }
    const handleSubmit = () => {

    }
    const toggle = () => {
        setModal(!modal)
    };

    return (
        <div className="main">
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className="align-items-center justify-content-center">
                            <Col xl={7} lg={7} md={12} className="text-center">
                                <div className="bs-heading typ-full-width typ-center">
                                    <h2 className="heading">Today is your Day 1 of Exercise Program</h2>
                                    <p className="green">Madhavbaug Save My Heart Program</p>
                                    <p className="cm-para mb-40">We will focus on “Disease Education”</p>
                                </div>
                                <div className="bs-img-sprite typ-exersice bg-1 typ-mb-on"></div>
                                <p className="cm-para typ-center">
                                    To View the Video
                                    <span className="block">
                                        Enter your Mobile Number
                                    </span>
                                </p>
                                <Link className="bs-btn typ-mb-on" to={`${homePageUrl}`}>
                                    Send OTP
                                </Link>
                                    
                                    <FeedbackComplaints />
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow
                submit
                prevBtn={handlePrevious}
                next={handleSubmit}
            />
            <Footer />
        </div>
    );
}

export default DiseaseEducation;
