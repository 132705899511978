import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';


//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// Import Swiper styles
import 'swiper/css';

// images
import Right from '../../../assets/images/select-language/right.svg';

// css
import '../../../assets/sass/custom.scss';

// helper 
import handleLottie from '../../../helper/lottieFunction';

// json 

// default 
import defaultLottieMaleBelow35 from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import defaultLottieMaleAbove35 from '../../../assets/json/default/male/above35/none-of-the-option-is-selected.json';
import defaultLottieMaleAbove72 from '../../../assets/json/default/male/above72/none-of-the-option-is-selected.json';
import defaultLottieFemaleBelow35 from '../../../assets/json/default/female/below35/none-of-the-option-is-selected.json';
import defaultLottieFemaleAbove35 from '../../../assets/json/default/female/above35/none-of-the-option-is-selected.json';
import defaultLottieFemaleAbove72 from '../../../assets/json/default/female/above72/none-of-the-option-is-selected.json';

// male below 35 
import veryHappyMaleBelow35 from '../../../assets/json/expression/male/below35/very-happy.json';
import verySadMaleBelow35 from '../../../assets/json/expression/male/below35/very-sad.json';

// male above 35 
import veryHappyMaleAbove35 from '../../../assets/json/expression/male/above35/very-happy.json';
import verySadMaleAbove35 from '../../../assets/json/expression/male/above35/very-sad.json';

// male above 72 
import veryHappyMaleAbove72 from '../../../assets/json/expression/male/above72/very-happy.json';
import verySadMaleAbove72 from '../../../assets/json/expression/male/above72/very-sad.json';

// // female below 35 
import veryHappyFemaleBelow35 from '../../../assets/json/expression/female/below35/very-happy.json';
import verySadFemaleBelow35 from '../../../assets/json/expression/female/below35/v-sad.json';

// // female above 35 
import veryHappyFemaleAbove35 from '../../../assets/json/expression/female/above35/very-happy.json';
import verySadFemaleAbove35 from '../../../assets/json/expression/female/above35/very-sad.json';

// // female above 72
import veryHappyFemaleAbove72 from '../../../assets/json/expression/female/above72/very-happy.json';
import verySadFemaleAbove72 from '../../../assets/json/expression/female/above72/very-sad.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function MedicationDetails() {
    const { t, i18n } = useTranslation();
    let [language, setLanguage] = useState("")

    const [lottiePlayer, setLottiePlayer] = useState("");

    let contentData = {
        qId: 6,
        question_text: t('MedicationDetails.question_text'),
        descriptions: [t('MedicationDetails.descriptions')],
        options: [
            {
                qId: 6,
                option_id: 1,
                option_text: t('MedicationDetails.option_text'),
                imgClass: 'bg-1',
                radio_button: [
                    {
                        rId: 1, radio_text: t('MedicationDetails.rId1'), gender: {
                            Male: {
                                default: {
                                    below35: defaultLottieMaleBelow35,
                                    above35: defaultLottieMaleAbove35,
                                    above72: defaultLottieMaleAbove72,
                                },
                                below35: verySadMaleBelow35,
                                above35: verySadMaleAbove35,
                                above72: verySadMaleAbove72
                            },
                            Female: {
                                default: {
                                    below35: defaultLottieFemaleBelow35,
                                    above35: defaultLottieFemaleAbove35,
                                    above72: defaultLottieFemaleAbove72,
                                },
                                below35: verySadFemaleBelow35,
                                above35: verySadFemaleAbove35,
                                above72: verySadFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 2, radio_text: t('MedicationDetails.rId2'), gender: {
                            Male: {
                                below35: veryHappyMaleBelow35,
                                above35: veryHappyMaleAbove35,
                                above72: veryHappyMaleAbove72
                            },
                            Female: {
                                below35: veryHappyFemaleBelow35,
                                above35: veryHappyFemaleAbove35,
                                above72: veryHappyFemaleAbove72
                            }
                        }
                    }
                ]
            }]
    }

    let navigate = useNavigate()

    let [medicine, setMedicine] = useState('')
    let [errorMsg, setErrorMsg] = useState('')

    const handleRadioBtn = (key) => {
        medicine = contentData.options[0].radio_button[key - 1].radio_text;
        let medicine_data;
        if(key == 1)
        {
            medicine_data = "Yes";
        }
        else
        {   medicine_data = "No";
        }
        console.log("Medicine details >>",medicine)
        setMedicine(medicine)
        window.localStorage.setItem('medicine', JSON.stringify(medicine_data))
        window.localStorage.setItem('medicine1', JSON.stringify(medicine))
        let lottie = handleLottie(key, contentData)
        setLottiePlayer(lottie);
    }

    const handleBack = () => {
        if (JSON.parse(window.localStorage.getItem('assessment')) != null) {
            navigate(`${homePageUrl}disease-details`)
        }
        else {
            navigate(`${homePageUrl}family-history`)
        }
    }

    const handleSubmit = () => {
        const medicine = window.localStorage.getItem('medicine')
        if (!medicine) {
            setErrorMsg(t('Messages.MedicationDetails.1'))
        }
        else if (JSON.parse(medicine) == "Yes") {
            setErrorMsg('')
            navigate(`${homePageUrl}medication-details2`)
        }
        else {
            setErrorMsg('')
            navigate(`${homePageUrl}heart-disease`)
        }
    }

    const handleNavigationLottie = () => {
        let medicine = JSON.parse(window.localStorage.getItem('medicine'))
        let medicine1 = JSON.parse(window.localStorage.getItem('medicine1'))
        setMedicine(medicine)

        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        if (medicine == null) {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            console.log(lottie, 'null?')
            setLottiePlayer(lottie);
        } else {
            
            medicine = medicine.replaceAll('"', '')
            medicine1 = medicine1.replaceAll('"', '')
            if(JSON.parse(window.localStorage.getItem('lang')) == "en")
            {
                let id = contentData.options[0].radio_button.find((rep) => rep.radio_text == medicine).rId
                console.log(id, 'not null?')
                handleRadioBtn(id)
            }
            else
            {
                let id = contentData.options[0].radio_button.find((rep) => rep.radio_text == medicine1).rId
                console.log(id, 'not null?')
                handleRadioBtn(id)
            }
           
        }
    }

    //get local storage
    useEffect(() => {
        if (JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en") {
            let lang = "en";
            handleClick(lang);
        }
        else {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
        handleNavigationLottie()
    }, [])

    const handleClick = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t);
        setLanguage(lang);
    }


    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className='align-items-center'>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                                <p className="cm-para typ-medium typ-center typ-form-title typ-mob-left typ-max-wid">
                                    {contentData.options[0].option_text}
                                </p>
                                <div className="bs-form mt-0">
                                    <FormGroup className='radio-box typ-gender' tag="fieldset">
                                        {
                                            contentData.options[0].radio_button.map((item) => {
                                                return (
                                                    <React.Fragment key={item.rId}>

                                                        <FormGroup className='check-box typ-btn form-check' check>
                                                            <Label check>
                                                                <Input
                                                                    checked={item.radio_text === medicine}
                                                                    type="radio"
                                                                    name="medicine"
                                                                    value={item.radio_text} onChange={() => handleRadioBtn(item.rId)} />{''}
                                                                <div className='radio-label'>{item.radio_text}
                                                                    <img src={Right} className='right-icon' alt="Next-arrow" />
                                                                </div>
                                                            </Label>
                                                        </FormGroup>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                        {errorMsg && <p className='error-message'>{errorMsg}</p>}
                                    </FormGroup>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prevBtn={handleBack} submit next={handleSubmit} />
            {/* <RoutingArrow prev={`${homePageUrl}family-history`} submit next={handleSubmit} /> */}
            <Footer />
        </div>
    )
}


export default MedicationDetails;