import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

// images
import Right from '../../../assets/images/select-language/right.svg';

// css
import '../../../assets/sass/custom.scss';

// helper 
import handleLottie from '../../../helper/lottieFunction';

// json 
import defaultThink from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import maleBelow35 from '../../../assets/json/gender/male/below35/hello.json';
import maleAbove35 from '../../../assets/json/gender/male/above35/hello.json';
import maleAbove72 from '../../../assets/json/gender/male/above72/hello.json';
import femaleBelow35 from '../../../assets/json/gender/female/below35/hello.json';
import femaleAbove35 from '../../../assets/json/gender/female/above35/hello.json';
import femaleAbove72 from '../../../assets/json/gender/female/above72/hello.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import axios from 'axios';

import { saveGeneralDetails } from '../../../helper/ContentConstants';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';


function GeneralDetails2() {
    const { t, i18n } = useTranslation();
    let [language, setLanguage] = useState("")
    const navigate = useNavigate()

    const contentData = {
        qId: 4,
        question_text: t('GeneralDetails2.question_text'),
        descriptions: [t('GeneralDetails2.descriptions')],
        options: [
            {
                qId: 4,
                option_id: 1,
                option_text: t('GeneralDetails2.option_text1'),
                icon_name: 'icon-male',
                imgClass: 'male',
                lottieData: {
                    below35: maleBelow35,
                    above35: maleAbove35,
                    above72: maleAbove72,
                }
            }, {
                qId: 4,
                option_id: 2,
                option_text: t('GeneralDetails2.option_text2'),
                icon_name: 'icon-female',
                imgClass: 'female',
                lottieData: {
                    below35: femaleBelow35,
                    above35: femaleAbove35,
                    above72: femaleAbove72,
                }
            }
        ]
    }

    const initialValues = { spriteImg: "bg-1", userAge: 25, gender: "" }
    let [contentValues, setContentValues] = useState(initialValues)
    let [errorMsg, setErrorMsg] = useState('')
    const [lottiePlayer, setLottiePlayer] = useState("");


    //Radio button class change for gender
    const handleRadioBtn = (key) => {
        console.log("Key value is >>", key);
        contentValues.spriteImg = contentData.options[key - 1].imgClass
        contentValues.gender = contentData.options[key - 1].option_text
        console.log("Gender value is >>", contentValues.gender)
        let gender;
        if (key == 1) {
            gender = "Male"
        }
        else {
            gender = "Female"
        }
        setContentValues({
            spriteImg: contentValues.spriteImg,
            userAge: contentValues.userAge,
            gender: contentValues.gender
        })
        window.localStorage.setItem('gender', JSON.stringify(gender))
        window.localStorage.setItem('gender1', JSON.stringify(contentValues.gender))
        setErrorMsg('')

        const age = window.localStorage.getItem('age')
        let lottie = contentData.options[key - 1].lottieData[age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
        setLottiePlayer(lottie)
    }

    // Slider age loop
    const createElements = (number) => {
        var elements = [];
        let i;
        for (i = 18; i < number; i++) {
            elements.push(<SwiperSlide key={i} index={i}>{i}</SwiperSlide>)
        }
        return elements;
    }

    //Swiper slide change for age 
    const handleChange = (event) => {
        let activeIndex = event.realIndex
        contentValues.userAge = activeIndex + 1
        setContentValues({ userAge: contentValues.userAge, gender: contentValues.gender })
        window.localStorage.setItem('age', JSON.stringify(contentValues.userAge + 17))

        const age = contentValues.userAge + 17
        const gender = window.localStorage.getItem('gender')
        const gender1 = window.localStorage.getItem('gender1')
        if (gender != null) {
            if (JSON.parse(window.localStorage.getItem('lang')) == "en") {
                let lottie = contentData.options.find(value => value.option_text == gender.replaceAll('"', '')).lottieData[age <= 34 ? 'below35' : age >= 35 && age <= 59 ? 'above35' : 'above72']
                // console.log(lottie)
                setLottiePlayer(lottie)
            }
            else {
                let lottie = contentData.options.find(value => value.option_text == gender1.replaceAll('"', '')).lottieData[age <= 34 ? 'below35' : age >= 35 && age <= 59 ? 'above35' : 'above72']
                // console.log(lottie)
                setLottiePlayer(lottie)
            }

        } else {
            setLottiePlayer(defaultThink)
        }
    }

    const SaveGeneralDetail = async () => {
        const allKeys = JSON.parse(JSON.stringify(window.localStorage));
        // const lastid = window.localStorage.getItem("lastID");
        // const uniqueid = window.localStorage.getItem("uniqueId");
        console.log("All keys >>", allKeys);

        try {
            const response = await axios({
                method: "post",
                url: saveGeneralDetails,
                data: allKeys,
            });
            console.log("Response is >>", response);
            if (response.data.statusCode == 200) {
                console.log(response.data)
                window.localStorage.setItem('uniqueId', response.data.uniqueId)
                window.localStorage.setItem('lastID', response.data.lastID)
                // let uid = response.data.uniqueId;
                // sendWhatsapp(uid);
                navigate(`${homePageUrl}sleep-hours`)
            }
        } catch (error) {
            toastr.options = {
                positionClass: 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 2000
            }
            toastr.clear()
            setTimeout(() => toastr.error('Something Went Wrong'), 200)
        }
    }

    const handleSubmit = () => {
        const gender = window.localStorage.getItem('gender')
        if (gender) {
            setErrorMsg('')

            SaveGeneralDetail();
        }
        else {
            setErrorMsg(t('Messages.GeneralDetails2.1'))
        }
    }

    let [bgClass, setBgClass] = useState("bg-1")

    useEffect(() => {
        const age = window.localStorage.getItem('age')
        // console.log(age)
        if (age > 35 && age <= 70) {
            setBgClass(`bg-2`)
        }
        else if (age >= 70) {
            setBgClass(`bg-3`)
        }
        else {
            setBgClass(`bg-1`)
        }
    })

    useEffect(() => {
        const age = JSON.parse(window.localStorage.getItem('age'))
        const gender = JSON.parse(window.localStorage.getItem('gender'))
        const gender1 = JSON.parse(window.localStorage.getItem('gender1'))
        if (JSON.parse(window.localStorage.getItem('lang')) == "en") {
            setContentValues({ ...contentValues, age: age - 17, gender: gender })
        }
        else {
            setContentValues({ ...contentValues, age: age - 17, gender: gender1 })
        }

        if (JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en") {
            let lang = "en";
            handleClick(lang);
        }
        else {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }

        if (gender == null) {
            setLottiePlayer(defaultThink)
        }

    }, [])

    const handleClick = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t)
        setLanguage(lang);

    }



    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                                <div className="bs-form">
                                    <p class="cm-para typ-medium typ-form-title typ-mob-left">{t('GeneralDetails2.SelectGender')}</p>
                                    <FormGroup className='radio-box typ-gender' tag="fieldset">
                                        {contentData.options.map((item) => {
                                            return (
                                                <React.Fragment key={item.option_id}>
                                                    <FormGroup className='check-box typ-btn form-check' check>
                                                        <Label check>
                                                            <Input type="radio" name="radio1"
                                                                checked={contentValues.gender == item.option_text}
                                                                onChange={() => handleRadioBtn(item.option_id)}
                                                                value={item.option_text} />
                                                            <div className='radio-label'><span className={`icon ${item.icon_name}`}></span>{item.option_text}
                                                                <img src={Right} className='right-icon' alt="Next-arrow" />
                                                            </div>
                                                        </Label>
                                                    </FormGroup>
                                                </React.Fragment>
                                            )
                                        })}
                                        {errorMsg && <p className='error-message'>{errorMsg}</p>}
                                    </FormGroup>
                                    <div className='bs-swiper typ-age'>
                                        <p className='slider-title'>
                                            {t('GeneralDetails2.SelectAge')}
                                        </p>
                                        <div className='swiper-parent'>
                                            <Swiper initialSlide={JSON.parse(window.localStorage.getItem('age')) === null ? 40 - 18 : JSON.parse(window.localStorage.getItem('age')) - 18}
                                                className='age-swiper' spaceBetween={0} grabCursor={false} slidesPerView={7} slideToClickedSlide={true} centeredSlides='true'
                                                breakpoints={{
                                                    767: {
                                                        slidesPerView: 5,
                                                    },
                                                    992: {
                                                        slidesPerView: 5,
                                                    },
                                                    1024: {
                                                        slidesPerView: 5,
                                                    },
                                                    1366: {
                                                        slidesPerView: 9,
                                                    },
                                                }}
                                                mousewheel={true}
                                                onSlideChange={e => handleChange(e)}
                                            // onSwiper={getAge}
                                            >
                                                {createElements(101)}
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow submit prev={`${homePageUrl}general-details`} next={handleSubmit} />
            <Footer />
        </div>
    )
}


export default GeneralDetails2;