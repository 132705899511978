import React, { useState, useEffect } from 'react';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// Import Swiper styles
import 'swiper/css';

// helper 
import handleLottie from '../../../helper/lottieFunction';

// images
import Right from '../../../assets/images/select-language/right.svg';

// css
import '../../../assets/sass/custom.scss';

// json 

// default 
import defaultLottieMaleBelow35 from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import defaultLottieMaleAbove35 from '../../../assets/json/default/male/above35/none-of-the-option-is-selected.json';
import defaultLottieMaleAbove72 from '../../../assets/json/default/male/above72/none-of-the-option-is-selected.json';
import defaultLottieFemaleBelow35 from '../../../assets/json/default/female/below35/none-of-the-option-is-selected.json';
import defaultLottieFemaleAbove35 from '../../../assets/json/default/female/above35/none-of-the-option-is-selected.json';
import defaultLottieFemaleAbove72 from '../../../assets/json/default/female/above72/none-of-the-option-is-selected.json';

// male below 35 
import veryOftenMaleBelow35 from '../../../assets/json/stress/male/below35/mood-laps-very-often.json';
import occasionallyMaleBelow35 from '../../../assets/json/stress/male/below35/very-sad.json';
import neverMaleBelow35 from '../../../assets/json/stress/male/below35/very-happy.json';

// male above 35 
import veryOftenMaleAbove35 from '../../../assets/json/stress/male/above35/mood-laps.json';
import occasionallyMaleAbove35 from '../../../assets/json/stress/male/above35/very-sad.json';
import neverMaleAbove35 from '../../../assets/json/stress/male/above35/very-happy.json';

// male above 72 
import veryOftenMaleAbove72 from '../../../assets/json/stress/male/above72/mood-laps.json';
import occasionallyMaleAbove72 from '../../../assets/json/stress/male/above72/very-sad.json';
import neverMaleAbove72 from '../../../assets/json/stress/male/above72/very-happy.json';

// // female below 35 
import veryOftenFemaleBelow35 from '../../../assets/json/stress/female/below35/mood-laps-very-often.json';
import occasionallyFemaleBelow35 from '../../../assets/json/stress/female/below35/v-sad.json';
import neverFemaleBelow35 from '../../../assets/json/stress/female/below35/very-happy.json';

// // female above 35 
import veryOftenFemaleAbove35 from '../../../assets/json/stress/female/above35/mood-laps-very-often.json';
import occasionallyFemaleAbove35 from '../../../assets/json/stress/female/above35/very-sad.json';
import neverFemaleAbove35 from '../../../assets/json/stress/female/above35/very-happy.json';

// // female above 72
import veryOftenFemaleAbove72 from '../../../assets/json/stress/female/above72/mood-laps-very-often.json';
import occasionallyFemaleAbove72 from '../../../assets/json/stress/female/above72/very-sad.json';
import neverFemaleAbove72 from '../../../assets/json/stress/female/above72/very-happy.json';

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function Stress() {
    const { t, i18n } = useTranslation();

    let navigate = useNavigate()

    let [stress, setStress] = useState('')

    let [errorMsg, setErrorMsg] = useState({ stress: "" })
    let [language, setLanguage] = useState("")
    const [lottiePlayer, setLottiePlayer] = useState("");

    let contentData = {
        qId: 6,
        question_text: t('Stress.question_text'),
        descriptions: t('Stress.descriptions'),
        options: [
            {
                qId: 6,
                option_id: 1,
                option_text: t('Stress.option_text'),
                imgClass: 'bg-1',
                radio_button: [
                    {
                        rId: 1, radio_text: t('Stress.rId1'), gender: {
                            Male: {
                                default: {
                                    below35: defaultLottieMaleBelow35,
                                    above35: defaultLottieMaleAbove35,
                                    above72: defaultLottieMaleAbove72,
                                },
                                below35: veryOftenMaleBelow35,
                                above35: veryOftenMaleAbove35,
                                above72: veryOftenMaleAbove72
                            },
                            Female: {
                                default: {
                                    below35: defaultLottieFemaleBelow35,
                                    above35: defaultLottieFemaleAbove35,
                                    above72: defaultLottieFemaleAbove72,
                                },
                                below35: veryOftenFemaleBelow35,
                                above35: veryOftenFemaleAbove35,
                                above72: veryOftenFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 2, radio_text: t('Stress.rId2'), gender: {
                            Male: {
                                below35: occasionallyMaleBelow35,
                                above35: occasionallyMaleAbove35,
                                above72: occasionallyMaleAbove72
                            },
                            Female: {
                                below35: occasionallyFemaleBelow35,
                                above35: occasionallyFemaleAbove35,
                                above72: occasionallyFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 3, radio_text: t('Stress.rId3'), gender: {
                            Male: {
                                below35: neverMaleBelow35,
                                above35: neverMaleAbove35,
                                above72: neverMaleAbove72
                            },
                            Female: {
                                below35: neverFemaleBelow35,
                                above35: neverFemaleAbove35,
                                above72: neverFemaleAbove72
                            }
                        }
                    }
                ]
            }
        ]
    }

    const handleTobacco = (e, key) => {

        let ht;
        if (key == 1) {
            ht = "Very Often";
        }
        else if (key == 2) {
            ht = "Occasionally";
        }
        else if (key == 3) {
            ht = "Never"
        }
        stress = contentData.options[0].radio_button[key - 1].radio_text;
        setStress(stress)
        setErrorMsg({ stress: "" })
        window.localStorage.setItem('stress', JSON.stringify(ht))
        window.localStorage.setItem('stress1', JSON.stringify(stress))
        let lottie = handleLottie(key, contentData)
        setLottiePlayer(lottie);
    }

    const handleSubmit = () => {
        const stress = JSON.parse(window.localStorage.getItem('stress'))
        if (stress) {
            setErrorMsg({ stress: "" })
            navigate(`${homePageUrl}waist-size`)
        }
        else {
            setErrorMsg({ stress: t('Messages.Stress.1') })
        }

    }

    const handleNavigationLottie = () => {
        let stress = window.localStorage.getItem('stress')
        let stress1 = window.localStorage.getItem('stress1')
        setStress(JSON.parse(stress))

        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        if (stress == null) {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
        } else {
            stress = stress.replaceAll('"', '')
            stress1 = stress1.replaceAll('"', '')
            if (JSON.parse(window.localStorage.getItem('lang')) == "en") {
                let id = contentData.options[0].radio_button.find((rep) => rep.radio_text == stress).rId
                handleTobacco(1, id)
            }
            else {
                let id = contentData.options[0].radio_button.find((rep) => rep.radio_text == stress1).rId
                handleTobacco(1, id)
            }

        }
    }


    useEffect(() => {
        if (JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en") {
            let lang = "en";
            handleClick(lang);
        }
        else {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
        handleNavigationLottie()
    }, [])
    const handleClick = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t)
        setLanguage(lang);
    }

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className='align-items-center'>
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {contentData.question_text}
                                        </h2>
                                        <p className="subheading">
                                            {contentData.descriptions}
                                        </p>
                                    </div>
                                }
                                <p className="cm-para typ-medium typ-center typ-form-title typ-mob-left typ-max-wid">
                                    {contentData.options[0].option_text}
                                </p>
                                <div>
                                    <div className="bs-form mt-0">
                                        <FormGroup className='radio-box typ-timeline' tag="fieldset">
                                            {
                                                contentData.options[0].radio_button.map((item) => {
                                                    return (
                                                        <React.Fragment key={item.rId}>
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input type="radio"
                                                                        checked={item.radio_text === stress}
                                                                        name="alcohol" value={item.radio_text} onChange={(e) => handleTobacco(e, item.rId)} />
                                                                    <div className='check-box-label'>
                                                                        <div className='circle-box'>
                                                                            <div className='circle'></div>
                                                                        </div>
                                                                        <p className='radio-txt'>
                                                                            {item.radio_text}
                                                                        </p>
                                                                    </div>
                                                                </Label>
                                                            </FormGroup>
                                                        </React.Fragment>
                                                    )
                                                })}
                                        </FormGroup>
                                        {errorMsg.stress && <p className='error-message'>{errorMsg.stress}</p>}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prev={`${homePageUrl}alcohol`} submit next={handleSubmit} />
            <Footer />
        </div>
    )
}


export default Stress;