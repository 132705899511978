import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { calcLength, motion } from 'framer-motion';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { isBrowser, isMobile } from 'react-device-detect';

//constant
import { homePageUrl } from '../../../helper/ContentConstants';// animation
import AnimationWrapper from '../../shared/animation-wrapper/AnimationWrapper';

// images

// css
import '../../../assets/sass/custom.scss';

// json 
// male below 35 
import defaultDiseaseMaleBelow35 from '../../../assets/json/default/male/below35/none-of-the-option-is-selected.json';
import happyMaleBelow35 from '../../../assets/json/expression/male/below35/happy.json';
import veryHappyMaleBelow35 from '../../../assets/json/expression/male/below35/very-happy.json';
import sadMaleBelow35 from '../../../assets/json/expression/male/below35/sad.json';
import verySadMaleBelow35 from '../../../assets/json/expression/male/below35/very-sad.json';

// male above 35 
import defaultDiseaseMaleAbove35 from '../../../assets/json/default/male/above35/none-of-the-option-is-selected.json';
import happyMaleAbove35 from '../../../assets/json/expression/male/above35/happy.json';
import veryHappyMaleAbove35 from '../../../assets/json/expression/male/above35/very-happy.json';
import sadMaleAbove35 from '../../../assets/json/expression/male/above35/sad.json';
import verySadMaleAbove35 from '../../../assets/json/expression/male/above35/very-sad.json';

// male above 72 
import defaultDiseaseMaleAbove72 from '../../../assets/json/default/male/above72/none-of-the-option-is-selected.json';
import happyMaleAbove72 from '../../../assets/json/expression/male/above72/happy.json';
import veryHappyMaleAbove72 from '../../../assets/json/expression/male/above72/very-happy.json';
import sadMaleAbove72 from '../../../assets/json/expression/male/above72/sad.json';
import verySadMaleAbove72 from '../../../assets/json/expression/male/above72/very-sad.json';

// // female below 35 
import defaultDiseaseFemaleBelow35 from '../../../assets/json/default/female/below35/none-of-the-option-is-selected.json';
import happyFemaleBelow35 from '../../../assets/json/expression/female/below35/happy.json';
import veryHappyFemaleBelow35 from '../../../assets/json/expression/female/below35/very-happy.json';
import sadFemaleBelow35 from '../../../assets/json/expression/female/below35/sad.json';
import verySadFemaleBelow35 from '../../../assets/json/expression/female/below35/v-sad.json';

// // female above 35 
import defaultDiseaseFemaleAbove35 from '../../../assets/json/default/female/above35/none-of-the-option-is-selected.json';
import happyFemaleAbove35 from '../../../assets/json/expression/female/above35/happy.json';
import veryHappyFemaleAbove35 from '../../../assets/json/expression/female/above35/very-happy.json';
import sadFemaleAbove35 from '../../../assets/json/expression/female/above35/sad.json';
import verySadFemaleAbove35 from '../../../assets/json/expression/female/above35/very-sad.json';

// // female above 72
import defaultDiseaseFemaleAbove72 from '../../../assets/json/default/female/above72/none-of-the-option-is-selected.json';
import happyFemaleAbove72 from '../../../assets/json/expression/female/above72/happy.json';
import veryHappyFemaleAbove72 from '../../../assets/json/expression/female/above72/very-happy.json';
import sadFemaleAbove72 from '../../../assets/json/expression/female/above72/sad.json';
import verySadFemaleAbove72 from '../../../assets/json/expression/female/above72/very-sad.json';

// import RangeSlider from '../../shared/RangeSlider/RangeSlider';
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

// components
import Footer from '../../layouts/footer/Footer';
import RoutingArrow from '../../shared/RoutingArrow/RoutingArrow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
function SugarValues() {
    const { t, i18n } = useTranslation();

    let navigate = useNavigate()

    let [fastingSugar, setFastingSugar] = useState(90);
    let [postFastingSugar, setPostFastingSugar] = useState(130)
    let [hbac1, setHbac1] = useState(4.5)
    let [errorMsg, setErrorMsg] = useState({ fastingSugar: "", postFastingSugar: "", hbac1: "" })
    let [events, setEvents] = useState("")
    const [lottiePlayer, setLottiePlayer] = useState("");

    const handle_var = document.getElementsByClassName('rangeslider__handle-label');

    let contentData = {
        options: [
            {
                radio_button: [
                    {
                        rId: 1, radio_text: 'Happy', gender: {
                            Male: {
                                default: {
                                    below35: defaultDiseaseMaleBelow35,
                                    above35: defaultDiseaseMaleAbove35,
                                    above72: defaultDiseaseMaleAbove72,
                                },
                                below35: happyMaleBelow35,
                                above35: happyMaleAbove35,
                                above72: happyMaleAbove72
                            },
                            Female: {
                                default: {
                                    below35: defaultDiseaseFemaleBelow35,
                                    above35: defaultDiseaseFemaleAbove35,
                                    above72: defaultDiseaseFemaleAbove72,
                                },
                                below35: happyFemaleBelow35,
                                above35: happyFemaleAbove35,
                                above72: happyFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 2, radio_text: 'Very Happy', gender: {
                            Male: {
                                below35: veryHappyMaleBelow35,
                                above35: veryHappyMaleAbove35,
                                above72: veryHappyMaleAbove72
                            },
                            Female: {
                                below35: veryHappyFemaleBelow35,
                                above35: veryHappyFemaleAbove35,
                                above72: veryHappyFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 3, radio_text: 'Sad', gender: {
                            Male: {
                                below35: sadMaleBelow35,
                                above35: sadMaleAbove35,
                                above72: sadMaleAbove72
                            },
                            Female: {
                                below35: sadFemaleBelow35,
                                above35: sadFemaleAbove35,
                                above72: sadFemaleAbove72
                            }
                        }
                    },
                    {
                        rId: 4, radio_text: 'Very Sad', gender: {
                            Male: {
                                below35: verySadMaleBelow35,
                                above35: verySadMaleAbove35,
                                above72: verySadMaleAbove72
                            },
                            Female: {
                                below35: verySadFemaleBelow35,
                                above35: verySadFemaleAbove35,
                                above72: verySadFemaleAbove72
                            }
                        }
                    },
                ]
            }
        ]
    }

    const handleFastingSugar = (value) => {
        let fs = value
        fastingSugar = fs
        setFastingSugar(fastingSugar)
        setErrorMsg({ ...errorMsg, fastingSugar: "" })
        setEvents(!events)
        window.localStorage.setItem('fasting-sugar', JSON.stringify(fastingSugar))
        handle_var[0].innerHTML = fastingSugar
    }
    const handlePostFastingSugar = (value) => {
        let pfs = value
        postFastingSugar = pfs
        setPostFastingSugar(postFastingSugar)
        setErrorMsg({ ...errorMsg, postFastingSugar: "" })
        setEvents(!events)
        window.localStorage.setItem('post-fasting-sugar', JSON.stringify(postFastingSugar))
        handle_var[1].innerHTML = postFastingSugar
    }
    const handleHbac1 = (value) => {
        let hc1 = value
        hbac1 = Math.round(hc1 * 10) / 10
        setHbac1(hbac1)
        setErrorMsg({ ...errorMsg, hbac1: "" })
        setEvents(!events)
        window.localStorage.setItem('hbac1', JSON.stringify(hbac1))
        handle_var[2].innerHTML = hbac1
    }

    const handleSubmit = () => {
        const fastingSugar = window.localStorage.getItem('fasting-sugar')
        const postFastingSugar = window.localStorage.getItem('post-fasting-sugar')
        const hbac1 = window.localStorage.getItem('hbac1')
        if (!fastingSugar && !postFastingSugar && !hbac1) {
            setErrorMsg({ ...errorMsg, fastingSugar: t('Messages.SugarValues2.1'), postFastingSugar: t('Messages.SugarValues2.2'), hbac1: t('Messages.SugarValues2.3') })
        } else if (fastingSugar && postFastingSugar && hbac1) {
            navigate(`${homePageUrl}cholesterol`)
        } else if (!fastingSugar || JSON.parse(fastingSugar) === null || JSON.parse(fastingSugar) === undefined) {
            setErrorMsg({ ...errorMsg, fastingSugar: t('Messages.SugarValues2.1') })
        } else if (!postFastingSugar || JSON.parse(postFastingSugar) === null || JSON.parse(postFastingSugar) === undefined) {
            setErrorMsg({ ...errorMsg, postFastingSugar: t('Messages.SugarValues2.2') })
        } else if (!hbac1 || JSON.parse(hbac1) === null || JSON.parse(hbac1) === undefined) {
            setErrorMsg({ ...errorMsg, hbac1: t('Messages.SugarValues2.3') })
        }
    }

    useEffect(() => {
        const fastingSugar = JSON.parse(window.localStorage.getItem('fasting-sugar'))
        const postFastingSugar = JSON.parse(window.localStorage.getItem('post-fasting-sugar'))
        const hbac1 = JSON.parse(window.localStorage.getItem('hbac1'))
        setFastingSugar(fastingSugar)
        setPostFastingSugar(postFastingSugar)
        setHbac1(hbac1)
        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        let sadData = [(fastingSugar >= 40 && fastingSugar <= 69), (postFastingSugar >= 40 && postFastingSugar <= 69), (hbac1 >= 0 && hbac1 <= 2.9), (fastingSugar >= 101 && fastingSugar <= 125), (postFastingSugar >= 141 && postFastingSugar <= 160), (hbac1 >= 5.8 && hbac1 <= 6.5)]
        let vSadData = [(fastingSugar >= 126 && fastingSugar <= 400), (postFastingSugar >= 161 && postFastingSugar <= 440), (hbac1 >= 6.6 && hbac1 <= 20)]

        let sadCount = sadData.filter(rep => rep == true).length
        let vSadCount = vSadData.filter(rep => rep == true).length

        if (JSON.parse(window.localStorage.getItem('lang')) == "null" || JSON.parse(window.localStorage.getItem('lang')) == "en") {
            let lang = "en";
            handleClick(lang);
        }
        else {
            handleClick(JSON.parse(window.localStorage.getItem('lang')));
        }
        if (fastingSugar == null && postFastingSugar == null && hbac1 == null) {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
        } else if (sadCount == 0 && vSadCount == 0) {
            let lottie = contentData.options[0].radio_button[0].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            console.log(lottie, 'happy')
            setLottiePlayer(lottie)
        } else if ((sadCount >= 1) && (vSadCount == 0)) {
            let lottie = contentData.options[0].radio_button[2].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            console.log(lottie, 'sad')
            setLottiePlayer(lottie)
        } else if (vSadCount >= 1) {
            let lottie = contentData.options[0].radio_button[3].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            console.log(lottie, 'v sad')
            setLottiePlayer(lottie)
        }
        console.log(fastingSugar)
        console.log(postFastingSugar)
        console.log(hbac1)
    }, [])

    useEffect(() => {
        const fastingSugar = window.localStorage.getItem('fasting-sugar')
        const postFastingSugar = window.localStorage.getItem('post-fasting-sugar')
        const hbac1 = window.localStorage.getItem('hbac1')
        let gender = localStorage.getItem('gender').replaceAll('"', '');
        let age = localStorage.getItem('age')
        let sadData = [(fastingSugar >= 40 && fastingSugar <= 69), (postFastingSugar >= 40 && postFastingSugar <= 69), (hbac1 >= 0 && hbac1 <= 2.9), (fastingSugar >= 101 && fastingSugar <= 125), (postFastingSugar >= 141 && postFastingSugar <= 160), (hbac1 >= 5.8 && hbac1 <= 6.5)]
        let vSadData = [(fastingSugar >= 126 && fastingSugar <= 400), (postFastingSugar >= 161 && postFastingSugar <= 440), (hbac1 >= 6.6 && hbac1 <= 20)]

        let sadCount = sadData.filter(rep => rep == true).length
        let vSadCount = vSadData.filter(rep => rep == true).length
        console.log(sadCount, 'sad')
        console.log(vSadCount, 'v sad')

        if (fastingSugar == null && postFastingSugar == null && hbac1 == null) {
            let lottie = contentData.options[0].radio_button[0].gender[gender]['default'][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            setLottiePlayer(lottie);
            console.log('default')
        } else if (sadCount == 0 && vSadCount == 0) {
            let lottie = contentData.options[0].radio_button[0].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            console.log(lottie, 'happy')
            setLottiePlayer(lottie)
        } else if ((sadCount >= 1) && (vSadCount == 0)) {
            let lottie = contentData.options[0].radio_button[2].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            console.log(lottie, 'sad')
            setLottiePlayer(lottie)
        } else if (vSadCount >= 1) {
            let lottie = contentData.options[0].radio_button[3].gender[gender][age <= 34 ? 'below35' : age > 34 && age <= 59 ? 'above35' : 'above72']
            console.log(lottie, 'v sad')
            setLottiePlayer(lottie)
        }
        console.log(fastingSugar)
        console.log(postFastingSugar)
        console.log(hbac1)
    }, [events])

    const handleClick = (lang) => {
        window.localStorage.setItem('lang', JSON.stringify(lang))
        console.log("Language is >>", lang)
        i18next.changeLanguage(lang)
        console.log("t value is >>", t)
    }

    return (
        <div className='main'>
            <Container>
                <AnimationWrapper>
                    <motion.div exit={{ x: "-100%", opacity: 0 }} className='bs-card typ-pl-on'>
                        <Row className="align-items-center">
                            <Col xl={{ size: 6, offset: 6 }} lg={{ size: 6, offset: 6 }} md={12} sm={6}>
                                {
                                    isMobile &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('SugarValues2.heading')}
                                        </h2>
                                        <p className="subheading">
                                            {t('SugarValues2.subheading')}
                                        </p>
                                    </div>
                                }
                            </Col>
                            <Col className='bs-lottifiles typ-animation' xl="6" lg="6" md="12">
                                <Player
                                    src={lottiePlayer}
                                    autoplay
                                ></Player>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                {
                                    isBrowser &&
                                    <div className="bs-heading typ-center">
                                        <h2 className="heading">
                                            {t('SugarValues2.heading')}
                                        </h2>
                                        <p className="subheading">
                                            {t('SugarValues2.subheading')}
                                        </p>
                                    </div>
                                }
                                <div className="bs-form typ-range">
                                    <p className="cm-para typ-small typ-black mb-10">
                                        {t('SugarValues2.SelectFasting')}
                                    </p>
                                    <Slider
                                        value={JSON.parse(window.localStorage.getItem('fasting-sugar')) == null ? 90 : fastingSugar}
                                        orientation="horizontal"
                                        onChange={handleFastingSugar}
                                        min={40}
                                        max={300}
                                        tooltip={false}
                                        labels={{ 0: '40 mg/dl', 100: '300+ mg/dl' }}
                                        handleLabel={JSON.parse(window.localStorage.getItem('fasting-sugar')) == null ? 90 : fastingSugar}
                                    />
                                </div>
                                {errorMsg.fastingSugar && <p className='error-message'>{errorMsg.fastingSugar}</p>}
                                <div className="bs-form typ-range">
                                    <p className="cm-para typ-small typ-black mb-10">
                                        {t('SugarValues2.SelectPost')}
                                    </p>
                                    <Slider
                                        value={JSON.parse(window.localStorage.getItem('post-fasting-sugar')) == null ? 130 : postFastingSugar}
                                        orientation="horizontal"
                                        onChange={handlePostFastingSugar}
                                        min={40}
                                        max={300}
                                        tooltip={false}
                                        labels={{ 0: '40 mg/dl', 100: '300+ mg/dl' }}
                                        handleLabel={JSON.parse(window.localStorage.getItem('post-fasting-sugar')) == null ? 130 : postFastingSugar}
                                    />
                                </div>
                                {errorMsg.postFastingSugar && <p className='error-message'>{errorMsg.postFastingSugar}</p>}
                                <div className="bs-form typ-range">
                                    <p className="cm-para typ-small typ-black mb-10">
                                        {t('SugarValues2.SelectHBA1C')}
                                    </p>
                                    <Slider
                                        value={JSON.parse(window.localStorage.getItem('hbac1')) == null ? 4.5 : hbac1}
                                        step={0.1}
                                        orientation="horizontal"
                                        onChange={handleHbac1}
                                        min={3.0}
                                        max={20.0}
                                        tooltip={false}
                                        labels={{ 0: '3 mmol/L', 100: '20 mmol/L' }}
                                        handleLabel={JSON.parse(window.localStorage.getItem('hbac1')) == null ? 4.5 : Math.round(hbac1 * 10) / 10}
                                    />
                                </div>
                                {errorMsg.hbac1 && <p className='error-message'>{errorMsg.hbac1}</p>}
                            </Col>
                        </Row>
                    </motion.div>
                </AnimationWrapper>
            </Container>
            <RoutingArrow prev={`${homePageUrl}sugar-values`} submit next={handleSubmit} />
            <Footer />
        </div>
    );
}

export default SugarValues;